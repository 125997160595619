import React from 'react';
import { Modal, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { CounterParty, ROUTES_UPDATE_LEGALENTITY } from '../../utils/constants';
import { useDispatch } from 'react-redux';
import { setLegalEntity } from '../../store/entities/legalentity';
import { useHistory } from 'react-router-dom';
import Paragraph from 'antd/lib/typography/Paragraph';

const { Title } = Typography;

const UpdateKeyDataModal = ({ isVisible, setIsVisible, legalEntity }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const dispatch = useDispatch();
  const history = useHistory();

  const handleOk = () => {
    dispatch(setLegalEntity(legalEntity));
    history.push(
      `/${language}/kyc/${ROUTES_UPDATE_LEGALENTITY}/legalEntity/${legalEntity?.id}/counterParty/${CounterParty.BVD}`
    );
  };

  return (
    <Modal
      visible={isVisible}
      onOk={handleOk}
      onCancel={() => setIsVisible(false)}
      okText={t('legalentity.updateKeyData.btnGet')}
      cancelText={t('global.btn.cancel')}
      width={600}
      title={t('legalentity.updateKeyData.caption')}
    >
      <Row>
        <Paragraph>
          {t('legalentity.updateKeyData.missingReqData').replace(
            '{legalEntityName}',
            legalEntity?.cI_Name
          )}
        </Paragraph>
      </Row>
      <Row>
        <Title level={4}>{t('legalentity.updateKeyData.title')}</Title>
      </Row>
      <Row>
        <ul>
          <li>{t('company.data.country')}</li>
          <li>{t('company.data.name')}</li>
          <li>{t('company.data.bvdid')}</li>
        </ul>
      </Row>
      <Row>
        <Title level={4}>{t('legalentity.updateKeyData.titleMissing')}</Title>
      </Row>
      <Row>
        <ul>
          <li>{t('company.data.bvdid')}</li>
        </ul>
      </Row>
    </Modal>
  );
};

export default UpdateKeyDataModal;
