import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import EditTranslationForm from '../components/translations/EditTranslationForm';
import AddTranslationForm from '../components/translations/AddTranslationForm';
import TranslationsTable from '../components/translations/TranslationsTable';

const TranslationEditor = () => {
  const [addTranslationVisible, setAddTranslationVisible] = useState(false);
  const activeTranslation = useSelector(
    state => state.translation.activeTranslation
  );

  return (
    <>
      <TranslationsTable toggleAddForm={setAddTranslationVisible} />
      {activeTranslation && <EditTranslationForm />}
      <AddTranslationForm
        visible={addTranslationVisible}
        setVisible={setAddTranslationVisible}
      />
    </>
  );
};

export default TranslationEditor;
