import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../../api';
import { clientBackend } from '../../../api-config';

const slice = createSlice({
  name: 'languageCode',
  initialState: {
    listAll: [],
    listAllLoading: false,
    listAllError: false,
    listUsed: [],
    listUsedLoading: false,
    listUsedError: false,
  },
  reducers: {
    languageCodesAllRequested: state => {
      state.listAllLoading = true;
      state.listAllError = false;
    },
    languageCodesAllRequestFailed: state => {
      state.listAllLoading = false;
      state.listAllError = true;
    },
    languageCodesAllReceived: (state, { payload }) => {
      state.listAll = payload.data.getlanguagecodes;
      state.listAllLoading = false;
      state.listAllError = false;
    },
    languageCodesUsedRequested: state => {
      state.listUsedLoading = true;
      state.listUsedError = false;
    },
    languageCodesUsedRequestFailed: state => {
      state.listUsedLoading = false;
      state.listUsedError = true;
    },
    languageCodesUsedReceived: (state, { payload }) => {
      state.listUsed = payload.data.getlanguagecodes;
      state.listUsedLoading = false;
      state.listUsedError = false;
    },
  },
});

const {
  languageCodesAllRequested,
  languageCodesAllReceived,
  languageCodesAllRequestFailed,
  languageCodesUsedRequested,
  languageCodesUsedReceived,
  languageCodesUsedRequestFailed,
} = slice.actions;

export const fetchLanguageCodes = onlyUsed =>
  apiCallBegan({
    url: `${clientBackend}/translations`,
    data: JSON.stringify({
      query: `{getlanguagecodes(onlyUsed: ${onlyUsed})}`,
    }),
    onStart: onlyUsed
      ? languageCodesUsedRequested.type
      : languageCodesAllRequested.type,
    onSuccess: onlyUsed
      ? languageCodesUsedReceived.type
      : languageCodesAllReceived.type,
    onFailure: onlyUsed
      ? languageCodesUsedRequestFailed.type
      : languageCodesAllRequestFailed.type,
  });

export default slice.reducer;
