import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { Layout, Menu, Row, Col, Dropdown, Button } from 'antd';
import {
  DownOutlined,
  LogoutOutlined,
  LeftOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

import { getMember, logout, logoutUser } from '../../store/user';
import {
  showErrorNotification,
  showInfoNotification,
} from '../../utils/showNotification';

import Breadcrumbs from './Breadcrumbs';
import LanguageSelector from './LanguageSelector';

const { Header: LayoutHeader } = Layout;

const Header = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { user } = useSelector(state => state.user);

  const handleLogout = async () => {
    try {
      await dispatch(logoutUser());
      dispatch(logout(language));
      history.push(`/${language}`);
    } catch (err) {
      showErrorNotification(t('logout.failed'));
    }
  };

  const handleChangeUser = async () => {
    try {
      const membersResponse = await dispatch(getMember());
      const {
        data: { getMembersForCurrentUser },
      } = unwrapResult(membersResponse);

      if (getMembersForCurrentUser.length === 1) {
        try {
          showInfoNotification(t('general.chooseMember.onlyOne'));
        } catch (chooseMemberError) {
          console.info(chooseMemberError);
          showErrorNotification(t('general.chooseMember.error'));
        }
      }
    } catch (getMembersError) {
      console.info(getMembersError);
      showErrorNotification(t('general.getMembers.error'));
    }
  };

  const handleMenuClick = ({ key }) => {
    key === 'logout' ? handleLogout() : handleChangeUser();
  };

  return (
    <LayoutHeader>
      <Row justify="space-between">
        <Col className="breadcrumb-container">
          {pathname.split('/').length > 4 && (
            <Button
              icon={<LeftOutlined />}
              type="link"
              className="ant-btn--back"
              onClick={history.goBack}
            />
          )}
          <Breadcrumbs />
        </Col>
        <Col>
          <Menu mode="horizontal" theme="light">
            {user ? (
              <Menu.Item>
                <Dropdown
                  overlay={
                    <Menu onClick={handleMenuClick}>
                      <Menu.Item key="changeRole">
                        <UserSwitchOutlined /> {t('nav.header.user.changeRole')}
                      </Menu.Item>
                      <Menu.Item key="logout">
                        <LogoutOutlined /> {t('nav.header.user.logOut')}
                      </Menu.Item>
                    </Menu>
                  }
                  placement="bottomCenter"
                >
                  <Button type="link">
                    {user?.Name} <DownOutlined />
                  </Button>
                </Dropdown>
              </Menu.Item>
            ) : null}
            <Menu.Item>
              <LanguageSelector />
            </Menu.Item>
          </Menu>
        </Col>
      </Row>
    </LayoutHeader>
  );
};

export default Header;
