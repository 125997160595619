import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Row, Col, Input, Card, Button, Space, Select, Tooltip } from 'antd';
import { SearchOutlined, UndoOutlined } from '@ant-design/icons';
import {
  fetchCountriesWithSearchConnectors,
  fetchOnboardingListBVD,
  fetchOnboardingListLTU,
  fetchOnboardingListLVA,
  fetchOnboardingListCI,
  fetchOnboardingListPP,
  fetchOnboardingListUcab,
  fetchOnboardingListAsiakastieto,
} from '../../store/entities/onboarding/queries';
import {
  setOnboardingCountryId,
  setOnboardingDatasource,
  setShowList,
} from '../../store/entities/onboarding';

import { CounterParty, SearchConnectorNames } from '../../utils/constants';

const { Option } = Select;

const initialSearchFields = {
  country: null,
  legalName: null,
  regCode: null,
};

const OnboardingSearch = ({
  legalEntityBeingUpdated,
  counterPartyToChoose,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    onboardingCountries,
    onboardingCountriesLoading,
    onboardingDatasource,
    onboardingListLoading,
  } = useSelector(state => state.onboarding);

  const [availableDataSources, setAvailableDataSources] = useState([]);
  const [searchFields, setSearchFields] = useState(initialSearchFields);
  const { country, legalName, regCode } = searchFields;

  useEffect(() => {
    if (!onboardingCountries.length)
      dispatch(fetchCountriesWithSearchConnectors());
  }, [dispatch, onboardingCountries.length]);

  useEffect(() => {
    if (legalEntityBeingUpdated && counterPartyToChoose) {
      const leFields = {
        country: legalEntityBeingUpdated.countryCodeISOAlpha3,
        legalName: legalEntityBeingUpdated.cI_Name,
        regCode: legalEntityBeingUpdated.registerCode,
      };
      setSearchFields(leFields);
      dispatch(setShowList(false));
      setDataSourceOptionsForCountry(leFields.country);
      dispatch(setOnboardingCountryId(leFields.country));

      dispatch(setOnboardingDatasource(parseInt(counterPartyToChoose)));
    } else if (country) {
      setSearchFields({ ...initialSearchFields, country });
      dispatch(setOnboardingDatasource(null));
      dispatch(setShowList(false));
      setDataSourceOptionsForCountry(country);
      dispatch(setOnboardingCountryId(country));
    }

    return () => clearFields();

    // we need onboardingCountries.length so that counterPartyToChoose is applied to a loaded list
  }, [country, onboardingCountries.length]);

  const setDataSourceOptionsForCountry = country => {
    if (country === 'allCountries') {
      setAvailableDataSources([
        {
          productName: SearchConnectorNames[CounterParty.BVD],
          counterPartyId: CounterParty.BVD,
        },
      ]);
      return;
    }

    const newAvailableDataSources = [];
    const onboardingCountry = onboardingCountries.find(
      x => x.countryCodeISOAlpha3 === country
    );

    if (onboardingCountry) {
      onboardingCountry.counterpartyConnectorCountries.forEach(source => {
        newAvailableDataSources.push(source.counterpartyConnector);
      });

      newAvailableDataSources.sort(
        (a, b) => a.sequenceNumber - b.sequenceNumber
      );

      setAvailableDataSources(newAvailableDataSources);
    }
  };

  const handleSearch = e => {
    e.preventDefault();

    const countryCode =
      country === 'allCountries'
        ? ''
        : onboardingCountries.find(x => x.countryCodeISOAlpha3 === country)
            .countryCodeISOAlpha3;

    switch (onboardingDatasource) {
      case CounterParty.PP:
        dispatch(fetchOnboardingListPP(legalName, countryCode));
        break;
      case CounterParty.BVD:
        dispatch(fetchOnboardingListBVD(legalName, countryCode));
        break;
      case CounterParty.CI:
        const searchValue = regCode
          ? { value: `${regCode}` }
          : { likeValue: legalName };
        dispatch(fetchOnboardingListCI(searchValue));
        break;
      case CounterParty.LTU:
        dispatch(fetchOnboardingListLTU(legalName, `${regCode}`));
        break;
      case CounterParty.LVA:
        dispatch(fetchOnboardingListLVA(legalName));
        break;
      case CounterParty.ASIAKASTIETO:
        dispatch(fetchOnboardingListAsiakastieto(legalName, `${regCode}`));
        break;
      case CounterParty.UCAB:
        dispatch(fetchOnboardingListUcab(legalName));
        break;
      default:
    }
  };

  const clearFields = () => {
    dispatch(setOnboardingDatasource(null));
    dispatch(setShowList(false));
    setSearchFields(initialSearchFields);
  };

  const handleCountrySelect = country => {
    if (country !== searchFields.country) clearFields();
    setSearchFields({
      ...searchFields,
      country,
    });
  };

  const isWithRegcodeAndName =
    onboardingDatasource === CounterParty.CI ||
    onboardingDatasource === CounterParty.LTU ||
    onboardingDatasource === CounterParty.ASIAKASTIETO;
  const isCIOrNotOnboadingDatasource =
    !onboardingDatasource || isWithRegcodeAndName;

  return (
    <Card className="card-simple space-vertical-lg has-shadow">
      <form onSubmit={handleSearch}>
        <Row gutter={[16, 16]} align="bottom" justify="center">
          <Col
            xs={24}
            md={24}
            lg={isCIOrNotOnboadingDatasource ? 5 : 7}
            xl={isCIOrNotOnboadingDatasource ? 4 : 6}
          >
            <div className="ant-col ant-form-item-label">
              <label>{t('onboarding.country')}</label>
            </div>
            <Select
              showSearch
              disabled={onboardingCountriesLoading || onboardingListLoading}
              style={{ width: '100%', marginBottom: 0 }}
              optionFilterProp="children"
              onChange={handleCountrySelect}
              value={country}
              loading={onboardingCountriesLoading}
            >
              {onboardingCountries.some(c =>
                c.counterpartyConnectorCountries.some(
                  ccc =>
                    ccc.counterpartyConnector.counterPartyId ===
                    CounterParty.BVD
                )
              ) ? (
                <Option value="allCountries">{t('global.country.all')}</Option>
              ) : null}
              {onboardingCountries.length
                ? onboardingCountries.map(({ countryCodeISOAlpha3 }) => (
                    <Option
                      key={countryCodeISOAlpha3}
                      value={countryCodeISOAlpha3}
                    >
                      {t(`global.country.${countryCodeISOAlpha3}`)}
                    </Option>
                  ))
                : null}
            </Select>
          </Col>
          <Col
            xs={24}
            md={24}
            lg={isCIOrNotOnboadingDatasource ? 5 : 7}
            xl={isCIOrNotOnboadingDatasource ? 4 : 6}
          >
            <div className="ant-col ant-form-item-label">
              <label>{t('onboarding.table.search.source')}</label>
            </div>
            <Select
              style={{ width: '100%', marginBottom: 0 }}
              optionFilterProp="children"
              disabled={onboardingListLoading || !country}
              onChange={value => dispatch(setOnboardingDatasource(value))}
              value={onboardingDatasource}
            >
              {availableDataSources.map(({ counterPartyId, productName }) => (
                <Option key={counterPartyId} value={counterPartyId}>
                  {t(productName)}
                </Option>
              ))}
            </Select>
          </Col>
          <Col
            xs={24}
            md={isCIOrNotOnboadingDatasource ? 11 : 25}
            lg={isCIOrNotOnboadingDatasource ? 7 : 9}
            xl={isCIOrNotOnboadingDatasource ? 6 : 8}
          >
            <div className="ant-col ant-form-item-label">
              <label>{t('onboarding.table.search.name')}</label>
            </div>
            <Input
              name="name"
              disabled={
                !country ||
                !onboardingDatasource ||
                (country &&
                  onboardingDatasource &&
                  regCode &&
                  isWithRegcodeAndName) ||
                onboardingListLoading
              }
              value={legalName}
              onChange={evt => {
                setSearchFields({
                  ...searchFields,
                  legalName: evt.target.value,
                });
              }}
            />
          </Col>
          {isCIOrNotOnboadingDatasource && (
            <>
              <Col xs={24} md={2} lg={1}>
                <label className="horizontal-label">
                  {t('onboarding.table.search.or')}
                </label>
              </Col>
              <Col xs={24} md={11} lg={6} xl={5}>
                <div className="ant-col ant-form-item-label">
                  <label>{t('onboarding.table.search.regCode')}</label>
                </div>
                <Input
                  name="regCode"
                  disabled={
                    !country ||
                    !onboardingDatasource ||
                    (country && onboardingDatasource && legalName) ||
                    onboardingListLoading
                  }
                  value={regCode}
                  onChange={evt =>
                    setSearchFields({
                      ...searchFields,
                      regCode: evt.target.value,
                    })
                  }
                />
              </Col>
            </>
          )}
          <Col xs={24} md={24} lg={24} xl={4}>
            <Space>
              <Tooltip title={t('service.management.filters.clear.tooltip')}>
                <Button
                  type="link"
                  onClick={clearFields}
                  icon={<UndoOutlined />}
                />
              </Tooltip>
              <Button
                type="primary"
                htmlType="submit"
                disabled={(!legalName && !regCode) || onboardingListLoading}
                icon={<SearchOutlined />}
              >
                {t('service.management.filters.button.search')}
              </Button>
            </Space>
          </Col>
        </Row>
      </form>
    </Card>
  );
};

export default OnboardingSearch;
