import React from 'react';
import { Upload, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { UploadOutlined } from '@ant-design/icons';

import { showErrorNotification } from '../../../utils/showNotification';

const SourceUpload = ({ setFile }) => {
  const { t } = useTranslation();

  const uploadProps = {
    name: 'file',
    showUploadList: false,
    beforeUpload(file) {
      const isBelow5MB = file.size / 1024 / 5120 < 1;
      if (!isBelow5MB)
        showErrorNotification(t('questionnaire.manual.modal.file.to.big'));
      return isBelow5MB;
    },
    onChange(info) {
      if (info.file.status === 'done') {
        const reader = new FileReader();
        reader.addEventListener('load', () =>
          setFile({ base64: reader.result.split(',')[1], name: info.file.name })
        );
        reader.readAsDataURL(info.file.originFileObj);
      }
    },
    customRequest({ onSuccess }) {
      setTimeout(() => {
        onSuccess('ok');
      }, 0);
    },
  };

  return (
    <Upload {...uploadProps}>
      <Button>
        <UploadOutlined /> {t('questionnaire.manual.modal.upload')}
      </Button>
    </Upload>
  );
};

export default SourceUpload;
