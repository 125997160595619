import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import * as gql from 'gql-query-builder';
import axios from 'axios';
import { clientBackend } from './api-config';

const translationsQuery = language =>
  gql.query({
    operation: 'translations',
    variables: { tLanguage: language },
    fields: ['tKey', 'tValue'],
  });

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    backend: {
      loadPath: `{{lng}}`,
      parse(data) {
        const translations = {};
        data.forEach(el => {
          translations[el.tKey] = el.tValue;
        });
        return translations;
      },
      request: (_options, url, callback) => {
        axios
          .post(`${clientBackend}/translations`, translationsQuery(url))
          .then(({ data }) => {
            callback(null, { status: 200, data: data.data.translations });
          })
          .catch(err => {
            callback(err);
          });
      },
    },
    fallbackLng: 'ee',
    interpolation: {
      escapeValue: false,
    },
    keySeparator: false,
    whitelist: ['ee', 'us', 'ru', 'lv', 'is', 'lt'],
    detection: {
      order: ['path', 'localstorage'],
      checkWhitelist: true,
    },
  });

i18n.on('languageChanged', lng => {
  const { location, history } = window;
  const { pathname, search } = location;
  const languageString = pathname.split('/')[1];
  if (languageString.length !== 2) {
    window.location.pathname = `/${lng}`;
  } else {
    const replacementPath = pathname.slice(3);
    history.pushState(null, '', `/${lng}${replacementPath}${search}`);
  }
});

export default i18n;

const t = i18n.t.bind(i18n);

export { t };
