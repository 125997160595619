import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import axios from 'axios';

import Routes from './Routes';
import LocaleProvider from './LocaleProvider';
import AppLayout from './views/Layout';

import './assets/scss/main.scss';
import { Spin, Layout as AntLayout } from 'antd';
import store from './store';
import i18n from './i18n.js';
import { logout } from './store/user';
import { showErrorNotification } from './utils/showNotification';
import CookieConsent from './components/cookieconsent/CookieConsent';
import ReactGA from 'react-ga4';

window.i18n = i18n;
window.store = store;
ReactGA.initialize('G-CKKH1HWZHT');
ReactGA.send('pageview');

ReactDOM.render(
  <Provider store={store}>
    <CookieConsent />
    <Suspense
      fallback={
        <Spin size="large" spinning={true}>
          <AntLayout style={{ height: '100vh' }} />
        </Spin>
      }
    >
      <LocaleProvider>
        <Router>
          <AppLayout>
            <Routes />
          </AppLayout>
        </Router>
      </LocaleProvider>
    </Suspense>
  </Provider>,
  document.getElementById('root')
);

const { dispatch, getState } = store;

axios.interceptors.response.use(
  response => response,
  error => {
    const status = error.response?.status;
    if (status === 401) {
      if (getState().user.user?.Name)
        showErrorNotification(i18n.t('user.session.timed.out'));
      dispatch(logout());
    } else {
      return Promise.reject(error);
    }
  }
);
