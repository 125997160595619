import React, { useState, useEffect, useRef } from 'react';
import { Input, Form, Row, Col, DatePicker, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { camelCase, debounce } from 'lodash';
import moment from 'moment';

import {
  fetchUsers,
  publishService,
} from '../../store/entities/createService/serviceQueries';
import {
  usersListEmpty,
  setMovingToPreviousStep,
} from '../../store/entities/createService';
import {
  deleteServiceDomain,
  fetchDomainDBs,
} from '../../store/entities/createService/thunks';
import {
  DATE_FORMAT,
  disabledDates,
  getDomainIndex,
} from '../../utils/createServiceHelpers';
import { scrollToRef } from '../../utils';
import {
  showErrorNotification,
  showSuccessNotification,
  showWarningNotification,
} from '../../utils/showNotification';

import { confirmationModal } from './ConfirmationModal';
import DomainTags from './DomainTags';

const { TextArea } = Input;
const { Option } = Select;

const initialValues = {
  domains: [],
  users: [],
};

const NewServiceForm = ({ form, statusId, setStatusId, onChange }) => {
  const dispatch = useDispatch();
  const {
    status,
    usersList,
    usersListLoading,
    serviceDeleted,
    serviceId,
    logoString,
    serviceActionError,
    serviceActionSuccess,
    domainsData,
    savedDataBlocks,
    movingToPreviousStep,
    formStep,
    formType,
    savedFormData,
  } = useSelector(state => state.createService);
  const { resources } = useSelector(state => state.resources);
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const history = useHistory();

  const [userListValue, setUserListValue] = useState([]);
  const [domainsList, setDomainsList] = useState([]);
  const domainsRef = useRef();

  useEffect(() => {
    if (domainsData.length) {
      const chosenDomains = domainsData.map(({ id }) => id);
      setDomainsList(chosenDomains);
    }
  }, []);

  useEffect(() => {
    if (domainsData.length && savedDataBlocks.length) {
      const chosenDomains = domainsData.map(({ id }) => id);
      setDomainsList(chosenDomains);
    }
  }, [domainsData, savedDataBlocks.length]);

  useEffect(() => {
    if (serviceActionError) {
      showErrorNotification(t('new.service.service.error'));
    }
    if (serviceActionSuccess) {
      showSuccessNotification(
        t(`new.service.${status === 1 ? 'saved' : 'published'}`)
      );
    }
  }, [serviceActionError, serviceActionSuccess, status, t]);

  useEffect(() => {
    if (serviceDeleted) {
      showSuccessNotification(t('new.service.deleted'));
      history.push(`/${language}/admin/service`);
    }
  }, [serviceDeleted, history, t, language]);

  useEffect(() => {
    if (domainsRef.current.first) return;
    if (domainsData.length === 0) domainsRef.current.first = false;
    if (
      domainsData.length === 1 &&
      formType !== 'edit' &&
      !movingToPreviousStep
    ) {
      scrollToRef(domainsRef);
      const refElement = domainsRef.current;
      domainsRef.current = { elem: refElement, first: true };
    }
  }, [domainsData, formType, movingToPreviousStep]);

  useEffect(() => {
    return () => form.resetFields();
  }, [formStep, form]);

  const serviceHasSelectedDataBlocks = domainsData.some(
    domain => domain.chosenBlocks.length
  );
  const domainsForForm = savedFormData.serviceDomains
    ? savedFormData.serviceDomains.map(domain => domain.domain.id)
    : savedFormData.domains;

  const onFormFinish = data => {
    if (formStep === 1) return;
    const domains =
      data.domains &&
      data.domains.length &&
      data.domains[0].hasOwnProperty('id')
        ? data.domains.map(({ id }) => id)
        : domainsList;
    const values = {
      ...data,
      validTo: data.validTo && data.validTo.format(),
      validFrom: data.validFrom && data.validFrom.format(),
      id: serviceId || null,
      statusId: status === 2 ? status : statusId,
      logo: logoString,
      users: data.users.map(user => parseInt(user.value)),
      domains,
    };
    const isFirstSave = !serviceId;
    const submitService = () => {
      setStatusId(2);
      dispatch(publishService(values, isFirstSave));
    };
    const cancelSubmit = () => setStatusId(1);

    if (statusId === 1) {
      dispatch(publishService(values, isFirstSave));
      return;
    }

    if (!serviceHasSelectedDataBlocks) {
      showWarningNotification(t('new.service.publish.error.no.dataBlocks'));
      setStatusId(1);
      return;
    }

    form
      .validateFields()
      .then(() => {
        confirmationModal('.submit', t, submitService, cancelSubmit);
      })
      .catch(() => setStatusId(1));
  };

  const dispatchFetchUsers = name => {
    if (name === '') return;

    dispatch(fetchUsers(name));
  };

  const handleUsersChange = value => {
    setUserListValue(value);
    dispatch(usersListEmpty());
  };

  const handleDomainChange = async (domainId, checked) => {
    if (movingToPreviousStep) dispatch(setMovingToPreviousStep(false));

    if (checked) {
      const result = await dispatch(fetchDomainDBs(domainId));

      if (fetchDomainDBs.fulfilled.match(result)) {
        const domains = domainsList.concat(domainId);
        setDomainsList(prevState => [...prevState, domainId]);
        form.setFieldsValue({ domains });

        !serviceId && form.submit();
        return;
      } else {
        showErrorNotification(t('new.service.domain.select.error'));
      }
    }

    const domainIndex = getDomainIndex(domainsData, domainId);

    const removeDomain = async () => {
      const result = await dispatch(
        deleteServiceDomain({
          serviceDomainId: domainsData[domainIndex].serviceDomainId,
          domainId,
        })
      );

      if (deleteServiceDomain.fulfilled.match(result)) {
        const newList = domainsList.filter(id => id !== domainId);
        setDomainsList(newList);
        form.setFieldsValue({ domains: newList });
      } else {
        showErrorNotification(t('new.service.domain.error'));
      }
    };

    if (
      domainsData[domainIndex] &&
      !domainsData[domainIndex].changedTitle &&
      domainsData[domainIndex].chosenBlocks.length === 0
    ) {
      removeDomain();
      return;
    }

    if (domainIndex === -1) return true;

    confirmationModal('.remove.domain', t, removeDomain, () => {});
  };

  const handleCountryChange = countries => {
    if (countries.includes('all')) {
      const countriesList = resources.countries.map(
        ({ countryCodeISOAlpha3 }) => countryCodeISOAlpha3
      );
      form.setFieldsValue({ countries: countriesList });
    }
  };

  return (
    <div className="new-service-block has-shadow">
      <div className="container space-vertical-lg">
        <Form
          form={form}
          hideRequiredMark={true}
          layout="vertical"
          name="newService"
          onFinish={onFormFinish}
          initialValues={
            Object.keys(savedFormData).length > 0
              ? {
                  ...savedFormData,
                  validTo: savedFormData.validTo
                    ? moment(savedFormData.validTo)
                    : undefined,
                  validFrom: savedFormData.validFrom
                    ? moment(savedFormData.validFrom)
                    : undefined,
                  domains: domainsForForm,
                }
              : initialValues
          }
          onFieldsChange={onChange}
        >
          <Row gutter={[16, 0]}>
            <Col xs={24} lg={12}>
              <Form.Item
                label={t('new.service.titleLong.label')}
                name="title"
                rules={[
                  {
                    required: statusId === 2,
                    message: t('new.service.field.required'),
                    whitespace: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={6}>
              <Form.Item
                label={t('new.service.language.label')}
                name="languageId"
                rules={[
                  {
                    required: statusId === 2,
                    message: t('new.service.field.required'),
                  },
                ]}
              >
                <Select showSearch optionFilterProp="children">
                  {resources.languages.map(lang => (
                    <Option key={lang.id} value={lang.id}>
                      {t(`new.service.language.${camelCase(lang.cI_Name)}`)}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={6}>
              <Form.Item
                label={t('new.service.country.label')}
                name="countries"
                rules={[
                  {
                    required: statusId === 2,
                    message: t('new.service.field.required'),
                  },
                ]}
              >
                <Select
                  showSearch
                  mode="multiple"
                  allowClear
                  optionFilterProp="children"
                  maxTagCount={2}
                  onChange={handleCountryChange}
                >
                  <Option value="all">{t('new.service.country.all')}</Option>
                  {resources.countries.map(({ countryCodeISOAlpha3 }) => (
                    <Option
                      key={countryCodeISOAlpha3}
                      value={countryCodeISOAlpha3}
                    >
                      {t(`global.country.${countryCodeISOAlpha3}`)}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col xs={24} lg={12}>
              <Form.Item
                label={t('new.service.description.label')}
                name="description"
                rules={[
                  {
                    required: statusId === 2,
                    message: t('new.service.field.required'),
                    whitespace: true,
                  },
                ]}
              >
                <TextArea style={{ height: '138px' }} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Row gutter={[16, 0]}>
                <Col xs={24} md={12}>
                  <Form.Item
                    label={t('new.service.validFrom.label')}
                    name="validFrom"
                    rules={[
                      {
                        required: statusId === 2,
                        message: t('new.service.field.required'),
                      },
                    ]}
                  >
                    <DatePicker
                      format={DATE_FORMAT}
                      allowClear={true}
                      disabledDate={disabledDates}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    label={t('new.service.validTo.label')}
                    name="validTo"
                  >
                    <DatePicker
                      format={DATE_FORMAT}
                      allowClear={true}
                      disabledDate={disabledDates}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    label={t('new.service.entityType.label')}
                    name="isLegalEntity"
                    rules={[
                      {
                        required: statusId === 2,
                        message: t('new.service.field.required'),
                      },
                    ]}
                  >
                    <Select>
                      <Option value={true}>
                        {t('new.service.entityType.leagal')}
                      </Option>
                      <Option value={false}>
                        {t('new.service.entityType.natural')}
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    label={t('new.service.type.label')}
                    name="serviceTypeId"
                  >
                    <Select>
                      {resources.types.map(type => (
                        <Option key={type.id} value={type.id}>
                          {t(`new.service.type.${camelCase(type.cI_Name)}`)}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col xs={24} md={12}>
              <Form.Item label={t('new.service.users.label')} name="users">
                <Select
                  mode="multiple"
                  labelInValue
                  onSearch={debounce(dispatchFetchUsers, 500)}
                  onChange={handleUsersChange}
                  notFoundContent={
                    usersListLoading ? t('new.service.users.loading') : null
                  }
                  filterOption={false}
                  value={userListValue}
                >
                  {usersList.map(user => (
                    <Option key={user.id}>{user.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={6}>
              <Form.Item
                label={t('new.service.priceVisibility.label')}
                name="isPriceVisibleToUser"
                rules={[
                  {
                    required: statusId === 2,
                    message: t('new.service.field.required'),
                  },
                ]}
              >
                <Select>
                  <Option value={true}>
                    {t('new.service.priceVisibility.yes')}
                  </Option>
                  <Option value={false}>
                    {t('new.service.priceVisibility.no')}
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={6}>
              <Form.Item
                label={t('new.service.representationRights.label')}
                name="onlyLegalRepresentativeCanFinish"
                rules={[
                  {
                    required: statusId === 2,
                    message: t('new.service.field.required'),
                  },
                ]}
              >
                <Select>
                  <Option value={true}>
                    {t('new.service.representationRights.yes')}
                  </Option>
                  <Option value={false}>
                    {t('new.service.representationRights.no')}
                  </Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col>
              <div ref={domainsRef}>
                <Form.Item
                  className="tag-group"
                  label={t('new.service.domains.label')}
                  name="domains"
                  rules={[
                    {
                      required: statusId === 2,
                      message: t('new.service.field.required'),
                    },
                  ]}
                >
                  <DomainTags
                    selectedDomains={domainsList}
                    handleCheckboxChange={handleDomainChange}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default NewServiceForm;
