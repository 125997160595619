import * as gql from 'gql-query-builder';
import {
  questionnaireReceived,
  sharedQuestionnaireReceived,
  questionnaireRequested,
  questionnaireRequestFailed,
} from './';
import { clientBackend } from '../../../api-config';
import { apiCallBegan } from '../../api';
import { LEGALENTITY_FIELDS } from '../company/queries';

export const getFullQuestionnaire = (operation, id) =>
  apiCallBegan({
    url: clientBackend,
    data: gql.query({
      operation,
      variables: {
        id: {
          value: id,
          type: 'Int!',
        },
      },
      fields: [
        'id',
        'statusId',
        'title',
        'createdByCustomerId',
        {
          createdBy: [
            { customer: ['name'] },
            { kYCUser: ['firstname', 'lastname'] },
          ],
        },
        {
          subjectLegalEntity: LEGALENTITY_FIELDS,
        },
        {
          service: [
            'id',
            'title',
            'counterpartyConnectorUniqueIds',
            'onlyLegalRepresentativeCanFinish',
            'isPriceVisibleToUser',
            {
              serviceDataBlocks: [
                'id',
                'parentServiceDataBlockId',
                'title',
                'heading',
                'sequenceNumber',
                'helpText',
                'isManualDataBlock',
                'isUserSelectableReferenceSource',
                'noReferenceSource',
                {
                  // this is where dataReferenceSources is fetched, supposedly this is copied over into "block" with ... operator
                  dataReferenceSources: [
                    {
                      counterpartyConnector: [
                        'productName',
                        'uniqueId',
                        'price',
                        'counterPartyId',
                      ],
                    },
                  ],
                },
                {
                  dataBlock: [
                    'id',
                    'isMultiple',
                    'serviceProviderHelpText',
                    'isManualEntryForbidden',
                    'isLexisNexisInput',
                    {
                      domain: ['id', 'title'],
                    },
                  ],
                },
              ],
            },
            {
              serviceDataAttributes: [
                'id',
                'parentServiceDataBlockId',
                'title',
                'sequenceNumber',
                'isManuallyFilled',
                'isManualRadioOption',
                'isVisible',
                {
                  dataAttribute: [
                    'id',
                    'cI_Name',
                    'cI_Type',
                    'idmName',
                    'dataAttributeKindId',
                  ],
                },
              ],
            },
          ],
        },
        {
          questionnaireDataBlockResults: [
            'id',
            'parentQuestionnaireDataBlockResultId',
            'retrievedAt',
            'isEnteredManually',
            {
              dataBlockReferenceSource: [
                {
                  counterpartyConnector: ['productName', 'counterPartyId'],
                },
              ],
            },
            'serviceDataBlockId',
            'manuallyModifiedAt',
            { createdBy: [{ kYCUser: ['id', 'firstname', 'lastname'] }] },
            {
              questionnaireDataBlockResultFiles: ['id', 'fileName', 'link'],
            },
          ],
        },
        {
          questionnaireDataAttributeResults: [
            'id',
            'parentQuestionnaireDataBlockResultId',
            'cI_Value',
            'retrievedAt',
            'isEnteredManually',
            'serviceDataAttributeId',
          ],
        },
        {
          questionnaireComments: [
            'comment',
            'questionnaireDataBlockResultId',
            'createdAt',
            {
              createdBy: [
                {
                  customer: ['name'],
                },
                { kYCUser: ['firstname', 'lastname'] },
              ],
            },
          ],
        },
        {
          iDMBeginStrategyRequests: [
            'id',
            'lastAttemptAt',
            'lexisNexisRequestCategoryId',
            'questionnaireDataBlockResultId',
            'request',
            'statusId',
            'statusName',
            'connectorId',
            {
              connector: ['productName', 'counterPartyId'],
            },
          ],
        },
      ],
    }),
    onStart: questionnaireRequested.type,
    onSuccess:
      operation === 'getSharedQuestionnaire'
        ? sharedQuestionnaireReceived.type
        : questionnaireReceived.type,
    onFailure: questionnaireRequestFailed.type,
  });
