import { apiCallBegan } from '../../api';
import { apiFetchLegalEntity } from '../company/queries';

import {
  legalEntityRequested,
  legalEntityRequestFailed,
  legalEntityReceived,
} from './';

export const fetchLegalEntity = id =>
  apiCallBegan({
    ...apiFetchLegalEntity(id),
    onStart: legalEntityRequested.type,
    onSuccess: legalEntityReceived.type,
    onFailure: legalEntityRequestFailed.type,
  });
