import * as gql from 'gql-query-builder';

import { apiCallBegan } from '../../api';
import { clientBackend } from '../../../api-config';
import {
  resourcesRequested,
  resourcesRequestFailed,
  resourcesRequestSuccess,
} from './';

export const fetchResources = () =>
  apiCallBegan({
    url: clientBackend,
    data: gql.query([
      {
        operation: 'listDomains',
        fields: ['id', 'title', 'sequenceNumber'],
      },
      {
        operation: 'listServiceLanguages',
        fields: ['id', 'cI_Name'],
      },
      {
        operation: 'listCountries',
        fields: ['countryCodeISOAlpha3', 'countryCodeISOAlpha2'],
      },
      {
        operation: 'listServiceTypes',
        fields: ['id', 'cI_Name'],
      },
      {
        operation: 'listServiceStatuses',
        fields: ['id', 'cI_Name'],
      },
    ]),
    onStart: resourcesRequested.type,
    onSuccess: resourcesRequestSuccess.type,
    onFailure: resourcesRequestFailed.type,
  });
