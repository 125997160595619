import React, { useMemo } from 'react';
import { Menu } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { moveDataBlockBetweenChapters } from '../../../store/entities/createService';
import { operateDataBlock } from '../../../store/entities/createService/thunks';
import { showErrorNotification } from '../../../utils/showNotification';

const ChooseChapterMenu = ({
  dataBlock,
  chapterIndex,
  subChapterIndex,
  dataBlockIndex,
}) => {
  const dispatch = useDispatch();
  const { savedDataBlocks, serviceId } = useSelector(
    state => state.createService
  );
  const { t } = useTranslation();
  const { id, heading, title, dataBlockId } = dataBlock;

  const createListForMenu = useMemo(() => {
    const elementsForMenu = [];

    for (const [idx, chapter] of savedDataBlocks.entries()) {
      if (chapter.childBlocks.length) {
        const chapterHasTheDataBlock = chapter.childBlocks.find(
          block => !block.heading && block.id === dataBlock.id
        );
        const subChapters = chapter.childBlocks.filter(
          ({ heading }) => heading
        );
        const simpleDataBlocksLength = chapter.childBlocks.filter(
          ({ heading }) => !heading
        ).length;

        if (!chapterHasTheDataBlock)
          elementsForMenu.push({
            chapterIndex: idx,
            name: chapter.heading,
            seqNumber: simpleDataBlocksLength,
            chapterId: chapter.id,
          });

        if (subChapters.length) {
          for (const subChapter of subChapters) {
            const subChapterIndex = chapter.childBlocks.findIndex(
              ({ id }) => id === subChapter.id
            );
            const subChapterHasTheDataBlock = subChapter.childBlocks.find(
              block => !block.heading && block.id === dataBlock.id
            );
            const simpleDataBlocksLengthInSubChapter = subChapter.childBlocks.filter(
              ({ heading }) => !heading
            ).length;

            if (!subChapterHasTheDataBlock) {
              elementsForMenu.push({
                chapterIndex: idx,
                subChapterIndex,
                name: subChapter.heading,
                seqNumber: simpleDataBlocksLengthInSubChapter,
                subChapterId: subChapter.id,
              });
            }
          }
        }
      } else {
        elementsForMenu.push({
          chapterIndex: idx,
          name: chapter.heading,
          seqNumber: 0,
          chapterId: chapter.id,
        });
      }
    }

    return elementsForMenu;
  }, [dataBlock, savedDataBlocks]);

  const handleChapterMoving = async newChapterInfo => {
    const { subChapterId, chapterId, seqNumber } = newChapterInfo;
    const resultAction = await dispatch(
      operateDataBlock({
        type: 'dataBlock',
        chapterIndex,
        subChapterIndex,
        dataBlockInfo: {
          id,
          serviceId,
          heading,
          title,
          dataBlockId,
          sequenceNumber: seqNumber,
          parentServiceDataBlockId: subChapterId ? subChapterId : chapterId,
        },
      })
    );

    if (operateDataBlock.fulfilled.match(resultAction)) {
      dispatch(
        moveDataBlockBetweenChapters({
          chapterIndex,
          subChapterIndex,
          dataBlockIndex,
          newChapterInfo,
        })
      );
    } else {
      showErrorNotification(t('new.service.dataBlock.move.error'));
    }
  };

  return (
    <Menu>
      {createListForMenu.length
        ? createListForMenu.map((elem, idx) => (
            <Menu.Item
              className={
                elem.subChapterIndex
                  ? 'menu__item-sub-chapter'
                  : 'menu__item-chapter'
              }
              key={elem.name + idx}
              onClick={() => handleChapterMoving(elem)}
            >
              {t(elem.name)}
            </Menu.Item>
          ))
        : null}
    </Menu>
  );
};

export default ChooseChapterMenu;
