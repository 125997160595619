import React from 'react';
import { Modal, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setLegalEntity } from '../../store/entities/legalentity';
import {
  updateLegalEntityKeyDatasThunk,
  updateManuallyInsertedThunk,
} from '../../store/entities/onboarding';
import { SearchConnectorNames } from '../../utils/constants';
import { useHistory } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';

export const LEGAL_ENTITY_UPDATE_MODAL = 'LegalEntityUpdateModal';

const LegalEntityUpdateModal = ({
  isVisible,
  setIsVisible,
  isGroupCompany,
  manualCompanyInfo,
  legalEntityFromIdmParams,
  isManual,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleActionChoice = async () => {
    // todo: keydata: show spinner

    if (isManual) {
      const result = await dispatch(
        updateManuallyInsertedThunk({
          // manualCompanyInfo.id has value
          ...manualCompanyInfo,
        })
      );
      if (updateManuallyInsertedThunk.fulfilled.match(result)) {
        const {
          data: { updateManuallyInserted },
        } = unwrapResult(result);
        dispatch(setLegalEntity(updateManuallyInserted));
      }
      history.push(
        `/${language}/kyc/legalEntityKeyDataView/${manualCompanyInfo.id}`,
        { from: LEGAL_ENTITY_UPDATE_MODAL }
      );
    } else {
      const result = await dispatch(
        updateLegalEntityKeyDatasThunk({
          ...legalEntityFromIdmParams,
        })
      );
      if (updateLegalEntityKeyDatasThunk.fulfilled.match(result)) {
        const {
          data: { updateLegalEntityKeyDatas },
        } = unwrapResult(result);
        dispatch(setLegalEntity(updateLegalEntityKeyDatas));
      }
      history.push(
        `/${language}/kyc/legalEntityKeyDataView/${legalEntityFromIdmParams.legalEntityId}`,
        { from: LEGAL_ENTITY_UPDATE_MODAL }
      );
    }
  };

  return (
    <Modal
      title={t('company.search.action.modal.update')}
      visible={isVisible}
      onCancel={() => setIsVisible(false)}
      cancelText={t('company.search.action.btn.cancel')}
      onOk={handleActionChoice}
      destroyOnClose
    >
      <Row>
        {isManual
          ? t('legalentity.updateModal.manually')
          : t('legalentity.updateModal.fromConnector').replace(
              '{connector}',
              t(SearchConnectorNames[legalEntityFromIdmParams?.counterPartyId])
            )}
      </Row>
    </Modal>
  );
};

export default LegalEntityUpdateModal;
