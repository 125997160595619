import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import LegalEntityData from './LegalEntityData';
import LegalEntityHeader from './LegalEntityHeader';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLegalEntity } from '../../store/entities/legalentity/queries';
import { groupBy } from 'lodash';
import { Col, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { CounterParty } from '../../utils/constants';

const LegalEntityKeyDataView = () => {
  const dispatch = useDispatch();
  const { legalEntityId } = useParams();

  const { t } = useTranslation();

  const { legalEntity } = useSelector(state => state.legalEntity);

  useEffect(() => {
    if (legalEntity === null) dispatch(fetchLegalEntity(legalEntityId));
  }, [dispatch, legalEntity, legalEntityId]);

  // doing .distinct
  const secondaryCounterParties =
    legalEntity &&
    Object.keys(
      groupBy(
        legalEntity.legalEntityKeyDatas
          .map(x => x.connector.counterPartyId)
          // this is overkill, because these will be filtered out by next filter anyway
          .filter(x => x !== CounterParty.MANUAL)
          .filter(x => x !== legalEntity.connector.counterPartyId)
      )
    );

  return legalEntity ? (
    <div className="container">
      <Col span={24}>
        <LegalEntityHeader company={legalEntity} isWithCaption={false} />
        <LegalEntityData
          companyData={legalEntity}
          specificCounterPartyId={legalEntity.counterPartyId}
          isFirstSource={true}
          isOnLegalEntityKeyDataView={true}
        />
        {secondaryCounterParties &&
          secondaryCounterParties.map(x => (
            <LegalEntityData
              companyData={legalEntity}
              specificCounterPartyId={x}
              key={x}
              isFirstSource={false}
              isOnLegalEntityKeyDataView={true}
            />
          ))}
      </Col>
    </div>
  ) : (
    <div className="container">
      <Spin size="large" tip={t('global.loading')} />
    </div>
  );
};

export default LegalEntityKeyDataView;
