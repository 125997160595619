import React from 'react';
import ServiceManagementHeader from '../components/servicemanagement/ServiceManagementHeader';
import ServiceManagementTable from '../components/servicemanagement/ServiceManagementTable';
import ServiceManagementFilters from '../components/servicemanagement/ServiceManagementFilters';

const ServiceManagement = () => (
  <>
    <ServiceManagementHeader />
    <ServiceManagementFilters />
    <ServiceManagementTable />
  </>
);

export default ServiceManagement;
