import React from 'react';
import { Row, Col, Typography, Button, Radio } from 'antd';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useHasPath from '../../hooks/useHasPath';

const { Title } = Typography;

const OnboardingHeader = ({ manualOnboarding, setManualOnboarding }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const history = useHistory();
  const { hasPath } = useHasPath('partners');

  return (
    <>
      <Row
        className="space-vertical-lg"
        gutter={[16, 16]}
        justify="space-between"
      >
        <Col xs={24} md={24} lg={10}>
          <Title>{t('onboarding.title')}</Title>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col xs={24} md={24} lg={10}>
          <Radio.Group
            size="small"
            onChange={e => setManualOnboarding(e.target.value)}
          >
            <Radio.Button checked={!manualOnboarding} value={false}>
              {t('onboarding.button.find')}
            </Radio.Button>
            <Radio.Button checked={manualOnboarding} value={true}>
              {t('onboarding.button.insert')}
            </Radio.Button>
          </Radio.Group>
        </Col>
        <Col xs={24} md={24} lg={4}>
          <Button
            style={{ float: 'right' }}
            className="is-primary"
            onClick={() =>
              history.push(
                `/${language}/kyc/${hasPath ? 'partners' : 'company'}`
              )
            }
          >
            {t('global.btn.back')}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default OnboardingHeader;
