import React from 'react';
import moment from 'moment';

import {
  CounterParty,
  prepareCounterPartiesDependingOnCountry,
} from '../../utils/constants';
import { groupBy } from 'lodash';
import LegalEntityKeyDatasGroup from './LegalEntityKeyDatasGroup';
import { useSelector } from 'react-redux';

const LegalEntityData = ({
  companyData,
  specificCounterPartyId,
  isFirstSource,
  isOnLegalEntityKeyDataView,
}) => {
  const {
    connector: { counterPartyId },
    legalEntityKeyDatas,
    id,
    countryCodeISOAlpha3,
  } = companyData;

  // if not specifically stated, show main source
  const counterPartyIdToShow = specificCounterPartyId
    ? parseInt(specificCounterPartyId)
    : counterPartyId;

  const sortedCreatedAt = legalEntityKeyDatas
    .filter(x => x.connector.counterPartyId === counterPartyIdToShow)
    .map(x => x.createdAt)
    .sort((a, b) => -1 * (moment(a) - moment(b))); // sort descending

  const lastUpdated = sortedCreatedAt[0];

  const filtered = legalEntityKeyDatas.filter(
    x =>
      x.createdAt === lastUpdated &&
      x.connector.counterPartyId === counterPartyIdToShow
  );

  const distinctSecondaryManualDates =
    counterPartyIdToShow === CounterParty.MANUAL &&
    Object.keys(groupBy(sortedCreatedAt.filter(x => x !== lastUpdated)));

  const { enabledCounterPartyConnectors } = useSelector(state => state.company);

  const prepareCounterPartiesForSelect = () => {
    // add CounterParty depending on the country
    let result = prepareCounterPartiesDependingOnCountry(
      countryCodeISOAlpha3?.toUpperCase()
    );

    // subtract existing counterparties
    const existingCounterParties = Object.keys(
      groupBy(legalEntityKeyDatas.map(x => x.connector.counterPartyId))
    ).map(x => parseInt(x));
    result = result.filter(x => !existingCounterParties.includes(x));

    // sieve only enabledCounterPartyConnectors
    result = result.filter(x =>
      enabledCounterPartyConnectors.some(
        enabledCC => enabledCC.counterPartyId === x
      )
    );

    return result;
  };
  const counterPartiesAvailableToAdd = prepareCounterPartiesForSelect();

  const calculateIsVisible = () => {
    // if manually entered and not manually/counterparty updated
    if (counterPartyId === CounterParty.MANUAL) {
      if (
        legalEntityKeyDatas.some(
          x => x.connector.counterPartyId !== CounterParty.MANUAL
        )
      )
        return false;

      const manualCreatedAts = legalEntityKeyDatas
        .filter(x => x.connector.counterPartyId === CounterParty.MANUAL)
        .map(x => x.createdAt);
      if (manualCreatedAts.some(x => x !== manualCreatedAts[0])) return false;
    } else {
      return legalEntityKeyDatas.some(
        x => x.connector.counterPartyId !== counterPartyId
      );
    }
  };
  const isVisibleBtnLegalEntityKeyDataView =
    !isOnLegalEntityKeyDataView && isFirstSource && calculateIsVisible();

  return (
    <>
      <LegalEntityKeyDatasGroup
        keyDatas={filtered}
        counterPartiesAvailableToAdd={counterPartiesAvailableToAdd}
        isFirstSource={isFirstSource}
        companyData={companyData}
        isVisibleBtnLegalEntityKeyDataView={isVisibleBtnLegalEntityKeyDataView}
      />
      {isOnLegalEntityKeyDataView &&
      counterPartyIdToShow === CounterParty.MANUAL &&
      isFirstSource && // cannot add manual as secondary source
        distinctSecondaryManualDates?.map(date => (
          <LegalEntityKeyDatasGroup
            key={date}
            keyDatas={legalEntityKeyDatas.filter(
              x =>
                x.createdAt === date &&
                x.connector.counterPartyId === counterPartyIdToShow
            )}
            isFirstSource={false}
            companyData={companyData}
          />
        ))}
    </>
  );
};
// todo: keydata: Tõlgete puudumise tõttu on praegu ainult asjad natuke laiaks venitatud ja sinna sektsiooni sisse tekib scroll-bar.

export default LegalEntityData;
