import React, { useState } from 'react';
import { Tooltip, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { ApiOutlined, UserOutlined, WarningFilled } from '@ant-design/icons';

import OwnershipWarningModal from './../OwnershipWarningModal';

import { questionnaireStatus } from '../../../../utils/constants';

const OwnershipTreeNode = ({
  MemberOwnershipShare,
  FirstNameOfMember,
  FamilyNameOfMember,
  BusinessNameOfMember,
  PersonalIdentificationCodeOfMember,
  CountryNameOfMemberId,
  CountryNameOfRegisterCodeOfMember,
  CodeOfWarningNoteOfMember,
  WarningNoteOfChainFirstMember,
  CountryCodeOfAddressOfMember,
  parentQuestionnaireDataBlockResultId: qdbrId,
  hasLexisNexis,
  lexisNexisConnectorEnabled,
  dataBlock,
  handleModal,
  questionnaireStatusId,
}) => {
  const { t } = useTranslation();

  const { questionnaireDataBlockResult } = dataBlock;

  const [isModalVisible, setIsModalVisible] = useState(false);

  const name = FirstNameOfMember
    ? `${FirstNameOfMember} ${FamilyNameOfMember}`
    : BusinessNameOfMember;
  const country = FirstNameOfMember
    ? CountryNameOfMemberId
    : CountryNameOfRegisterCodeOfMember;
  const memberInfo = [
    { info: PersonalIdentificationCodeOfMember, type: 'code' },
    { info: country, type: 'country' },
    { info: CountryCodeOfAddressOfMember, type: 'countryCode' },
  ].filter(({ info }) => Boolean(info));

  const handleWarningModal = () => {
    setIsModalVisible(true);
  };

  const QDBRHasLNResult = () => {
    const LNResults = questionnaireDataBlockResult.find(
      ({ id }) => id === qdbrId
    ).LNResults;

    return (
      LNResults?.PEPs?.length ||
      LNResults?.Sanctions?.length ||
      LNResults?.Watchlists?.length
    );
  };

  return (
    <>
      <div className="tree__option">
        <div className="tree__perc">
          {MemberOwnershipShare}
          <span>%</span>
        </div>
        <div className="tree__description">
          <div className="tree__title">
            <Tooltip
              title={t(
                `ownershipStructure.member.type.${
                  FirstNameOfMember ? 'natural' : 'legal'
                }`
              )}
            >
              {!FirstNameOfMember ? <ApiOutlined /> : <UserOutlined />}
            </Tooltip>{' '}
            <span>{name}</span>
            {CodeOfWarningNoteOfMember ? (
              <Button
                className="btn-action btn-action--warning"
                type="link"
                icon={<WarningFilled />}
                onClick={() => handleWarningModal('structure')}
              />
            ) : null}
            {hasLexisNexis && (
              <>
                {QDBRHasLNResult() ? (
                  <Button
                    size="small"
                    className="space-left-sm"
                    onClick={() => handleModal('result', qdbrId)}
                  >
                    {t('global.btn.show.LN.results')}
                  </Button>
                ) : lexisNexisConnectorEnabled ? (
                  <Button
                    size="small"
                    onClick={() =>
                      handleModal('single', qdbrId, FirstNameOfMember)
                    }
                    className="btn-secondary space-left-sm"
                    disabled={
                      questionnaireStatusId === questionnaireStatus.finished
                    }
                  >
                    {t('global.btn.screen')}
                  </Button>
                ) : null}
              </>
            )}
          </div>
          <div className="tree__subtitle">
            {memberInfo.map((info, idx) => (
              <Tooltip
                key={info.type}
                title={t(
                  `ownershipStructure.tooltip.${
                    FirstNameOfMember ? 'natural.' : 'legal.'
                  }${info.type}`
                )}
              >
                {`${idx === 0 ? '' : ','} ${info.info}`}
              </Tooltip>
            ))}
          </div>
        </div>
      </div>
      <OwnershipWarningModal
        warningText={WarningNoteOfChainFirstMember}
        isVisible={isModalVisible}
        handleClose={setIsModalVisible}
      />
    </>
  );
};

export default OwnershipTreeNode;
