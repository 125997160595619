import React, { useState } from 'react';
import { Col, Modal, Row, Select, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';

import {
  postOnboardingFormData,
  saveServiceAsQuestionnaire,
} from '../../store/entities/onboarding';
import { showErrorNotification } from '../../utils/showNotification';
import {
  dublicateEntityInPortfolioError,
  sameRegisterCodeError,
} from '../../utils/constants';

import OnboardingDataSource from './OnboardingDataSource';

import { relationTypes } from '../../utils/constants';

const { Option } = Select;

const CompanySearchModal = ({
  isVisible,
  setIsVisible,
  isGroupCompany,
  manualCompanyInfo,
  legalEntityFromIdmParams,
  isManual,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { postOnboardingDataLoading, onboardingServicesLoading } = useSelector(
    state => state.onboarding
  );

  const [serviceSelectionOpen, setServiceSelectionOpen] = useState(false);
  const [relationTypeId, setRelationTypeId] = useState(null);
  const [onboardingService, setOnboardingService] = useState(false);

  const handleActionChoice = async () => {
    if (relationTypeId === relationTypes.onboarding && !onboardingService) {
      setServiceSelectionOpen(true);
      return;
    }

    const saveInfo = {
      relationTypeId,
      relationStatusId: relationTypeId === relationTypes.onboarding ? 1 : 2,
      childLegalEntityId: 0,
    };
    if (isManual) saveInfo['childLegalEntity'] = { ...manualCompanyInfo };
    else
      saveInfo['createLegalEntityFromIdmParams'] = {
        ...legalEntityFromIdmParams,
      };

    const result = await dispatch(postOnboardingFormData(saveInfo));
    if (postOnboardingFormData.fulfilled.match(result)) {
      const {
        data: {
          legalEntityRelation: { id, childLegalEntityId },
        },
      } = unwrapResult(result);

      if (isGroupCompany) {
        return history.push(`/${language}/kyc/company/group/${id}`);
      }
      if (relationTypeId === relationTypes.onboarding && onboardingService) {
        const questionnaireInput = {
          serviceId: onboardingService,
          legalEntityId: childLegalEntityId,
        };

        try {
          const questionnaire = await dispatch(
            saveServiceAsQuestionnaire(questionnaireInput)
          );
          const {
            data: {
              createQuestionnaireFromService: { id: questionnaireId },
            },
          } = unwrapResult(questionnaire);

          history.push(
            `/${language}/kyc/partners/search/${id}/questionnaire/${questionnaireId}`
          );
        } catch (err) {
          showErrorNotification(t('company.search.questionnaire.save.error'));
        }
      } else {
        history.push(`/${language}/kyc/partners/search/${id}`);
      }
    } else {
      const { error } = result.payload;

      if (error === dublicateEntityInPortfolioError) {
        showErrorNotification(t('onboarding.form.error.dublicate.entity'));
      } else if (error === sameRegisterCodeError) {
        showErrorNotification(t('onboarding.form.error.same.register.code'));
      } else {
        showErrorNotification(t('onboarding.form.error'));
      }
    }
  };

  const handleCancel = () => {
    setServiceSelectionOpen(false);
    setRelationTypeId(null);
    setOnboardingService(null);
    setIsVisible(false);
  };

  return (
    <>
      <Modal
        title={t('company.search.action.modal')}
        visible={isVisible}
        onCancel={handleCancel}
        cancelText={t('company.search.action.btn.cancel')}
        onOk={handleActionChoice}
        destroyOnClose
      >
        <Spin spinning={postOnboardingDataLoading || onboardingServicesLoading}>
          <Row>
            {isGroupCompany ? (
              <Col xs={24}>
                <Select
                  size="large"
                  onChange={setRelationTypeId}
                  placeholder={t('company.search.add.placeholder')}
                  style={{ width: '100%' }}
                >
                  <Option value={relationTypes.parent}>
                    {t('company.search.add.parent')}
                  </Option>
                  <Option value={relationTypes.subsidiary}>
                    {t('company.search.add.subsidiary')}
                  </Option>
                  <Option value={relationTypes.associatedCompany}>
                    {t('company.search.add.associated')}
                  </Option>
                </Select>
              </Col>
            ) : serviceSelectionOpen ? (
              <OnboardingDataSource
                setService={setOnboardingService}
                // todo: keydata: showing only when isManual
                companyName={isManual ? manualCompanyInfo?.cI_Name : ''}
                countryCode={
                  isManual
                    ? manualCompanyInfo.countryCodeISOAlpha3
                    : legalEntityFromIdmParams.countryAlpha
                }
              />
            ) : (
              <Col xs={24}>
                <Select
                  size="large"
                  onChange={setRelationTypeId}
                  placeholder={t('company.search.add.placeholder')}
                  style={{ width: '100%' }}
                >
                  <Option value={relationTypes.onboarding}>
                    {t('company.search.add.onboarding')}
                  </Option>
                  <Option value={relationTypes.partner}>
                    {t('company.search.add.partner')}
                  </Option>
                  <Option value={relationTypes.customer}>
                    {t('company.search.add.customer')}
                  </Option>
                </Select>
              </Col>
            )}
          </Row>
        </Spin>
      </Modal>
    </>
  );
};

export default CompanySearchModal;
