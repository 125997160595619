import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { resetLegalEntities } from '../store/entities/company';

import { Spin } from 'antd';

import PartnersHeader from '../components/partners/PartnersHeader';
import PartnersTable from '../components/partners/PartnersTable';

const Partners = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { company, companyLoading } = useSelector(state => state.company);

  useEffect(() => {
    return () => {
      dispatch(resetLegalEntities());
    };
  }, [dispatch]);

  return (
    <Spin size="large" spinning={companyLoading} tip={t('global.loading')}>
      <div className="container">
        {company && (
          <>
            <PartnersHeader />
            <PartnersTable />
          </>
        )}
      </div>
    </Spin>
  );
};

export default Partners;
