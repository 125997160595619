import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

import { Popover, Typography } from 'antd';

import { downloadFile } from '../../../utils/downloadFile';
import { getDownloadUrl } from '../../../store/entities/questionnaire/thunks';
import { showErrorNotification } from '../../../utils/showNotification';

const { Text } = Typography;

const SourceWithPopover = ({
  referenceSource,
  sourceFile,
  name,
  questionnaireDataBlockResultId,
  isEnteredManually,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);

  const handleDownload = async () => {
    try {
      const result = await dispatch(
        getDownloadUrl(questionnaireDataBlockResultId)
      );
      const {
        data: { questionnaireDataBlockResult },
      } = unwrapResult(result);

      downloadFile(
        questionnaireDataBlockResult[0].questionnaireDataBlockResultFiles[0]
          .downloadUrl
      );
    } catch (err) {
      console.info(err);
      showErrorNotification(t('questionnaire.source.download.error'));
    }
  };

  const popoverContent = (
    <div>
      {name?.firstname ? (
        <p>
          <Text strong>
            {t(
              `questionnaire.card.${
                isEnteredManually ? 'createdBy' : 'gatheredBy'
              }`
            )}
          </Text>{' '}
          <Text>{`${name.firstname} ${name.lastname}`}</Text>
        </p>
      ) : null}
      {sourceFile?.link ? (
        <p>
          <Text strong>{t('questionnaire.card.link')}</Text>{' '}
          <a href={sourceFile.link} rel="noopener noreferrer" target="_blank">
            {sourceFile.link}
          </a>
        </p>
      ) : null}
      {sourceFile?.fileName ? (
        <p onClick={handleDownload}>
          <Text strong>{t('questionnaire.card.download')}</Text>{' '}
          <Text>{sourceFile.fileName}</Text>
        </p>
      ) : null}
    </div>
  );

  const handleClick = () => {
    if (!sourceFile && !name) return;
    setIsVisible(!isVisible);
  };

  return (
    <Popover
      content={popoverContent}
      trigger="click"
      visible={isVisible}
      onVisibleChange={handleClick}
    >
      <Text type="secondary">{t('questionnaire.card.source')}</Text>
      <br />
      <span className="color-success">
        {t(
          `${
            isEnteredManually
              ? 'questionnaire.card.source.manually.filled'
              : referenceSource
          }`
        )}
      </span>
    </Popover>
  );
};

export default SourceWithPopover;
