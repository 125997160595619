import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Spin, Row, Col, Space, Table, Input } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { setActiveTranslation } from '../../store/entities/translation';
import {
  fetchTranslations,
  deleteTranslation,
} from '../../store/entities/translation/queries';
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../utils/showNotification';

import TranslationsTableHeader from './TranslationsTableHeader';

const TranslationsTable = ({ toggleAddForm }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    list: translations,
    listLoading: isLoading,
    listLanguage,
    deleteTranslationSuccess,
    deleteTranslationFailed,
  } = useSelector(state => state.translation);

  useEffect(() => {
    dispatch(fetchTranslations(listLanguage));
  }, [dispatch, listLanguage]);

  useEffect(() => {
    if (deleteTranslationSuccess) {
      showSuccessNotification(
        t('translation.editor.actions.delete.deleteSuccess')
      );
      dispatch(fetchTranslations(listLanguage));
    }
    if (deleteTranslationFailed)
      showErrorNotification(
        t('translation.editor.actions.delete.deleteFailed')
      );
  }, [
    deleteTranslationSuccess,
    deleteTranslationFailed,
    t,
    dispatch,
    listLanguage,
  ]);

  const columns = [
    {
      title: t('translation.editor.key'),
      dataIndex: 'tKey',
      key: 'tKey',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.tKey.localeCompare(b.tKey),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm: search,
        clearFilters,
      }) => (
        <div className="translations-table__search-box padding-sm">
          <Space direction="vertical">
            <Input
              placeholder={t('translation.editor.actions.search')}
              value={selectedKeys[0]}
              onChange={e =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={() => search()}
            />
            <Space>
              <Button type="primary" size="small" onClick={() => search()}>
                {t('global.btn.search')}
              </Button>
              <Button onClick={() => clearFilters()} size="small">
                {t('global.btn.reset')}
              </Button>
            </Space>
          </Space>
        </div>
      ),
      onFilter: (value, record) =>
        record.tKey.toLowerCase().includes(value.toLowerCase()) ||
        record.tValue.toLowerCase().includes(value.toLowerCase()),
      render: text => text,
      filterIcon: filtered => (
        <SearchOutlined style={{ color: filtered ? '#c40f11' : undefined }} />
      ),
    },
    {
      title: t('translation.editor.value'),
      dataIndex: 'tValue',
      key: 'tValue',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.tValue.localeCompare(b.tValue),
    },
    {
      title: t('translation.editor.actions'),
      key: 'actions',
      render: record => (
        <div>
          <Space>
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={() => dispatch(setActiveTranslation(record))}
            />
            <Button
              type="link"
              icon={<DeleteOutlined />}
              onClick={() => dispatch(deleteTranslation(record.tKey))}
            />
          </Space>
        </div>
      ),
    },
  ];

  return (
    <div className="container">
      <TranslationsTableHeader toggleAddForm={toggleAddForm} />
      <Row>
        <Col span={24}>
          <Spin size="large" spinning={isLoading} tip={t('global.loading')}>
            <Table rowKey="id" columns={columns} dataSource={translations} />
          </Spin>
        </Col>
      </Row>
    </div>
  );
};

export default TranslationsTable;
