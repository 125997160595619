import React, { useState } from 'react';
import { Button, Col, Row, Select, Space, Typography, Modal, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';

import {
  getQuestionnairePDF,
  setQuestionnaireStatus,
  startFillQuestionnaire,
} from '../../store/entities/questionnaire/thunks';
import {
  showErrorNotification,
  showInfoNotification,
  showSuccessNotification,
} from '../../utils/showNotification';
import { downloadFile } from '../../utils/downloadFile';
import {
  offlineConnectors,
  idmStrategyStatus,
  questionnaireStatus,
  CounterParty,
} from '../../utils/constants';

import ShareKYCModal from '../global/ShareKYCModal';
import ConnectorModal from './ConnectorModal';
import UpdateKeyDataModal from './UpdateKeyDataModal';
import RequestHistoryModal from './RequestHistoryModal';

const { Title, Text } = Typography;
const { Option } = Select;
const { confirm } = Modal;

const QuestionnaireHeader = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { customerId } = useParams();
  const {
    questionnaire,
    pdfLoading,
    connectors,
    offlineRequestPending,
  } = useSelector(state => state.questionnaire);
  const { enabledCounterPartyConnectors } = useSelector(state => state.company);
  const { user } = useSelector(state => state.user);

  const {
    subjectLegalEntity,
    title,
    id,
    statusId,
    service: { onlyLegalRepresentativeCanFinish, isPriceVisibleToUser },
    createdByCustomerId,
  } = questionnaire;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [connectorIndex, setConnectorIndex] = useState(undefined);
  const [isConnectorModalVisible, setIsConnectorModalVisible] = useState(false);
  const [isHistoryModalVisible, setIsHistoryModalVisible] = useState(false);
  const [isRequestStarting, setIsRequestStarting] = useState(false);
  const [isUpdateWithBvdModalShown, setIsUpdateWithBvdModalShown] = useState(
    false
  );

  const showFinishButton =
    statusId === questionnaireStatus.inProgress
      ? onlyLegalRepresentativeCanFinish
        ? user.Role === 'CustomerLegalRepresentative'
        : true
      : false;

  const filteredConnectors = connectors.filter(
    ({ counterPartyId, uniqueId }) =>
      counterPartyId !== CounterParty.LEXISNEXIS &&
      enabledCounterPartyConnectors.some(x => x.uniqueId === uniqueId)
  );

  const finishedConnectorsLength = filteredConnectors.filter(
    ({ strategyStatusId }) => strategyStatusId === idmStrategyStatus.ok
  ).length;

  const handleModal = () => {
    setIsModalVisible(true);
  };

  const handlePDFDownload = async () => {
    const date = moment(new Date()).format();
    try {
      const actionResult = await dispatch(
        getQuestionnairePDF({ id, date, language })
      );
      const {
        data: { getFullQuestionnairePDF },
      } = unwrapResult(actionResult);

      downloadFile(getFullQuestionnairePDF);
    } catch (err) {
      console.info(err);
      showErrorNotification(t('questionnaire.pdf.download.error'));
    }
  };

  const handleConnectorChoice = value => {
    const connectorIdx = connectors.findIndex(
      ({ uniqueId }) => uniqueId === value
    );

    if (connectors[connectorIdx].counterPartyId === CounterParty.BVD) {
      // SK-401: check for BVDId existence
      const isWithBvdId = questionnaire?.subjectLegalEntity?.legalEntityKeyDatas?.some(
        x => x.cI_Key === 'BVDId' && x.cI_Value
      );

      if (!isWithBvdId) {
        setIsUpdateWithBvdModalShown(true);
        return;
      }
    }
    setConnectorIndex(connectorIdx);
    setIsConnectorModalVisible(true);
  };

  const handleConnectorCancel = () => {
    setConnectorIndex(undefined);
    setIsConnectorModalVisible(false);
  };

  const handleIDMRequestInitiation = async () => {
    setIsRequestStarting(true);
    const offlineRequest = offlineConnectors.some(
      connector => connector === connectors[connectorIndex].productName
    );
    const result = await dispatch(
      startFillQuestionnaire({
        questionnaireId: id,
        connectorId: connectors[connectorIndex].uniqueId,
        language,
        offlineRequest,
      })
    );

    if (startFillQuestionnaire.fulfilled.match(result)) {
      setIsRequestStarting(false);
      offlineRequest &&
        showInfoNotification(
          t('questionnaire.connector.offline.request.started')
        );
    } else {
      showErrorNotification(t('questionnaire.conncector.request.error'));
    }
    setIsConnectorModalVisible(false);
  };

  const changeQuestionnaireStatus = async () => {
    const status = await dispatch(
      setQuestionnaireStatus({
        questionnaireId: id,
        statusId: questionnaireStatus.finished,
      })
    );

    if (setQuestionnaireStatus.fulfilled.match(status)) {
      showSuccessNotification(t('questionnaire.finish.success'));
    } else {
      showErrorNotification(t('questionnaire.finish.error'));
    }
  };

  const handleFinish = () => {
    confirm({
      title: t('questionnaire.finish.confirm'),
      okText: t('global.btn.finish'),
      cancelText: t('global.btn.cancel'),
      onOk() {
        changeQuestionnaireStatus();
      },
    });
  };

  const handleBack = () => {
    history.push(
      `/${language}/kyc/${
        customerId ? `partners/search/${customerId}` : 'company/mykyc'
      }`
    );
  };

  return (
    <>
      <Row className="space-vertical-lg space-bottom-none">
        <Col>
          <Title>{subjectLegalEntity.cI_Name}</Title>
          {offlineRequestPending ? (
            <Tag className="tag-sm" color="green">
              <strong>{t('questionnaire.PP.Offline.request.pending')}</strong>
            </Tag>
          ) : null}
        </Col>
      </Row>
      <Row justify="end" className="space-bottom-lg">
        <Col>
          <Space>
            {createdByCustomerId === Number(user.CustomerId) ? (
              <Button onClick={() => setIsHistoryModalVisible(true)}>
                {t('global.btn.questionnaire.history')}
              </Button>
            ) : null}
            {filteredConnectors.length > 0 &&
            filteredConnectors.length !== finishedConnectorsLength ? (
              <Select
                className="storybookSelect"
                onSelect={handleConnectorChoice}
                placeholder={t(
                  'questionnaire.initiate.request'
                ).toLocaleUpperCase()}
                value={connectors?.[connectorIndex]?.uniqueId ?? undefined}
              >
                {filteredConnectors.map(connector => (
                  <Option
                    disabled={
                      connector.strategyStatusId === idmStrategyStatus.ok ||
                      connector.strategyStatusId === idmStrategyStatus.pending
                    }
                    key={connector.uniqueId}
                    value={connector.uniqueId}
                  >
                    <Text
                      type={
                        connector.strategyStatusId === idmStrategyStatus.failed
                          ? 'danger'
                          : false
                      }
                    >
                      {`${t(connector.productName)}${
                        isPriceVisibleToUser ? ` ${connector.price} €` : ''
                      }`}
                    </Text>
                  </Option>
                ))}
              </Select>
            ) : null}
          </Space>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col>
          <Title level={2}>{title}</Title>
        </Col>
        <Col>
          <Space>
            <Button onClick={handleBack}>{t('global.btn.back')}</Button>
            {statusId === questionnaireStatus.finished ? (
              <>
                <Button
                  type="default"
                  onClick={handlePDFDownload}
                  loading={pdfLoading}
                >
                  {t('global.btn.export')}
                </Button>
                <Button type="default" onClick={handleModal}>
                  {t('global.btn.share')}
                </Button>
              </>
            ) : null}
            {showFinishButton ? (
              <Button type="primary" onClick={handleFinish}>
                {t('global.btn.finish')}
              </Button>
            ) : null}
          </Space>
        </Col>
      </Row>
      <ShareKYCModal
        isVisible={isModalVisible}
        setIsVisible={setIsModalVisible}
        questionnaireId={id}
      />
      {isConnectorModalVisible ? (
        <ConnectorModal
          isVisible={isConnectorModalVisible}
          connector={connectors[connectorIndex]}
          handleCancel={handleConnectorCancel}
          handleOk={handleIDMRequestInitiation}
          isPriceVisibleToUser={isPriceVisibleToUser}
          isRequestStarting={isRequestStarting}
        />
      ) : null}
      <UpdateKeyDataModal
        isVisible={isUpdateWithBvdModalShown}
        setIsVisible={setIsUpdateWithBvdModalShown}
        legalEntity={questionnaire?.subjectLegalEntity}
      />
      <RequestHistoryModal
        isVisible={isHistoryModalVisible}
        setIsVisible={setIsHistoryModalVisible}
      />
    </>
  );
};

export default QuestionnaireHeader;
