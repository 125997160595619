import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { camelCase } from 'lodash';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { Spin, Table } from 'antd';

import { PAGESIZE } from '../../../utils/constants';
import { sortLegalEntityTableColumnValue } from '../../../utils';

import LegalEntityTableFilters from './LegalEntityTableFilters';
import StatusBadge from '../StatusBadge';

const formatDate = date => moment(date).format('DD.MM.YYYY HH:mm');

const initialTableState = {
  pagination: {
    pageIndex: 0,
    pageSize: 10,
  },
  sortOrder: {
    isSortAsc: true,
    sortColumn: 'childLegalEntity.cI_Name',
  },
};

const LegalEntityTable = ({ entitiesType }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const history = useHistory();

  const {
    legalEntities,
    legalEntitiesLoading,
    legalEntitiesSize,
    legalEntityCountriesLoading,
  } = useSelector(state => state.company);

  const [tableState, setTableState] = useState(initialTableState);

  const handleTableChange = (
    { current: page },
    UNUSED,
    { columnKey = 'cI_Name', order = 'ascend' }
  ) => {
    const state = {};
    state.pagination = { pageIndex: page - 1, pageSize: PAGESIZE };
    state.sortOrder = {
      sortColumn: sortLegalEntityTableColumnValue(columnKey),
      isSortAsc: order === 'ascend',
    };

    if (state.sortOrder.sortColumn !== tableState.sortOrder.sortColumn)
      state.pagination.pageIndex = 0;

    setTableState(state);
  };

  const handleCompanySelect = id => {
    history.push(
      `/${language}/kyc/${
        entitiesType === 'companyGroup'
          ? `company/group/${id}`
          : `partners/search/${id}`
      }`
    );
  };

  const columns = [
    {
      title: t('partners.table.name'),
      dataIndex: 'cI_Name',
      key: 'cI_Name',
      sortDirections: ['descend', 'ascend'],
      defaultSortOrder: 'ascend',
      sorter: true,
      render: (text, { parentId }) => (
        <span onClick={() => handleCompanySelect(parentId)}>{text}</span>
      ),
    },
    {
      title: t('partners.table.regCode'),
      dataIndex: 'registerCode',
      key: 'registerCode',
      sortDirections: ['descend', 'ascend'],
      sorter: true,
    },
    {
      title: t('partners.table.type'),
      dataIndex: 'relationType',
      key: 'relationType',
      sortDirections: ['descend', 'ascend'],
      sorter: true,
      render: ({ cI_Name }) =>
        t(`global.legalEntity.type.${camelCase(cI_Name)}`),
    },
    {
      title: t('partners.table.country'),
      dataIndex: 'countryCodeISOAlpha3',
      key: 'countryCodeISOAlpha3',
      sortDirections: ['descend', 'ascend'],
      sorter: true,
      render: text => t(`global.country.${text}`),
    },
    {
      title: t('partners.table.regStatus'),
      dataIndex: 'statusInRegister',
      key: 'statusInRegister',
      sortDirections: ['descend', 'ascend'],
      sorter: true,
      render: status => <StatusBadge status={status} onlyText />,
    },
    {
      title: t('partners.table.portfolioStatus'),
      dataIndex: 'relationStatus',
      key: 'relationStatus',
      sortDirections: ['descend', 'ascend'],
      sorter: true,
      render: ({ cI_Name }) => <StatusBadge status={cI_Name} />,
    },
    {
      title: t(
        `partners.table.added${
          entitiesType === 'partnersCustomers' ? '.initiated' : ''
        }`
      ),
      dataIndex: 'addedToPortfolioAt',
      key: 'addedToPortfolioAt',
      sortDirections: ['descend', 'ascend'],
      sorter: true,
      render: (text, record) =>
        record.addedToPortfolioAt
          ? formatDate(record.addedToPortfolioAt)
          : formatDate(record.initiatedAt),
    },
  ];

  return (
    <>
      <LegalEntityTableFilters
        entitiesType={entitiesType}
        tableState={tableState}
      />
      <Spin
        size="large"
        spinning={legalEntitiesLoading || legalEntityCountriesLoading}
        tip={t('global.loading')}
      >
        <Table
          rowKey="id"
          columns={columns}
          dataSource={legalEntities}
          scroll={{ x: true }}
          onChange={handleTableChange}
          pagination={{
            current: tableState.pagination.pageIndex + 1,
            pageSize: PAGESIZE,
            total: legalEntitiesSize,
            showSizeChanger: false,
          }}
        />
      </Spin>
    </>
  );
};

export default LegalEntityTable;
