import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getReport } from './queries';

export const fetchReport = createAsyncThunk(
  'billing/prepareReport',
  async props => {
    const response = await getReport(props);
    return response.data;
  }
);

const slice = createSlice({
  name: 'billing',
  initialState: {
    customersList: [],
    customersListLoading: false,
    customersListError: false,
    counterpartyConnectorsList: [],
    counterpartyConnectorsLoading: false,
    counterpartyConnectorsError: false,
    reportLoading: false,
  },
  reducers: {
    customersRequested: state => {
      state.customersListLoading = true;
      state.customersListError = false;
    },
    customersFailed: state => {
      state.customersListLoading = false;
      state.customersListError = true;
    },
    customersReceived: (state, { payload }) => {
      state.customersList = payload.data.getCustomersWithContracts;
      state.customersListLoading = false;
    },
    counterpartyConnectorsRequested: state => {
      state.counterpartyConnectorsLoading = true;
      state.counterpartyConnectorsError = false;
    },
    counterpartyConnectorsFailed: state => {
      state.counterpartyConnectorsLoading = false;
      state.counterpartyConnectorsError = true;
    },
    counterpartyConnectorsReceived: (state, { payload }) => {
      state.counterpartyConnectorsList =
        payload.data.listCounterPartyConnectors;
      state.counterpartyConnectorsLoading = false;
    },
  },
  extraReducers: {
    [fetchReport.pending]: state => {
      state.reportLoading = true;
    },
    [fetchReport.rejected]: state => {
      state.reportLoading = false;
    },
    [fetchReport.fulfilled]: state => {
      state.reportLoading = false;
    },
  },
});

export const {
  customersRequested,
  customersFailed,
  customersReceived,
  counterpartyConnectorsRequested,
  counterpartyConnectorsFailed,
  counterpartyConnectorsReceived,
} = slice.actions;

export default slice.reducer;
