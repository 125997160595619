const { useLocation } = require('react-router-dom');

const useHasPath = path => {
  const location = useLocation();

  const hasPath = location.pathname.split('/').includes(path);

  return { hasPath };
};

export default useHasPath;
