import React from 'react';
import { Col, Row, Typography } from 'antd';

import { sortBySeqNumber } from '../../../utils';

const { Text } = Typography;

const CardContentKeyValue = ({ dataAttributes, t, i18n }) => (
  <>
    {dataAttributes
      .slice()
      .sort(sortBySeqNumber)
      .map(
        ({
          id,
          dataAttribute,
          title,
          questionnaireDataAttributeResult,
          isManualRadioOption,
        }) => {
          const nameProps =
            isManualRadioOption &&
            questionnaireDataAttributeResult?.cI_Value.toLowerCase() === 'true'
              ? { strong: true }
              : { type: 'secondary' };
          return (
            <Row gutter={[16, 8]} key={id}>
              <Col span={12}>
                <Text {...nameProps}>
                  {dataAttribute?.cI_Name
                    ? t(dataAttribute.cI_Name)
                    : i18n.exists(title)
                    ? t(title)
                    : title}
                </Text>
              </Col>
              {!isManualRadioOption ? (
                <Col span={12}>
                  <span className="text-light">
                    {questionnaireDataAttributeResult?.cI_Value}
                  </span>
                </Col>
              ) : null}
            </Row>
          );
        }
      )}
  </>
);

export default CardContentKeyValue;
