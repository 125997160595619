import React from 'react';
import { Select, Checkbox, Space } from 'antd';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const EditReferenceSources = ({
  dataReferenceSources,
  setDataReferencesSources,
  sources = [],
  setIsUserSelectable,
  isUserSelectable,
  counterPartiesLoading: loading,
  noReferenceSource,
  setNoReferenceSource,
  isManualEntryForbidden,
}) => {
  const { t } = useTranslation();

  const onSourcesChange = values => {
    setDataReferencesSources(values);
  };

  const handleUserSelectable = e => {
    setIsUserSelectable(e.target.checked);
  };

  const handleNoReferenceSource = e => {
    setNoReferenceSource(e.target.checked);
  };

  return (
    <>
      <div>
        <Select
          mode="multiple"
          style={{ width: '100%' }}
          placeholder={t('new.service.dataBlock.choose.reference.source')}
          defaultValue={dataReferenceSources}
          onChange={onSourcesChange}
          showSearch={false}
          loading={loading}
          disabled={loading || noReferenceSource}
        >
          {sources.map(child => (
            <Option key={child.id}>{t(`${child?.name}`)}</Option>
          ))}
        </Select>
      </div>
      <Space direction="vertical">
        <Checkbox checked={isUserSelectable} onChange={handleUserSelectable}>
          {t('new.service.dataBlock.user.selectable')}
        </Checkbox>
        <Checkbox
          checked={noReferenceSource}
          onChange={handleNoReferenceSource}
          disabled={isManualEntryForbidden || dataReferenceSources.length}
        >
          {t('new.service.dataBlock.no.reference.source')}
        </Checkbox>
      </Space>
    </>
  );
};

export default EditReferenceSources;
