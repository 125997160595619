import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import * as gql from 'gql-query-builder';

import { apiBaseConfig } from '../utils/apiUtils';

export const getUser = createAsyncThunk('user/getUser', async () => {
  const response = await axios({
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true,
    url: '/clientbackend/auth/loggedInUser',
    method: 'GET',
  });
  return response.data;
});

export const logoutUser = createAsyncThunk(
  'user/logoutUser',
  async language => {
    const response = await axios({
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
      url: `/clientbackend/auth/logout?language=${language}`,
      method: 'GET',
    });
    return response;
  }
);

export const getMember = createAsyncThunk('user/getMember', async () => {
  const response = await axios({
    ...apiBaseConfig,
    data: gql.query({
      operation: 'getMembersForCurrentUser',
      fields: [
        'id',
        'kYCUserId',
        { customer: ['accountNumber', 'name'] },
        { role: ['id', 'name'] },
      ],
    }),
  });
  return response.data;
});

export const chooseMember = createAsyncThunk(
  'user/chooseMember',
  async memberId => {
    const response = await axios({
      header: { 'Content-Type': 'application/json' },
      withCredentials: true,
      url: `/clientbackend/auth/choosemember/${memberId}`,
      method: 'POST',
    });
    return response.data;
  }
);

const slice = createSlice({
  name: 'user',
  initialState: {
    user: null,
    userLoading: false,
    userError: false,
    members: [],
    isMemberModalVisible: false,
  },
  reducers: {
    logout: state => {
      state.user = null;
    },
    setIsMemberModalVisible: (state, payload) => {
      state.isMemberModalVisible = payload;
    },
  },
  extraReducers: {
    [getUser.pending]: state => {
      state.userLoading = true;
      state.userError = false;
    },
    [getUser.fulfilled]: (state, { payload }) => {
      if (!payload.KycUserId) state.user = null;
      else state.user = payload;
      state.userLoading = false;
    },
    [getUser.rejected]: state => {
      state.userLoading = false;
      state.userError = true;
    },
    [logoutUser.pending]: state => {
      state.userLoading = true;
      state.userError = false;
    },
    [logoutUser.fulfilled]: state => {
      state.userLoading = false;
      state.user = null;
    },
    [logoutUser.rejected]: state => {
      state.userLoading = false;
      state.userError = true;
    },
    [getMember.pending]: state => {
      state.userLoading = true;
      state.userError = false;
    },
    [getMember.fulfilled]: (state, { payload }) => {
      state.members = payload.data.getMembersForCurrentUser;

      if (payload.data.getMembersForCurrentUser.length > 1) {
        state.isMemberModalVisible = true;
      }

      state.userLoading = false;
    },
    [getMember.rejected]: state => {
      state.userLoading = false;
      state.userError = true;
    },
    [chooseMember.pending]: state => {
      state.userLoading = true;
    },
    [chooseMember.fulfilled]: (state, { payload }) => {
      state.user = payload;
      state.members = [];
      state.userLoading = false;
    },
    [chooseMember.rejected]: state => {
      state.userLoading = false;
    },
  },
});

export const { logout, setIsMemberModalVisible } = slice.actions;

export default slice.reducer;
