import React from 'react';
import { notification } from 'antd';
import { WarningFilled } from '@ant-design/icons';
import colors from './colors';

export const showSuccessNotification = (message, additionalProps = {}) =>
  notification.success({
    message,
    className: 'success-notification',
    ...additionalProps,
  });

export const showErrorNotification = (message, additionalProps = {}) =>
  notification.error({
    message,
    className: 'error-notification',
    ...additionalProps,
  });

export const showInfoNotification = (message, additionalProps = {}) =>
  notification.info({
    message,
    className: 'info-notification',
    ...additionalProps,
  });

export const showWarningNotification = (message, additionalProps = {}) =>
  notification.warning({
    message,
    className: 'warning-notification',
    icon: <WarningFilled style={{ color: colors['warning'] }} />,
    ...additionalProps,
  });
