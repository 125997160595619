import React from 'react';
import { Modal, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import { CardActionsContext } from './CardActionsProvider';
import { PEPsList, SanctionsList, Watchlists } from '../../../utils/constants';

const LNDetailsModal = ({ qdbrs, qdbrId, selectedLNResult }) => {
  const { t } = useTranslation();

  const { category, index } = selectedLNResult;

  const {
    toggleLNResults,
    toggleLNDetails,
    isLNDetailsOpen,
  } = React.useContext(CardActionsContext);

  const qdbr = qdbrs.find(({ id }) => id === qdbrId);
  const LNResults = Object.values(qdbr.LNResults[category][index]);
  const list =
    category === 'PEPs'
      ? PEPsList
      : category === 'Sanctions'
      ? SanctionsList
      : Watchlists;
  const displayData = [];

  for (const name of list) {
    const results = LNResults.filter(({ idmName }) => idmName === name);
    const result = results[0];
    const cI_Value = results.map(({ cI_Value }) => cI_Value).join('\n');

    if (result)
      displayData.push({
        ...result,
        cI_Value,
      });
  }

  const handleDetails = () => {
    toggleLNResults();
    toggleLNDetails();
  };

  return (
    <Modal
      title={t(`lexis.nexis.details.modal.title.${category}`)}
      visible={isLNDetailsOpen}
      cancelButtonProps={{
        style: {
          display: 'none',
        },
      }}
      okButtonProps={{
        className: 'btn-secondary',
      }}
      okText={t('global.btn.back')}
      onOk={handleDetails}
      width={700}
      onCancel={toggleLNDetails}
    >
      {displayData.map(result => (
        <Row key={result.id} className="padding-xxsm white-space-pre-wrap">
          <strong>{t(result.cI_Name)}: </strong> {result.cI_Value}
        </Row>
      ))}
    </Modal>
  );
};

export default LNDetailsModal;
