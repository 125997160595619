import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'company',
  initialState: {
    company: null,
    companyLoading: false,
    companyError: false,
    legalEntities: [],
    legalEntitiesLoading: false,
    legalEntitiesError: false,
    legalEntitiesSize: 0,
    legalEntitiesTableFilters: null,
    legalEntityCountries: null,
    legalEntityCountriesLoading: false,
    legalEntityCountriesError: false,
    enabledCounterPartyConnectorsLoading: [],
    enabledCounterPartyConnectors: [],
  },
  reducers: {
    companyRequested: state => {
      state.companyLoading = true;
      state.companyError = false;
    },
    companyRequestFailed: state => {
      state.companyLoading = false;
      state.companyError = true;
    },
    companyReceived: (state, { payload }) => {
      state.company =
        payload.data.legalEntity.length > 0
          ? payload.data.legalEntity[0]
          : null;
      state.companyLoading = false;
      state.companyError = false;
    },
    childLegalEntitiesListRequested: state => {
      state.legalEntitiesLoading = true;
      state.legalEntitiesError = false;
    },
    childLegalEntitiesListRequestFailed: state => {
      state.legalEntitiesLoading = false;
      state.legalEntitiesError = true;
    },
    childLegalEntitiesListReceived: (state, { payload }) => {
      state.legalEntities = payload.data.listLegalEntityRelationsWithPagination.result.map(
        ({
          addedToPortfolioAt,
          relationStatus,
          relationType,
          childLegalEntity,
          id,
        }) => ({
          parentId: id,
          addedToPortfolioAt,
          relationStatus,
          relationType,
          ...childLegalEntity,
        })
      );
      state.legalEntitiesSize =
        payload.data.listLegalEntityRelationsWithPagination.total;
      state.legalEntitiesLoading = false;
      state.legalEntitiesError = false;
    },
    setLegalEntitiesTableFilters: (state, { payload }) => {
      state.legalEntities = [];
      state.legalEntitiesTableFilters = payload;
    },
    countriesRequested: state => {
      state.countriesLoading = true;
      state.countriesError = false;
    },
    countriesRequestSuccess: (state, { payload }) => {
      state.legalEntityCountries = payload.data.listCountries;
      state.countriesLoading = false;
      state.countriesError = false;
    },
    countriesRequestFailed: state => {
      state.countriesLoading = false;
      state.countriesError = true;
    },
    resetLegalEntities: state => {
      state.legalEntities = [];
      state.legalEntitiesLoading = false;
      state.legalEntitiesError = false;
      state.legalEntitiesSize = 0;
      state.legalEntitiesTableFilters = null;
    },
    enabledCounterPartyConnectorsRequested: state => {
      state.enabledCounterPartyConnectorsLoading = true;
      state.enabledCounterPartyConnectors = [];
    },
    enabledCounterPartyConnectorsReceived: (state, { payload }) => {
      state.enabledCounterPartyConnectorsLoading = false;
      state.enabledCounterPartyConnectors = payload.data
        .getEnabledCounterPartyConnectorsForCustomer
        ? payload.data.getEnabledCounterPartyConnectorsForCustomer.map(
            ({ uniqueId, counterPartyId }) => ({ uniqueId, counterPartyId })
          )
        : [];
    },
    enabledCounterPartyConnectorsRequestFailed: state => {
      state.enabledCounterPartyConnectorsLoading = false;
      state.enabledCounterPartyConnectors = [];
    },
  },
});

export const {
  companyRequested,
  companyRequestFailed,
  companyReceived,
  childLegalEntitiesListRequested,
  childLegalEntitiesListRequestFailed,
  childLegalEntitiesListReceived,
  setLegalEntitiesTableFilters,
  countriesRequested,
  countriesRequestSuccess,
  countriesRequestFailed,
  resetLegalEntities,
  enabledCounterPartyConnectorsRequested,
  enabledCounterPartyConnectorsReceived,
  enabledCounterPartyConnectorsRequestFailed,
} = slice.actions;

export default slice.reducer;
