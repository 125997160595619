import React from 'react';
import { Tree, Col } from 'antd';

const OwnershipTree = ({ treeData, businessName, businessRegisterCode }) => (
  <>
    <Col xs={24}>{businessName}</Col>
    <Col xs={24} className="ownership-tree__subtitle">
      {businessRegisterCode}
    </Col>
    <Col xs={24}>
      <Tree treeData={treeData} selectable={false} defaultExpandAll />
    </Col>
  </>
);

export default OwnershipTree;
