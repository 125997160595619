import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Row, Table, Button } from 'antd';
import moment from 'moment';

import { fetchIdmRequests } from '../../store/entities/questionnaire/thunks';
import { showErrorNotification } from '../../utils/showNotification';
import { DDMMYYYY_HHmm, idmStrategyStatus } from '../../utils/constants';

const RequestHistoryModal = ({ isVisible, setIsVisible }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    historyLoading,
    requestsHistory,
    questionnaire: { id },
  } = useSelector(state => state.questionnaire);

  useEffect(() => {
    const getRequests = async () => {
      const result = await dispatch(fetchIdmRequests(id));

      if (!fetchIdmRequests.fulfilled.match(result)) {
        console.info(result.error.message);
        showErrorNotification(t('questionnaire.history.error'));
      }
    };

    if (isVisible) getRequests();
  }, [dispatch, id, isVisible, t]);

  const columns = [
    {
      title: t('history.table.source'),
      dataIndex: 'connector',
      render: connector => t(connector.productName),
    },
    {
      title: t('history.table.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      defaultSortOrder: 'ascend',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      render: date => moment(date).format(DDMMYYYY_HHmm),
    },
    {
      title: t('history.table.lastAttemptAt'),
      dataIndex: 'lastAttemptAt',
      key: 'lastAttemptAt',
      render: (date, { statusId }) =>
        date
          ? moment(date).format(DDMMYYYY_HHmm)
          : statusId === idmStrategyStatus.failed
          ? '-'
          : t('history.table.lastAttemptAt.pending'),
    },
    {
      title: t('history.table.status'),
      dataIndex: 'statusName',
      key: 'statusName',
      render: status => t(`global.status.${status}`),
    },
    {
      title: t('history.table.initiatedBy'),
      dataIndex: 'member',
      key: 'member',
      render: ({ kYCUser }) => `${kYCUser.firstname} ${kYCUser.lastname}`,
    },
  ];

  return (
    <Modal
      visible={isVisible}
      onCancel={() => setIsVisible(false)}
      title={t('history.modal.title')}
      width={1000}
      footer={null}
    >
      <Table
        rowKey="id"
        columns={columns}
        dataSource={requestsHistory}
        loading={historyLoading}
        pagination={false}
        style={{ marginBottom: '2rem' }}
        locale={{
          emptyText: historyLoading
            ? t('global.loading')
            : t('history.table.empty'),
        }}
      />
      <Row justify="end">
        <Button onClick={() => setIsVisible(false)}>
          {t('global.btn.close')}
        </Button>
      </Row>
    </Modal>
  );
};

export default RequestHistoryModal;
