import React from 'react';
import { Row, Space, Col, Typography, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LEGAL_ENTITY_UPDATE_MODAL } from '../onboarding/LegalEntityUpdateModal';

const { Title } = Typography;

const LegalEntityHeader = ({ company, isWithCaption }) => {
  const { t } = useTranslation();
  const { cI_Name } = company;
  const history = useHistory();

  const handleBackClick = () => {
    if (history.location.state?.from === LEGAL_ENTITY_UPDATE_MODAL) {
      // “Back” button on the legalEntityKeyDataView should operate
      // by opening the LE view of the legal entity
      // (not go to the previous key data search view for example).
      // If the key data purchasing was initiated (or legalEntityKeyDataView opened)
      // from “My KYC” view, the “Back” button should navigate there.
      // If the key data purchasing was initiated (or legalEntityKeyDataView opened)
      // from “My Company and Group” view, the “Back” button should navigate there

      // skip "Update" history entry
      history.go(-2);
    } else history.goBack();
  };

  return (
    <>
      <Row className="space-vertical-lg company-data-block">
        <Space className="space-between">
          <Col className="page-title">
            <Title>{cI_Name}</Title>
          </Col>
          <Col>
            <Button onClick={handleBackClick}>{t('global.btn.back')}</Button>
          </Col>
        </Space>
      </Row>
      <Row>{isWithCaption && t('legalentity.header.caption')}</Row>
    </>
  );
};

export default LegalEntityHeader;
