import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Row, Col } from 'antd';
import { WarningFilled } from '@ant-design/icons';

const Warning = ({ children, warningCode = null }) =>
  warningCode ? (
    <Row className="space-vertical-sm">
      <Col xs={2}>
        <span className="fw-600">{warningCode}</span>
      </Col>
      <Col xs={22}>
        <p>{children}</p>
      </Col>
    </Row>
  ) : (
    <Row>
      <Col>
        <p>{children}</p>
      </Col>
    </Row>
  );

const OwnershipStructureWarningModal = ({
  warningCode,
  warningText,
  isVisible,
  handleClose,
  isStructureWarningModal,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={
        <>
          <Row>
            <Col xs={2}>
              <WarningFilled className="icon icon--secondary" />
            </Col>
            <Col>
              <span>{`${t(
                `ownershipStructure.${
                  isStructureWarningModal ? 'warnings' : 'warning'
                }.title`
              )}`}</span>
            </Col>
          </Row>
        </>
      }
      visible={isVisible}
      onOk={() => handleClose(false)}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ className: 'btn-secondary' }}
      okText={t('global.btn.close')}
      closable={false}
      zIndex={1050}
    >
      {isStructureWarningModal ? (
        <>
          <Warning warningCode={warningCode}>{warningText}</Warning>
        </>
      ) : (
        <Warning>{warningText}</Warning>
      )}
    </Modal>
  );
};

export default OwnershipStructureWarningModal;
