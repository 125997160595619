import React from 'react';
import { Row, Space, Col, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

const { Title } = Typography;

const PartnersHeader = () => {
  const { t } = useTranslation();

  return (
    <Row className="space-vertical-lg">
      <Col>
        <Space size={20} className="space-left-lg">
          <Title>{t('partners.title')}</Title>
        </Space>
      </Col>
    </Row>
  );
};

export default PartnersHeader;
