import * as gql from 'gql-query-builder';
import axios from 'axios';

import { adminBackend } from '../../../api-config';
import { apiCallBegan } from '../../api';
import {
  customersFailed,
  customersReceived,
  customersRequested,
  counterpartyConnectorsRequested,
  counterpartyConnectorsReceived,
  counterpartyConnectorsFailed,
} from '.';
import { adminApiBaseConfig } from '../../../utils/apiUtils';

export const fetchCustomersWithContracts = () =>
  apiCallBegan({
    url: adminBackend,
    data: gql.query({
      operation: 'getCustomersWithContracts',
      fields: ['id', 'name'],
    }),
    onStart: customersRequested.type,
    onSuccess: customersReceived.type,
    onFailure: customersFailed.type,
  });

export const fetchCounterPartyConnectors = () =>
  apiCallBegan({
    url: adminBackend,
    data: gql.query({
      operation: 'listCounterPartyConnectors',
      fields: ['id', 'productName', 'uniqueId', 'counterPartyId'],
    }),
    onStart: counterpartyConnectorsRequested.type,
    onSuccess: counterpartyConnectorsReceived.type,
    onFailure: counterpartyConnectorsFailed.type,
  });

export const getReport = payload => {
  const variables = { ...payload };
  const query =
    'query ($to: DateTimeOffset!, $from: DateTimeOffset!, $customerIds: [Int], $counterpartyConnectorIds: [String]) {prepareReport(params: {to: $to, from: $from, customerIds: $customerIds, counterpartyConnectorIds: $counterpartyConnectorIds})}';
  const response = axios({
    ...adminApiBaseConfig,
    data: JSON.stringify({ variables, query }),
  });
  return response;
};
