import React, { useState, useEffect } from 'react';
import { Radio, Space, Col, Button, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import useIsFirstRender from '../../../hooks/useIsFirstRender';
import {
  fetchChildLegalEntitiesList,
  fetchCountries,
} from '../../../store/entities/company/queries';
import { PAGESIZE } from '../../../utils/constants';
import { relationTypes } from '../../../utils/constants';

const entityTypeFilters = {
  companyGroup: [
    relationTypes.parent,
    relationTypes.subsidiary,
    relationTypes.associatedCompany,
  ],
  partnersCustomers: [
    relationTypes.partner,
    relationTypes.customer,
    relationTypes.onboarding,
  ],
};
const legalEntitiesTableState = {
  pagination: {
    pageSize: PAGESIZE,
    pageIndex: 0,
  },
};

const LegalEntityTableFilter = ({ entitiesType, tableState }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { company, legalEntityCountries } = useSelector(state => state.company);
  const [isSecondRender, setIsSecondRender] = useIsFirstRender();

  const [statusFilter, setStatusFilter] = useState(0);
  const [localityFilter, setLocalityFilter] = useState('BOTH');

  useEffect(() => {
    dispatch(fetchCountries());
  }, [dispatch]);

  const composeFilters = (status, locality) => {
    const currentTableState = tableState ? tableState : legalEntitiesTableState;
    const allFilters = [
      {
        propertyName: 'parentLegalEntityId',
        value: `${company.id}`,
      },
      {
        propertyName: 'relationTypeId',
        set: entityTypeFilters[entitiesType],
      },
    ];

    if (status !== 0) {
      allFilters.push({
        propertyName: 'relationStatusId',
        set: [status],
      });
    }

    if (locality === 'LOCAL' || locality === 'FOREIGN') {
      allFilters.push({
        propertyName: 'childLegalEntity.countryCodeISOAlpha3',
        [locality === 'LOCAL'
          ? 'value'
          : 'notValue']: company.countryCodeISOAlpha3,
      });
    }

    dispatch(fetchChildLegalEntitiesList(currentTableState, allFilters));
  };

  useEffect(() => {
    if (company && legalEntityCountries && !isSecondRender) {
      setIsSecondRender(true);
      composeFilters(statusFilter, localityFilter);
    }
  }, [
    statusFilter,
    localityFilter,
    company,
    legalEntityCountries,
    isSecondRender,
    setIsSecondRender,
  ]);

  useEffect(() => {
    if (tableState) composeFilters(statusFilter, localityFilter);
  }, [tableState]);

  useEffect(() => {
    if (isSecondRender && company) composeFilters(statusFilter, localityFilter);
  }, [company]);

  const handleInitiateNew = () => {
    history.push(
      `/${language}/kyc/${
        entitiesType === 'companyGroup' ? 'company/search' : 'partners/search'
      }`
    );
  };

  const handleRadioChange = e => {
    tableState.pagination.pageIndex = 0;
    if (e.target.name === 'locality') {
      setLocalityFilter(e.target.value);
      composeFilters(statusFilter, e.target.value);
      return;
    }
    setStatusFilter(e.target.value);
    composeFilters(e.target.value, localityFilter);
  };

  return (
    <Row
      className="space-bottom-lg space-between"
      align="middle"
      gutter={[16, 16]}
    >
      <Col>
        <Space size={50}>
          {company && legalEntityCountries && (
            <Radio.Group
              name="locality"
              size="small"
              onChange={handleRadioChange}
            >
              <Radio.Button checked={localityFilter === 'LOCAL'} value="LOCAL">
                {t('partners.table.filter.local')}
              </Radio.Button>
              <Radio.Button checked={localityFilter === 'BOTH'} value="BOTH">
                {t('partners.table.filter.both')}
              </Radio.Button>
              <Radio.Button
                checked={localityFilter === 'FOREIGN'}
                value="FOREIGN"
              >
                {t('partners.table.filter.foreign')}
              </Radio.Button>
            </Radio.Group>
          )}
          <Radio.Group name="status" size="small" onChange={handleRadioChange}>
            <Radio.Button checked={statusFilter === 2} value={2}>
              {t('partners.table.filter.existing')}
            </Radio.Button>
            <Radio.Button checked={statusFilter === 0} value={0}>
              {t('partners.table.filter.all')}
            </Radio.Button>
            {entitiesType === 'companyGroup' ? null : (
              <Radio.Button checked={statusFilter === 1} value={1}>
                {t('partners.table.filter.onboarding')}
              </Radio.Button>
            )}
          </Radio.Group>
        </Space>
      </Col>
      <Col>
        <Button type="primary" onClick={handleInitiateNew}>
          {t('partners.button.initiateNew')}
        </Button>
      </Col>
    </Row>
  );
};

export default LegalEntityTableFilter;
