import { Modal } from 'antd';

const { confirm } = Modal;

export const confirmationModal = (action, translate, submit, cancel) => {
  confirm({
    title: translate(`new.service.confirm${action}`),
    okText: translate(`new.service.confirm${action}.btn`),
    cancelText: translate('global.btn.cancel'),
    onOk() {
      submit();
    },
    onCancel() {
      cancel();
    },
  });
};
