import React from 'react';
import { Layout } from 'antd';
import { useSelector } from 'react-redux';

import Sidebar from '../components/global/Sidebar';
import Header from '../components/global/Header';

const { Content } = Layout;

const AppLayout = ({ children }) => {
  const { user } = useSelector(state => state.user);

  return (
    <Layout style={{ minHeight: '100vh' }}>
      {user ? <Sidebar /> : null}
      <Layout>
        <Header />
        <Content>{children}</Content>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
