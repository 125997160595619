import React, { useState } from 'react';
import { Modal, Divider, Radio, Typography, Row, Col } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { chooseMember, setIsMemberModalVisible } from '../../store/user';
import { showErrorNotification } from '../../utils/showNotification';

const { Text } = Typography;

const RoleSelectionModal = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { isMemberModalVisible, members, user } = useSelector(
    state => state.user
  );

  const [selectedRole, setSelectedRole] = useState(() => {
    if (!user.MemberId) return members[0]?.id;

    return parseInt(user.MemberId);
  });

  const firstname = user.Name.split(' ')[0];

  const handleRoleSelect = e => {
    setSelectedRole(e.target.value);
  };

  const handleOk = async () => {
    try {
      await dispatch(chooseMember(selectedRole));
      dispatch(setIsMemberModalVisible(false));
      history.push(`/${language}/kyc/company`);
    } catch (error) {
      showErrorNotification(t('general.chooseMember.error'));
    }
  };

  const modalTitle = (
    <Trans i18nKey="authentication.title">
      Hello, {{ firstname }}!<br />
      Please select your active role:
    </Trans>
  );

  return (
    <Modal
      title={modalTitle}
      visible={isMemberModalVisible}
      closable={false}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ className: 'btn-secondary' }}
      onOk={handleOk}
    >
      <Radio.Group
        onChange={handleRoleSelect}
        defaultValue={selectedRole}
        style={{ display: 'block' }}
      >
        {members.map((member, idx) => (
          <React.Fragment key={member.id}>
            <Row align="middle" gutter={[8, 0]}>
              <Col>
                <Radio value={member.id} className="radio-custom" />
              </Col>
              <Col>
                <Text
                  strong
                >{`${member.customer.name} (${member.customer.accountNumber})`}</Text>
                <br />
                <Text>
                  {t('general.user.role')}:{' '}
                  {t(`general.user.role.${member.role.name}`)}
                </Text>
              </Col>
            </Row>
            {idx !== members.length - 1 ? <Divider /> : null}
          </React.Fragment>
        ))}
      </Radio.Group>
    </Modal>
  );
};

export default RoleSelectionModal;
