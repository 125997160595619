import React from 'react';
import { useSelector } from 'react-redux';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';

const { CheckableTag } = Tag;

const DomainTags = ({ selectedDomains, handleCheckboxChange }) => {
  const { t } = useTranslation();
  const {
    resources: { domains: allDomains },
  } = useSelector(state => state.resources);

  return allDomains.map(({ id, title }) => (
    <CheckableTag
      className="tag-lg"
      key={id}
      checked={selectedDomains.includes(id)}
      onChange={checked => handleCheckboxChange(id, checked)}
    >
      {t(title)}
    </CheckableTag>
  ));
};

export default DomainTags;
