import axios from 'axios';
import * as gql from 'gql-query-builder';

import { adminApiBaseConfig } from '../../../utils/apiUtils';

export const cloneExistingService = id => {
  const response = axios({
    ...adminApiBaseConfig,
    data: gql.mutation({
      operation: 'cloneService',
      variables: {
        serviceId: {
          value: id,
          type: 'Int!',
        },
      },
      fields: ['id'],
    }),
  });
  return response;
};
