import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  fetchDomainDataBlocks,
  deleteDomain,
  fetchDataBlockInfo,
  deleteDataBlock,
  updateServiceDataBlockHeading,
  fetchDataBlockReferenceSources,
  deleteCurrentService,
  updateServiceDataBlock,
  fetchManualFieldData,
  saveDataBlock,
} from './thunkApis';

export const fetchDomainDBs = createAsyncThunk(
  'createService/fetchDomainDBs',
  async domainId => {
    const response = await fetchDomainDataBlocks(domainId);
    return response.data;
  }
);

export const deleteServiceDomain = createAsyncThunk(
  'createService/deleteServiceDomain',
  async props => {
    const response = await deleteDomain(props.serviceDomainId);
    return response.data;
  }
);

export const fetchDataBlock = createAsyncThunk(
  'createService/fetchDataBlock',
  async props => {
    const response = await fetchDataBlockInfo(props);
    return response.data;
  }
);

export const deleteServiceDataBlock = createAsyncThunk(
  'createService/deleteDataBlock',
  async props => {
    const response = await deleteDataBlock(props.id);
    return response.data;
  }
);

export const updateHeading = createAsyncThunk(
  'createService/updateHeading',
  async props => {
    const response = await updateServiceDataBlockHeading(props.requestParams);
    return response.data;
  }
);

export const getReferenceSources = createAsyncThunk(
  'createService/getReferenceSources',
  async props => {
    const response = await fetchDataBlockReferenceSources(props.id);
    return response.data;
  }
);

export const deleteService = createAsyncThunk(
  'createService/deleteService',
  async (id, { rejectWithValue }) => {
    try {
      const response = await deleteCurrentService(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const operateDataBlock = createAsyncThunk(
  'createService/operateDataBlock',
  async ({ type, dataBlockInfo }) => {
    const response = await updateServiceDataBlock({ type, dataBlockInfo });
    return response.data;
  }
);

export const getManualField = createAsyncThunk(
  'createService/getManualField',
  async props => {
    const response = await fetchManualFieldData(props.id);
    return response.data;
  }
);

export const updateDataBlock = createAsyncThunk(
  'createService/updateDataBlock',
  async props => {
    const response = await saveDataBlock(props.payload);
    return response.data;
  }
);
