import { createAsyncThunk } from '@reduxjs/toolkit';

import { cloneExistingService } from './thunkApi';

export const cloneService = createAsyncThunk(
  'serviceManagement/cloneService',
  async serviceId => {
    const response = await cloneExistingService(serviceId);
    return response.data;
  }
);
