import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Space, Button, Tooltip } from 'antd';
import {
  EditOutlined,
  StopOutlined,
  CheckCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';

import { updateServiceStatus } from '../../store/entities/serviceManagement/queries';
import {
  resetServiceState,
  setFormType,
} from '../../store/entities/createService';
import { cloneService } from '../../store/entities/serviceManagement/thunks';
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../utils/showNotification';

const ServiceTableActions = ({ id, status, version, versions }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const dispatch = useDispatch();
  const history = useHistory();

  const handleEdit = id => {
    dispatch(resetServiceState());
    dispatch(setFormType('edit'));
    history.push(`/${language}/admin/service/edit/${id}`);
  };

  const handleCloneService = async id => {
    const result = await dispatch(cloneService(id));

    if (cloneService.fulfilled.match(result)) {
      showSuccessNotification(t('service.management.clone.success'));
      const newServiceId = result.payload.data.cloneService.id;
      dispatch(resetServiceState());
      dispatch(setFormType('edit'));
      history.push(`/${language}/admin/service/edit/${newServiceId}`);
    } else {
      showErrorNotification(t('service.management.clone.error'));
    }
  };

  const handleStatusToggle = (id, serviceId) => {
    switch (serviceId) {
      case 2:
        dispatch(updateServiceStatus(id, 3));
        break;
      case 3:
        dispatch(updateServiceStatus(id, 2));
        break;
      default:
        break;
    }
  };

  let hasNewerVersion = false;
  let isStatusToggleAllowed = false;

  if (versions.length > 1) {
    const sortedVersions = versions
      .slice()
      .sort((a, b) => a.version - b.version);

    for (const serviceVersion of sortedVersions) {
      if (serviceVersion.version > version) {
        hasNewerVersion = true;
        if (hasNewerVersion && serviceVersion.statusId === 1)
          isStatusToggleAllowed = true;
        break;
      }
    }
    if (!hasNewerVersion) isStatusToggleAllowed = true;
  } else {
    isStatusToggleAllowed = true;
  }

  return (
    <Space>
      <Tooltip title={t('service.management.table.tooltip.edit')}>
        <Button
          className="btn-action"
          type="link"
          icon={<EditOutlined />}
          onClick={() => handleEdit(id)}
        />
      </Tooltip>
      {status.id === 2 && !hasNewerVersion && (
        <Tooltip title={t('service.management.table.tooltip.addNew')}>
          <Button
            className="btn-action"
            type="link"
            icon={<PlusOutlined />}
            onClick={() => handleCloneService(id)}
          />
        </Tooltip>
      )}
      {(status.id === 3 || status.id === 2) && isStatusToggleAllowed ? (
        <Tooltip
          title={
            status.id === 2
              ? t('service.management.table.tooltip.disable')
              : status.id === 3
              ? t('service.management.table.tooltip.enable')
              : ''
          }
        >
          <Button
            className="btn-action"
            type="link"
            icon={status.id === 3 ? <CheckCircleOutlined /> : <StopOutlined />}
            onClick={() => handleStatusToggle(id, status.id)}
          />
        </Tooltip>
      ) : null}
    </Space>
  );
};

export default ServiceTableActions;
