import React from 'react';
import { Menu, Dropdown, Button, Space } from 'antd';
import {
  CloseOutlined,
  DeleteOutlined,
  EllipsisOutlined,
  SaveOutlined,
  ArrowRightOutlined,
  ArrowLeftOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { deleteService } from '../../store/entities/createService/thunks';
import {
  showErrorNotification,
  showInfoNotification,
  showSuccessNotification,
} from '../../utils/showNotification';

const DropDownMenuInner = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const dispatch = useDispatch();
  const { serviceId } = useSelector(state => state.createService);
  const history = useHistory();

  const handleCancel = () => {
    history.push(`/${language}/admin/service`);
  };

  const handleDelete = async () => {
    const resultAction = await dispatch(deleteService(serviceId));

    if (deleteService.fulfilled.match(resultAction)) {
      showSuccessNotification(t('new.service.delete.success'));
      history.push(`/${language}/admin/service`);
    } else {
      resultAction.payload?.error === 'ForeignKeyViolationExceptionMessage'
        ? showInfoNotification(t('new.service.delete.error.not.allowed'))
        : showErrorNotification(t('new.service.delete.error'));
    }
  };

  return (
    <Menu>
      <Menu.Item key="2" icon={<CloseOutlined />}>
        <a target="_blank" rel="noopener noreferrer" onClick={handleCancel}>
          {t('global.btn.cancel')}
        </a>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="3" icon={<DeleteOutlined />}>
        <a target="_blank" rel="noopener noreferrer" onClick={handleDelete}>
          {t('global.btn.delete')}
        </a>
      </Menu.Item>
    </Menu>
  );
};

const DropdownMenu = ({ disabled }) => (
  <Dropdown
    disabled={disabled}
    key="more"
    placement="bottomRight"
    overlay={<DropDownMenuInner />}
  >
    <Button type="link">
      <EllipsisOutlined
        style={{
          fontSize: 20,
          verticalAlign: 'top',
        }}
      />
    </Button>
  </Dropdown>
);

const FormMenu = ({ handleSubmit, handleStepChange }) => {
  const { t } = useTranslation();
  const {
    formStep,
    domainsData,
    dataBlockActionLoading,
    isTitleInputActive,
    status,
  } = useSelector(state => state.createService);

  const serviceHasSelectedDataBlocks = domainsData.some(
    domain => domain.chosenBlocks.length
  );

  return (
    <Space>
      {formStep === 0 && status !== 2 ? (
        <Button
          type="default"
          icon={<SaveOutlined />}
          onClick={() => handleSubmit(1)}
        >
          {t('global.btn.save')}
        </Button>
      ) : null}
      <Button
        type="default"
        icon={formStep === 0 ? <ArrowRightOutlined /> : <ArrowLeftOutlined />}
        disabled={
          !serviceHasSelectedDataBlocks ||
          dataBlockActionLoading ||
          isTitleInputActive
        }
        onClick={() => handleStepChange(formStep === 0 ? 1 : 0)}
      >
        {formStep === 0
          ? t('new.service.next.step')
          : t('new.service.previous.step')}
      </Button>
      <Button
        className="btn-secondary"
        onClick={() => handleSubmit(2)}
        disabled={isTitleInputActive}
      >
        {t('global.btn.publish')}
      </Button>
      <DropdownMenu disabled={isTitleInputActive} key="more" />
    </Space>
  );
};

export default FormMenu;
