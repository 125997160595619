import React from 'react';
import { Modal, Row, Typography, List, Spin } from 'antd';
import { useTranslation, Trans } from 'react-i18next';

const { Title } = Typography;

const ConnectorModal = ({
  isVisible,
  connector,
  handleCancel,
  handleOk,
  isPriceVisibleToUser,
  isRequestStarting,
}) => {
  const { productName, dataBlocks, price: totalPrice } = connector;
  const { t } = useTranslation();

  return (
    <Modal
      visible={isVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={t('global.btn.confirm')}
      cancelText={t('global.btn.cancel')}
      okButtonProps={{ disabled: isRequestStarting }}
      cancelButtonProps={{ disabled: isRequestStarting }}
      width={600}
    >
      <Spin spinning={isRequestStarting}>
        <Row>
          <Title level={4}>
            {isPriceVisibleToUser ? (
              <Trans i18nKey="questionnaire.connector.confirmation">
                Are you sure you want to initiate the data request from{' '}
                <Trans i18nKey={productName}>{{ productName }}</Trans>? Your
                account will be billed {{ totalPrice }}
                €.
              </Trans>
            ) : (
              `${t('questionnaire.connector.confirmation.no.price')} ${t(
                productName
              )}?`
            )}
          </Title>
        </Row>
        <List
          header={t('questionnaire.connector.dataBlocks')}
          split={false}
          className="grouped-list"
          itemLayout="vertical"
        >
          {dataBlocks.map(title => (
            <List.Item key={title}>{t(title)}</List.Item>
          ))}
        </List>
      </Spin>
    </Modal>
  );
};

export default ConnectorModal;
