import React from 'react';

import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

const IconWithTooltip = ({ name, icon }) => {
  const { t } = useTranslation();
  return (
    <Tooltip
      placement="top"
      key={name}
      title={() => <>{t(`onboarding.questionnaire.tooltip.${name}`)}</>}
    >
      {icon}
    </Tooltip>
  );
};

export default IconWithTooltip;
