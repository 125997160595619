import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  fetchDownloadFile,
  fetchClassificationValue,
  postComment,
  postManualData,
  fetchQuestionnairePDF,
  fillQuestionnaire,
  saveQuestionnaireStatus,
  getIdmRequests,
  createServiceDataBlockLexisNexisRequests,
  createQdbrLexisNexisRequest,
} from './thunkApis';

export const saveManuallyInsertedData = createAsyncThunk(
  'questionnaire/saveManuallyInsertedData',
  async props => {
    const response = await postManualData(props.payload);
    return response.data;
  }
);

export const saveComment = createAsyncThunk(
  'questionnaire/saveComment',
  async props => {
    const response = await postComment(props.payload);
    return response.data;
  }
);

export const getDownloadUrl = createAsyncThunk(
  'questionnaire/getDownloadUrl',
  async id => {
    const response = await fetchDownloadFile(id);
    return response.data;
  }
);

export const getQuestionnairePDF = createAsyncThunk(
  'questionnaire/getQuestionnairePDF',
  async ({ id, date, language }) => {
    const response = await fetchQuestionnairePDF(id, date, language);
    return response.data;
  }
);

export const getClassification = createAsyncThunk(
  'questionnaire/getNACECode',
  async payload => {
    const response = await fetchClassificationValue(payload);
    return response.data;
  }
);

export const startFillQuestionnaire = createAsyncThunk(
  'questionnaire/startFillQuestioannire',
  async ({ questionnaireId, connectorId, language }) => {
    const response = await fillQuestionnaire(
      questionnaireId,
      connectorId,
      language
    );
    return response.data;
  }
);

export const setQuestionnaireStatus = createAsyncThunk(
  'questionnaire/setQuestionnaireStatus',
  async ({ questionnaireId, statusId }) => {
    const response = await saveQuestionnaireStatus(questionnaireId, statusId);
    return response.data;
  }
);

export const fetchIdmRequests = createAsyncThunk(
  'questionnaire/fetchIdmRequests',
  async questionnaireId => {
    const response = await getIdmRequests(questionnaireId);
    return response.data;
  }
);

export const createSdbLNRequests = createAsyncThunk(
  'questionnaire/createSdbLNRequests',
  async payload => {
    const response = await createServiceDataBlockLexisNexisRequests(payload);
    return response.data;
  }
);

export const createQdbrRequest = createAsyncThunk(
  'questionnaire/createQdbrRequest',
  async ({ questionnaireId, qdbrId, requestCategoryIds }) => {
    const response = await createQdbrLexisNexisRequest(
      questionnaireId,
      qdbrId,
      requestCategoryIds
    );
    return response.data;
  }
);
