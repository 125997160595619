import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'legalEntity',
  initialState: {
    legalEntity: null,
    legalEntityLoading: false,
    legalEntityError: false,
  },
  reducers: {
    legalEntityRequested: state => {
      state.legalEntityLoading = true;
      state.legalEntityError = false;
    },
    legalEntityRequestFailed: state => {
      state.legalEntityLoading = false;
      state.legalEntityError = true;
    },
    legalEntityReceived: (state, { payload }) => {
      state.legalEntity =
        payload.data.legalEntity.length > 0
          ? payload.data.legalEntity[0]
          : null;
      state.legalEntityLoading = false;
      state.legalEntityError = false;
    },
    setLegalEntity: (state, { payload }) => {
      state.legalEntity = payload;
      state.legalEntityLoading = false;
      state.legalEntityError = false;
    },
  },
});

export const {
  legalEntityRequested,
  legalEntityRequestFailed,
  legalEntityReceived,
  setLegalEntity,
} = slice.actions;

export default slice.reducer;
