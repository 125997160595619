import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export const useRefresh = (path, resetRoute = '/kyc/partners/search') => {
  const history = useHistory();
  let handler;

  const refresh = () => {
    history.push(resetRoute);

    handler = setTimeout(() => history.push(path), 10);
  };

  useEffect(() => {
    return () => handler && clearTimeout(handler);
  }, [handler]);

  return refresh;
};
