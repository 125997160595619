import React, { useContext, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Card, Row, Space, Tag, Tooltip, Button } from 'antd';
import {
  EditOutlined,
  InfoCircleOutlined,
  MessageOutlined,
} from '@ant-design/icons';

import {
  DDMMYYYY_HHmm,
  questionnaireStatus,
  CounterParty,
} from '../../../utils/constants';
import colors from '../../../utils/colors';
import CardActionsProvider, { CardActionsContext } from './CardActionsProvider';

import CardContentKeyValue from './CardContentKeyValue';
import CardContentTable from './CardContentTable';
import CommentsModal from './CommentsModal';
import HelpTextModal from './HelpTextModal';
import IconWithTooltip from './IconWithTooltip';
import ManualDataEntryModal from './ManualDataEntryModal';
import SourceWithPopover from './SourceWithPopover';
import LNInitiationModal from './LNInitiationModal';
import LNResultsModal from './LNResultsModal';
import LNDetailsModal from './LNDetailsModal';

const QuestionnaireCard = ({
  block,
  layout,
  chapterIdx,
  subChapterIdx,
  LNPrice,
}) => {
  const { enabledCounterPartyConnectors } = useSelector(state => state.company);
  const {
    questionnaire: { statusId },
  } = useSelector(state => state.questionnaire);
  const {
    id: serviceDataBlockId,
    title,
    comments = [],
    serviceDataAttributes,
    dataBlock,
    helpText,
    questionnaireDataBlockResult,
    isManualDataBlock,
    questionnaireDataAttributeResults,
  } = block;
  const {
    toggleComments,
    toggleHelpText,
    toggleLNInitiationModal,
    toggleLNResults,
    isLNResultsOpen,
    isLNDetailsOpen,
  } = useContext(CardActionsContext);
  const { t, i18n } = useTranslation();

  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [LNInitiationModalType, setLNInitiationModalType] = useState(
    'multiple'
  );
  const [LNModalIsLegalEntity, setLNModalIsLegalEntity] = useState(false);

  const [qdbrId, setQdbrId] = useState(null);
  const [selectedLNResult, setSelectedLNResult] = useState({});

  const sourceFile =
    questionnaireDataBlockResult?.[questionnaireDataBlockResult.length - 1]
      ?.questionnaireDataBlockResultFiles?.[0];
  const sourceName =
    questionnaireDataBlockResult?.[questionnaireDataBlockResult.length - 1]
      ?.createdBy?.kYCUser;
  const hasComments = comments.length;
  const hasHelpText = helpText || dataBlock?.serviceProviderHelpText;
  const date =
    questionnaireDataBlockResult?.[questionnaireDataBlockResult.length - 1]
      ?.retrievedAt ??
    questionnaireDataBlockResult?.[questionnaireDataBlockResult.length - 1]
      ?.manuallyModifiedAt;
  const questionnaireDataBlockResultId =
    questionnaireDataBlockResult.id ||
    questionnaireDataBlockResult[questionnaireDataBlockResult.length - 1].id;
  const isEnteredManually =
    questionnaireDataBlockResult?.[questionnaireDataBlockResult.length - 1]
      ?.isEnteredManually;

  const dataBlockRowCount = questionnaireDataBlockResult?.length ?? 0;

  const getConnectorName = x =>
    x?.dataBlockReferenceSource?.counterpartyConnector?.productName;
  const connectorName =
    getConnectorName(questionnaireDataBlockResult) ??
    getConnectorName(
      questionnaireDataBlockResult?.[questionnaireDataBlockResult.length - 1]
    );

  const hasLexisNexis = dataBlock?.isLexisNexisInput;
  const lexisNexisConnectorEnabled = enabledCounterPartyConnectors.some(
    x => x.counterPartyId === CounterParty.LEXISNEXIS
  );
  const entityTypeAttributeId = serviceDataAttributes.find(
    ({ dataAttribute }) => dataAttribute?.idmName === 'EntityType'
  )?.id;

  const isLegalEntityValue = ciValue => ciValue === 'J' || ciValue === 'LE';

  const legalEntityCount =
    hasLexisNexis && lexisNexisConnectorEnabled
      ? questionnaireDataAttributeResults.filter(
          ({ serviceDataAttributeId, cI_Value }) =>
            serviceDataAttributeId === entityTypeAttributeId &&
            isLegalEntityValue(cI_Value)
        )?.length ?? 0
      : 0;

  const hasLNResults = questionnaireDataBlockResult.some(
    ({ LNResults }) =>
      LNResults?.PEPs || LNResults?.Sanctions || LNResults?.Watchlists
  );

  const handleModal = () => setIsEditModalVisible(!isEditModalVisible);

  const handleLNInitiationModal = (type, qdbrId, isLegalEntity) => {
    if (!lexisNexisConnectorEnabled) return;
    setQdbrId(qdbrId);
    if (type === 'result') {
      toggleLNResults();
    } else {
      setLNInitiationModalType(type);
      setLNModalIsLegalEntity(isLegalEntity);
      toggleLNInitiationModal();
    }
  };

  const getActions = () => {
    const actions = [
      <SourceWithPopover
        sourceFile={sourceFile}
        referenceSource={connectorName}
        name={sourceName}
        questionnaireDataBlockResultId={questionnaireDataBlockResultId}
        isEnteredManually={isEnteredManually}
      />,
      <IconWithTooltip
        name="info"
        icon={
          <InfoCircleOutlined
            onClick={toggleHelpText}
            style={{
              color: colors[hasHelpText ? 'success' : 'gray'],
            }}
          />
        }
      />,
    ];

    const questionnaireIsUnfinished = statusId !== questionnaireStatus.finished;

    if (hasComments || questionnaireIsUnfinished) {
      actions.push(
        <IconWithTooltip
          name="comments"
          icon={
            <MessageOutlined
              onClick={toggleComments}
              style={{
                color: colors[hasComments ? 'success' : 'gray'],
              }}
            />
          }
        />
      );
    }

    if (dataBlockRowCount > 1 || questionnaireIsUnfinished) {
      const tooltipName = questionnaireIsUnfinished ? 'edit' : 'view';

      actions.push(
        <IconWithTooltip
          name={tooltipName}
          icon={
            <EditOutlined
              onClick={handleModal}
              style={{
                color: colors['success'],
              }}
            />
          }
        />
      );
    }

    return actions;
  };

  return (
    <>
      <Card
        bordered={false}
        className={`questionnaire-card ${layout === 'table' ? 'table' : ''}`}
        title={
          <Row justify="space-between">
            <Space>
              <span>{i18n.exists(title) ? t(title) : title}</span>
            </Space>
            <Space>
              {hasLexisNexis ? (
                hasLNResults ? (
                  <Tag className="tag-sm">
                    {t('lexis.nexis.screening.output')}
                  </Tag>
                ) : lexisNexisConnectorEnabled ? (
                  <Button
                    className="btn-secondary"
                    onClick={() => handleLNInitiationModal('multiple')}
                    disabled={statusId === questionnaireStatus.finished}
                  >
                    {t('lexis.nexis.btn.screen.all')}
                  </Button>
                ) : null
              ) : null}
              {questionnaireDataBlockResult && (
                <Tooltip title={t('questionnaire.card.date.retrieved.at')}>
                  <Tag className="tag-sm">
                    {moment(date).format(DDMMYYYY_HHmm)}
                  </Tag>
                </Tooltip>
              )}
            </Space>
          </Row>
        }
        actions={getActions()}
      >
        {layout === 'table' ? (
          <CardContentTable
            dataBlock={block}
            hasLexisNexis={hasLexisNexis}
            lexisNexisConnectorEnabled={lexisNexisConnectorEnabled}
            handleModal={handleLNInitiationModal}
            questionnaireStatusId={statusId}
          />
        ) : (
          <CardContentKeyValue
            dataAttributes={serviceDataAttributes}
            t={t}
            i18n={i18n}
          />
        )}
      </Card>
      <CommentsModal
        comments={comments}
        chapterIdx={chapterIdx}
        subChapterIdx={subChapterIdx}
        questionnaireDataBlockResultId={questionnaireDataBlockResultId}
        serviceDataBlockId={serviceDataBlockId}
        questionnaireStatusId={statusId}
      />
      {hasHelpText ? (
        <HelpTextModal
          title={title}
          helpText={helpText}
          serviceProviderHelpText={dataBlock?.serviceProviderHelpText ?? null}
        />
      ) : null}
      {block ? (
        <ManualDataEntryModal
          dataBlock={block}
          visible={isEditModalVisible}
          onCancel={handleModal}
          isManualDataBlock={isManualDataBlock}
          chapterIdx={chapterIdx}
          subChapterIdx={subChapterIdx}
          isViewMode
          questionnaireStatusId={statusId}
        />
      ) : null}
      {hasLexisNexis && lexisNexisConnectorEnabled ? (
        <LNInitiationModal
          type={LNInitiationModalType}
          LECount={legalEntityCount}
          resultCount={questionnaireDataBlockResult.length}
          price={LNPrice}
          serviceDataBlockId={serviceDataBlockId}
          qdbrId={qdbrId}
          isLegalEntity={LNModalIsLegalEntity}
        />
      ) : null}
      {isLNResultsOpen ? (
        <LNResultsModal
          qdbrs={questionnaireDataBlockResult}
          qdbrId={qdbrId}
          setSelectedLNResult={setSelectedLNResult}
          price={LNPrice}
          questionnaireStatusId={statusId}
        />
      ) : null}
      {isLNDetailsOpen ? (
        <LNDetailsModal
          qdbrs={questionnaireDataBlockResult}
          qdbrId={qdbrId}
          selectedLNResult={selectedLNResult}
        />
      ) : null}
    </>
  );
};

const CardWithControls = ({
  block,
  layout,
  chapterIdx,
  subChapterIdx,
  LNPrice,
}) => (
  <CardActionsProvider>
    <QuestionnaireCard
      layout={layout}
      block={block}
      chapterIdx={chapterIdx}
      subChapterIdx={subChapterIdx}
      LNPrice={LNPrice}
    />
  </CardActionsProvider>
);

export default CardWithControls;
