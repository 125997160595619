import React from 'react';
import { Row, Space, Col, Typography, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { resetServiceState } from '../../store/entities/createService';

const { Title } = Typography;

const ServiceManagementHeader = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(resetServiceState());
    history.push(`/${language}/admin/service/new`);
  };

  return (
    <div className="container space-vertical-lg">
      <Row gutter={[16, 16]}>
        <Space className="space-between">
          <Col>
            <Space size={20} className="space-left-lg">
              <Title>{t('service.management.title')}</Title>
            </Space>
          </Col>
          <Col>
            <Button className="btn-secondary" onClick={handleClick}>
              {t('service.management.button.newService')}
            </Button>
          </Col>
        </Space>
      </Row>
    </div>
  );
};

export default ServiceManagementHeader;
