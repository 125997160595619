import React from 'react';
import { Breadcrumb } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const viewsWithId = [
  // Edit existing service /admin/service/edit/144
  'edit',

  // /kyc/partners/search - Initiate new
  // /kyc/partners/search/392 - customer view, task example
  'search',

  // /kyc/company/group/341 -- click "My Company and group", then some partner below.
  'group',

  // /kyc/shared/withMe/questionnaire/188,
  // /kyc/shared/byMe/questionnaire/14
  // path="/:lang/kyc/company/mykyc/questionnaire/:questionnaireId" from Routes
  // path="/:lang/kyc/partners/search/:customerId/questionnaire/:questionnaireId" from Routes
  'questionnaire',

  // /kyc/update/legalEntity/5/counterParty/3
  // todo: SK-440: there is no separate /kyc/update/legalEntity/5 route
  'legalEntity',
  'counterParty',

  // /kyc/legalEntityKeyDataView/5
  'legalEntityKeyDataView',
];

const Breadcrumbs = () => {
  const { pathname } = useLocation();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  const getBreadcrumbTitles = () => {
    const pathAsArray = pathname.split('/').slice(2); // leaving out domain and language;

    const breadcrumbArray = [];

    for (let i = 0; i < pathAsArray.length; i++) {
      const part = pathAsArray[i];

      if (isNumeric(part)) {
        const previous = breadcrumbArray[breadcrumbArray.length - 1];
        previous.path += `/${part}`;

        // /kyc/partners/search - Initiate new, /kyc/partners/search/:customerId - customer view
        // these are completely different
        if (previous.name === 'search') {
          // todo: SK-440: maybe fetch customer's (or any other entity's, see viewsWithId) name
          previous.name = 'customer';
        }
      } else {
        breadcrumbArray.push({
          name: part,
          path: part,
        });
      }
    }

    if (breadcrumbArray.length > 1) {
      breadcrumbArray.forEach((element, i) => {
        if (i) {
          const previous = breadcrumbArray[i - 1];
          element.name = `${previous.name}.${element.name}`;
          element.path = `${previous.path}/${element.path}`;
        }
      });
    }
    return breadcrumbArray;
  };

  return (
    <Breadcrumb>
      {getBreadcrumbTitles().map((item, i) => (
        <Breadcrumb.Item key={`${i}breadcrumb`}>
          <Link to={`/${language}/${item.path}`}>
            {t(`nav.header.breadcrumb.${item.name}`)}
          </Link>
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
