import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Typography, Card } from 'antd';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

import { downloadFile } from '../../../utils/downloadFile';
import { getDownloadUrl } from '../../../store/entities/questionnaire/thunks';
import { showErrorNotification } from '../../../utils/showNotification';

const { Title, Text } = Typography;

const colStyles = {
  marginBottom: '1.5rem',
  padding: '0 2rem',
};

const DataBlockResultCards = ({ results, isMultiple }) => {
  const { t } = useTranslation();

  const isMultipleResultsLength =
    isMultiple &&
    results.filter(({ isEnteredManually }) => !isEnteredManually).length;

  return (
    <Row gutter={[16, 0]} align="bottom" justify="space-between">
      {results
        .slice(0)
        .reverse()
        .map((result, idx) =>
          !isMultiple && idx === 0 ? (
            <Col span={12} key={result.id} style={colStyles}>
              {result.attrResults.map(attr => (
                <Row gutter={[0, 16]} key={attr.id}>
                  <Col xs={24} md={12}>
                    <Text strong level={4}>
                      {t(attr.cI_Name)}
                    </Text>
                  </Col>
                  {!attr.isManualRadioOption && (
                    <Col xs={24} md={12}>
                      <Text>{attr.cI_Value}</Text>
                    </Col>
                  )}
                </Row>
              ))}
              <DataBlockResultSources result={result} isMultiple={isMultiple} />
            </Col>
          ) : (
            <Col span={12} key={result.id} offset={idx > 1 ? 12 : 0}>
              {(isMultiple && isMultipleResultsLength === idx) ||
              (!isMultiple && idx === 1) ? (
                <Row>
                  <Title level={4}>
                    {t('questionnaire.manual.modal.manual.data')}
                  </Title>
                </Row>
              ) : null}
              <Card className={'questionnaire-result'}>
                {result.attrResults.map(attr => (
                  <Row gutter={[0, 16]} key={attr.id}>
                    <Col xs={24} md={12}>
                      <Text strong level={4}>
                        {t(attr.cI_Name)}
                      </Text>
                    </Col>
                    {!attr.isManualRadioOption && (
                      <Col xs={24} md={12}>
                        <Text>{attr.cI_Value}</Text>
                      </Col>
                    )}
                  </Row>
                ))}
                <DataBlockResultSources result={result} />
              </Card>
            </Col>
          )
        )}
    </Row>
  );
};

const DataBlockResultSources = ({ result }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const referenceSource =
    result.dataBlockReferenceSource?.counterpartyConnector?.productName;

  if (referenceSource && !result.isEnteredManually)
    return (
      <Row gutter={[0, 16]}>
        <Col xs={24} md={12}>
          <Text strong level={4}>
            {t('global.text.source')}
          </Text>
        </Col>
        <Col xs={24} md={12}>
          <Text>{`${t(referenceSource)} ${result.date}`}</Text>
        </Col>
      </Row>
    );

  const handleDownload = async qdbrId => {
    try {
      const result = await dispatch(getDownloadUrl(qdbrId));

      const {
        data: { questionnaireDataBlockResult },
      } = unwrapResult(result);

      downloadFile(
        questionnaireDataBlockResult[0].questionnaireDataBlockResultFiles[0]
          .downloadUrl
      );
    } catch (err) {
      showErrorNotification(t('questionnaire.source.download.error'));
    }
  };

  const getLinkAndFileSource = (qdbrId, sourceFile, translate) => {
    if (!sourceFile || (!sourceFile.link && !sourceFile.fileName))
      return (
        <Row gutter={[0, 16]}>
          <Col xs={24} md={12}>
            <Text strong level={4}>
              {t('global.text.source')}
            </Text>
          </Col>
          <Col xs={24} md={12}>
            <Text>
              {translate('questionnaire.card.source.manually.filled')}
            </Text>
          </Col>
        </Row>
      );

    return (
      <>
        {sourceFile.link ? (
          <Row gutter={[0, 16]}>
            <Col xs={24} md={12}>
              <Text strong level={4}>
                {translate('questionnaire.card.link')}
              </Text>
            </Col>
            <Col xs={24} md={12}>
              <a
                href={sourceFile.link}
                rel="noopener noreferrer"
                target="_blank"
              >
                {sourceFile.link}
              </a>
            </Col>
          </Row>
        ) : null}
        {sourceFile.fileName ? (
          <Row gutter={[0, 16]}>
            <Col xs={24} md={12}>
              <Text strong level={4}>
                {translate('questionnaire.card.download')}
              </Text>
            </Col>
            <Col xs={24} md={12}>
              <Text onClick={() => handleDownload(qdbrId)}>
                {sourceFile.fileName}
              </Text>
            </Col>
          </Row>
        ) : null}
      </>
    );
  };

  return (
    <>
      {getLinkAndFileSource(result.id, result.source, t)}
      <Row gutter={[0, 16]}>
        <Col xs={24} md={12}>
          <Text strong level={4}>
            {t('global.text.addedBy')}
          </Text>
        </Col>
        <Col xs={24} md={12}>
          <Text>{`${result.createdBy} ${result.date}`}</Text>
        </Col>
      </Row>
    </>
  );
};

export default DataBlockResultCards;
