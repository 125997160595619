import axios from 'axios';
import * as gql from 'gql-query-builder';

import { apiBaseConfig } from '../../../utils/apiUtils';

export const postManualData = payload => {
  const response = axios({
    ...apiBaseConfig,
    data: gql.mutation({
      operation: 'questionnaireDataBlockResult',
      variables: {
        input: {
          value: { ...payload },
          type: 'QuestionnaireDataBlockResultInputType!',
        },
      },
      fields: [
        'id',
        'serviceDataBlockId',
        {
          createdBy: [{ kYCUser: ['id', 'firstname', 'lastname'] }],
        },
        {
          childAttributes: [
            'id',
            'cI_Value',
            'serviceDataAttributeId',
            'retrievedAt',
          ],
        },
        {
          questionnaireDataBlockResultFiles: [
            'id',
            'downloadUrl',
            'fileName',
            'link',
          ],
        },
      ],
    }),
  });
  return response;
};

export const postComment = payload => {
  const response = axios({
    ...apiBaseConfig,
    data: gql.mutation({
      operation: 'questionnaireComment',
      variables: {
        input: {
          value: payload,
          type: 'QuestionnaireCommentInputType!',
        },
      },
      fields: [
        'comment',
        'questionnaireDataBlockResultId',
        'createdAt',
        {
          createdBy: [
            {
              customer: ['name'],
            },
            { kYCUser: ['firstname', 'lastname'] },
          ],
        },
      ],
    }),
  });
  return response;
};

export const fetchDownloadFile = id => {
  const response = axios({
    ...apiBaseConfig,
    data: gql.query({
      operation: 'questionnaireDataBlockResult',
      variables: {
        id: {
          value: id,
          type: 'Int!',
        },
      },
      fields: [
        {
          questionnaireDataBlockResultFiles: ['downloadUrl'],
        },
      ],
    }),
  });
  return response;
};

export const fetchQuestionnairePDF = (id, date, language) => {
  const variables = {
    id,
    dateTimeWithTimeZone: date.toString().slice(-6),
    language,
  };
  const query =
    'query ($id: Int!, $dateTimeWithTimeZone: String!, $language: String!) {getFullQuestionnairePDF(id: $id, dateTimeWithTimeZone: $dateTimeWithTimeZone, language: $language)}';
  const response = axios({
    ...apiBaseConfig,
    data: JSON.stringify({ variables, query }),
  });
  return response;
};

export const fetchClassificationValue = payload => {
  const response = axios({
    ...apiBaseConfig,
    data: gql.query({
      operation: `list${
        payload.source === 'NACE' ? 'NACERev2' : 'EMTAK2008'
      }WithPagination`,
      variables: {
        searchParams: {
          value: {
            filter: {
              filterConditions: [
                {
                  propertyName: 'code',
                  value: payload.code,
                },
              ],
            },
          },
          type: 'SearchParamsInputType!',
        },
      },
      fields: [
        {
          result: ['code', 'textLocal', 'textEnglish'],
        },
      ],
    }),
  });
  return response;
};

export const fillQuestionnaire = (questionnaireId, connectorId, language) => {
  const query = JSON.stringify({
    query: `mutation {
      beginStrategy(
        questionnaireId: ${questionnaireId}
        connectorId: "${connectorId}"
        language: "${language}"
      )
    }`,
  });
  const response = axios({
    ...apiBaseConfig,
    data: query,
  });
  return response;
};

export const saveQuestionnaireStatus = (questionnaireId, statusId) => {
  const response = axios({
    ...apiBaseConfig,
    data: gql.mutation({
      operation: 'setQuestionnaireStatus',
      variables: {
        questionnaireId: {
          value: questionnaireId,
          type: 'Int!',
        },
        questionnaireStatusId: {
          value: statusId,
          type: 'Int!',
        },
      },
      fields: ['statusId'],
    }),
  });
  return response;
};

export const getIdmRequests = questionnaireId => {
  const response = axios({
    ...apiBaseConfig,
    data: gql.query({
      operation: 'getIdmRequests',
      variables: {
        questionnaireId: {
          value: questionnaireId,
          type: 'Int',
        },
      },
      fields: [
        'id',
        'createdAt',
        'lastAttemptAt',
        'statusId',
        'statusName',
        'connectorId',
        { member: [{ kYCUser: ['firstname', 'lastname'] }] },
        { connector: ['productName', 'counterPartyId'] },
      ],
    }),
  });
  return response;
};

export const createServiceDataBlockLexisNexisRequests = payload => {
  const { questionnaireId, serviceDataBlockId, requestCategoryIds } = payload;
  const response = axios({
    ...apiBaseConfig,
    data: gql.mutation({
      operation: 'createServiceDataBlockLexisNexisRequests',
      variables: {
        questionnaireId: {
          value: questionnaireId,
          type: 'Int!',
        },
        serviceDataBlockId: {
          value: serviceDataBlockId,
          type: 'Int!',
        },
        requestCategoryIds: {
          value: requestCategoryIds,
          type: '[Int]!',
        },
      },
      fields: ['statusId'],
    }),
  });
  return response;
};

export const createQdbrLexisNexisRequest = (
  questionnaireId,
  qdbrId,
  requestCategoryIds
) => {
  const response = axios({
    ...apiBaseConfig,
    data: gql.mutation({
      operation: 'createQdbrLexisNexisRequests',
      variables: {
        questionnaireId: {
          value: questionnaireId,
          type: 'Int!',
        },
        qdbrId: {
          value: qdbrId,
          type: 'Int!',
        },
        requestCategoryIds: {
          value: requestCategoryIds,
          type: '[Int]!',
        },
      },
      fields: ['statusId'],
    }),
  });
  return response;
};
