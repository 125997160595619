import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Spin } from 'antd';

import { resetLegalEntities } from '../store/entities/company';

import CompanyHeader from '../components/company/CompanyHeader';
import LegalEntityData from '../components/legalentity/LegalEntityData';
import CompanyGroupTable from '../components/company/CompanyGroupTable';

const Company = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { company, companyLoading } = useSelector(state => state.company);

  useEffect(() => {
    return () => {
      dispatch(resetLegalEntities());
    };
  }, [dispatch]);

  return (
    <>
      <Spin size="large" spinning={companyLoading} tip={t('global.loading')}>
        <div className="container">
          {company && !companyLoading && (
            <>
              <CompanyHeader company={company} />
              <LegalEntityData companyData={company} isFirstSource={true} />
              <CompanyGroupTable />
            </>
          )}
        </div>
      </Spin>
    </>
  );
};

export default Company;
