import React, { useEffect } from 'react';
import { Button, Row, Space, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { setListLanguage } from '../../store/entities/translation';
import { fetchLanguageCodes } from '../../store/entities/languageCode';

const TranslationsTableHeader = ({ toggleAddForm }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { listLanguage, listLoading: isTableLoading } = useSelector(
    state => state.translation
  );
  const {
    listUsed: languageCodes,
    listLoading: isLanguageCodesLoading,
  } = useSelector(state => state.languageCode);
  const { Option } = Select;

  useEffect(() => {
    dispatch(fetchLanguageCodes(true));
  }, [dispatch]);

  return (
    <Space direction="vertical">
      <Row>
        <Space>
          <Select
            defaultValue={listLanguage}
            style={{ width: 120 }}
            onChange={value => dispatch(setListLanguage(value))}
            disabled={isTableLoading || isLanguageCodesLoading}
          >
            {languageCodes.map(code => (
              <Option key={code} value={code.toLowerCase()}>
                {code}
              </Option>
            ))}
          </Select>
          <Button onClick={() => toggleAddForm(true)} disabled={isTableLoading}>
            {t('translation.editor.actions.add')}
          </Button>
        </Space>
      </Row>
    </Space>
  );
};

export default TranslationsTableHeader;
