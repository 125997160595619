import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

import { Modal, Form, Spin, DatePicker, Select } from 'antd';

import {
  fetchCounterPartyConnectors,
  fetchCustomersWithContracts,
} from '../../store/entities/billing/queries';
import { fetchReport } from '../../store/entities/billing';
import { downloadFile } from '../../utils/downloadFile';
import { showErrorNotification } from '../../utils/showNotification';

const { RangePicker } = DatePicker;
const { Option } = Select;

const BillingModal = ({ setIsVisible, isVisible }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {
    customersList,
    customersListLoading,
    customersListError,
    counterpartyConnectorsList,
    counterpartyConnectorsLoading,
    counterpartyConnectorsError,
    reportLoading,
  } = useSelector(state => state.billing);

  useEffect(() => {
    if (
      isVisible &&
      !customersList.length &&
      !counterpartyConnectorsList.length
    ) {
      dispatch(fetchCustomersWithContracts());
      dispatch(fetchCounterPartyConnectors());
    }
  }, [
    counterpartyConnectorsList.length,
    customersList.length,
    dispatch,
    isVisible,
  ]);

  useEffect(() => {
    isVisible && form.resetFields();
  }, [customersList, counterpartyConnectorsList, form, isVisible]);

  useEffect(() => {
    if (customersListError) {
      showErrorNotification(t('billing.modal.customers.error'));
    }

    if (counterpartyConnectorsError) {
      showErrorNotification(t('billing.modal.connectors.error'));
    }
  }, [counterpartyConnectorsError, customersListError, t]);

  const defaultCustomers = customersList && customersList.map(({ id }) => id);
  const defaultConnectors =
    counterpartyConnectorsList &&
    counterpartyConnectorsList.map(({ uniqueId }) => uniqueId);

  const onFinish = async data => {
    try {
      const dates = data['date'];
      const formatedData = {
        ...data,
        from: dates[0].format(),
        to: dates[1].format(),
      };

      delete formatedData.date;

      const report = await dispatch(fetchReport(formatedData));
      const {
        data: { prepareReport },
      } = unwrapResult(report);

      downloadFile(prepareReport, 'adminbackend');
    } catch (err) {
      showErrorNotification(t('billing.modal.download.error'));
    }
  };

  const handleOk = async () => {
    form
      .validateFields()
      .then(values => {
        onFinish(values);
      })
      .catch(info => {
        console.info(info);
      });
  };

  return (
    <Modal
      title={t('billing.modal.title')}
      visible={isVisible}
      onCancel={() => setIsVisible(false)}
      okText={t('global.btn.export')}
      onOk={handleOk}
      cancelText={t('global.btn.cancel')}
      okButtonProps={{ className: 'btn-secondary' }}
      forceRender
    >
      <Spin
        spinning={
          customersListLoading || counterpartyConnectorsLoading || reportLoading
        }
      >
        <Form
          form={form}
          name="billing"
          hideRequiredMark
          layout="vertical"
          initialValues={{
            customerIds: defaultCustomers,
            counterpartyConnectorIds: defaultConnectors,
          }}
        >
          <Form.Item
            label={t('billing.modal.date')}
            name="date"
            rules={[
              {
                required: true,
                message: t('questioner.sharing.modal.field.required'),
              },
            ]}
          >
            <RangePicker format="DD.MM.YYYY" />
          </Form.Item>
          <Form.Item
            label={t('billing.modal.customers')}
            name="customerIds"
            rules={[
              {
                required: true,
                message: t('questioner.sharing.modal.field.required'),
              },
            ]}
          >
            <Select
              mode="multiple"
              placeholder={t('billing.modal.placeholder.customers')}
            >
              {customersList
                ? customersList.map(({ id, name }) => (
                    <Option value={id} key={id}>
                      {name}
                    </Option>
                  ))
                : null}
            </Select>
          </Form.Item>
          <Form.Item
            label={t('billing.modal.counterparty.connectors')}
            name="counterpartyConnectorIds"
            rules={[
              {
                required: true,
                message: t('questioner.sharing.modal.field.required'),
              },
            ]}
          >
            <Select
              mode="multiple"
              placeholder={t('billing.modal.placeholder.connectors')}
            >
              {counterpartyConnectorsList
                ? counterpartyConnectorsList.map(
                    ({ productName, uniqueId }) => (
                      <Option value={uniqueId} key={uniqueId}>
                        {t(productName)}
                      </Option>
                    )
                  )
                : null}
            </Select>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default BillingModal;
