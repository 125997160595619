import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { sortBySeqNumber } from '../../../utils';

import { fetchQuestionnaires, fetchServices } from './thunkApis';

export const getQuestionnaires = createAsyncThunk(
  'onboarding/getQuestionnairesList',
  async ({ tableState, id }) => {
    const response = await fetchQuestionnaires(tableState, id);
    return response.data;
  }
);

export const getServices = createAsyncThunk(
  'customer/getServices',
  async country => {
    const response = await fetchServices(country);
    return response.data;
  }
);

const initialState = {
  customer: null,
  customerLoading: false,
  customerError: false,
  list: [],
  listSize: 0,
  questionnairesLoading: false,
  services: [],
  servicesLoading: false,
};

const slice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    customerRequested: state => {
      state.customer = null;
      state.tableState = { pageSize: 10, pageIndex: 0 };
      state.customerLoading = true;
      state.customerError = false;
    },
    customerFailed: state => {
      state.customerLoading = false;
      state.customerError = true;
    },
    customerSuccess: (state, { payload }) => {
      state.customer = payload.data.legalEntityRelation.length
        ? payload.data.legalEntityRelation[0]
        : null;
      state.services = [];
      state.customerError = false;
      state.customerLoading = false;
    },
    savingCustomerRelation: state => {
      state.customerError = false;
      state.customerLoading = true;
    },
    customerRelationSaveSuccess: (state, { payload }) => {
      const {
        relationTypeId,
        relationStatusId,
      } = payload.data.legalEntityRelation;

      state.customer.relationTypeId = relationTypeId;
      state.customer.relationStatusId = relationStatusId;
      state.customerError = false;
      state.customerLoading = false;
    },
    customerRelationStatusSaveSuccess: (state, { payload }) => {
      const {
        relationStatusId,
      } = payload.data.legalEntityRelationUpdateProperty;

      state.customer.relationStatusId = relationStatusId;
      state.customerError = false;
      state.customerLoading = false;
    },
    resetCustomer: state => {
      state.customer = null;
    },
    resetList: state => {
      state.list = [];
      state.listSize = 0;
    },
    resetServicesList: state => {
      state.services = [];
    },
  },
  extraReducers: {
    [getQuestionnaires.pending]: state => {
      state.list = [];
      state.questionnairesLoading = true;
    },
    [getQuestionnaires.fulfilled]: (state, { payload }) => {
      state.list = payload.data.listQuestionnairesWithPagination.result;
      state.listSize = payload.data.listQuestionnairesWithPagination.total;
      state.questionnairesLoading = false;
    },
    [getQuestionnaires.rejected]: state => {
      state.questionnairesLoading = false;
    },
    [getServices.pending]: state => {
      state.servicesLoading = true;
    },
    [getServices.fulfilled]: (state, { payload }) => {
      const services = payload.data.getWithConnectors;

      services.sort((a, b) => a.serviceId - b.serviceId);

      for (const {
        service: { listChapterServiceDataBlocks },
      } of services) {
        listChapterServiceDataBlocks.sort(sortBySeqNumber);

        for (const { childBlocks } of listChapterServiceDataBlocks) {
          childBlocks.sort(sortBySeqNumber);

          for (const { childBlocks: children } of childBlocks) {
            children.sort(sortBySeqNumber);
          }
        }
      }

      state.services = services.length ? services : ['Empty'];
      state.servicesLoading = false;
    },
    [getServices.rejected]: state => {
      state.servicesLoading = false;
    },
  },
});

export const {
  customerRequested,
  customerFailed,
  customerSuccess,
  savingCustomerRelation,
  customerRelationSaveSuccess,
  customerRelationStatusSaveSuccess,
  resetCustomer,
  resetList,
  resetServicesList,
} = slice.actions;

export default slice.reducer;
