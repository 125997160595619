import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { getSharedQuestionnaires } from '../store/entities/sharing';

import { Col, Row, Tabs, Space, Button, Typography, Spin, Table } from 'antd';
import { DATE_FORMAT_LONG } from '../utils/createServiceHelpers';
import { showErrorNotification } from '../utils/showNotification';

const { TabPane } = Tabs;
const { Title } = Typography;

// todo: SK-440: use withMe/ByMe from URL and open correct tab
const Sharing = () => {
  const dispatch = useDispatch();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const history = useHistory();

  const {
    sharedWithMe,
    sharedByMe,
    sharedWithMeLoading,
    sharedByMeLoading,
    sharedWithMeError,
    sharedByMeError,
  } = useSelector(state => state.sharing);

  const [openedTab, setOpenedTab] = useState('with');

  useEffect(() => {
    openedTab === 'me' && dispatch(getSharedQuestionnaires('getSharedByMe'));
    openedTab === 'with' &&
      dispatch(getSharedQuestionnaires('getSharedWithMe'));
  }, [dispatch, openedTab]);

  useEffect(() => {
    if (sharedWithMeError)
      showErrorNotification(t('sharing.notification.sharedWithMeError'));
    if (sharedByMeError)
      showErrorNotification(t('sharing.notification.sharedByMeError'));
  }, [sharedWithMeError, sharedByMeError, t]);

  const handleQuestionnaireSelect = id => {
    const sharingOperation = openedTab === 'me' ? 'byMe' : 'withMe';

    history.push(
      `/${language}/kyc/shared/${sharingOperation}/questionnaire/${id}`
    );
  };

  const columns = [
    {
      title: t('sharing.table.questionnaire'),
      dataIndex: 'questionnaire',
      key: 'title',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) =>
        a.questionnaire.title.localeCompare(b.questionnaire.title),
      render: ({ id, title }) => (
        <p onClick={() => handleQuestionnaireSelect(id)}>{title}</p>
      ),
    },
    ...(openedTab === 'with'
      ? [
          {
            title: t('sharing.table.sharedByCustomer'),
            dataIndex: 'sharedByCustomer',
            key: 'sharedByCustomer',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) =>
              a.sharedByCustomer.name.localeCompare(b.sharedByCustomer.name),
            render: ({ name, kYCUser }) => (
              <>
                <p>{name}</p>
                {kYCUser && <p className="color-darkergray">{kYCUser}</p>}
              </>
            ),
          },
        ]
      : []),
    ...(openedTab === 'me'
      ? [
          {
            title: t('sharing.table.sharedToCustomer'),
            dataIndex: 'sharedToCustomer',
            key: 'sharedToCustomer',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) =>
              a.sharedToCustomer.name.localeCompare(b.sharedToCustomer.name),
            render: ({ id, name, registryCode }) => (
              <>
                <p>{name}</p>
                <p className="color-darkergray">{`${t(
                  'sharing.table.customerId'
                )}: ${id}`}</p>
                <p className="color-darkergray">{`${t(
                  'sharing.table.regCode'
                )}: ${registryCode}`}</p>
              </>
            ),
          },
        ]
      : []),
    {
      title: t('sharing.table.serviceSubject'),
      dataIndex: 'questionnaire',
      key: 'subject',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) =>
        a.questionnaire.subjectLegalEntity.cI_Name.localeCompare(
          b.questionnaire.subjectLegalEntity.cI_Name
        ),
      render: ({ subjectLegalEntity }) => (
        <>
          <p>{subjectLegalEntity.cI_Name}</p>
          <p className="color-darkergray">{`${t('sharing.table.regCode')}: ${
            subjectLegalEntity.registerCode
          }`}</p>
        </>
      ),
    },
    {
      title: t('sharing.table.country'),
      dataIndex: 'questionnaire',
      key: 'country',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) =>
        a.questionnaire.subjectLegalEntity.countryCodeISOAlpha3.localeCompare(
          b.questionnaire.subjectLegalEntity.countryCodeISOAlpha3
        ),
      render: ({ subjectLegalEntity }) => (
        <p>{t(`global.country.${subjectLegalEntity.countryCodeISOAlpha3}`)}</p>
      ),
    },
    {
      title: t('sharing.table.sharedAt'),
      dataIndex: 'sharedAt',
      key: 'sharedAt',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.sharedAt.localeCompare(b.sharedAt),
      render: date => <p>{moment(date).format(DATE_FORMAT_LONG)}</p>,
    },
    ...(openedTab === 'me'
      ? [
          {
            title: t('sharing.table.sharedBy'),
            dataIndex: 'sharedByCustomer',
            key: 'sharedBy',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) =>
              a.sharedByCustomer.kYCUser.localeCompare(
                b.sharedByCustomer.kYCUser
              ),
            render: ({ kYCUser }) => <p>{kYCUser}</p>,
          },
        ]
      : []),
  ];

  return (
    <div className="container">
      <Col span={24}>
        <Row className="space-vertical-lg company-data-block">
          <Space className="space-between">
            <Col className="page-title">
              <Title>{t('nav.sidebar.shared')}</Title>
            </Col>
            <Col>
              <Button type="default" onClick={history.goBack}>
                {t('global.btn.back')}
              </Button>
            </Col>
          </Space>
        </Row>
        <Row>
          <Tabs
            activeKey={openedTab}
            tabPosition="top"
            style={{ height: 'auto', width: '100%' }}
            onChange={activeKey => setOpenedTab(activeKey)}
          >
            <TabPane tab={t('sharing.tab.withMe')} key={'with'}>
              <Spin
                size="large"
                spinning={sharedWithMeLoading}
                tip={t('global.loading')}
              >
                <Table
                  rowKey="id"
                  columns={columns}
                  dataSource={sharedWithMe}
                  scroll={{ x: true }}
                />
              </Spin>
            </TabPane>
            <TabPane tab={t('sharing.tab.byMe')} key={'me'}>
              <Spin
                size="large"
                spinning={sharedByMeLoading}
                tip={t('global.loading')}
              >
                <Table
                  rowKey="id"
                  columns={columns}
                  dataSource={sharedByMe}
                  scroll={{ x: true }}
                />
              </Spin>
            </TabPane>
          </Tabs>
        </Row>
      </Col>
    </div>
  );
};

export default Sharing;
