import React from 'react';
import CC from 'react-cookie-consent';

import '../../assets/scss/cookie-consent.scss';

const CookieConsent = () => (
  <CC
    disableStyles
    containerClasses="gdpr-cookie-consent"
    contentClasses="gdpr-cookie-consent-content"
    buttonWrapperClasses="gdpr-cookie-consent-btns"
    buttonClasses="button is-blue"
    buttonText="Sain aru"
  >
    KYC utility veebilehel kasutatakse küpsiseid. Küpsiste kasutamisest ja
    isikuandmete töötlemisest saate pikemalt lugeda &nbsp;
    <a
      href="https://www.creditinfo.ee/meist/kupsised/"
      target="_blank"
      rel="noopener noreferrer"
    >
      Küpsised | Creditinfo Eesti AS
    </a>
    .
  </CC>
);

export default CookieConsent;
