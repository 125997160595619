import axios from 'axios';
import { apiCallBegan, apiCallSuccess, apiCallFailure } from '../api';

const defaultHeaders = {
  'Content-Type': 'application/json',
};
const defaultMethod = 'post';
const baseURL = '/';

const api = ({ dispatch }) => next => action => {
  if (action.type !== apiCallBegan.type) {
    return next(action);
  }

  const {
    url,
    method,
    onStart,
    data,
    headers,
    onSuccess,
    onFailure,
    propsToPass,
  } = action.payload;

  if (onStart) dispatch({ type: onStart });
  next(action);
  axios
    .request({
      baseURL,
      url,
      method: method || defaultMethod,
      data,
      headers: headers || defaultHeaders,
      onSuccess,
      onFailure,
    })
    .then(response => {
      dispatch(apiCallSuccess({ data: response.data }));
      if (onSuccess)
        dispatch({
          type: onSuccess,
          payload: propsToPass
            ? { data: response.data, ...propsToPass }
            : response.data,
        });
    })
    .catch(error => {
      dispatch(apiCallFailure({ message: error.message }));
      if (onFailure)
        dispatch({
          type: onFailure,
          payload: {
            message: error.message,
            errorResponseData: error?.response?.data?.error,
          },
        });
    });
};

export default api;
