import React, { createContext, useState } from 'react';

export const CardActionsContext = createContext({});

const CardActionsProvider = ({ children }) => {
  const [commentsOpen, setCommentsOpen] = useState(false);
  const [helpTextOpen, setHelpTextOpen] = useState(false);
  const [manualDataEntryOpen, setManualDataEntryOpen] = useState(false);
  const [isLNInitiationOpen, setIsLNInitiationOpen] = useState(false);
  const [isLNResultsOpen, setIsLNResultsOpen] = useState(false);
  const [isLNDetailsOpen, setIsLNDetailsOpen] = useState(false);

  const toggleComments = () => setCommentsOpen(!commentsOpen);
  const toggleHelpText = () => setHelpTextOpen(!helpTextOpen);
  const toggleManualDataEntry = () =>
    setManualDataEntryOpen(!manualDataEntryOpen);
  const toggleLNInitiationModal = () =>
    setIsLNInitiationOpen(!isLNInitiationOpen);
  const toggleLNResults = () => setIsLNResultsOpen(!isLNResultsOpen);
  const toggleLNDetails = () => setIsLNDetailsOpen(!isLNDetailsOpen);

  const value = {
    commentsOpen,
    helpTextOpen,
    manualDataEntryOpen,
    isLNInitiationOpen,
    isLNResultsOpen,
    isLNDetailsOpen,
    toggleComments,
    toggleHelpText,
    toggleManualDataEntry,
    toggleLNInitiationModal,
    toggleLNResults,
    toggleLNDetails,
  };

  return (
    <CardActionsContext.Provider value={value}>
      {children}
    </CardActionsContext.Provider>
  );
};

export default CardActionsProvider;
