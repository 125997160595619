import moment from 'moment';

export const DATE_FORMAT = 'DD.MM.YYYY';
export const DATE_FORMAT_LONG = 'DD.MM.YYYY HH:mm';
export const rowGutters = { xs: 8, sm: 16, md: 24, lg: 32 };

export const disabledDates = current => current < moment().startOf('day');

export const getDomainIndex = (domains, domainId) =>
  domains.findIndex(({ id }) => id === domainId);

export const notRequiredFields = ['validTo', 'serviceTypeId', 'users'];

export const isAttributeVisible = (child, hasSaved) =>
  (hasSaved && child.dataAttribute) || child.dataAttribute
    ? child.dataAttribute.cI_Name === 'IdInOwnershipStructure' ||
      child.dataAttribute.cI_Name === 'ParentIdInOwnershipStructure'
      ? 'none'
      : 'block'
    : child.cI_Name === 'IdInOwnershipStructure' ||
      child.cI_Name === 'ParentIdInOwnershipStructure'
    ? 'none'
    : 'block';
