import { apiCallBegan } from '../../api';
import * as gql from 'gql-query-builder';
import {
  translationsRequested,
  translationsRequestFailed,
  translationsReceived,
  addTranslationStarted,
  addTranslationSuccess,
  addTranslationFailed,
  editTranslationStarted,
  editTranslationSuccess,
  editTranslationFailed,
  deleteTranslationStarted,
  deleteTranslationSuccess,
  deleteTranslationFailed,
} from './';
import { clientBackend, adminBackend } from '../../../api-config';

export const fetchTranslations = language =>
  apiCallBegan({
    url: `${clientBackend}/translations`,
    data: gql.query({
      operation: 'translations',
      variables: { tLanguage: language },
      fields: ['id', 'tKey', 'tValue', 'tLanguage'],
    }),
    onStart: translationsRequested.type,
    onSuccess: translationsReceived.type,
    onFailure: translationsRequestFailed.type,
  });

export const addTranslation = translation =>
  apiCallBegan({
    url: adminBackend,
    data: gql.mutation({
      operation: 'addTranslation',
      variables: {
        translation: {
          value: {
            tKey: translation.tKey,
            tValue: translation.tValue,
            tLanguage: 'US',
          },
          type: 'TranslationInputType!',
        },
      },
      fields: ['id', 'tKey', 'tValue', 'tLanguage'],
    }),
    onStart: addTranslationStarted.type,
    onSuccess: addTranslationSuccess.type,
    onFailure: addTranslationFailed.type,
  });

export const editTranslation = translation =>
  apiCallBegan({
    url: adminBackend,
    data: gql.mutation({
      operation: 'translate',
      variables: {
        translation: {
          value: {
            tKey: translation.tKey,
            tValue: translation.tValue,
            tLanguage: translation.tLanguage,
          },
          type: 'TranslationInputType!',
        },
      },
      fields: ['id', 'tKey', 'tValue', 'tLanguage'],
    }),
    onStart: editTranslationStarted.type,
    onSuccess: editTranslationSuccess.type,
    onFailure: editTranslationFailed.type,
  });

export const deleteTranslation = key =>
  apiCallBegan({
    url: adminBackend,
    data: gql.mutation({
      operation: 'deleteTranslation',
      variables: {
        tKey: {
          value: key,
          type: 'String!',
        },
      },
    }),
    onStart: deleteTranslationStarted.type,
    onSuccess: deleteTranslationSuccess.type,
    onFailure: deleteTranslationFailed.type,
  });
