import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as gql from 'gql-query-builder';

import { isValidCustomer, sharedQuestionnaire } from './thunkApis';

import { apiCallBegan } from '../../api';
import { clientBackend } from '../../../api-config';

export const checkCustomer = createAsyncThunk(
  'sharing/checkCustomer',
  async props => {
    const response = await isValidCustomer(props);
    return response.data;
  }
);

export const shareQuestionnaire = createAsyncThunk(
  'sharing/shareQuestionnaire',
  async props => {
    const response = await sharedQuestionnaire(props);
    return response.data;
  }
);

const slice = createSlice({
  name: 'sharing',
  initialState: {
    sharedByMe: [],
    sharedByMeLoading: false,
    sharedByMeError: false,
    sharedWithMe: [],
    sharedWithMeLoading: false,
    sharedWithMeError: false,
    customerIds: [],
    sharingLoading: false,
    sharingError: false,
  },
  reducers: {
    sharedByMeRequested: state => {
      state.sharedByMeLoading = true;
      state.sharedByMeError = false;
    },
    sharedByMeRequestFailed: state => {
      state.sharedByMeLoading = false;
      state.sharedByMeError = true;
    },
    sharedByMeReceived: (state, { payload }) => {
      state.sharedByMe = payload.data.getSharedByMe;
      state.sharedByMe.forEach(item => {
        item.sharedByCustomer.kYCUser = `${item.createdBy.kYCUser.firstname} ${item.createdBy.kYCUser.lastname}`;
      });
      state.sharedByMeLoading = false;
      state.sharedByMeError = false;
    },
    sharedWithMeRequested: state => {
      state.sharedWithMeLoading = true;
      state.sharedWithMeError = false;
    },
    sharedWithMeRequestFailed: state => {
      state.sharedWithMeLoading = false;
      state.sharedWithMeError = true;
    },
    sharedWithMeReceived: (state, { payload }) => {
      state.sharedWithMe = payload.data.getSharedWithMe;
      state.sharedWithMe.forEach(item => {
        item.sharedByCustomer.kYCUser = `${item.createdBy.kYCUser.firstname} ${item.createdBy.kYCUser.lastname}`;
      });
      state.sharedWithMeLoading = false;
      state.sharedWithMeError = false;
    },
  },
  extraReducers: {
    [checkCustomer.pending]: state => {
      state.sharingLoading = true;
    },
    [checkCustomer.fulfilled]: state => {
      state.sharingLoading = false;
      state.sharingError = false;
    },
    [checkCustomer.rejected]: state => {
      state.sharingLoading = false;
      state.sharingError = true;
    },
  },
});

export const getSharedQuestionnaires = operation =>
  apiCallBegan({
    url: clientBackend,
    data: gql.query({
      operation,
      fields: [
        'id',
        'sharedAt',
        {
          createdBy: [
            {
              kYCUser: ['firstname', 'lastname'],
            },
          ],
        },
        {
          sharedByCustomer: ['id', 'name', 'registryCode'],
        },
        {
          sharedToCustomer: ['id', 'name', 'registryCode'],
        },
        {
          questionnaire: [
            'id',
            'title',
            {
              subjectLegalEntity: [
                'cI_Name',
                'countryCodeISOAlpha3',
                'registerCode',
              ],
            },
          ],
        },
      ],
    }),
    onStart:
      operation === 'getSharedWithMe'
        ? sharedWithMeRequested.type
        : sharedByMeRequested.type,
    onSuccess:
      operation === 'getSharedWithMe'
        ? sharedWithMeReceived.type
        : sharedByMeReceived.type,
    onFailure:
      operation === 'getSharedWithMe'
        ? sharedWithMeRequestFailed.type
        : sharedByMeRequestFailed.type,
  });

export const {
  sharedByMeRequested,
  sharedByMeRequestFailed,
  sharedByMeReceived,
  sharedWithMeRequested,
  sharedWithMeRequestFailed,
  sharedWithMeReceived,
} = slice.actions;

export default slice.reducer;
