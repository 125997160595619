import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'translation',
  initialState: {
    list: [],
    listLoading: false,
    listLanguage: 'US',
    listError: false,
    addTranslationSuccess: false,
    addTranslationLoading: false,
    addTranslationError: false,
    editTranslationSuccess: false,
    editTranslationLoading: false,
    editTranslationError: false,
    deleteTranslationSuccess: false,
    deleteTranslationLoading: false,
    deleteTranslationError: false,
    activeTranslation: null,
  },
  reducers: {
    translationsRequested: state => {
      state.listLoading = true;
      state.listError = false;
    },
    translationsRequestFailed: state => {
      state.listLoading = false;
      state.listError = true;
    },
    translationsReceived: (state, { payload }) => {
      state.list = payload.data.translations;
      state.listLoading = false;
      state.listError = false;
    },
    addTranslationStarted: state => {
      state.addTranslationSuccess = false;
      state.addTranslationError = false;
      state.addTranslationLoading = true;
    },
    addTranslationSuccess: (state, { payload }) => {
      state.addTranslationSuccess = true;
      state.addTranslationError = false;
      state.addTranslationLoading = false;
      if (
        payload.data.addTranslation.tLanguage.toLowerCase() ===
        state.listLanguage.toLowerCase()
      ) {
        state.list.push(payload.data.addTranslation);
      }
    },
    addTranslationFailed: state => {
      state.addTranslationSuccess = false;
      state.addTranslationError = true;
      state.addTranslationLoading = false;
    },
    addTranslationReset: state => {
      state.addTranslationSuccess = false;
      state.addTranslationError = false;
      state.addTranslationLoading = false;
    },
    editTranslationStarted: state => {
      state.editTranslationSuccess = false;
      state.editTranslationError = false;
      state.editTranslationLoading = true;
    },
    editTranslationSuccess: (state, { payload }) => {
      state.editTranslationSuccess = true;
      state.editTranslationError = false;
      state.editTranslationLoading = false;
      if (
        payload.data.translate.tLanguage.toLowerCase() ===
        state.listLanguage.toLowerCase()
      ) {
        const i = state.list.findIndex(o => o.id === payload.data.translate.id);
        if (state.list[i]) state.list[i] = payload.data.translate;
      }
    },
    deleteTranslationFailed: state => {
      state.deleteTranslationSuccess = false;
      state.deleteTranslationError = true;
      state.deleteTranslationLoading = false;
    },
    deleteTranslationStarted: state => {
      state.deleteTranslationSuccess = false;
      state.deleteTranslationError = false;
      state.deleteTranslationLoading = true;
    },
    deleteTranslationSuccess: state => {
      state.deleteTranslationSuccess = true;
      state.deleteTranslationError = false;
      state.deleteTranslationLoading = false;
    },
    editTranslationFailed: state => {
      state.editTranslationSuccess = false;
      state.editTranslationError = true;
      state.editTranslationLoading = false;
    },
    editTranslationReset: state => {
      state.editTranslationSuccess = false;
      state.editTranslationError = false;
      state.editTranslationLoading = false;
    },
    setActiveTranslation: (state, { payload }) => {
      state.activeTranslation = payload;
    },
    setListLanguage: (state, { payload }) => {
      state.listLanguage = payload;
    },
  },
});

export const {
  setActiveTranslation,
  setListLanguage,
  updateTranslation,
  addTranslationReset,
  editTranslationReset,
  translationsRequested,
  translationsRequestFailed,
  translationsReceived,
  addTranslationStarted,
  addTranslationSuccess,
  addTranslationFailed,
  editTranslationStarted,
  editTranslationSuccess,
  editTranslationFailed,
  deleteTranslationStarted,
  deleteTranslationSuccess,
  deleteTranslationFailed,
} = slice.actions;

export default slice.reducer;
