import { createSlice } from '@reduxjs/toolkit';
import { intersection, omit } from 'lodash';

import { sortBySeqNumber, sortCountriesByISOAlpha3 } from '../../../utils';
import {
  getDomainIndex,
  notRequiredFields,
} from '../../../utils/createServiceHelpers';
import {
  fetchDomainDBs,
  deleteServiceDomain,
  fetchDataBlock,
  updateHeading,
  getReferenceSources,
  operateDataBlock,
  deleteServiceDataBlock,
  getManualField,
  updateDataBlock,
} from './thunks';

const initialState = {
  status: 1,
  serviceId: '',
  logoString: null,
  usersList: [],
  domainsData: [],
  savedDataBlocks: [],
  formData: [],
  usersListError: false,
  usersListLoading: false,
  serviceUserLoading: false,
  serviceUserError: false,
  serviceActionSuccess: false,
  serviceActionLoading: false,
  serviceActionError: false,
  serviceDomainSuccess: false,
  serviceDomainError: false,
  serviceDomainLoading: false,
  serviceDeleted: false,
  domainsDataError: false,
  domainsDataLoading: false,
  counterPartyConnectors: [],
  counterPartyConnectorsLoading: false,
  counterPartyConnectorsError: false,
  referenceSourceLists: [],
  formStep: 0,
  serviceData: {},
  savedFormData: {},
  serviceDataLoading: false,
  serviceDataError: false,
  idForNewDataBlock: 0,
  dataBlockActionError: false,
  dataBlockActionLoading: false,
  movingToPreviousStep: false,
  formType: 'new',
  hasFormChanged: false,
  initialFormData: {},
  isTitleInputActive: false,
};

const slice = createSlice({
  name: 'createService',
  initialState,
  reducers: {
    setLogoString: (state, { payload }) => {
      state.logoString = payload;
    },
    setFormType: (state, { payload }) => {
      state.formType = payload;
    },
    usersListRequested: state => {
      state.usersList = [];
      state.usersListLoading = true;
      state.usersListError = false;
    },
    usersListRequestFailed: state => {
      state.usersListLoading = false;
      state.usersListError = true;
    },
    usersListRequestSuccess: (state, { payload }) => {
      state.usersList = payload.data.searchusers;
      state.usersListLoading = false;
      state.usersListError = false;
    },
    usersListEmpty: state => {
      state.usersList = [];
    },
    serviceUsersRequested: state => {
      state.serviceUsersLoading = true;
      state.serviceUserError = false;
    },
    serviceUsersRequestFailed: state => {
      state.serviceUsersLoading = false;
      state.serviceUserError = true;
    },
    serviceUsersRequestSuccess: (state, { payload }) => {
      if (!payload.data.users[0]) {
        state.serviceUserLoading = false;
        state.serviceUserError = true;
        return;
      }

      const { id, firstname, lastname } = payload.data.users[0];
      const user = {
        value: id.toString(),
        label: `${firstname} ${lastname}`,
      };

      state.serviceData.users.push(user);
      state.initialFormData.users.push(user);
      state.savedFormData.users.push(user);
      state.serviceUserLoading = false;
    },
    serviceActionStarted: state => {
      state.serviceActionSuccess = false;
      state.serviceActionLoading = true;
      state.serviceActionError = false;
    },
    servicePublishingSuccess: (state, { payload }) => {
      const { statusId, serviceDomains, id } = payload.data.service;
      const stateDomainsData = state.domainsData;

      if (stateDomainsData.length > 0 && serviceDomains) {
        serviceDomains.forEach(domain => {
          const domainIndex = getDomainIndex(stateDomainsData, domain.domainId);

          state.domainsData[domainIndex].serviceDomainId = domain.id;
        });
      }

      if (state.formData.length) {
        const savedFormData = {};

        state.formData.forEach(
          field => (savedFormData[field.name] = field.value)
        );
        state.serviceData = { ...state.serviceData, ...savedFormData };
        state.savedFormData = { ...state.serviceData, ...savedFormData };
      }

      state.hasFormChanged = false;
      state.status = statusId;
      state.serviceId = id;
      state.serviceActionSuccess = true;
      state.serviceActionLoading = false;
      state.serviceActionError = false;
    },
    serviceActionError: state => {
      state.serviceActionSuccess = false;
      state.serviceActionLoading = false;
      state.serviceActionError = true;
    },
    saveDomainSuccess: (state, { payload }) => {
      const { id } = payload.data.data.serviceDomain;
      const { type, domainId, title } = payload;
      const stateDomainsData = state.domainsData;

      const domainIndex = getDomainIndex(stateDomainsData, domainId);

      stateDomainsData[domainIndex].changedTitle = type ? title : null;
      stateDomainsData[domainIndex].serviceDomainId = id;

      state.serviceDomainError = false;
      state.serviceDomainLoading = false;
      state.serviceDomainSuccess = true;
    },
    saveDomainError: state => {
      state.serviceDomainLoading = false;
      state.serviceDomainSuccess = false;
      state.serviceDomainError = true;
    },
    saveDomainLoading: state => {
      state.serviceDomainLoading = true;
      state.serviceDomainSuccess = false;
      state.serviceDomainError = false;
    },
    modifyDomainData: (state, { payload }) => {
      const { modName, value, domainIndex, dataBlockIndex } = payload;
      const domainState = state.domainsData[domainIndex];

      if (modName === 'chosenDataBlocks') {
        const dataBlockIndexInChosenBlocks = domainState.chosenBlocks.findIndex(
          id => id === value
        );

        if (dataBlockIndexInChosenBlocks === -1) {
          domainState.chosenBlocks.push(value);
        } else {
          const dataBlock = domainState.dataBlocks.find(
            ({ id }) => id === value
          );

          domainState.chosenBlocks.splice(dataBlockIndexInChosenBlocks, 1);
          const cleanDataBlock = omit(dataBlock, [
            'helpText',
            'savedReferenceSources',
            'serviceDataAttributes',
            'dataBlockReferenceSources',
            'serviceDataBlockId',
          ]);

          cleanDataBlock.childAttributes.forEach(attr => {
            if (attr.serviceDataAttributeId) {
              attr.id = attr.serviceDataAttributeId;
            }
            delete attr.serviceDataAttributeId;
          });

          domainState.dataBlocks[dataBlockIndex] = cleanDataBlock;
          domainState.dataBlockSequenceNumber -= 1;
        }
      }
    },
    setFormStep: (state, { payload }) => {
      state.formStep = payload;

      if (payload === 0) {
        state.movingToPreviousStep = true;
        state.serviceActionSuccess = false;
      }
      if (payload === 1) {
        state.movingToPreviousStep = false;
        state.serviceActionSuccess = false;
      }
    },
    setMovingToPreviousStep: (state, { payload }) => {
      state.movingToPreviousStep = payload;
      state.serviceActionError = false;
    },
    resetServiceState: state => {
      Object.assign(state, initialState);
    },
    setServiceData: (state, { payload }) => {
      state.serviceData = { ...state.serviceData, ...payload };
    },
    setFormData: (state, { payload }) => {
      const formValues = payload;

      if (formValues[0] && !formValues[0].hasOwnProperty('isRequired')) {
        formValues.forEach((field, idx) => {
          const name = field.name[0];
          const isRequired = !notRequiredFields.includes(name);

          formValues[idx].isRequired = isRequired;
        });
        state.formData = formValues;
      } else {
        state.formData = [...formValues];
      }
    },
    serviceDataRequested: state => {
      state.serviceData = {};
      state.domainsData = [];
      state.serviceDataLoading = true;
      state.serviceDataError = false;
    },
    serviceDataRequestFailed: state => {
      state.serviceDataLoading = false;
      state.serviceDataError = true;
    },
    serviceDataRequestSuccess: (state, { payload }) => {
      const payloadData = payload.data.getFullService;
      if (!payloadData) {
        state.serviceDataLoading = false;
        state.serviceDataError = true;
        return;
      }

      const { serviceDomains: fetchedDomains, serviceDataBlocks } = payloadData;

      const sortedCountries =
        payloadData.serviceCountries &&
        sortCountriesByISOAlpha3(payloadData.serviceCountries);

      state.serviceData = {
        ...state.serviceData,
        ...payloadData,
        serviceDataBlocks: [],
        validTo: payloadData?.validTo,
        validFrom: payloadData?.validFrom,
        serviceUsers: payloadData.users,
        users: [],
        countries: sortedCountries.map(
          ({ countryCodeISOAlpha3 }) => countryCodeISOAlpha3
        ),
      };
      state.serviceId = payloadData.id;
      state.status = payloadData.statusId;
      state.logoString = payloadData.logo;
      state.version = payloadData.version;

      state.initialFormData = state.serviceData;
      state.initialLogoString = payloadData.logo;
      state.savedFormData = state.serviceData;

      delete state.savedFormData.serviceCountries;

      for (const [i, domain] of fetchedDomains.entries()) {
        const dataBlocks = domain.domain.dataBlocks.sort(sortBySeqNumber);
        const serviceDomain = payloadData.serviceDomains[i];
        const newDataBlocks = [];

        for (const [j, block] of dataBlocks.entries()) {
          const sortedAttributes = block.childAttributes
            .map(attr => ({ ...attr, isVisible: true }))
            .sort((a, b) => a.sequenceNumber - b.sequenceNumber);

          const dataBlockData = {};
          serviceDataBlocks.forEach(item => {
            if (!item.dataBlockId) return;
            if (item.dataBlockId === block.id) {
              dataBlockData.serviceDataBlockId = item.id;
              dataBlockData.helpText = item.helpText;
              dataBlockData.sequenceNumber = item.sequenceNumber;
              dataBlockData.dataBlockReferenceSources =
                item.dataReferenceSources;
              dataBlockData.isUserSelectableReferenceSource =
                item.isUserSelectableReferenceSource;
              dataBlockData.noReferenceSource = item.noReferenceSource;
              dataBlockData.savedReferenceSources = item.dataReferenceSources;
              return;
            }
          });

          const serviceDataAttributes = [];
          payloadData.serviceDataAttributes.forEach(dataAttribute => {
            if (
              dataAttribute.parentServiceDataBlockId ===
              dataBlockData.serviceDataBlockId
            ) {
              serviceDataAttributes.push(dataAttribute);
            }
          });
          const sortedServiceAttributes = serviceDataAttributes.sort(
            (a, b) => a.sequenceNumber - b.sequenceNumber
          );

          newDataBlocks.push({
            ...block,
            ...dataBlockData,
            orderNr: j,
            childAttributes: sortedAttributes,
            serviceDataAttributes: sortedServiceAttributes,
          });
        }

        const modifiedDomainData = {
          ...domain,
          id: domain.domain.id,
          serviceDomainId: serviceDomain.id,
          chosenBlocks: [],
          dataBlocks: newDataBlocks,
        };
        state.domainsData.push(modifiedDomainData);

        delete state.domainsData[i].domain;
      }

      // Handles showing saved datablocks
      const dataBlocksIds = serviceDataBlocks.map(
        ({ dataBlockId }) => dataBlockId
      );

      for (const [idx, domain] of state.domainsData.entries()) {
        const domainDataBlocksIds = domain.dataBlocks.map(({ id }) => id);

        state.domainsData[idx].chosenBlocks = intersection(
          dataBlocksIds,
          domainDataBlocksIds
        );
        state.domainsData[idx].dataBlockSequenceNumber =
          state.domainsData[idx].chosenBlocks.length;
      }

      delete state.serviceData.logo;

      state.serviceDataLoading = false;
    },
    counterPartyConnectorsRequested: state => {
      state.counterPartyConnectorsLoading = true;
      state.counterPartyConnectorsError = false;
    },
    counterPartyConnectorsFailed: state => {
      state.counterPartyConnectorsLoading = false;
      state.counterPartyConnectorsError = true;
    },
    counterPartyConnectorsSuccess: (state, { payload }) => {
      state.counterPartyConnectors = payload.data.listCounterPartyConnectors;
      state.counterPartyConnectorsLoading = false;
      state.counterPartyConnectorsError = false;
    },
    setDataBlockConnectors: (state, { payload }) => {
      const { domainIndex, dataBlockIndex, type } = payload;
      const domainState = state.domainsData[domainIndex];
      const currentDataBlock = domainState.dataBlocks[dataBlockIndex];
      const dataBlockReferenceSources =
        currentDataBlock.dataBlockReferenceSources;
      const referenceSourcesFromDB = currentDataBlock.savedReferenceSources;
      const referenceSourcesList = type
        ? referenceSourcesFromDB
        : dataBlockReferenceSources;

      const newSources = [];

      for (const source of referenceSourcesList) {
        const counterPartyConnector = state.counterPartyConnectors.find(
          ({ uniqueId }) => uniqueId === source.counterpartyConnectorUniqueId
        );

        newSources.push({
          ...source,
          name: counterPartyConnector?.productName,
          seqNr: counterPartyConnector?.id,
        });
      }

      newSources.sort((a, b) => a.seqNr - b.seqNr);

      currentDataBlock[
        type ? 'savedReferenceSources' : 'dataBlockReferenceSources'
      ] = newSources;
    },
    getSavedDataBlocksSuccess: (state, { payload }) => {
      const { data } = payload;

      const chaptersOnly = ({ dataBlock }) => dataBlock === null;

      let dataBlocks = data.service2ndStep.listChapterServiceDataBlocks
        .filter(chaptersOnly)
        .sort((a, b) => a.sequenceNumber - b.sequenceNumber);

      for (const [idx, chapter] of dataBlocks.entries()) {
        const sortedChapters = chapter.childBlocks.sort(
          (a, b) => a.sequenceNumber - b.sequenceNumber
        );
        const subChapters = chapter.childBlocks.filter(
          ({ heading }) => heading
        );

        if (subChapters.length) {
          for (const [index, dataBlock] of sortedChapters.entries()) {
            if (dataBlock.childBlocks.length > 1) {
              const sortedSubChapters = dataBlock.childBlocks.sort(
                (a, b) => a.sequenceNumber - b.sequenceNumber
              );

              sortedChapters[index].childBlocks = sortedSubChapters;
            }
          }
        }

        dataBlocks[idx].childBlocks = sortedChapters;
      }

      state.savedDataBlocks = data.service2ndStep.listChapterServiceDataBlocks
        .filter(chaptersOnly)
        .sort((a, b) => a.sequenceNumber - b.sequenceNumber);
      state.serviceActionLoading = false;
      state.serviceActionError = false;
    },
    getSavedDataBlocksLoading: state => {
      state.serviceActionLoading = true;
      state.serviceActionError = false;
    },
    getSavedDataBlocksError: state => {
      state.serviceActionLoading = false;
      state.serviceActionError = true;
    },
    addChapterToList: (state, { payload }) => {
      state.savedDataBlocks.splice(payload + 1, 0, newChapter);
    },
    removeChapterFromList: (state, { payload }) => {
      const { chapterIndex, chapterId } = payload;

      const chapterInDomains = state.domainsData.findIndex(
        ({ chapterDataBlockId }) => chapterDataBlockId === chapterId
      );

      if (chapterInDomains >= 0)
        state.domainsData[chapterInDomains].chapterDataBlockId = null;

      state.savedDataBlocks.splice(chapterIndex, 1);
    },
    addSubChapterToList: (state, { payload }) => {
      state.savedDataBlocks[payload].childBlocks.push({
        ...newSubChapter,
        mockId: state.idForNewDataBlock,
      });
      state.idForNewDataBlock += 1;
    },
    removeSubChapterFromList: (state, { payload }) => {
      state.savedDataBlocks[payload.chapterIndex].childBlocks.splice(
        payload.subChapterIndex,
        1
      );
    },
    moveDataBlockLeftAndRight: (state, { payload }) => {
      const {
        chapterIndex,
        subChapterIndex,
        direction,
        dataBlockIndex,
      } = payload;
      const chapterDataBlocks = state.savedDataBlocks[chapterIndex].childBlocks;

      if (subChapterIndex >= 0) {
        const dataBlock =
          chapterDataBlocks[subChapterIndex].childBlocks[dataBlockIndex];
        chapterDataBlocks[subChapterIndex].childBlocks.splice(
          dataBlockIndex,
          1
        );

        if (direction === 'left') {
          state.savedDataBlocks[chapterIndex].childBlocks[
            subChapterIndex
          ].childBlocks.splice(dataBlockIndex - 1, 0, dataBlock);
        }
        if (direction === 'right') {
          state.savedDataBlocks[chapterIndex].childBlocks[
            subChapterIndex
          ].childBlocks.splice(dataBlockIndex + 1, 0, dataBlock);
        }
        return;
      }

      const dataBlock = chapterDataBlocks[dataBlockIndex];
      chapterDataBlocks.splice(dataBlockIndex, 1);

      if (direction === 'left') {
        state.savedDataBlocks[chapterIndex].childBlocks.splice(
          dataBlockIndex - 1,
          0,
          dataBlock
        );
      }
      if (direction === 'right') {
        state.savedDataBlocks[chapterIndex].childBlocks.splice(
          dataBlockIndex + 1,
          0,
          dataBlock
        );
      }
    },
    moveDataBlockBetweenChapters: (state, { payload }) => {
      const {
        chapterIndex,
        subChapterIndex,
        dataBlockIndex,
        newChapterInfo: {
          chapterIndex: newChapterIndex,
          subChapterIndex: newSubChapterIndex,
        },
      } = payload;

      const chapterDataBlocks = state.savedDataBlocks[chapterIndex].childBlocks;
      if (subChapterIndex >= 0) {
        const dataBlockToMove =
          chapterDataBlocks[subChapterIndex].childBlocks[dataBlockIndex];

        if (newSubChapterIndex >= 0) {
          state.savedDataBlocks[newChapterIndex].childBlocks[
            newSubChapterIndex
          ].childBlocks.push(dataBlockToMove);
        } else {
          state.savedDataBlocks[newChapterIndex].childBlocks.push(
            dataBlockToMove
          );
        }

        chapterDataBlocks[subChapterIndex].childBlocks.splice(
          dataBlockIndex,
          1
        );
        return;
      }

      const dataBlockToMove = chapterDataBlocks[dataBlockIndex];

      if (newSubChapterIndex >= 0) {
        state.savedDataBlocks[newChapterIndex].childBlocks[
          newSubChapterIndex
        ].childBlocks.push(dataBlockToMove);
      } else {
        state.savedDataBlocks[newChapterIndex].childBlocks.push(
          dataBlockToMove
        );
      }
      chapterDataBlocks.splice(dataBlockIndex, 1);
    },
    setHasFormChanged: (state, { payload }) => {
      state.hasFormChanged = payload;
    },
    revertFormData: state => {
      state.serviceData = state.initialFormData;
      state.logoString = state.initialLogoString;
      state.hasFormChanged = false;

      const savedFormData = state.savedFormData;

      for (const field of state.formData) {
        if (field.touched) {
          if (field.name[0] === 'validFrom' || field.name[0] === 'validTo') {
            field.value = savedFormData[field.name[0]] ?? null;
          }

          if (field.name[0] === 'domains') {
            field.value = savedFormData.serviceDomains.map(
              ({ domain }) => domain.id
            );
          }

          field.value = savedFormData[field.name[0]];
        }
      }
    },
    setIsTitleInputActive: (state, { payload }) => {
      state.isTitleInputActive = payload;
    },
  },
  extraReducers: {
    [fetchDomainDBs.pending]: state => {
      if (!state.domainsDataLoading) {
        state.domainsDataLoading = true;
        state.domainsDataError = false;
      }
    },
    [fetchDomainDBs.fulfilled]: (state, actions) => {
      if (state.domainsDataLoading) {
        const domain = actions.payload.data.domain[0];
        const dataBlocks = domain.dataBlocks.sort(sortBySeqNumber);
        let domainData = domain;
        const newDataBlocks = [];

        for (const [idx, block] of dataBlocks.entries()) {
          const sortedAttributes = block.childAttributes
            .sort((a, b) => a.sequenceNumber - b.sequenceNumber)
            .map(attr => ({ ...attr, isVisible: true }));

          newDataBlocks.push({
            ...block,
            orderNr: idx,
            childAttributes: sortedAttributes,
            dataBlockReferenceSources: [],
          });
        }

        const modifiedDomainData = {
          ...domainData,
          chosenBlocks: [],
          dataBlocks: newDataBlocks,
          dataBlockSequenceNumber: 0,
        };

        state.domainsData.push(modifiedDomainData);
        state.domainsDataError = false;
        state.serviceDomainError = false;
        state.domainsDataLoading = false;
      }
    },
    [fetchDomainDBs.rejected]: state => {
      if (state.domainsDataLoading) {
        state.domainsDataLoading = false;
        state.domainsDataError = true;
      }
    },
    [deleteServiceDomain.pending]: state => {
      if (!state.serviceDomainLoading) {
        state.serviceDomainLoading = true;
        state.serviceDomainError = false;
      }
    },
    [deleteServiceDomain.fulfilled]: (state, actions) => {
      if (state.serviceDomainLoading) {
        const newDomainsList = state.domainsData.filter(
          domain => domain.id !== actions.meta.arg.domainId
        );

        state.domainsData = newDomainsList;
        state.serviceDomainLoading = false;
      }
    },
    [deleteServiceDomain.rejected]: state => {
      if (state.serviceDomainLoading) {
        state.serviceDomainLoading = false;
        state.serviceDomainError = true;
      }
    },
    [fetchDataBlock.pending]: (state, actions) => {
      if (!state.dataBlockActionLoading) {
        state.dataBlockActionLoading = true;
        state.dataBlockActionError = false;
        state.currentRequestId = actions.meta.requestId;
      }
    },
    [fetchDataBlock.fulfilled]: (state, actions) => {
      const {
        requestId,
        arg: {
          isFirstDataBlockInDomain,
          sequenceNumber,
          dataBlock: { domainId, orderNr },
        },
      } = actions.meta;
      const payload = actions.payload.data.serviceDataBlock;

      if (
        state.dataBlockActionLoading &&
        state.currentRequestId === requestId
      ) {
        const stateDomainsData = state.domainsData;
        const domainIndex = getDomainIndex(stateDomainsData, domainId);
        const currentDataBlock =
          stateDomainsData[domainIndex].dataBlocks[orderNr];
        const sortedAttributes = payload.childAttributes.sort(
          (a, b) => a.sequenceNumber - b.sequenceNumber
        );
        const childAttributes = currentDataBlock.childAttributes.map(
          (attr, idx) => ({
            ...attr,
            isVisible: true,
            id: sortedAttributes[idx].id,
            serviceDataAttributeId: attr.id,
          })
        );

        const newDataBlock = {
          ...currentDataBlock,
          sequenceNumber,
          serviceDataBlockId: payload.id,
          childAttributes,
        };

        state.domainsData[domainIndex].dataBlocks[orderNr] = newDataBlock;
        state.domainsData[domainIndex].dataBlockSequenceNumber += 1;

        if (isFirstDataBlockInDomain) {
          state.domainsData[domainIndex].chapterDataBlockId =
            payload.parentServiceDataBlockId;
          state.domainsData[domainIndex].dataBlockSequenceNumber = 1;
        }

        state.dataBlockActionLoading = false;
        state.dataBlockActionError = false;
        state.currentRequestId = undefined;
      }
    },
    [fetchDataBlock.rejected]: (state, action) => {
      const { requestId } = action.meta;

      if (
        state.dataBlockActionLoading &&
        state.currentRequestId === requestId
      ) {
        state.dataBlockActionLoading = false;
        state.dataBlockActionError = true;
        state.currentRequestId = undefined;
      }
    },
    [updateHeading.pending]: state => {
      if (!state.updateHeadingLoading) state.updateHeadingLoading = true;
    },
    [updateHeading.fulfilled]: (state, action) => {
      if (state.updateHeadingLoading) {
        const { type, chapterIndex, subChapterIndex } = action.meta.arg;
        const { savedDataBlocks } = state;
        const { heading } = action.payload.data.serviceDataBlockUpdateProperty;

        if (type === 'chapter') {
          savedDataBlocks[chapterIndex].heading = heading;
        }

        if (type === 'subChapter') {
          savedDataBlocks[chapterIndex].childBlocks[
            subChapterIndex
          ].heading = heading;
        }

        state.updateHeadingLoading = false;
      }
    },
    [updateHeading.rejected]: state => {
      if (state.updateHeadingLoading) {
        state.updateHeadingLoading = false;
      }
    },
    [getReferenceSources.pending]: state => {
      if (!state.counterPartyConnectorsLoading) {
        state.counterPartyConnectorsLoading = true;
        state.counterPartyConnectorsError = false;
      }
    },
    [getReferenceSources.fulfilled]: (state, actions) => {
      if (state.counterPartyConnectorsLoading) {
        const {
          arg: { domainIndex, dataBlockIndex },
        } = actions.meta;
        const dataBlock =
          state.domainsData[domainIndex].dataBlocks[dataBlockIndex];

        dataBlock.dataBlockReferenceSources =
          actions.payload.data.dataBlock[0].dataBlockReferenceSources;
        state.counterPartyConnectorsLoading = false;
        state.counterPartyConnectorsError = false;
      }
    },
    [getReferenceSources.rejected]: state => {
      if (state.counterPartyConnectorsLoading) {
        state.counterPartyConnectorsLoading = false;
        state.counterPartyConnectorsError = true;
      }
    },
    [operateDataBlock.pending]: state => {
      if (!state.dataBlockActionLoading) {
        state.dataBlockActionLoading = true;
        state.dataBlockActionError = false;
      }
    },
    [operateDataBlock.fulfilled]: (state, actions) => {
      if (state.dataBlockActionLoading) {
        const { savedDataBlocks } = state;
        const {
          chapterIndex,
          subChapterIndex,
          type,
          operation,
          dataBlockIndex,
        } = actions.meta.arg;
        const { serviceDataBlock } = actions.payload.data;

        const chapterData = {
          ...serviceDataBlock,
          childBlocks: [],
        };

        if (operation) {
          if (type === 'chapter') {
            chapterData.childBlocks = savedDataBlocks[chapterIndex].childBlocks;

            operation !== 'changeTitle' &&
              state.savedDataBlocks.splice(chapterIndex, 1);

            if (operation === 'up') {
              state.savedDataBlocks.splice(chapterIndex - 1, 0, chapterData);
            }
            if (operation === 'down') {
              state.savedDataBlocks.splice(chapterIndex + 1, 0, chapterData);
            }
            if (operation === 'changeTitle') {
              state.savedDataBlocks[chapterIndex] = chapterData;
              state.isTitleInputActive = false;
            }
          }
          if (type === 'subChapter') {
            chapterData.childBlocks =
              savedDataBlocks[chapterIndex].childBlocks[
                subChapterIndex
              ].childBlocks;

            const currentSubChapterId = serviceDataBlock.id;
            const subChapters = savedDataBlocks[
              chapterIndex
            ].childBlocks.filter(({ heading }) => heading);
            const dataBlocks = savedDataBlocks[chapterIndex].childBlocks.filter(
              ({ heading }) => !heading
            );
            const subChapterIndexInSubChapters = subChapters.findIndex(
              ({ id }) => currentSubChapterId === id
            );

            subChapters.splice(subChapterIndexInSubChapters, 1);

            if (operation === 'up') {
              subChapters.splice(
                subChapterIndexInSubChapters - 1,
                0,
                chapterData
              );
              state.savedDataBlocks[chapterIndex].childBlocks = [
                ...subChapters,
                ...dataBlocks,
              ];
            }
            if (operation === 'down') {
              subChapters.splice(
                subChapterIndexInSubChapters + 1,
                0,
                chapterData
              );
              state.savedDataBlocks[chapterIndex].childBlocks = [
                ...subChapters,
                ...dataBlocks,
              ];
            }
            if (operation === 'changeTitle') {
              state.savedDataBlocks[chapterIndex].childBlocks[
                subChapterIndex
              ] = chapterData;
              state.isTitleInputActive = false;
            }
          }
        } else {
          if (type === 'chapter') {
            state.savedDataBlocks[chapterIndex] = chapterData;
          }

          if (type === 'subChapter') {
            state.savedDataBlocks[chapterIndex].childBlocks[
              subChapterIndex
            ] = chapterData;
          }

          if (type === 'manualField') {
            if (subChapterIndex >= 0) {
              state.savedDataBlocks[chapterIndex].childBlocks[
                subChapterIndex
              ].childBlocks.push(serviceDataBlock);
            } else {
              state.savedDataBlocks[chapterIndex].childBlocks.push(
                serviceDataBlock
              );
            }
          }

          if (type === 'manualFieldEdit') {
            if (subChapterIndex >= 0) {
              state.savedDataBlocks[chapterIndex].childBlocks[
                subChapterIndex
              ].childBlocks[dataBlockIndex] = serviceDataBlock;
            } else {
              state.savedDataBlocks[chapterIndex].childBlocks[
                dataBlockIndex
              ] = serviceDataBlock;
            }
          }
        }

        state.dataBlockActionError = false;
        state.dataBlockActionLoading = false;
      }
    },
    [operateDataBlock.rejected]: state => {
      if (state.dataBlockActionLoading) {
        state.dataBlockActionLoading = false;
        state.dataBlockActionError = true;
      }
    },
    [deleteServiceDataBlock.pending]: state => {
      if (!state.dataBlockActionLoading) {
        state.dataBlockActionLoading = true;
      }
    },
    [deleteServiceDataBlock.fulfilled]: (state, actions) => {
      if (state.dataBlockActionLoading) {
        const { type, chapterIndex, subChapterIndex, id } = actions.meta.arg;
        const { savedDataBlocks } = state;

        if (type === 'manualField') {
          const parent =
            subChapterIndex >= 0
              ? savedDataBlocks[chapterIndex].childBlocks[subChapterIndex]
              : savedDataBlocks[chapterIndex];
          const dataBlockIndex = parent.childBlocks.findIndex(
            block => block.id === id
          );

          if (subChapterIndex >= 0) {
            state.savedDataBlocks[chapterIndex].childBlocks[
              subChapterIndex
            ].childBlocks.splice(dataBlockIndex, 1);
          } else {
            state.savedDataBlocks[chapterIndex].childBlocks.splice(
              dataBlockIndex,
              1
            );
          }
        }

        state.dataBlockActionLoading = false;
      }
    },
    [deleteServiceDataBlock.rejected]: state => {
      if (state.dataBlockActionLoading) {
        state.dataBlockActionLoading = false;
      }
    },
    [getManualField.pending]: state => {
      if (!state.dataBlockActionLoading) {
        state.dataBlockActionLoading = true;
      }
    },
    [getManualField.fulfilled]: (state, actions) => {
      if (state.dataBlockActionLoading) {
        const {
          chapterIndex,
          subChapterIndex,
          id: dataBlockId,
        } = actions.meta.arg;
        const serviceDataBlock = actions.payload.data.serviceDataBlock[0];
        const { savedDataBlocks } = state;

        serviceDataBlock.id = dataBlockId;

        const dataBlocks =
          subChapterIndex >= 0
            ? savedDataBlocks[chapterIndex].childBlocks[subChapterIndex]
            : savedDataBlocks[chapterIndex];
        const dataBlockIndex = dataBlocks.childBlocks.findIndex(
          ({ id }) => id === dataBlockId
        );

        if (subChapterIndex >= 0) {
          state.savedDataBlocks[chapterIndex].childBlocks[
            subChapterIndex
          ].childBlocks[dataBlockIndex] = serviceDataBlock;
        } else {
          state.savedDataBlocks[chapterIndex].childBlocks[
            dataBlockIndex
          ] = serviceDataBlock;
        }

        state.dataBlockActionLoading = false;
      }
    },
    [getManualField.rejected]: state => {
      if (state.dataBlockActionLoading) {
        state.dataBlockActionLoading = false;
      }
    },
    [updateDataBlock.fulfilled]: (state, actions) => {
      const {
        saveType,
        dataBlockIndex,
        domainIndex,
        payload: {
          helpText,
          serviceDataAttributes,
          dataReferenceSources: referenceSources,
          isUserSelectableReferenceSource,
          noReferenceSource,
        },
      } = actions.meta.arg;
      const dataBlock =
        state.domainsData[domainIndex].dataBlocks[dataBlockIndex];

      if (saveType === 'helpText') {
        dataBlock.helpText = helpText;
        return;
      }
      if (saveType === 'referenceSource') {
        const savedSources = [];
        referenceSources.forEach(source => {
          const dataBlockSources = dataBlock.dataBlockReferenceSources.find(
            ({ id }) => id === parseInt(source)
          );

          savedSources.push({ id: source, name: dataBlockSources.name });
          return;
        });
        dataBlock.savedReferenceSources = savedSources;
        dataBlock.isUserSelectableReferenceSource = isUserSelectableReferenceSource;
        dataBlock.noReferenceSource = noReferenceSource;
        return;
      }
      if (saveType === 'visibility') {
        const updatedAttributes = serviceDataAttributes.map((attr, idx) => ({
          id: attr.id,
          isVisible: attr.isVisible,
          sequenceNumber: attr.sequenceNumber,
          parentServiceDataBlockId: attr.parentServiceDataBlockId,
          dataAttribute: {
            id: attr.dataAttributeId,
            cI_Name: dataBlock.childAttributes[idx].cI_Name,
          },
        }));
        dataBlock.serviceDataAttributes = updatedAttributes;
        return;
      }
    },
  },
});

const newChapter = {
  heading: '',
  sequenceNumber: 0,
  childBlocks: [],
};

const newSubChapter = {
  heading: '',
  isSubChapter: true,
  childBlocks: [],
};

export const {
  setFormType,
  usersListRequested,
  usersListRequestFailed,
  usersListRequestSuccess,
  serviceUsersRequested,
  serviceUsersRequestFailed,
  serviceUsersRequestSuccess,
  servicePublishingSuccess,
  serviceActionStarted,
  serviceActionError,
  usersListEmpty,
  setLogoString,
  setServiceDataBlockId,
  saveDomainSuccess,
  saveDomainError,
  saveDomainLoading,
  modifyDomainData,
  setFormStep,
  resetServiceState,
  setServiceData,
  serviceDataRequested,
  serviceDataRequestFailed,
  serviceDataRequestSuccess,
  counterPartyConnectorsRequested,
  counterPartyConnectorsFailed,
  counterPartyConnectorsSuccess,
  counterPartiesSuccess,
  counterPartiesError,
  counterPartiesLoading,
  getSavedDataBlocksSuccess,
  getSavedDataBlocksLoading,
  getSavedDataBlocksError,
  addChapterToList,
  removeChapterFromList,
  saveChapterTitle,
  addSubChapterToList,
  removeSubChapterFromList,
  addManualFieldToChapter,
  addManualFieldToSubChapter,
  moveDataBlockLeftAndRight,
  moveDataBlockBetweenChapters,
  setMovingToPreviousStep,
  setFormData,
  setHasFormChanged,
  revertFormData,
  setIsTitleInputActive,
  setDataBlockConnectors,
} = slice.actions;

export default slice.reducer;
