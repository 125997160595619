import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { sortCountriesByISOAlpha3 } from '../../../utils';

import {
  createQuestionnaire,
  postOnboardingForm,
  postUpdateLegalEntityKeyDatas,
  postUpdateManuallyInserted,
} from './api';

export const postOnboardingFormData = createAsyncThunk(
  'onboarding/postFormData',
  async (props, { rejectWithValue }) => {
    try {
      const response = await postOnboardingForm(props);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateLegalEntityKeyDatasThunk = createAsyncThunk(
  'onboarding/updateLegalEntityKeyDatasThunk',
  async props => {
    const reponse = await postUpdateLegalEntityKeyDatas(props);
    return reponse.data;
  }
);

export const updateManuallyInsertedThunk = createAsyncThunk(
  'onboarding/updateManuallyInsertedThunk',
  async props => {
    const reponse = await postUpdateManuallyInserted(props);
    return reponse.data;
  }
);

export const saveServiceAsQuestionnaire = createAsyncThunk(
  'onboarding/createQuestionnaire',
  async props => {
    const reponse = await createQuestionnaire(props);
    return reponse.data;
  }
);

const initialState = {
  onboardingList: [],
  onboardingListLoading: false,
  onboardingBVDTimeout: false,
  onboardingListError: false,
  onboardingListHasMatches: true,
  onboardingCountries: [],
  onboardingDatasource: null,
  onboardingCountryId: null,
  showList: false,
  LEActivitiesList: [],
  onboardingFormData: {},
  onboardingServices: [],
  onboardingServicesLoading: false,
  onboardingServicesError: false,
  isCompanyGroupView: false,
  onboardingCountriesLoading: false,
  onboardingCountriesError: false,
  LEActivitiesListLoading: false,
  LEActivitiesListError: false,
  postOnboardingDataLoading: false,
  postOnboardingDataError: false,
};

const scoreDesc = (a, b) => b.score - a.score;

const slice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    onboardingListRequested: state => {
      state.onboardingList = [];
      state.onboardingListLoading = true;
      state.onboardingListError = false;
      state.onboardingBVDTimeout = false;
      state.onboardingListHasMatches = true;
    },
    onboardingListRequestFailed: state => {
      state.onboardingListLoading = false;
      state.onboardingListError = true;
    },
    onboardingListBVDFailed: (state, { payload }) => {
      state.onboardingListLoading = false;
      let isTimeout =
        payload?.errorResponseData === 'IDMTimeoutExceptionMessage';
      state.onboardingBVDTimeout = isTimeout;
      state.onboardingListError = !isTimeout;
    },
    onboardingListReceivedCI: (state, { payload }) => {
      state.onboardingList =
        payload.data.listCompanyBasicDatasWithPagination?.result?.map(
          (result, idx) => ({
            ...result,
            Nr: idx + 1,
          })
        ) ?? [];
      state.onboardingListHasMatches =
        payload.data.listCompanyBasicDatasWithPagination?.result?.length > 0;
      state.showList = true;
      state.onboardingListLoading = false;
      state.onboardingListError = false;
    },
    onboardingListReceivedPP: (state, { payload }) => {
      state.onboardingList =
        payload.data.proSearch?.map((result, index) => ({
          ...result,
          Nr: index + 1,
        })) ?? [];
      state.onboardingListHasMatches = payload.data.proSearch?.length > 0;
      state.showList = true;
      state.onboardingListLoading = false;
      state.onboardingListError = false;
    },
    onboardingListReceivedLTU: (state, { payload }) => {
      state.onboardingList =
        payload.data.infobankasSearch?.map((result, index) => ({
          ...result,
          Nr: index + 1,
        })) ?? [];
      state.onboardingListHasMatches =
        payload.data.infobankasSearch?.length > 0;
      state.showList = true;
      state.onboardingListLoading = false;
      state.onboardingListError = false;
    },
    onboardingListReceivedLVA: (state, { payload }) => {
      state.onboardingList =
        payload.data.commercialLvaSearch?.map((result, index) => ({
          ...result,
          Nr: index + 1,
        })) ?? [];
      state.onboardingListHasMatches =
        payload.data.commercialLvaSearch?.length > 0;
      state.showList = true;
      state.onboardingListLoading = false;
      state.onboardingListError = false;
    },
    onboardingListReceivedUcab: (state, { payload }) => {
      state.onboardingList =
        payload.data.ucabSearch?.map((result, index) => ({
          ...result,
          Nr: index + 1,
        })) ?? [];
      state.onboardingListHasMatches = payload.data.ucabSearch?.length > 0;
      state.showList = true;
      state.onboardingListLoading = false;
      state.onboardingListError = false;
    },
    onboardingListReceivedAsiakastieto: (state, { payload }) => {
      state.onboardingList =
        payload.data.asiaSearch?.map((result, index) => ({
          ...result,
          Nr: index + 1,
        })) ?? [];
      state.onboardingListHasMatches = payload.data.asiaSearch?.length > 0;
      state.showList = true;
      state.onboardingListLoading = false;
      state.onboardingListError = false;
    },
    onboardingListReceivedBVD: (state, { payload }) => {
      state.onboardingList =
        payload.data.bvdMatch?.sort(scoreDesc).map((result, index) => ({
          ...result,
          address: [
            result.address,
            result.city,
            result.country,
            result.postCode,
          ]
            .filter(Boolean)
            .join(', '),
          Nr: index + 1,
        })) ?? [];
      state.onboardingListHasMatches = payload.data.bvdMatch?.length > 0;
      state.showList = true;
      state.onboardingListLoading = false;
      state.onboardingListError = false;
      state.onboardingBVDTimeout = false;
    },
    onboardingCountriesRequested: state => {
      state.onboardingCountriesError = false;
      state.onboardingCountriesLoading = true;
    },
    onboardingCountriesFailed: state => {
      state.onboardingCountriesError = true;
      state.onboardingCountriesLoading = false;
    },
    onboardingCountriesSuccess: (state, { payload }) => {
      state.onboardingCountries = sortCountriesByISOAlpha3(
        payload.data.listCountriesWithSearchConnectors
      );
      state.onboardingCountriesError = false;
      state.onboardingCountriesLoading = false;
    },
    setOnboardingDatasource: (state, { payload }) => {
      state.onboardingDatasource = payload;
      state.showList = false;
    },
    LEActivitiesListRequested: state => {
      state.LEActivitiesListLoading = true;
      state.LEActivitiesListError = false;
    },
    LEActivitiesListFailed: state => {
      state.LEActivitiesListLoading = false;
      state.LEActivitiesListError = true;
    },
    LEActivitiesListSuccess: (state, { payload }) => {
      state.LEActivitiesList = payload.data.listActivities;
      state.LEActivitiesListLoading = false;
      state.LEActivitiesListError = false;
    },
    setShowList: (state, { payload }) => {
      state.showList = payload;
    },
    setIsCompanyGroupView: (state, { payload }) => {
      state.isCompanyGroupView = payload;
    },
    setOnboardingCountryId: (state, { payload }) => {
      state.onboardingCountryId = payload;
    },
    onboardingServicesRequested: state => {
      state.onboardingServicesLoading = true;
      state.onboardingServicesError = false;
    },
    onboardingServicesFailed: state => {
      state.onboardingServicesLoading = false;
      state.onboardingServicesError = true;
    },
    onboardingServicesSuccess: (state, { payload }) => {
      state.onboardingServices = payload.data.getOnboardingServices;
      state.onboardingServicesLoading = false;
    },
    resetOnboardingState: state => initialState,
  },
  extraReducers: {
    [postOnboardingFormData.pending]: state => {
      if (!state.postOnboardingDataLoading)
        state.postOnboardingDataLoading = true;
    },
    [postOnboardingFormData.fulfilled]: (state, actions) => {
      if (state.postOnboardingDataLoading) {
        state.postOnboardingDataLoading = false;
        state.onboardingFormData = actions.meta.arg;

        delete state.onboardingFormData.requestId;
      }
    },
    [postOnboardingFormData.rejected]: state => {
      if (state.postOnboardingDataLoading) {
        state.postOnboardingDataLoading = false;
        state.postOnboardingDataError = true;
      }
    },
    [saveServiceAsQuestionnaire.pending]: state => {
      state.postOnboardingDataLoading = true;
    },
    [saveServiceAsQuestionnaire.fulfilled]: state => {
      state.postOnboardingDataLoading = false;
    },
    [saveServiceAsQuestionnaire.rejected]: state => {
      state.postOnboardingDataLoading = false;
    },
  },
});

export const {
  onboardingListRequested,
  onboardingListRequestFailed,
  onboardingListReceivedCI,
  onboardingListReceivedPP,
  onboardingListReceivedLTU,
  onboardingListReceivedLVA,
  onboardingListReceivedAsiakastieto,
  onboardingListReceivedUcab,
  onboardingListReceivedBVD,
  onboardingListBVDFailed,
  onboardingCountriesFailed,
  onboardingCountriesRequested,
  onboardingCountriesSuccess,
  setOnboardingDatasource,
  LEActivitiesListFailed,
  LEActivitiesListRequested,
  LEActivitiesListSuccess,
  setShowList,
  setIsCompanyGroupView,
  setOnboardingCountryId,
  onboardingServicesRequested,
  onboardingServicesFailed,
  onboardingServicesSuccess,
  resetOnboardingState,
} = slice.actions;

export default slice.reducer;
