import React from 'react';
import { Modal, Row, Typography, List, Space } from 'antd';
import { useTranslation } from 'react-i18next';

const { Title, Text, Paragraph } = Typography;

const ServiceInfoModal = ({ service, isVisible, setIsVisible }) => {
  const { t } = useTranslation();
  const {
    counterpartyConnectors,
    service: {
      title,
      description,
      listChapterServiceDataBlocks,
      isPriceVisibleToUser,
    },
    totalPrice,
  } = service;

  const LNFreeChaptersList = listChapterServiceDataBlocks.filter(
    ({ heading }) =>
      heading !== 'PEPs' && heading !== 'Sanctions' && heading !== 'Watchlists'
  );

  const handleCancel = () => {
    setIsVisible(false);
  };

  return (
    <Modal
      visible={isVisible}
      onCancel={handleCancel}
      cancelText={t('global.btn.close')}
      okButtonProps={{ style: { display: 'none' } }}
    >
      <Row>
        <Title level={4}>{title}</Title>
      </Row>
      <Row>
        <Paragraph>{description}</Paragraph>
      </Row>
      {isPriceVisibleToUser ? (
        <Space direction="vertical">
          <Text strong>
            {t('global.text.price')}: {totalPrice} €
          </Text>
        </Space>
      ) : null}
      <Row>
        <Text disabled>{t('global.text.dataBlocks')}</Text>
      </Row>
      {LNFreeChaptersList.map(chapter => (
        <List
          key={chapter.id}
          header={<Text strong>{t(chapter.heading)}</Text>}
          split={false}
          className="grouped-list"
          itemLayout="vertical"
        >
          {chapter.childBlocks.map(child => (
            <React.Fragment key={child.id}>
              {child.heading ? (
                <List
                  header={<Text strong>{child.heading}</Text>}
                  split={false}
                  className="grouped-list nested-list"
                  itemLayout="vertical"
                >
                  {child.childBlocks.map(block => (
                    <List.Item key={block.id}>{t(block.title)}</List.Item>
                  ))}
                </List>
              ) : (
                <List.Item>{t(child.title)}</List.Item>
              )}
            </React.Fragment>
          ))}
        </List>
      ))}
      <Space direction="vertical">
        <List
          header={
            <Text disabled>{t('global.text.counterpartyConnectors')}</Text>
          }
          split={false}
          className="grouped-list"
        >
          {counterpartyConnectors.length ? (
            counterpartyConnectors.map(({ id, productName }) => (
              <List.Item key={id}>{t(productName)}</List.Item>
            ))
          ) : (
            <List.Item>{t('global.text.manual.field')}</List.Item>
          )}
        </List>
      </Space>
    </Modal>
  );
};

export default ServiceInfoModal;
