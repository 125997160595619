import React, { useEffect, useState } from 'react';
import { Row, Col, DatePicker, Form, Select, Button, Input, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { DATE_FORMAT } from '../../utils/createServiceHelpers';
import { fetchLEActivities } from '../../store/entities/onboarding/queries';

import CompanySearchModal from './CompanySearchModal';
import LegalEntityUpdateModal from './LegalEntityUpdateModal';
import { ROUTES_UPDATE_LEGALENTITY } from '../../utils/constants';
import useHasPath from '../../hooks/useHasPath';
import { useParams } from 'react-router-dom';

const { Option } = Select;

const ManualOnboarding = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const {
    LEActivitiesList,
    isCompanyGroupView,
    LEActivitiesListLoading,
  } = useSelector(state => state.onboarding);
  const { resources } = useSelector(state => state.resources);

  const dispatch = useDispatch();

  const isOnUpdateLegalEntityForm = useHasPath(ROUTES_UPDATE_LEGALENTITY)
    .hasPath;

  const { legalEntityId } = useParams();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalForUpdatingVisible, setIsModalForUpdatingVisible] = useState(
    false
  );
  const [companyInfo, setCompanyInfo] = useState(null);

  useEffect(() => {
    !LEActivitiesList.length && dispatch(fetchLEActivities());
  }, [dispatch, LEActivitiesList]);

  useEffect(() => {
    dispatch(fetchLEActivities());
  }, [t, dispatch]);

  const handleSubmit = data => {
    const formData = {
      ...data,
      registrationDate:
        data.registrationDate && data.registrationDate.format('YYYY-MM-DD'),
      id: legalEntityId,
    };
    form
      .validateFields()
      .then(() => {
        setCompanyInfo(formData);
        if (isOnUpdateLegalEntityForm) setIsModalForUpdatingVisible(true);
        else setIsModalVisible(true);
      })
      .catch(e => console.error(e));
  };

  return (
    <>
      <Form
        form={form}
        hideRequiredMark={true}
        layout="vertical"
        name="serviceListFilters"
        onFinish={handleSubmit}
      >
        <Card className="card-simple space-vertical-lg has-shadow">
          <Row gutter={[16, 16]}>
            <Col xs={24} md={12} lg={6}>
              <Form.Item
                label={t('onboarding.manual.field.country')}
                name="countryCodeISOAlpha3"
                rules={[
                  {
                    required: true,
                    message: t('onboarding.manual.field.required'),
                  },
                ]}
              >
                <Select
                  optionFilterProp="children"
                  className="space-bottom-none"
                  showSearch
                >
                  {resources.countries.length
                    ? resources.countries.map(({ countryCodeISOAlpha3 }) => (
                        <Option
                          key={countryCodeISOAlpha3}
                          value={countryCodeISOAlpha3}
                        >
                          {t(`global.country.${countryCodeISOAlpha3}`)}
                        </Option>
                      ))
                    : null}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={12}>
              <Form.Item
                label={t('onboarding.manual.field.name')}
                name="cI_Name"
                rules={[
                  {
                    required: true,
                    message: t('onboarding.manual.field.required'),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={6}>
              <Form.Item
                label={t('onboarding.manual.field.regCode')}
                name="registerCode"
                rules={[
                  {
                    required: true,
                    message: t('onboarding.manual.field.required'),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={6}>
              <Form.Item
                label={t('onboarding.manual.field.regDate')}
                name="registrationDate"
              >
                <DatePicker
                  format={DATE_FORMAT}
                  allowClear={true}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={14} xl={6}>
              <Form.Item
                label={t('onboarding.manual.field.legalForm')}
                name="legalForm"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={6}>
              <Form.Item
                label={t('onboarding.manual.field.activity')}
                name="activities"
              >
                <Select
                  mode="multiple"
                  optionFilterProp="children"
                  loading={LEActivitiesListLoading}
                  disabled={LEActivitiesListLoading}
                  allowClear
                  maxTagCount={2}
                >
                  {LEActivitiesList.length
                    ? LEActivitiesList.map(({ id, cI_Name }) => (
                        <Option key={id} value={id}>
                          {t(cI_Name)}
                        </Option>
                      ))
                    : null}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={6}>
              <Form.Item
                label={t('onboarding.manual.field.phone')}
                name="phone"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={12}>
              <Form.Item
                label={t('onboarding.manual.field.address')}
                name="address"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={6}>
              <Form.Item
                label={t('onboarding.manual.field.website')}
                name="website"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={6}>
              <Form.Item
                label={t('onboarding.manual.field.email')}
                name="email"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="end">
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {t('onboarding.manual.button.start')}
              </Button>
            </Form.Item>
          </Row>
        </Card>
      </Form>
      <CompanySearchModal
        isVisible={isModalVisible}
        setIsVisible={setIsModalVisible}
        manualCompanyInfo={companyInfo}
        isGroupCompany={isCompanyGroupView}
        isManual={true}
      />
      <LegalEntityUpdateModal
        isVisible={isModalForUpdatingVisible}
        setIsVisible={setIsModalForUpdatingVisible}
        isGroupCompany={isCompanyGroupView}
        manualCompanyInfo={companyInfo}
        isManual={true}
      />
    </>
  );
};

export default ManualOnboarding;
