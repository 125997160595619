import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spin } from 'antd';

import { getSavedDataBlocks } from '../../../store/entities/createService/dataBlockQueries';

import ServiceStructureHeading from './ServiceStructureHeading';
import Chapter from './Chapter';

const ServiceStructure = () => {
  const dispatch = useDispatch();
  const {
    serviceId,
    savedDataBlocks,
    serviceActionLoading,
    dataBlockActionLoading,
  } = useSelector(state => state.createService);

  useEffect(() => {
    dispatch(getSavedDataBlocks(serviceId));
  }, [dispatch, serviceId]);

  return (
    <>
      <ServiceStructureHeading />
      <Spin size="large" spinning={dataBlockActionLoading}>
        {!serviceActionLoading && savedDataBlocks.length
          ? savedDataBlocks.map((dataBlock, idx) => (
              <Chapter
                key={dataBlock.id || idx}
                dataBlock={dataBlock}
                chapterIndex={idx}
              />
            ))
          : null}
      </Spin>
    </>
  );
};

export default ServiceStructure;
