import * as gql from 'gql-query-builder';

import { apiCallBegan } from '../../api';
import { clientBackend } from '../../../api-config';
import {
  customerRequested,
  customerSuccess,
  customerFailed,
  savingCustomerRelation,
  customerRelationSaveSuccess,
  customerRelationStatusSaveSuccess,
} from '.';

import { LEGALENTITY_FIELDS } from '../company/queries';

export const fetchCustomer = id =>
  apiCallBegan({
    url: clientBackend,
    data: gql.query({
      operation: 'legalEntityRelation',
      variables: {
        id: {
          value: id,
          type: 'Int!',
        },
      },
      fields: [
        'id',
        'relationStatusId',
        'relationTypeId',
        'createdById',
        'parentLegalEntityId',
        {
          childLegalEntity: LEGALENTITY_FIELDS,
        },
      ],
    }),
    onStart: customerRequested.type,
    onSuccess: customerSuccess.type,
    onFailure: customerFailed.type,
  });

export const saveCustomerRelations = relationInfo =>
  apiCallBegan({
    url: clientBackend,
    data: gql.mutation({
      operation: 'legalEntityRelation',
      variables: {
        input: {
          value: {
            ...relationInfo,
          },
          type: 'LegalEntityRelationInputType!',
        },
      },
      fields: ['id', 'relationTypeId', 'relationStatusId'],
    }),
    onStart: savingCustomerRelation.type,
    onSuccess: customerRelationSaveSuccess.type,
    onFailure: customerFailed.type,
  });

export const updateRelationStatus = relationInfo =>
  apiCallBegan({
    url: clientBackend,
    data: gql.mutation({
      operation: 'legalEntityRelationUpdateProperty',
      variables: {
        input: {
          value: {
            ...relationInfo,
          },
          type: 'LegalEntityRelationInputType!',
        },
        propertyName: 'relationStatusId',
      },
      fields: ['id', 'relationStatusId'],
    }),
    onStart: savingCustomerRelation.type,
    onSuccess: customerRelationStatusSaveSuccess.type,
    onFailure: customerFailed.type,
  });
