import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import {
  setLogoString,
  setHasFormChanged,
} from '../../store/entities/createService';
import { showErrorNotification } from '../../utils/showNotification';

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file, t) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    showErrorNotification(t('new.service.upload.type.error'));
  }
  const isLt2M = file.size / 1024 / 1024 < 1;
  if (!isLt2M) {
    showErrorNotification(t('new.service.upload.size.error'));
  }
  return isJpgOrPng && isLt2M;
}

const ImageUpload = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [imageList, setImageList] = useState([]);
  const { logoString, formStep } = useSelector(state => state.createService);

  useEffect(() => {
    if (logoString && !imageList.length) {
      const fetchedLogo = {
        uid: '1',
        name: 'fetched_logo',
        status: 'done',
        url: logoString,
      };
      setImageList([fetchedLogo]);
    }
  }, [imageList.length, logoString]);

  useEffect(() => {
    if (formStep === 1 && !logoString && imageList.length) {
      setImageList([]);
    }
  }, [formStep, imageList.length, logoString]);

  const handleChange = info => {
    dispatch(setHasFormChanged(true));
    if (info.fileList.length === 0) dispatch(setLogoString(null));
    info.file.status && setImageList(info.fileList);

    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, imageUrl => {
        dispatch(setLogoString(imageUrl));
      });
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">{t('new.service.upload.logo')}</div>
    </div>
  );

  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  return (
    <Upload
      name="logo"
      listType="picture-card"
      fileList={imageList}
      className="avatar-uploader"
      showUploadList={{ showRemoveIcon: true, showPreviewIcon: false }}
      customRequest={dummyRequest}
      beforeUpload={file => beforeUpload(file, t)}
      onChange={handleChange}
      disabled={formStep === 1}
    >
      {imageList.length === 0 && uploadButton}
    </Upload>
  );
};

export default ImageUpload;
