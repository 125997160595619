import React from 'react';
import { Row, Space, Col, Typography, Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import {
  saveCustomerRelations,
  updateRelationStatus,
} from '../../store/entities/customer/queries';
import { relationStatuses, relationTypes } from '../../utils/constants';

const { Title } = Typography;

const CustomerHeader = ({ customer }) => {
  const {
    childLegalEntity: { id, cI_Name },
    relationTypeId,
    relationStatusId,
  } = customer;

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const handleCancelOnboarding = relationStatusId => {
    const relations = {
      childLegalEntityId: 0,
      id: customer.id,
      relationTypeId: 0,
      relationStatusId,
    };

    dispatch(updateRelationStatus(relations));
  };

  const abortOnboarding = relationStatusId =>
    innerAbortOnboarding(t('onboarding.abort.message'), relationStatusId);

  const innerAbortOnboarding = (contentText, relationStatusId) => {
    Modal.confirm({
      content: contentText,
      okText: t('onboarding.abort.button.yes'),
      cancelText: t('onboarding.abort.button.no'),
      onOk: () => handleCancelOnboarding(relationStatusId),
    });
  };

  const handleSavingLegalEntity = typeId => {
    const relations = {
      relationTypeId: typeId,
      childLegalEntityId: id,
      relationStatusId: relationStatuses.active,
      id: customer.id,
    };

    dispatch(saveCustomerRelations(relations));
  };

  return (
    <Row className="space-vertical-lg company-data-block">
      <Space className="space-between">
        <Col className="page-title">
          <Title>{cI_Name}</Title>
        </Col>
        <Col>
          <Space>
            {relationTypeId === relationTypes.onboarding ? (
              <>
                <Button
                  onClick={() => abortOnboarding(relationStatuses.canceled)}
                  disabled={relationStatusId === relationStatuses.canceled}
                >
                  {t('customer.button.abortOnboarding')}
                </Button>
                <Button
                  type="primary"
                  onClick={() =>
                    handleSavingLegalEntity(relationTypes.customer)
                  }
                >
                  {t('customer.button.addToCustomers')}
                </Button>
                <Button
                  type="primary"
                  onClick={() => handleSavingLegalEntity(relationTypes.partner)}
                >
                  {t('customer.button.addToPartners')}
                </Button>
              </>
            ) : relationTypeId === relationTypes.partner ? (
              <>
                <Button
                  onClick={() => abortOnboarding(relationStatuses.offboarded)}
                  disabled={relationStatusId === relationStatuses.offboarded}
                >
                  {t('customer.button.remove.from.partners')}
                </Button>
                <Button
                  type="primary"
                  onClick={() =>
                    handleSavingLegalEntity(relationTypes.customer)
                  }
                >
                  {t('customer.button.addToCustomers')}
                </Button>
              </>
            ) : relationTypeId === relationTypes.customer ? (
              <>
                <Button
                  onClick={() => abortOnboarding(relationStatuses.offboarded)}
                  disabled={relationStatusId === relationStatuses.offboarded}
                >
                  {t('customer.button.remove.from.customers')}
                </Button>
                <Button
                  type="primary"
                  onClick={() => handleSavingLegalEntity(relationTypes.partner)}
                >
                  {t('customer.button.addToPartners')}
                </Button>
              </>
            ) : [
                relationTypes.parent,
                relationTypes.subsidiary,
                relationTypes.associatedCompany,
              ].includes(relationTypeId) &&
              relationStatusId === relationStatuses.active ? (
              <>
                <Button
                  onClick={() =>
                    innerAbortOnboarding(
                      t('LE.abort.message'),
                      relationStatuses.offboarded
                    )
                  }
                >
                  {t('LE.button.remove')}
                </Button>
              </>
            ) : null}
          </Space>
        </Col>
      </Space>
    </Row>
  );
};

export default CustomerHeader;
