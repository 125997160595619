import * as gql from 'gql-query-builder';

import { apiCallBegan } from '../../api';
import { clientBackend } from '../../../api-config';
import {
  onboardingListRequested,
  onboardingListReceivedCI,
  onboardingListReceivedPP,
  onboardingListReceivedLTU,
  onboardingListReceivedLVA,
  onboardingListReceivedAsiakastieto,
  onboardingListReceivedUcab,
  onboardingListRequestFailed,
  onboardingCountriesRequested,
  onboardingCountriesSuccess,
  onboardingCountriesFailed,
  LEActivitiesListRequested,
  LEActivitiesListSuccess,
  LEActivitiesListFailed,
  onboardingListReceivedBVD,
  onboardingServicesRequested,
  onboardingServicesFailed,
  onboardingServicesSuccess,
  onboardingListBVDFailed,
} from '.';

export const fetchCountriesWithSearchConnectors = () =>
  apiCallBegan({
    url: clientBackend,
    data: gql.query({
      operation: 'listCountriesWithSearchConnectors',
      fields: [
        'id',
        'countryCodeISOAlpha3',
        'countryCodeISOAlpha2',
        {
          counterpartyConnectorCountries: [
            'id',
            'countryCodeISOAlpha3',
            'counterpartyConnectorId',
            {
              counterpartyConnector: [
                'counterPartyId',
                'sequenceNumber',
                'productName',
              ],
            },
          ],
        },
      ],
    }),
    onStart: onboardingCountriesRequested.type,
    onSuccess: onboardingCountriesSuccess.type,
    onFailure: onboardingCountriesFailed.type,
  });

export const fetchLEActivities = () =>
  apiCallBegan({
    url: clientBackend,
    data: gql.query({
      operation: 'listActivities',
      fields: ['id', 'cI_Name'],
    }),
    onStart: LEActivitiesListRequested.type,
    onSuccess: LEActivitiesListSuccess.type,
    onFailure: LEActivitiesListFailed.type,
  });

const searchConnectorResponseBaseDto = [
  'iDMBeginStrategyRequestId',
  'indexInResponse',
];

export const fetchOnboardingListPP = (searchValue, country) =>
  apiCallBegan({
    url: clientBackend,
    data: gql.query({
      operation: 'proSearch',
      variables: {
        code: searchValue,
        country,
      },
      fields: [
        ...searchConnectorResponseBaseDto,
        'legalAddress',
        'legalForm',
        'name',
        'registryCode',
        'status',
      ],
    }),
    onStart: onboardingListRequested.type,
    onSuccess: onboardingListReceivedPP.type,
    onFailure: onboardingListRequestFailed.type,
  });

export const fetchOnboardingListUcab = searchValue =>
  apiCallBegan({
    url: clientBackend,
    data: gql.query({
      operation: 'ucabSearch',
      variables: {
        name: searchValue,
      },
      fields: [
        ...searchConnectorResponseBaseDto,
        'name',
        'registerCode',
        'address',
        'city',
        'postalCode',
        'phone',
        'status1',
        'status2',
      ],
    }),
    onStart: onboardingListRequested.type,
    onSuccess: onboardingListReceivedUcab.type,
    onFailure: onboardingListRequestFailed.type,
  });

export const fetchOnboardingListAsiakastieto = (name, code) =>
  apiCallBegan({
    url: clientBackend,
    data: gql.query({
      operation: 'asiaSearch',
      variables: {
        name: name ? name : code,
      },
      fields: [
        ...searchConnectorResponseBaseDto,
        'businessId',
        'name',
        'address',
        'domicile',
        'dissolvedFromRegister',
        'group',
        'parallelBusinessName',
        'tradingName',
        'groupTradingName',
        'previousName',
        'groupPreviousName',
        'underFoundation',
      ],
    }),
    onStart: onboardingListRequested.type,
    onSuccess: onboardingListReceivedAsiakastieto.type,
    onFailure: onboardingListRequestFailed.type,
  });

export const fetchOnboardingListLVA = searchValue =>
  apiCallBegan({
    url: clientBackend,
    data: gql.query({
      operation: 'commercialLvaSearch',
      variables: {
        name: searchValue,
      },
      fields: [
        ...searchConnectorResponseBaseDto,
        'address',
        'currentName',
        'linksSelf',
        'previousName',
        'register',
        'registrationNumber',
        'status',
        'type',
      ],
    }),
    onStart: onboardingListRequested.type,
    onSuccess: onboardingListReceivedLVA.type,
    onFailure: onboardingListRequestFailed.type,
  });

export const fetchOnboardingListBVD = (code, country) =>
  apiCallBegan({
    url: clientBackend,
    data: gql.query({
      operation: 'bvdMatch',
      variables: {
        code,
        country,
      },
      fields: [
        ...searchConnectorResponseBaseDto,
        'bVDId',
        'address',
        'city',
        'consolidationCode',
        'country',
        'emailOrWebSite',
        'hint',
        'legalForm',
        'matchedName',
        'matchedNameType',
        'name',
        'nationalId',
        'nationalIdLabel',
        'phoneOrFax',
        'postCode',
        'postCodeSpecified',
        'score',
        'addressType',
      ],
    }),
    onStart: onboardingListRequested.type,
    onSuccess: onboardingListReceivedBVD.type,
    onFailure: onboardingListBVDFailed.type,
    onError: onboardingListBVDFailed.type,
  });

export const fetchOnboardingListLTU = (name, code) =>
  apiCallBegan({
    url: clientBackend,
    data: gql.query({
      operation: 'infobankasSearch',
      variables: {
        name: name ? name : code,
      },
      fields: [
        ...searchConnectorResponseBaseDto,
        'cityEN',
        'cityNative',
        'creditInfoID',
        'isCompany',

        'jar_kodas',

        'lERStatusName',
        'lERStatusNameEN',
        'nACE',
        'nACE2',
        'nACE_ENG',
        'nACE_ENG2',
        'nameEN',
        'nameNative',
        'nUMBER',
        'org_nameEN_status_code',

        'org_name_status_code',
        'org_name_status_code1',
        'org_status_code',
        'postalCode',
        'sLERStatusName',
        'sLERStatusNameEN',
        'streetEN',
        'streetNative',
      ],
    }),
    onStart: onboardingListRequested.type,
    onSuccess: onboardingListReceivedLTU.type,
    onFailure: onboardingListRequestFailed.type,
  });

export const fetchOnboardingListCI = searchValue =>
  apiCallBegan({
    url: clientBackend,
    data: gql.query({
      operation: 'listCompanyBasicDatasWithPagination',
      variables: {
        searchParams: {
          value: {
            pagination: {
              pageSize: 10,
              pageIndex: 0,
            },
            filter: {
              filterConditions: [searchValue],
            },
          },
          type: 'SearchParamsInputType!',
        },
      },
      fields: [
        'total',
        {
          result: [
            ...searchConnectorResponseBaseDto,
            'name',
            'status',
            'regCode',
            'legalStreet',
            'legalCity',
            'activities',
            'phones',
            'emails',
            'web',
            'regTime',
          ],
        },
      ],
    }),
    onStart: onboardingListRequested.type,
    onSuccess: onboardingListReceivedCI.type,
    onFailure: onboardingListRequestFailed.type,
  });

export const fetchOnboardingServices = country =>
  apiCallBegan({
    url: clientBackend,
    data: gql.query({
      operation: 'getOnboardingServices',
      variables: {
        country: {
          value: country,
          type: 'String!',
        },
      },
      fields: ['serviceId', 'totalPrice', { service: ['title'] }],
    }),
    onStart: onboardingServicesRequested.type,
    onSuccess: onboardingServicesSuccess.type,
    onFailure: onboardingServicesFailed.type,
  });
