import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { CardActionsContext } from './CardActionsProvider';

import { Modal, Row, Typography, Col } from 'antd';

const { Title, Paragraph } = Typography;

const HelpTextModal = ({ title, helpText, serviceProviderHelpText }) => {
  const { t } = useTranslation();
  const { helpTextOpen: isOpen, toggleHelpText: toggleVisible } = useContext(
    CardActionsContext
  );

  return (
    <Modal
      title={t(title)}
      visible={isOpen}
      onCancel={toggleVisible}
      cancelText={t('global.btn.close')}
      cancelButtonProps={{ className: 'btn-secondary' }}
      okButtonProps={{ style: { display: 'none' } }}
    >
      {helpText && (
        <Row>
          <Col>
            <Title level={4}>{t('questionnaire.help.modal.help')}</Title>
            <Paragraph>{helpText}</Paragraph>
          </Col>
        </Row>
      )}
      {serviceProviderHelpText && (
        <Row>
          <Col>
            <Title level={4}>{t('questionnaire.help.modal.general')}</Title>
            <Paragraph>{t(serviceProviderHelpText)}</Paragraph>
          </Col>
        </Row>
      )}
    </Modal>
  );
};

export default HelpTextModal;
