import React, { useEffect, useRef } from 'react';
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useHistory,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';

import { showErrorNotification } from './utils/showNotification';
import { fetchResources } from './store/entities/resources/queries';
import {
  fetchCompany,
  fetchEnabledCounterPartyConnectors,
} from './store/entities/company/queries';
import { chooseMember, getMember, getUser, logout } from './store/user';

import Home from './views/Home';
import TranslationEditor from './views/TranslationEditor';
import NotFound from './views/NotFound';
import NewService from './views/NewService';
import ServiceManagement from './views/ServiceManagement';
import Partners from './views/Partners';
import CompanySearch from './views/CompanySearch';
import Company from './views/Company';
import Customer from './views/Customer';
import Questionnaire from './views/Questionnaire';
import CustomerSelectServices from './views/CustomerSelectServices';
import Sharing from './views/Sharing';
import { ROUTES_UPDATE_LEGALENTITY } from './utils/constants';
import LegalEntityKeyDataView from './components/legalentity/LegalEntityKeyDataView';

const Routes = () => {
  const { pathname, search } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { user } = useSelector(state => state.user);

  const initialUrl = useRef(pathname);

  useEffect(() => {
    const path = pathname.split('/').slice(2).join('/');
    initialUrl.current = `/${language}/${path}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  useEffect(() => {
    const handleLoginError = () => {
      dispatch(logout());
      history.push(`/${language}`);
    };

    const getUserWithRole = async () => {
      try {
        const userResult = await dispatch(getUser());
        const { Role, KycUserId } = unwrapResult(userResult);

        if (!KycUserId) throw new Error('No user');

        if (!Role) {
          try {
            const membersResponse = await dispatch(getMember());
            const {
              data: { getMembersForCurrentUser },
            } = unwrapResult(membersResponse);

            if (getMembersForCurrentUser.length === 1) {
              try {
                await dispatch(chooseMember(getMembersForCurrentUser[0].id));
                history.push(`/${language}/kyc/company`);
              } catch (chooseMemberError) {
                console.info(chooseMemberError);
                showErrorNotification(t('general.chooseMember.error'));
                handleLoginError();
              }
            }
          } catch (getMembersError) {
            console.info(getMembersError);
            showErrorNotification(t('general.getMembers.error'));
            handleLoginError();
          }
        }
      } catch (getUserError) {
        console.info(getUserError);
        if (getUserError.message === 'No user') return;

        showErrorNotification(t('general.getUser.error'));
      }
    };

    if (user) {
      dispatch(fetchResources());

      if (user.CustomerLegalentityId)
        dispatch(fetchCompany(user.CustomerLegalentityId));

      if (user.CustomerId) dispatch(fetchEnabledCounterPartyConnectors());

      if (user.Role) {
        history.push(initialUrl.current);
      }
    } else {
      getUserWithRole();
    }
  }, [dispatch, history, language, t, user]);

  if (!user) {
    return (
      <>
        <Redirect to={`/${language}${search}`} />
        <Route exact path="/:lang" component={Home} />
      </>
    );
  }

  return (
    <Switch>
      <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />

      <Route exact path="/:lang/kyc/company" component={Company} />
      <Route exact path="/:lang/kyc/company/mykyc" component={Customer} />
      <Route
        exact
        path="/:lang/kyc/company/mykyc/service"
        component={CustomerSelectServices}
      />
      <Route
        exact
        path="/:lang/kyc/company/group/:customerId"
        component={Customer}
      />
      <Route
        exact
        path="/:lang/kyc/company/mykyc/questionnaire/:questionnaireId"
        component={Questionnaire}
      />
      <Route exact path="/:lang/kyc/company/search" component={CompanySearch} />

      <Route
        exact
        path={`/:lang/kyc/${ROUTES_UPDATE_LEGALENTITY}/legalEntity/:legalEntityId/counterParty/:counterPartyId`}
        component={CompanySearch}
      />
      <Route
        exact
        path={`/:lang/kyc/legalEntityKeyDataView/:legalEntityId`}
        component={LegalEntityKeyDataView}
      />

      <Route exact path="/:lang/kyc/partners" component={Partners} />
      <Route
        exact
        path="/:lang/kyc/partners/search"
        component={CompanySearch}
      />
      <Route
        exact
        path="/:lang/kyc/partners/search/:customerId"
        component={Customer}
      />
      <Route
        exact
        path="/:lang/kyc/partners/search/:customerId/service"
        component={CustomerSelectServices}
      />
      <Route
        exact
        path="/:lang/kyc/partners/search/:customerId/questionnaire/:questionnaireId"
        component={Questionnaire}
      />
      <Route
        exact
        path="/:lang/kyc/partners/search/questionnaire/:questionnaireId"
        component={Questionnaire}
      />

      <Route exact path="/:lang/kyc/shared" component={Sharing} />
      <Route exact path="/:lang/kyc/shared/withMe" component={Sharing} />
      <Route exact path="/:lang/kyc/shared/byMe" component={Sharing} />

      <Route
        exact
        path="/:lang/kyc/shared/withMe/questionnaire/:questionnaireId"
        component={Questionnaire}
      />
      <Route
        exact
        path="/:lang/kyc/shared/byMe/questionnaire/:questionnaireId"
        component={Questionnaire}
      />

      <Redirect from="/" to={`/${language}/kyc/company`} exact />
      <Redirect from="/:lang" to={`/${language}/kyc/company`} exact />

      {user.Role === 'KYCAdmin' && (
        <>
          <Route
            path="/:lang/admin/translation"
            component={TranslationEditor}
          />
          <Route
            exact
            path="/:lang/admin/service"
            component={ServiceManagement}
          />
          <Route exact path="/:lang/admin/service/new" component={NewService} />
          <Route
            exact
            path="/:lang/admin/service/edit/:id"
            component={NewService}
          />
        </>
      )}

      <Route path="*" component={NotFound} />
    </Switch>
  );
};

export default Routes;
