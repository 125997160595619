import React from 'react';
import { useTranslation } from 'react-i18next';
import { List, Typography } from 'antd';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';

import { isAttributeVisible } from '../../../utils/createServiceHelpers';

const { Text } = Typography;

const EditVisibility = ({ dataAttributes, setDataAttributes }) => {
  const { t } = useTranslation();

  const setVisibility = attributeIndex => {
    const attributes = dataAttributes.map(attr => ({ ...attr }));
    attributes[attributeIndex].isVisible = !attributes[attributeIndex]
      .isVisible;

    setDataAttributes(attributes);
  };

  return (
    <List>
      {dataAttributes.map((child, index) => (
        <List.Item
          className="list_ul"
          size="small"
          key={'attribute' + child.id}
          onClick={() => setVisibility(index)}
          style={{
            display: isAttributeVisible(child),
          }}
        >
          {child.isVisible ? (
            <>
              <EyeOutlined key="visibility" />
              {child.dataAttribute
                ? t(child.dataAttribute.cI_Name)
                : t(child.cI_Name)}
            </>
          ) : (
            <>
              <EyeInvisibleOutlined key="visibility" />
              <Text delete>
                {child.dataAttribute
                  ? t(child.dataAttribute.cI_Name)
                  : t(child.cI_Name)}
              </Text>
            </>
          )}{' '}
        </List.Item>
      ))}
    </List>
  );
};

export default EditVisibility;
