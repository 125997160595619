import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

import { Modal, Form, Input, Button, Row, Col, Select, Spin } from 'antd';
import { CloseOutlined, SaveOutlined, DeleteOutlined } from '@ant-design/icons';

import useIsFirstRender from '../../../hooks/useIsFirstRender';

import {
  operateDataBlock,
  getManualField,
  deleteServiceDataBlock,
} from '../../../store/entities/createService/thunks';
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../utils/showNotification';

import { confirmationModal } from '../ConfirmationModal';

const { TextArea } = Input;
const { Option } = Select;

const ManualFieldForm = ({
  chapterIndex,
  chapterId,
  subChapterIndex,
  subChapterId,
  isVisible,
  setIsVisible,
  modalType,
  dataBlockId,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { serviceId, savedDataBlocks, dataBlockActionLoading } = useSelector(
    state => state.createService
  );

  const [form] = Form.useForm();
  const [isFirstRender, setIsFirstRender] = useIsFirstRender();

  const [fieldType, setFieldType] = useState('');

  const parentChapter = subChapterId
    ? savedDataBlocks[chapterIndex].childBlocks[subChapterIndex]
    : savedDataBlocks[chapterIndex];
  const dataBlockIndex = parentChapter.childBlocks.findIndex(
    ({ id }) => id === dataBlockId
  );
  const dataBlockInState =
    modalType === 'edit'
      ? parentChapter.childBlocks.find(({ id }) => id === dataBlockId)
      : undefined;
  const attributeIds =
    dataBlockInState &&
    dataBlockInState.childAttributes &&
    dataBlockInState.childAttributes.map(({ id }) => id).sort();

  useEffect(() => {
    const getManualFormData = async () => {
      const result = await dispatch(
        getManualField({ id: dataBlockId, chapterIndex, subChapterIndex })
      );

      if (getManualField.fulfilled.match(result)) {
        const {
          data: {
            serviceDataBlock: [dataBlock],
          },
        } = unwrapResult(result);

        form.setFieldsValue({
          title: dataBlock.title,
          type: 'textarea',
          helpText: dataBlock.helpText,
        });

        if (dataBlock.childAttributes[0].isManualRadioOption) {
          form.setFieldsValue({
            type: 'radio',
            option1: dataBlock.childAttributes[0].title,
            option2: dataBlock.childAttributes[1].title,
          });

          setFieldType('radio');
        }
      }
    };

    if (modalType === 'edit' && isVisible && !isFirstRender) {
      setIsFirstRender(true);
      getManualFormData();
    }
  }, [
    dispatch,
    isVisible,
    dataBlockId,
    modalType,
    chapterIndex,
    subChapterIndex,
    isFirstRender,
    setIsFirstRender,
    form,
  ]);

  const simpleDataBlocksLengthInChapter = parentChapter.childBlocks.length;

  const onFinish = async data => {
    try {
      await form.validateFields();
      const resultAction = await dispatch(
        operateDataBlock({
          type: modalType === 'edit' ? 'manualFieldEdit' : 'manualField',
          chapterIndex,
          subChapterIndex,
          dataBlockIndex,
          dataBlockInfo: {
            serviceId,
            id: dataBlockId || null,
            title: data.title,
            parentServiceDataBlockId: subChapterId ? subChapterId : chapterId,
            sequenceNumber:
              modalType === 'edit'
                ? dataBlockIndex
                : simpleDataBlocksLengthInChapter,
            helpText: data.helpText,
            isManualDataBlock: true,
            isUserSelectableReferenceSource: false,
            noReferenceSource: false,
            serviceDataAttributes:
              data.type === 'radio'
                ? [
                    {
                      id: (attributeIds && attributeIds[0]) || null,
                      title: data.option1,
                      sequenceNumber: 0,
                      isManualRadioOption: true,
                    },
                    {
                      id: (attributeIds && attributeIds[1]) || null,
                      title: data.option2,
                      sequenceNumber: 1,
                      isManualRadioOption: true,
                    },
                  ]
                : [
                    {
                      id: (attributeIds && attributeIds[0]) || null,
                      title: data.title,
                      sequenceNumber: 0,
                      isManualRadioOption: false,
                    },
                  ],
          },
        })
      );

      if (operateDataBlock.fulfilled.match(resultAction)) {
        setIsVisible(false);
        form.resetFields();
        setIsFirstRender(false);
      } else {
        showErrorNotification(t('new.service.add.manual.field.error'));
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  const handleClose = () => setIsVisible(false);

  const deleteField = async () => {
    const result = await dispatch(
      deleteServiceDataBlock({
        id: dataBlockId,
        type: 'manualField',
        chapterIndex,
        subChapterIndex,
      })
    );

    if (deleteServiceDataBlock.fulfilled.match(result)) {
      showSuccessNotification(t('new.service.dataBlock.delete.success'));
    } else {
      showErrorNotification(t('new.service.dataBlock.delete.error'));
    }
  };

  const handleDelete = () => {
    confirmationModal('.manualField.delete', t, deleteField, () => {});
  };

  return (
    <Modal
      title={t('new.service.manual.field.title')}
      visible={isVisible}
      onCancel={handleClose}
      footer={null}
    >
      <Spin spinning={dataBlockActionLoading}>
        <Form
          form={form}
          name="manualField"
          onFinish={onFinish}
          hideRequiredMark
          layout="vertical"
        >
          <Row gutter={[16, 0]}>
            <Col xs={24} lg={12}>
              <Form.Item
                label={t('new.service.manual.field.title.lable')}
                name="title"
                rules={[
                  {
                    required: true,
                    message: t('new.service.field.required'),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label={t('new.service.manual.field.type.lable')}
                name="type"
                rules={[
                  {
                    required: true,
                    message: t('new.service.field.required'),
                  },
                ]}
              >
                <Select
                  onChange={value => setFieldType(value)}
                  disabled={modalType === 'edit'}
                >
                  <Option value="textarea">
                    {t('new.service.manual.field.option.textarea')}
                  </Option>
                  <Option value="radio">
                    {t('new.service.manual.field.option.radio')}
                  </Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {fieldType === 'radio' && (
            <Row gutter={[16, 0]}>
              <Col xs={24} lg={12}>
                <Form.Item
                  label={t('new.service.manual.field.radio.option1')}
                  name="option1"
                  rules={[
                    {
                      required: true,
                      message: t('new.service.field.required'),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item
                  label={t('new.service.manual.field.radio.option2')}
                  name="option2"
                  dependencies={['option1']}
                  rules={[
                    {
                      required: true,
                      message: t('new.service.field.required'),
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        const fieldValue = getFieldValue('option1').trim();
                        if (!value || fieldValue !== value.trim()) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          t('new.service.manual.field.labels.matching')
                        );
                      },
                    }),
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          )}
          <Form.Item
            label={t('new.service.manual.field.helpText.label')}
            name="helpText"
            rules={[
              {
                required: true,
                message: t('new.service.field.required'),
              },
            ]}
          >
            <TextArea />
          </Form.Item>
          <Row justify="space-between">
            <Form.Item className="margin-bottom-none">
              <Button
                type="default"
                htmlType="button"
                icon={<CloseOutlined />}
                onClick={handleClose}
              >
                {t('global.btn.discard')}
              </Button>
            </Form.Item>
            {modalType === 'edit' ? (
              <Form.Item className="margin-bottom-none">
                <Button
                  className="btn-secondary"
                  htmlType="button"
                  icon={<DeleteOutlined />}
                  onClick={handleDelete}
                >
                  {t('global.btn.delete')}
                </Button>
              </Form.Item>
            ) : null}
            <Form.Item className="margin-bottom-none">
              <Button
                className="btn-secondary"
                htmlType="submit"
                icon={<SaveOutlined />}
              >
                {t('global.btn.save')}
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};

export default ManualFieldForm;
