import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Input, Button, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import {
  setActiveTranslation,
  editTranslationReset,
} from '../../store/entities/translation';
import { editTranslation } from '../../store/entities/translation/queries';
import { fetchLanguageCodes } from '../../store/entities/languageCode';
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../utils/showNotification';

const EditTranslationForm = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const editTranslationSuccess = useSelector(
    state => state.translation.editTranslationSuccess
  );
  const editTranslationFailed = useSelector(
    state => state.translation.editTranslationFailed
  );
  const editTranslationLoading = useSelector(
    state => state.translation.editTranslationLoading
  );
  const translation = useSelector(state => state.translation.activeTranslation);
  const languageCodes = useSelector(state => state.languageCode.listAll);

  useEffect(() => {
    dispatch(fetchLanguageCodes(false));
  }, [dispatch]);

  useEffect(() => {
    if (editTranslationSuccess) {
      showSuccessNotification(t('translation.editor.editModal.editSuccess'));
      dispatch(fetchLanguageCodes(true));
    }
    if (editTranslationFailed)
      showErrorNotification(t('translation.editor.editModal.editFailed'));
  }, [editTranslationSuccess, editTranslationFailed, t, dispatch]);

  const [form] = Form.useForm();

  const onFinish = async data => {
    try {
      await form.validateFields();
      dispatch(editTranslation(data));
    } catch (errorInfo) {
      return;
    }
  };

  const handleClose = () => {
    if (editTranslationLoading) return;
    else {
      dispatch(setActiveTranslation(null));
      dispatch(editTranslationReset());
    }
  };

  const handleLangChange = value => {
    formRef.current.setFieldsValue({
      tLanguage: value,
    });
  };

  const { Option } = Select;

  const formRef = useRef();

  return (
    <Modal
      form={form}
      title={t('translation.editor.editModal.title')}
      visible
      onCancel={handleClose}
      okButtonProps={{ style: { display: 'none' } }}
      cancelText={t('global.btn.close')}
    >
      <Form
        name="basic"
        initialValues={{
          tKey: translation.tKey,
          tValue: translation.tValue,
          tLanguage: translation.tLanguage || 'us',
        }}
        onFinish={onFinish}
        ref={formRef}
      >
        <Form.Item label={t('translation.editor.key')} name="tKey">
          <Input disabled />
        </Form.Item>

        <Form.Item
          label={t('translation.editor.value')}
          name="tValue"
          rules={[
            {
              required: true,
              message: t('translation.editor.editModal.valueRequiredMsg'),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="tLanguage"
          label={t('translation.editor.language')}
          rules={[
            {
              required: true,
              message: t('translation.editor.editModal.langRequiredMsg'),
            },
          ]}
        >
          <Select showSearch onChange={handleLangChange}>
            {languageCodes.map(code => (
              <Option key={code} value={code.toLowerCase()}>
                {code}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item>
          <Button
            loading={editTranslationLoading}
            type="primary"
            htmlType="submit"
          >
            {t('global.btn.submit')}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditTranslationForm;
