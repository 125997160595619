import React, { useState, useEffect } from 'react';
import { Row, Col, Tag, Space, Steps, Spin, Typography, Form } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { debounce } from 'lodash';

import {
  setFormStep,
  setFormType,
  setFormData,
  setHasFormChanged,
  revertFormData,
} from '../store/entities/createService';
import {
  fetchUser,
  fetchService,
  publishService,
} from '../store/entities/createService/serviceQueries';
import { getCounterPartyConnectors } from '../store/entities/createService/dataBlockQueries';
import {
  showErrorNotification,
  showSuccessNotification,
} from '../utils/showNotification';
import { YYYYMMDD } from '../utils/constants';

import Domains from '../components/services/Domains';
import NewServiceForm from '../components/services/NewServiceForm';
import ServiceStructure from '../components/services/ServiceStructure';
import ImageUpload from '../components/services/ImageUpload';
import FormMenu from '../components/services/FormMenu';
import { confirmationModal } from '../components/services/ConfirmationModal';

const { Title } = Typography;
const { Step } = Steps;

const autoSavingFields = [
  'serviceDataBlocks',
  'serviceDataAttributes',
  'listChapterServiceDataBlocks',
  'serviceUsers',
];

const NewService = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { id } = useParams();
  const savedServiceId = Number(id);
  const {
    domainsData,
    formStep,
    status,
    serviceActionLoading,
    serviceActionSuccess,
    serviceActionError,
    serviceData,
    formType,
    serviceId,
    domainsDataLoading,
    formData,
    logoString: logo,
    dataBlockActionLoading,
    serviceDomainLoading,
    hasFormChanged,
    isTitleInputActive,
    savedFormData,
  } = useSelector(state => state.createService);
  const { resourcesLoading } = useSelector(state => state.resources);
  const [isFormDataLoaded, setIsFormDataLoaded] = useState(false);
  const [statusId, setStatusId] = useState(status);

  const [isSaveButtonPressed, setIsSaveButtonPressed] = useState(false);
  const serviceHasSelectedDataBlocks = domainsData.some(
    domain => domain.chosenBlocks.length
  );

  useEffect(() => {
    const pathArray = location.pathname.split('/');
    if (pathArray.includes('edit')) {
      dispatch(setFormType('edit'));
      dispatch(fetchService(savedServiceId));
    } else {
      setIsFormDataLoaded(true);
    }
  }, [dispatch, location.pathname, savedServiceId]);

  useEffect(() => {
    if (serviceData.serviceUsers) {
      serviceData.serviceUsers.forEach(userId => {
        dispatch(fetchUser(userId));
      });
    }
  }, [serviceData.serviceUsers, dispatch]);

  useEffect(() => {
    if (serviceData.users && serviceData.serviceUsers) {
      if (serviceData.users.length === serviceData.serviceUsers.length) {
        setIsFormDataLoaded(true);
      }
    }
  }, [serviceData.users, serviceData.serviceUsers, dispatch]);

  useEffect(() => {
    if (formStep === 1) {
      if (serviceActionSuccess) {
        showSuccessNotification(
          t(`new.service.${status === 1 ? 'saved' : 'published'}`)
        );
      }
      if (serviceActionError) {
        showErrorNotification(t('new.service.service.error'));
      }
    }
  }, [formStep, serviceActionError, serviceActionSuccess, status, t]);

  useEffect(() => {
    dispatch(getCounterPartyConnectors());
  }, [dispatch]);

  // aStatusId=1 save, aStatusId=2 publish
  const handleSubmit = aStatusId => {
    if (formStep === 0) {
      if (aStatusId === 1) setIsSaveButtonPressed(true);
      // saving or publishing on 1st step
      setStatusId(aStatusId);
      saveService();
    } else if (aStatusId === 2) {
      // this is *publishing* on 2nd step

      const isFormDataValid = !formData.some(
        field =>
          (!field.hasOwnProperty('value') && field.isRequired) ||
          (field.isRequired &&
            Array.isArray(field.value) &&
            !field.value.length)
      );

      const dataToPublish = {
        id: savedServiceId || serviceId,
        statusId: aStatusId,
        logo,
      };

      if (formData.length === 0) {
        Object.keys(savedFormData).map(fieldName => {
          if (!autoSavingFields.includes(fieldName)) {
            if (fieldName === 'users') {
              dataToPublish[fieldName] = savedFormData[fieldName].map(
                ({ value }) => value
              );
            } else if (fieldName === 'serviceDomains') {
              dataToPublish.domains = savedFormData[fieldName].map(
                ({ domain }) => domain.id
              );
            } else dataToPublish[fieldName] = savedFormData[fieldName];
          }
        });
      } else {
        for (const field of formData) {
          const name = field.name[0];
          dataToPublish[name] = field.value;

          if (name === 'users') {
            dataToPublish[name] = field.value.map(({ value }) => value);
          }
        }
      }

      const submitService = () => {
        setStatusId(2);
        dispatch(publishService(dataToPublish));
      };
      const cancelSubmit = () => setStatusId(1);

      if (!isFormDataValid) {
        return showErrorNotification(t('new.service.form.not.valid'));
      }
      confirmationModal('.submit', t, submitService, cancelSubmit);
    }
  };

  const saveService = () => {
    // this will call NewServiceForm.onFormFinish
    form.submit();
  };

  // changedValues is used from NewServiceForm.Form.onFieldsChange
  const handleFormChange = (changedValues, allFields) => {
    if (isSaveButtonPressed) {
      setIsSaveButtonPressed(false);
      return;
    }
    const newFormData = allFields;
    newFormData.forEach((field, idx) => {
      const name = field.name[0];

      if (name === 'validFrom' || name === 'validTo') {
        if (!field.value) return;

        newFormData[idx].value = field.value.format(YYYYMMDD);
      }

      if (name === 'domains') return;
    });

    dispatch(setHasFormChanged(true));
    dispatch(setFormData(newFormData));
  };

  const moveTo2ndStepAndRevert = () => {
    dispatch(setFormStep(1));
    dispatch(revertFormData()); // this sets hasFormChanged = false
  };

  const handleStepChange = newStep => {
    if (hasFormChanged) {
      confirmationModal('.next.step', t, moveTo2ndStepAndRevert, () => {});
      return;
    }
    if (!formData.length) {
      const fieldsMeta = [];
      const fields = form.getFieldsValue(true, meta => fieldsMeta.push(meta));
      Object.keys(fields).forEach(
        (field, idx) => (fieldsMeta[idx].value = fields[field])
      );

      handleFormChange(null, fieldsMeta);
    }

    // if we added new ServiceDomain for a *new* service,
    // it will not be saved after changing to 2nd tab, see SK-385
    if (newStep === 1 && formType !== 'edit') {
      saveService();
    }

    dispatch(setFormStep(newStep));
    dispatch(setHasFormChanged(false));
  };

  return (
    <div className="new-service-block has-shadow">
      <Spin
        size="large"
        tip={t('global.loading')}
        spinning={
          resourcesLoading ||
          !isFormDataLoaded ||
          serviceActionLoading ||
          domainsDataLoading ||
          serviceDomainLoading
        }
      >
        <div className="container space-vertical-lg">
          <Row gutter={[16, 16]}>
            <Space className="space-between">
              <Col xs={24} lg={24}>
                <Space size={20}>
                  <ImageUpload />
                  <Title>{t(`new.service.title.${formType}`)}</Title>
                  <ColorChangingTag />
                </Space>
              </Col>
              <Col xs={24} lg={24}>
                <FormMenu
                  handleSubmit={handleSubmit}
                  handleStepChange={handleStepChange}
                />
              </Col>
            </Space>
          </Row>
          {isFormDataLoaded && (
            <>
              <Row className="space-vertical-lg">
                <Col>
                  <Steps
                    current={formStep}
                    onChange={newStep => handleStepChange(newStep)}
                  >
                    <Step
                      title={t('new.service.step1')}
                      disabled={isTitleInputActive}
                    />
                    <Step
                      disabled={
                        !serviceHasSelectedDataBlocks ||
                        dataBlockActionLoading ||
                        isTitleInputActive
                      }
                      title={t('new.service.step2')}
                    />
                  </Steps>
                </Col>
              </Row>
              {formStep === 0 && (
                <>
                  <NewServiceForm
                    form={form}
                    statusId={statusId}
                    onChange={debounce(handleFormChange, 200)}
                    setStatusId={setStatusId}
                  />
                  {domainsData.length > 0 && <Domains />}
                </>
              )}

              {formStep === 1 && <ServiceStructure />}
            </>
          )}
        </div>
      </Spin>
    </div>
  );
};

const ColorChangingTag = () => {
  const { status } = useSelector(state => state.createService);
  const { t } = useTranslation();

  const statusState =
    status === 1 ? 'draft' : status === 2 ? 'published' : 'inactive';

  return (
    <Tag color={status === 1 ? 'orange' : status === 2 ? 'green' : 'red'}>
      {t(`new.service.status.${statusState}`)}
    </Tag>
  );
};

export default NewService;
