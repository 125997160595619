import { combineReducers } from 'redux';

import user from './user';
import translation from './entities/translation';
import languageCode from './entities/languageCode';
import createService from './entities/createService';
import serviceManagement from './entities/serviceManagement';
import resources from './entities/resources';
import onboarding from './entities/onboarding';
import company from './entities/company';
import legalEntity from './entities/legalentity';
import customer from './entities/customer';
import sharing from './entities/sharing';
import questionnaire from './entities/questionnaire';
import billing from './entities/billing';

export default combineReducers({
  user,
  translation,
  createService,
  languageCode,
  serviceManagement,
  resources,
  onboarding,
  company,
  legalEntity,
  customer,
  sharing,
  questionnaire,
  billing,
});
