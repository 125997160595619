import React, { useState, useEffect } from 'react';
import { Collapse, Button, Input, Space, Row, Col, Tooltip, Spin } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import Masonry from 'react-masonry-css';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { saveDomain } from '../../store/entities/createService/serviceQueries';
import useIsFirstRender from '../../hooks/useIsFirstRender';
import { showErrorNotification } from '../../utils/showNotification';

import DataBlockCard from './DataBlockCard';

const { Panel } = Collapse;

const breakpointCols = {
  default: 5,
  1920: 4,
  1200: 3,
  992: 2,
  768: 1,
};

const Domains = () => {
  const {
    domainsData,
    serviceActionLoading,
    dataBlockActionLoading,
  } = useSelector(state => state.createService);

  return (
    <Spin
      size="large"
      spinning={serviceActionLoading || dataBlockActionLoading}
    >
      <div className="container">
        <Collapse accordion expandIconPosition="left" defaultActiveKey="0">
          {domainsData.map((domain, idx) => (
            <Panel
              header={
                <PanelHeader
                  title={domain.title}
                  domainId={domain.id}
                  domainIndex={idx}
                />
              }
              key={idx}
            >
              <Masonry
                className="masonry"
                columnClassName="masonry_column"
                breakpointCols={breakpointCols}
              >
                {domain.dataBlocks.map((dataBlock, index) => (
                  <DataBlockCard
                    key={dataBlock.id}
                    dataBlock={dataBlock}
                    domainId={domain.id}
                    domainIndex={idx}
                    dataBlockIndex={index}
                  />
                ))}
              </Masonry>
            </Panel>
          ))}
        </Collapse>
      </div>
    </Spin>
  );
};

const PanelHeader = ({ title, domainId, domainIndex }) => {
  const dispatch = useDispatch();
  const { domainsData, serviceId, movingToPreviousStep } = useSelector(
    state => state.createService
  );
  const { t } = useTranslation();
  const [isFirstRender, setIsFirstRender] = useIsFirstRender();

  const titleValueFromBaseOrState =
    domainsData[domainIndex].changedTitle || title;
  const [isEditing, setIsEditing] = useState(false);
  const [titleValue, setTitleValue] = useState(titleValueFromBaseOrState);

  useEffect(() => {
    const saveDomainOnRender = () => {
      dispatch(
        saveDomain({
          serviceId,
          domainId,
          title: titleValue,
          serviceDomainId: domainsData[domainIndex].serviceDomainId || null,
        })
      );
    };
    const isDomainSaved = domainsData[domainIndex].serviceDomainId;

    if (serviceId && !isFirstRender && !movingToPreviousStep) {
      setIsFirstRender(true);
      if (!isDomainSaved) {
        saveDomainOnRender();
      }
    }
  }, [
    dispatch,
    domainId,
    domainIndex,
    domainsData,
    isFirstRender,
    serviceId,
    setIsFirstRender,
    titleValue,
    movingToPreviousStep,
  ]);

  const handleTitleSave = e => {
    e.stopPropagation();
    if (titleValue === '') {
      return showErrorNotification(t('new.service.domain.title.error'));
    }
    dispatch(
      saveDomain({
        serviceId,
        domainId,
        title: titleValue,
        serviceDomainId: domainsData[domainIndex].serviceDomainId || null,
        type: 'changedTitle',
      })
    );
    setIsEditing(!isEditing);
  };

  const handleTitleCancel = e => {
    e.stopPropagation();
    setIsEditing(!isEditing);
    setTitleValue(titleValueFromBaseOrState);
  };

  if (!isEditing) {
    return (
      <Row justify="space-between">
        <Col>{t(titleValueFromBaseOrState)}</Col>
        <Col>
          <Tooltip placement="top" title={t('new.service.domain.title.change')}>
            <EditOutlined
              onClick={e => {
                e.stopPropagation();
                setIsEditing(!isEditing);
              }}
            />
          </Tooltip>
        </Col>
      </Row>
    );
  }

  return (
    <div className="space-between">
      <div>
        <Input
          className="title__input"
          onClick={e => e.stopPropagation()}
          onChange={e => setTitleValue(e.target.value)}
          value={t(titleValue)}
        />
      </div>
      <div>
        <Space>
          <Button onClick={e => handleTitleCancel(e)}>
            {t('global.btn.discard')}
          </Button>
          <Button type="primary" onClick={e => handleTitleSave(e)}>
            {t('global.btn.save')}
          </Button>
        </Space>
      </div>
    </div>
  );
};

export default Domains;
