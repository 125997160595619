/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Button, PageHeader, Row, Col, Input, Menu, Dropdown } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  EditOutlined,
  PlusOutlined,
  ArrowDownOutlined,
  ArrowUpOutlined,
  CloseOutlined,
  SaveOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import {
  addChapterToList,
  removeChapterFromList,
  addSubChapterToList,
  setIsTitleInputActive,
} from '../../../store/entities/createService/index';
import {
  updateHeading,
  deleteServiceDataBlock,
  operateDataBlock,
} from '../../../store/entities/createService/thunks';
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../utils/showNotification';

import MoveableDataBlock from './MoveableDataBlock';
import SubChapter from './SubChapter';
import ManualFieldForm from './ManualFieldForm';

const Chapter = ({ dataBlock, chapterIndex }) => {
  const dispatch = useDispatch();
  const {
    serviceId,
    savedDataBlocks,
    updateHeadingLoading,
    isTitleInputActive,
  } = useSelector(state => state.createService);
  const { t } = useTranslation();
  const currentDataBlockInState = savedDataBlocks[chapterIndex];
  const [isEditing, setIsEditing] = useState(!dataBlock.heading);
  const [titleValue, setTitleValue] = useState(
    dataBlock.heading || currentDataBlockInState.heading
  );
  const [isManualFieldModalVisible, setIsManualFieldModalVisible] = useState(
    false
  );

  const handleEditInputChange = isEditing => {
    setIsEditing(isEditing);
    dispatch(setIsTitleInputActive(isEditing));
  };

  const saveUpdateDataBlock = async operation => {
    const resultAction = await dispatch(
      operateDataBlock({
        type: 'chapter',
        chapterIndex,
        operation,
        dataBlockInfo: {
          id: currentDataBlockInState.id || null,
          heading: titleValue.trim(),
          sequenceNumber:
            operation === 'up'
              ? chapterIndex - 1
              : operation === 'down'
              ? chapterIndex + 1
              : chapterIndex,
          serviceId,
        },
      })
    );
    return resultAction;
  };

  const handleTitleSave = async e => {
    e.stopPropagation();

    if (currentDataBlockInState.id) {
      if (currentDataBlockInState.heading === titleValue) {
        handleEditInputChange(false);
        return;
      }

      const resultAction = await dispatch(
        updateHeading({
          type: 'chapter',
          chapterIndex,
          requestParams: {
            id: currentDataBlockInState.id,
            heading: titleValue.trim(),
          },
        })
      );

      if (updateHeading.fulfilled.match(resultAction)) {
        handleEditInputChange(false);
      } else {
        showErrorNotification(t('new.service.dataBlock.heading.error'));
      }
      return;
    }

    const result = await saveUpdateDataBlock('changeTitle');

    if (!operateDataBlock.fulfilled.match(result)) {
      showErrorNotification(t('new.service.add.dataBlock.error'));
    }
  };

  const handleTitleCancel = e => {
    e.stopPropagation();

    if (!dataBlock.heading) dispatch(removeChapterFromList({ chapterIndex }));

    setTitleValue(currentDataBlockInState.heading || dataBlock.heading);
    handleEditInputChange(false);
  };

  const addChapter = () => {
    dispatch(addChapterToList(chapterIndex));
    dispatch(setIsTitleInputActive(true));
  };

  const addSubChapter = () => {
    dispatch(addSubChapterToList(chapterIndex));
    dispatch(setIsTitleInputActive(true));
  };

  const addManualField = () => {
    setIsManualFieldModalVisible(true);
  };

  const moveDomain = async direction => {
    const result = await saveUpdateDataBlock(direction);

    if (operateDataBlock.fulfilled.match(result)) {
      handleEditInputChange(false);
    } else {
      showErrorNotification(t('new.service.dataBlock.move.error'));
    }
  };

  const removeChapter = async () => {
    const resultAction = await dispatch(
      deleteServiceDataBlock({ id: currentDataBlockInState.id })
    );

    if (deleteServiceDataBlock.fulfilled.match(resultAction)) {
      dispatch(
        removeChapterFromList({ chapterIndex, chapterId: dataBlock.id })
      );
      showSuccessNotification(t('new.service.chapter.delete.success'));
    } else {
      showErrorNotification(t('new.service.chapter.delete.error'));
    }
  };

  const addMenu = (
    <Menu>
      <Menu.Item key="add">
        <a onClick={addChapter}>{t('new.service.add.chapter')}</a>
      </Menu.Item>
      <Menu.Item key="addSub">
        <a onClick={addSubChapter}>{t('new.service.add.sub.chapter')}</a>
      </Menu.Item>
      <Menu.Item key="addManual">
        <a onClick={addManualField}>{t('new.service.add.manual.field')}</a>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <PageHeader
        key={`domainBlock-${dataBlock.id}`}
        title={
          isEditing ? (
            <Input
              className="title__input"
              onClick={e => e.stopPropagation()}
              onChange={e => setTitleValue(e.target.value)}
              value={t(titleValue)}
              disabled={updateHeadingLoading}
            />
          ) : (
            t(titleValue)
          )
        }
        className="domain-page-header"
        extra={
          isEditing
            ? [
                <Button
                  key="discard"
                  type="link"
                  icon={<CloseOutlined />}
                  onClick={e => handleTitleCancel(e)}
                  disabled={updateHeadingLoading}
                >
                  {t('global.btn.discard')}
                </Button>,
                <Button
                  key="save"
                  type="link"
                  icon={<SaveOutlined />}
                  onClick={e => handleTitleSave(e)}
                  disabled={updateHeadingLoading || titleValue.trim() === ''}
                >
                  {t('global.btn.save')}
                </Button>,
              ]
            : [
                <Dropdown overlay={addMenu} disabled={isTitleInputActive}>
                  <Button key="add" type="link" icon={<PlusOutlined />}>
                    {t('new.service.chapter.add')}
                  </Button>
                </Dropdown>,
                <Button
                  key="down"
                  type="link"
                  icon={<ArrowDownOutlined />}
                  onClick={() => moveDomain('down')}
                  disabled={
                    isTitleInputActive ||
                    chapterIndex === savedDataBlocks.length - 1
                  }
                >
                  {t('new.service.chapter.move.down')}
                </Button>,
                <Button
                  key="up"
                  type="link"
                  icon={<ArrowUpOutlined />}
                  onClick={() => moveDomain('up')}
                  disabled={isTitleInputActive || chapterIndex === 0}
                >
                  {t('new.service.chapter.move.up')}
                </Button>,
                <Button
                  key="edit"
                  type="link"
                  icon={<EditOutlined />}
                  onClick={() => handleEditInputChange(true)}
                  disabled={isTitleInputActive}
                >
                  {t('new.service.chapter.edit.name')}
                </Button>,
                <>
                  {!dataBlock.childBlocks.length ? (
                    <Button
                      key="delete"
                      type="link"
                      icon={<DeleteOutlined />}
                      onClick={removeChapter}
                      disabled={isTitleInputActive}
                    >
                      {t('global.btn.delete')}
                    </Button>
                  ) : null}
                </>,
              ]
        }
      >
        <Row gutter={[16, 16]}>
          {dataBlock.childBlocks.map((block, idx) =>
            !block.heading && !block.isSubChapter ? (
              <Col xs={24} lg={12} xl={8} xxl={6} key={block.id}>
                <MoveableDataBlock
                  chapterIndex={chapterIndex}
                  dataBlockIndex={idx}
                  dataBlock={block}
                  chapterId={currentDataBlockInState.id}
                />
              </Col>
            ) : null
          )}
        </Row>
        {dataBlock.childBlocks.map((block, idx) =>
          block.heading || block.isSubChapter ? (
            <SubChapter
              key={block.id || block.mockId}
              dataBlock={block}
              chapterIndex={chapterIndex}
              subChapterIndex={idx}
              chapterId={currentDataBlockInState.id}
            />
          ) : null
        )}
      </PageHeader>
      <ManualFieldForm
        chapterIndex={chapterIndex}
        chapterId={currentDataBlockInState.id}
        isVisible={isManualFieldModalVisible}
        setIsVisible={setIsManualFieldModalVisible}
      />
    </>
  );
};

export default Chapter;
