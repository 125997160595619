import React from 'react';
import {
  CheckCircleFilled,
  ClockCircleFilled,
  CloseCircleFilled,
  ExclamationCircleFilled,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import colors from '../../utils/colors';
import { camelCase } from 'lodash';

const StatusBadge = ({ status, onlyIcon, onlyText }) => {
  const { t } = useTranslation();
  const formattedStatus = camelCase(status);
  const icon = getBadgeIcon(formattedStatus);
  return (
    <span>
      {!onlyText && icon}
      {status && !onlyIcon && ` ${t(`global.status.${formattedStatus}`)}`}
    </span>
  );
};

const getBadgeIcon = status => {
  const icons = {
    active: <CheckCircleFilled style={{ color: colors['success'] }} />,
    onboarding: <ClockCircleFilled style={{ color: colors['info'] }} />,
    offboarded: <CloseCircleFilled style={{ color: colors['error'] }} />,
    onboardingCanceled: <ExclamationCircleFilled />,
    kustutatud: <CloseCircleFilled style={{ color: colors['error'] }} />,
    likvideerimisel: (
      <ExclamationCircleFilled style={{ color: colors['warning'] }} />
    ),
  };

  return icons[status] || icons['active'];
};

export default StatusBadge;
