import React from 'react';
import { Modal, Typography, Row, Checkbox } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { reduce } from 'lodash';

import { showErrorNotification } from '../../../utils/showNotification';
import { CardActionsContext } from './CardActionsProvider';
import {
  LNRequestCategorys,
  LNRequestCategoryIds,
} from '../../../utils/constants';
import {
  createQdbrRequest,
  createSdbLNRequests,
} from '../../../store/entities/questionnaire/thunks';

const { Text } = Typography;

const TYPE_SINGLE = 'single';
const TYPE_MULTIPLE = 'multiple';

const LNInitiationModal = ({
  type,
  LECount,
  resultCount,
  price,
  serviceDataBlockId,
  qdbrId,
  isLegalEntity,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { questionnaireId } = useParams();
  const { isLNInitiationOpen, toggleLNInitiationModal } = React.useContext(
    CardActionsContext
  );
  const {
    questionnaire: {
      service: { isPriceVisibleToUser },
    },
  } = useSelector(state => state.questionnaire);

  const [checkedValues, setCheckedValues] = React.useState([]);
  const [handlingOk, setHandlingOk] = React.useState(false);

  const isTypeSingle = type === TYPE_SINGLE;

  const resultsCount = isTypeSingle ? 1 : resultCount;

  const handleCancel = () => {
    setCheckedValues([]);
    setHandlingOk(false);
    toggleLNInitiationModal();
  };

  const handleError = () => {
    setHandlingOk(false);
    showErrorNotification(t('lexis.nexis.initiation.error'));
  };

  const handleOk = async () => {
    setHandlingOk(true);

    const payload = {
      requestCategoryIds: checkedValues,
      questionnaireId: Number(questionnaireId),
    };

    if (type === TYPE_MULTIPLE) {
      payload.serviceDataBlockId = serviceDataBlockId;

      const result = await dispatch(createSdbLNRequests(payload));

      if (createSdbLNRequests.fulfilled.match(result)) {
        handleCancel();
      } else {
        handleError();
      }
    } else {
      payload.qdbrId = qdbrId;

      const result = await dispatch(createQdbrRequest(payload));

      if (createQdbrRequest.fulfilled.match(result)) {
        handleCancel();
      } else {
        handleError();
      }
    }
  };

  const hidePeps = () => {
    if (isTypeSingle) return isLegalEntity;

    return LECount >= resultsCount;
  };

  const calculateFinalPrice = () => {
    if (checkedValues.length === 0) return 0;

    if (isTypeSingle)
      return Math.round(checkedValues.length * price * 100) / 100;

    if (LECount === 0)
      return (
        Math.round(checkedValues.length * resultsCount * price * 100) / 100
      );

    const personCount = resultsCount - LECount;

    return reduce(
      checkedValues,
      (priceAggregate, checkedCategory) => {
        if (checkedCategory === LNRequestCategoryIds.PEPS) {
          if (personCount < 1) return priceAggregate;

          return priceAggregate + Math.round(personCount * price * 100) / 100;
        }

        return priceAggregate + Math.round(resultsCount * price * 100) / 100;
      },
      0
    );
  };

  return (
    <Modal
      title={t('lexis.nexis.initiation.modal.title')}
      visible={isLNInitiationOpen}
      cancelButtonProps={{
        disabled: handlingOk,
      }}
      okButtonProps={{
        className: 'btn-secondary',
        disabled: !checkedValues.length,
        loading: handlingOk,
      }}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={t('global.btn.initiate.request')}
      cancelText={t('global.btn.discard')}
      destroyOnClose
    >
      <Checkbox.Group onChange={setCheckedValues}>
        {LNRequestCategorys.map(({ id, cI_Value }) => {
          if (id === LNRequestCategoryIds.PEPS && hidePeps()) return null;
          else
            return (
              <Row key={id}>
                <Checkbox value={id}>
                  <Text type="secondary">
                    {isPriceVisibleToUser ? (
                      <Trans
                        i18nKey={`lexis.nexis.initiation.modal.${cI_Value}.${type}`}
                      >
                        {cI_Value} ({{ price }}€
                        {isTypeSingle ? '' : ' per entity'})
                      </Trans>
                    ) : (
                      t(`lexis.nexis.initiation.modal.${cI_Value}.no.price`)
                    )}
                  </Text>
                </Checkbox>
              </Row>
            );
        })}
      </Checkbox.Group>
      {isPriceVisibleToUser ? (
        <Row className="space-top-xsm">
          <Text type="secondary">
            {`${t(
              'lexis.nexis.initiation.modal.price'
            )} ${calculateFinalPrice().toFixed(1)}€`}
          </Text>
        </Row>
      ) : null}
    </Modal>
  );
};

export default LNInitiationModal;
