import * as gql from 'gql-query-builder';

import { apiCallBegan } from '../../api';
import { clientBackend } from '../../../api-config';
import {
  childLegalEntitiesListRequested,
  childLegalEntitiesListRequestFailed,
  childLegalEntitiesListReceived,
  companyRequested,
  companyRequestFailed,
  companyReceived,
  countriesRequested,
  countriesRequestSuccess,
  countriesRequestFailed,
  enabledCounterPartyConnectorsRequested,
  enabledCounterPartyConnectorsReceived,
  enabledCounterPartyConnectorsRequestFailed,
} from './';

export const LEGALENTITY_FIELDS = [
  'id',
  'cI_Name',
  'registerCode',
  'email',
  'registrationDate',
  'website',
  'phone',
  'address',
  'countryCodeISOAlpha3',
  'statusInRegister',
  {
    activities: ['cI_Name'],
  },
  'connectorId',
  {
    connector: ['counterPartyId'],
  },
  'updated',
  {
    legalEntityKeyDatas: [
      'cI_Key',
      'cI_Value',
      {
        connector: ['counterPartyId'],
      },
      'createdAt',
      'id',
      'legalEntityId',
      {
        member: [
          {
            kYCUser: ['firstname', 'lastname'],
          },
          {
            customer: ['name'],
          },
        ],
      },
    ],
  },
];

export const apiFetchLegalEntity = id => ({
  url: clientBackend,
  data: gql.query({
    operation: 'legalEntity',
    variables: {
      id: {
        value: id,
        type: 'Int!',
      },
    },
    fields: LEGALENTITY_FIELDS,
  }),
});

export const fetchCompany = id =>
  apiCallBegan({
    ...apiFetchLegalEntity(id),
    onStart: companyRequested.type,
    onSuccess: companyReceived.type,
    onFailure: companyRequestFailed.type,
  });

export const fetchChildLegalEntitiesList = (tableState, filters) =>
  apiCallBegan({
    url: clientBackend,
    data: gql.query({
      operation: 'listLegalEntityRelationsWithPagination',
      variables: {
        searchParams: {
          value: {
            ...tableState,
            filter: {
              filterConditions: filters,
            },
          },
          type: 'SearchParamsInputType!',
        },
      },
      fields: [
        'total',
        {
          result: [
            'addedToPortfolioAt',
            'initiatedAt',
            'id',
            { relationType: ['cI_Name'] },
            { relationStatus: ['cI_Name'] },
            {
              childLegalEntity: [
                'id',
                'cI_Name',
                'registerCode',
                'statusInRegister',
                'countryCodeISOAlpha3',
              ],
            },
          ],
        },
      ],
    }),
    onStart: childLegalEntitiesListRequested.type,
    onSuccess: childLegalEntitiesListReceived.type,
    onFailure: childLegalEntitiesListRequestFailed.type,
  });

export const fetchCountries = () =>
  apiCallBegan({
    url: clientBackend,
    data: gql.query([
      {
        operation: 'listCountries',
        fields: ['id', 'countryCodeISOAlpha3'],
      },
    ]),
    onStart: countriesRequested.type,
    onSuccess: countriesRequestSuccess.type,
    onFailure: countriesRequestFailed.type,
  });

export const fetchEnabledCounterPartyConnectors = () =>
  apiCallBegan({
    url: clientBackend,
    data: gql.query([
      {
        operation: 'getEnabledCounterPartyConnectorsForCustomer',
        fields: ['uniqueId', 'counterPartyId'],
      },
    ]),
    onStart: enabledCounterPartyConnectorsRequested.type,
    onSuccess: enabledCounterPartyConnectorsReceived.type,
    onFailure: enabledCounterPartyConnectorsRequestFailed.type,
  });
