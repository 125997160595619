import React from 'react';
import { Table, Button } from 'antd';
import { useTranslation } from 'react-i18next';

import { sortBySeqNumber } from '../../../utils';

import { questionnaireStatus } from '../../../utils/constants';

const CardContentTable = ({
  dataBlock,
  hasLexisNexis,
  lexisNexisConnectorEnabled,
  handleModal,
  questionnaireStatusId,
}) => {
  const {
    serviceDataAttributes,
    questionnaireDataAttributeResults,
    questionnaireDataBlockResult,
  } = dataBlock;
  const { t } = useTranslation();

  const QDBRHasLNResult = qdbrId => {
    const LNResults = questionnaireDataBlockResult.find(
      ({ id }) => id === qdbrId
    ).LNResults;

    return (
      LNResults?.PEPs?.length ||
      LNResults?.Sanctions?.length ||
      LNResults?.Watchlists?.length
    );
  };

  const isLegalEntity = id => {
    return Boolean(
      questionnaireDataAttributeResults.filter(
        x =>
          x.parentQuestionnaireDataBlockResultId === id && x.cI_Value === 'LE'
      ).length
    );
  };

  const columns = serviceDataAttributes
    .filter(({ isVisible }) => isVisible)
    .sort(sortBySeqNumber)
    .map(attr => ({
      title: t(attr.dataAttribute.cI_Name),
      dataIndex: attr.dataAttribute.idmName,
      key: attr.dataAttribute.cI_Name,
    }));

  hasLexisNexis &&
    columns.unshift({
      title: t('lexis.nexis.table.column.title'),
      key: 'actions',
      render: (_, { id }) => (
        <>
          {QDBRHasLNResult(id) ? (
            <Button onClick={() => handleModal('result', id)}>
              {t('global.btn.show.LN.results')}
            </Button>
          ) : lexisNexisConnectorEnabled ? (
            <Button
              onClick={() => handleModal('single', id, isLegalEntity(id))}
              className="btn-secondary"
              disabled={questionnaireStatusId === questionnaireStatus.finished}
            >
              {t('global.btn.screen')}
            </Button>
          ) : null}
        </>
      ),
    });

  const dataSource = [];

  for (const { id } of questionnaireDataBlockResult) {
    const tableRow = { id };
    const attributeResults = questionnaireDataAttributeResults.filter(
      ({ parentQuestionnaireDataBlockResultId }) =>
        parentQuestionnaireDataBlockResultId === id
    );
    attributeResults.forEach(result => {
      const idmName = serviceDataAttributes.find(
        ({ id }) => id === result.serviceDataAttributeId
      )?.dataAttribute?.idmName;

      if (idmName) tableRow[idmName] = result.cI_Value;
    });

    dataSource.push(tableRow);
  }

  return (
    <>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={dataSource}
        scroll={{ x: true }}
        pagination={false}
      />
    </>
  );
};

export default CardContentTable;
