import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Select } from 'antd';
import i18next from 'i18next';

import { fetchLanguageCodes } from '../../store/entities/languageCode';

const LanguageSelector = () => {
  const dispatch = useDispatch();
  const { listUsed: languageCodes } = useSelector(state => state.languageCode);

  const { Option } = Select;

  useEffect(() => {
    dispatch(fetchLanguageCodes(true));
  }, [dispatch]);

  const handleChange = value => {
    i18next.changeLanguage(value, err => {
      if (err) console.log('err', err);
    });
  };

  return (
    <Select
      defaultValue={i18next.language}
      bordered={false}
      onChange={handleChange}
    >
      {languageCodes.map(code => (
        <Option key={code} value={code.toLowerCase()}>
          {code}
        </Option>
      ))}
    </Select>
  );
};

export default LanguageSelector;
