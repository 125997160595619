import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { unwrapResult } from '@reduxjs/toolkit';
import moment from 'moment';
import { Spin, Table, Button, Row, Space } from 'antd';

import { getQuestionnaires, resetList } from '../../store/entities/customer';
import { getQuestionnairePDF } from '../../store/entities/questionnaire/thunks';
import {
  DDMMYYYY_HHmm,
  PAGESIZE,
  relationStatuses,
} from '../../utils/constants';
import { sortCustomerServiceTableColumnValue } from '../../utils';
import { showErrorNotification } from '../../utils/showNotification';
import { downloadFile } from '../../utils/downloadFile';

import ShareKYCModal from '../global/ShareKYCModal';
import StatusBadge from '../global/StatusBadge';

const initialTableState = {
  pagination: {
    pageIndex: 0,
    pageSize: 10,
  },
  sortOrder: {
    isSortAsc: true,
    sortColumn: 'status.cI_Name',
  },
};

const CustomerServicesTable = ({ id, relationStatusId }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { customerId } = useParams();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const {
    list: customerServicesList,
    listSize,
    questionnairesLoading,
  } = useSelector(state => state.customer);
  const { pdfLoading } = useSelector(state => state.questionnaire);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [questionnaireData, setQuestionnaireData] = useState(null);
  const [tableState, setTableState] = useState(initialTableState);
  const [pdfDownloadId, setPdfDownloadId] = useState(null);

  useEffect(() => {
    const fetchQuestionnaires = async () => {
      const result = await dispatch(
        getQuestionnaires({ tableState, id: id.toString() })
      );

      if (!getQuestionnaires.fulfilled.match(result)) {
        showErrorNotification(t('customer.table.fetch.error'));
        console.info(result.error);
      }
    };

    if (id) fetchQuestionnaires();

    return () => dispatch(resetList());
  }, [dispatch, tableState, t, id]);

  const handleQuestionnaireShare = record => {
    setIsModalVisible(true);
    setQuestionnaireData(record);
  };

  const handleTableChange = (
    { current: page },
    _,
    { columnKey = 'status', order = 'ascend' }
  ) => {
    const state = {};
    state.pagination = { pageIndex: page - 1, pageSize: PAGESIZE };
    state.sortOrder = {
      sortColumn: sortCustomerServiceTableColumnValue(columnKey),
      isSortAsc: order === 'ascend',
    };

    if (state.sortOrder.sortColumn !== tableState.sortOrder.sortColumn)
      state.pagination.pageIndex = 0;

    setTableState(state);
  };

  const handleQuestionnaireSelect = questionnaireId => {
    history.push(
      `/${language}/kyc/${
        customerId ? `partners/search/${customerId}` : 'company/mykyc'
      }/questionnaire/${questionnaireId}`
    );
  };

  const handleNewService = () => {
    history.push(
      `/${language}/kyc/${
        customerId
          ? `partners/search/${customerId}/service`
          : 'company/mykyc/service'
      }`
    );
  };

  const handlePDFDownload = async id => {
    setPdfDownloadId(id);
    const date = moment(new Date()).format();
    try {
      const actionResult = await dispatch(
        getQuestionnairePDF({ id, date, language })
      );
      const {
        data: { getFullQuestionnairePDF },
      } = unwrapResult(actionResult);

      downloadFile(getFullQuestionnairePDF);
    } catch (err) {
      console.info(err);
      showErrorNotification(t('questionnaire.pdf.download.error'));
    }
  };

  const dataForTable =
    customerServicesList.length && !questionnairesLoading
      ? customerServicesList.map(service => ({
          id: service.id,
          title: service.title,
          initiator: `${service.createdBy.kYCUser.firstname} ${service.createdBy.kYCUser.lastname}`,
          initiatedAt: service.createdAt,
          status: service.status,
        }))
      : [];

  const columns = [
    {
      title: t('customer.services.table.services'),
      dataIndex: 'title',
      key: 'title',
      sorter: true,
      render: (text, { id }) => (
        <span onClick={() => handleQuestionnaireSelect(id)}>{text}</span>
      ),
    },
    {
      title: t('customer.services.table.initiator'),
      dataIndex: 'initiator',
      key: 'initiator',
    },
    {
      title: t('customer.services.table.initiated'),
      dataIndex: 'initiatedAt',
      key: 'initiatedAt',
      sorter: true,
      render: date => (date ? moment(date).format(DDMMYYYY_HHmm) : ''),
    },
    {
      title: t('customer.services.table.status'),
      dataIndex: 'status',
      key: 'status',
      defaultSortOrder: 'ascend',
      sorter: true,
      render: status => <StatusBadge status={status.cI_Name} />,
    },
    {
      key: 'actions',
      align: 'center',
      render: (_, record) => (
        <Space size="small">
          <Button
            onClick={() => handleQuestionnaireShare(record)}
            disabled={record.status.id !== 3}
          >
            {t('global.btn.share')}
          </Button>
          <Button
            loading={pdfLoading && pdfDownloadId === record.id}
            disabled={record.status.id !== 3}
            onClick={() => handlePDFDownload(record.id)}
          >
            {t('global.btn.export')}
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Row gutter={[0, 32]} justify="end">
        {![relationStatuses.offboarded, relationStatuses.canceled].includes(
          relationStatusId
        ) ? (
          <Button type="primary" onClick={handleNewService}>
            {t('customer.button.new.service')}
          </Button>
        ) : null}
      </Row>
      <Spin
        size="large"
        spinning={questionnairesLoading}
        tip={t('global.loading')}
      >
        <Table
          rowKey="id"
          columns={columns}
          dataSource={dataForTable}
          scroll={{ x: true }}
          onChange={handleTableChange}
          pagination={{
            current: tableState.pagination.pageIndex + 1,
            pageSize: PAGESIZE,
            total: listSize,
            showSizeChanger: false,
          }}
        />
      </Spin>
      <ShareKYCModal
        isVisible={isModalVisible}
        setIsVisible={setIsModalVisible}
        questionnaireId={questionnaireData?.id}
      />
    </>
  );
};

export default CustomerServicesTable;
