import React, { useState } from 'react';
import { Card, Dropdown, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  BarsOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

import { renderTextWhenNoTranslationKey } from '../../../utils';
import { showErrorNotification } from '../../../utils/showNotification';
import { moveDataBlockLeftAndRight } from '../../../store/entities/createService';
import { operateDataBlock } from '../../../store/entities/createService/thunks';

import ManualFieldForm from './ManualFieldForm';
import ChooseChapterMenu from './ChooseChapterMenu';

const MoveableDataBlock = ({
  dataBlock,
  chapterIndex,
  dataBlockIndex,
  subChapterIndex,
  chapterId,
  subChapterId,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { savedDataBlocks, serviceId } = useSelector(
    state => state.createService
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { id, heading, title, dataBlockId } = dataBlock;

  const chapterChildBlocks = savedDataBlocks[chapterIndex].childBlocks;
  const dataBlockParent =
    subChapterIndex >= 0
      ? chapterChildBlocks[subChapterIndex].childBlocks
      : chapterChildBlocks;
  const dataBlocks = dataBlockParent.filter(({ heading }) => !heading);
  const dataBlockIndexInDataBlocks = dataBlocks.findIndex(
    ({ id }) => id === dataBlock.id
  );
  const isManualField = !dataBlockId && !heading;
  const dataBlockTitle = heading || title;

  const moveDataBlock = async direction => {
    const resultAction = await dispatch(
      operateDataBlock({
        type: 'dataBlock',
        chapterIndex,
        subChapterIndex,
        dataBlockInfo: {
          id,
          serviceId,
          heading,
          title,
          dataBlockId,
          sequenceNumber:
            direction === 'left' ? dataBlockIndex - 1 : dataBlockIndex + 1,
          parentServiceDataBlockId: subChapterId ? subChapterId : chapterId,
        },
      })
    );

    if (operateDataBlock.fulfilled.match(resultAction)) {
      dispatch(
        moveDataBlockLeftAndRight({
          chapterIndex,
          subChapterIndex,
          direction,
          dataBlockIndex,
        })
      );
    } else {
      showErrorNotification(t('new.service.dataBlock.move.error'));
    }
  };

  const handleModalOpen = () => setIsModalVisible(true);

  return (
    <>
      <Card
        title={
          <div className="space-between">
            {renderTextWhenNoTranslationKey(dataBlockTitle)}
          </div>
        }
        actions={
          isManualField
            ? [
                <Button
                  key="moveRight"
                  type="link"
                  icon={<ArrowRightOutlined />}
                  className="btn--card-action"
                  onClick={() => moveDataBlock('right')}
                  disabled={
                    dataBlockIndexInDataBlocks === dataBlocks.length - 1
                  }
                />,
                <Button
                  key="moveLeft"
                  type="link"
                  className="btn--card-action"
                  icon={<ArrowLeftOutlined />}
                  onClick={() => moveDataBlock('left')}
                  disabled={dataBlockIndexInDataBlocks === 0}
                />,
                <Dropdown
                  overlay={
                    <ChooseChapterMenu
                      dataBlock={dataBlock}
                      chapterIndex={chapterIndex}
                      chapterId={chapterId}
                      subChapterIndex={subChapterIndex}
                      subChapterId={subChapterId}
                      dataBlockIndex={dataBlockIndex}
                    />
                  }
                >
                  <BarsOutlined />
                </Dropdown>,
                <Button
                  key="edit"
                  type="link"
                  className="btn--card-action"
                  icon={<EditOutlined />}
                  onClick={handleModalOpen}
                />,
              ]
            : [
                <Button
                  key="moveRight"
                  type="link"
                  icon={<ArrowRightOutlined />}
                  className="btn--card-action"
                  onClick={() => moveDataBlock('right')}
                  disabled={
                    dataBlockIndexInDataBlocks === dataBlocks.length - 1
                  }
                />,
                <Button
                  key="moveLeft"
                  type="link"
                  className="btn--card-action"
                  icon={<ArrowLeftOutlined />}
                  onClick={() => moveDataBlock('left')}
                  disabled={dataBlockIndexInDataBlocks === 0}
                />,
                <Dropdown
                  overlay={
                    <ChooseChapterMenu
                      dataBlock={dataBlock}
                      chapterIndex={chapterIndex}
                      chapterId={chapterId}
                      subChapterIndex={subChapterIndex}
                      subChapterId={subChapterId}
                      dataBlockIndex={dataBlockIndex}
                    />
                  }
                >
                  <BarsOutlined />
                </Dropdown>,
              ]
        }
      />
      <ManualFieldForm
        chapterIndex={chapterIndex}
        chapterId={chapterId}
        subChapterIndex={subChapterIndex}
        subChapterId={subChapterId}
        isVisible={isModalVisible}
        setIsVisible={setIsModalVisible}
        modalType="edit"
        dataBlockId={id}
      />
    </>
  );
};

export default MoveableDataBlock;
