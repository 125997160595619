import React from 'react';
import { Row, Col, Typography, Statistic, Space } from 'antd';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { DATE_FORMAT } from '../../../utils/createServiceHelpers';

const { Title, Paragraph } = Typography;

const ServiceStructureHeading = () => {
  const { resources } = useSelector(state => state.resources);
  const {
    savedFormData: {
      title,
      description,
      users,
      validFrom,
      validTo,
      languageId,
      countries = [],
      isLegalEntity,
      serviceTypeId,
    },
  } = useSelector(state => state.createService);
  const { t } = useTranslation();

  const savedCountriesList = [];

  for (const [idx, country] of countries.entries()) {
    if (idx === 3) {
      savedCountriesList.push(`+ ${countries.length - 3}`);
      break;
    }

    savedCountriesList.push(t(`global.country.${country}`));
  }

  return (
    <>
      <Row className="statistic-block" gutter={[16, 16]}>
        {title && (
          <Col xs={24} lg={24}>
            <Space size={20}>
              <Title>{title}</Title>
            </Space>
          </Col>
        )}
        <Col xs={24} md={12} lg={16}>
          <Paragraph className="fs-16">{description}</Paragraph>
          <Statistic
            title={t('new.service.structure.users')}
            value={
              users && users.length
                ? users.map(user => user.label).join(', ')
                : t('new.service.structure.users.all')
            }
          />
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Paragraph>
            <Statistic
              title={t('new.service.structure.valid')}
              value={`${
                validFrom
                  ? moment(validFrom, [
                      DATE_FORMAT,
                      'YYYY-MM-DD[T]HH:mm:ss',
                    ]).format(DATE_FORMAT)
                  : ' '
              } - ${
                validTo
                  ? moment(validTo, [
                      DATE_FORMAT,
                      'YYYY-MM-DD[T]HH:mm:ss',
                    ]).format(DATE_FORMAT)
                  : ' '
              }`}
            />
          </Paragraph>
          <Paragraph>
            <Statistic
              title={t('new.service.structure.language.country')}
              value={`${
                languageId
                  ? resources.languages.find(lang => lang.id === languageId)
                      .cI_Name
                  : '-'
              }, ${savedCountriesList.join(', ')}`}
            />
          </Paragraph>
          <Paragraph>
            <Statistic
              title={t('new.service.structure.entityType.type')}
              value={`${
                isLegalEntity
                  ? t('new.service.entityType.leagal')
                  : t('new.service.entityType.natural')
              }, ${
                serviceTypeId === 1
                  ? t('new.service.structure.corporation')
                  : serviceTypeId === 2
                  ? t('new.service.structure.smallBusiness')
                  : '-'
              }`}
            />
          </Paragraph>
        </Col>
      </Row>
    </>
  );
};

export default ServiceStructureHeading;
