import React, { useEffect } from 'react';
import { Radio, Row, Typography, Tag, Col } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { fetchOnboardingServices } from '../../store/entities/onboarding/queries';
import { showErrorNotification } from '../../utils/showNotification';

const { Text } = Typography;

// this is actually OnboardingService
const OnboardingDataSource = ({ setService, companyName, countryCode }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { onboardingServices, onboardingServicesError } = useSelector(
    state => state.onboarding
  );

  const { resources } = useSelector(state => state.resources);

  useEffect(() => {
    let countryCodeISO3 = countryCode;
    if (countryCode.length === 2) {
      countryCodeISO3 = resources.countries.find(
        country => country.countryCodeISOAlpha2 === countryCode
      )?.countryCodeISOAlpha3;
    }
    dispatch(fetchOnboardingServices(countryCodeISO3));
  }, [countryCode, dispatch, resources.countries]);

  useEffect(() => {
    if (onboardingServicesError) {
      showErrorNotification(t('onboarding.data.source.error'));
    }
  }, [onboardingServicesError, t]);

  const handleServiceSelection = e => {
    setService(e.target.value);
  };

  return (
    <>
      {onboardingServices.length ? (
        <Radio.Group size="middle" onChange={handleServiceSelection}>
          <Row className="space-vertical-sm">
            <Text className="fs-16">
              <Trans i18nKey="onboarding.data.source.company">
                Palun vali andmeallikas <strong>{{ companyName }}</strong>{' '}
                andmete pärimiseks
              </Trans>
            </Text>
          </Row>
          {onboardingServices.map(service => (
            <Row
              gutter={[16, 24]}
              justify="space-between"
              key={service.serviceId}
            >
              <Col span={18}>
                <Radio value={service.serviceId} />
                <strong>{service.service.title}</strong>
              </Col>
              <Col span={6}>
                <span>
                  <Tag className="tag-sm tag-price">{service.totalPrice} €</Tag>
                </span>
              </Col>
            </Row>
          ))}
        </Radio.Group>
      ) : (
        <Text>{t('onboarding.data.source.no.available.services')}</Text>
      )}
    </>
  );
};

export default OnboardingDataSource;
