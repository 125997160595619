import React, { useEffect, useState } from 'react';
import Masonry from 'react-masonry-css';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';

import {
  Typography,
  Space,
  Button,
  Col,
  Row,
  Spin,
  Modal,
  Layout as AntLayout,
} from 'antd';

import { fetchCustomer } from '../store/entities/customer/queries';
import { showErrorNotification } from '../utils/showNotification';
import { saveServiceAsQuestionnaire } from '../store/entities/onboarding';
import {
  getServices,
  resetCustomer,
  resetList,
  resetServicesList,
} from '../store/entities/customer';

import ServiceCard from '../components/services/ServiceCard';

const { Title } = Typography;
const { confirm } = Modal;

const breakpointCols = {
  default: 3,
  1200: 2,
  900: 1,
};

const CustomerSelectServices = () => {
  const dispatch = useDispatch();
  const { customerId } = useParams();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const history = useHistory();
  const { customer, servicesLoading, customerLoading, services } = useSelector(
    state => state.customer
  );
  const { company } = useSelector(state => state.company);

  const [selected, setSelected] = useState(false);

  const customerName = customerId
    ? customer?.childLegalEntity?.cI_Name
    : company?.cI_Name;
  const customerCountry = customerId
    ? customer?.childLegalEntity?.countryCodeISOAlpha3
    : company?.countryCodeISOAlpha3;

  useEffect(() => {
    if (!customer && customerId) dispatch(fetchCustomer(customerId));
  }, [customer, customerId, dispatch]);

  useEffect(() => {
    const fetchServices = async () => {
      const servicesResult = await dispatch(getServices(customerCountry));

      if (!getServices.fulfilled.match(servicesResult)) {
        showErrorNotification(t('customer.services.list.error'));
        console.info(servicesResult.error);
      }
    };

    fetchServices();

    return () => dispatch(resetServicesList());
  }, [customerCountry, dispatch, t]);

  const handleContinue = () => {
    confirm({
      title: t('company.search.select.service.confirmation'),
      okText: t('global.btn.continue'),
      cancelText: t('global.btn.cancel'),
      onOk() {
        handleServiceSelect();
      },
    });
  };

  const handleServiceSelect = async () => {
    try {
      const questionnaire = await dispatch(
        saveServiceAsQuestionnaire({
          serviceId: selected,
          legalEntityId: customerId ? customer.childLegalEntity.id : company.id,
        })
      );
      const {
        data: {
          createQuestionnaireFromService: { id },
        },
      } = unwrapResult(questionnaire);

      history.push(
        `/${language}/kyc/${
          customerId ? `partners/search/${customerId}` : 'company/mykyc'
        }/questionnaire/${id}`
      );
      dispatch(resetCustomer());
      dispatch(resetList());
    } catch (err) {
      console.info(err);
      showErrorNotification(t('company.search.questionnaire.save.error'));
    }
  };

  const handleBack = () => {
    history.push(
      `/${language}/kyc/${
        customerId ? `partners/search/${customerId}` : 'company/mykyc'
      }`
    );
  };

  if (!company) {
    return (
      <div className="container">
        <Spin size="large" spinning={true}>
          <AntLayout style={{ height: '100vh' }} />
        </Spin>
      </div>
    );
  }

  return (
    <div className="container">
      <Spin size="large" spinning={customerLoading || servicesLoading}>
        <Row
          className="space-vertical-lg company-data-block"
          justify="space-between"
        >
          <Space className="space-between">
            <Col className="page-title">
              <Title>{customerName}</Title>
            </Col>
            <Col>
              <Space>
                <Button onClick={handleBack} type="default">
                  {t('global.btn.back')}
                </Button>
                <Button
                  disabled={!selected}
                  onClick={handleContinue}
                  type="primary"
                >
                  {t('global.btn.continue')}
                </Button>
              </Space>
            </Col>
          </Space>
        </Row>
        {services[0] === 'Empty' ? (
          <Title level={3}>{t('customer.services.list.empty')}</Title>
        ) : (
          <Masonry
            className="masonry masonry-service-selection"
            columnClassName="masonry_column"
            breakpointCols={breakpointCols}
          >
            {services.map(service => (
              <ServiceCard
                key={service.serviceId}
                service={service}
                selected={selected}
                setSelected={setSelected}
              />
            ))}
          </Masonry>
        )}
      </Spin>
    </div>
  );
};

export default CustomerSelectServices;
