import i18n, { t } from '../i18n';

export const differenceOf2Arrays = (arr1, arr2) => {
  const arr = [...arr1, ...arr2];
  const difference = [];

  arr.flat(Infinity).forEach((item, idx, arr) => {
    if (arr.lastIndexOf(item) === arr.indexOf(item)) {
      difference.push(item);
    }
  });

  return difference;
};

export const scrollToRef = ref =>
  window.scrollTo({
    top: ref.current.getBoundingClientRect().top,
    behavior: 'smooth',
  });

export const sortLegalEntityTableColumnValue = columnKey => {
  switch (columnKey) {
    case 'relationStatus':
    case 'relationType':
      return `${columnKey}.cI_Name`;
    case 'addedToPortfolioAt':
      return 'addedToPortfolioAt';
    case 'countryCodeISOAlpha3':
      return 'childLegalEntity.countryCodeISOAlpha3';
    default:
      return `childLegalEntity.${columnKey}`;
  }
};

export const sortCustomerServiceTableColumnValue = columnKey => {
  switch (columnKey) {
    case 'initiatedAt':
      return 'createdAt';
    case 'initiator':
      return 'createdBy.kYCUser.firstname';
    case 'status':
      return 'status.cI_Name';
    default:
      return columnKey;
  }
};

export const sortCountriesByISOAlpha3 = countries =>
  countries.sort((a, b) =>
    a.countryCodeISOAlpha3.localeCompare(b.countryCodeISOAlpha3)
  );

export const sortBySeqNumber = (a, b) => a.sequenceNumber - b.sequenceNumber;
export const sortById = (a, b) => a.id - b.id;
export const sortByIdDesc = (a, b) => {
  if (a.id === b.id) return 0;

  return a.id > b.id ? -1 : 1;
};

export const renderTextWhenNoTranslationKey = key =>
  i18n.exists(key) ? t(key) : key;
