import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { Spin, Layout, Col } from 'antd';

import {
  resetOnboardingState,
  setIsCompanyGroupView,
} from '../store/entities/onboarding';
import {
  showWarningNotification,
  showErrorNotification,
} from '../utils/showNotification';

import OnboardingHeader from '../components/onboarding/OnboardingHeader';
import OnboardingSearch from '../components/onboarding/OnboardingSearch';
import OnboardingTable from '../components/onboarding/OnboardingTable';
import ManualOnboarding from '../components/onboarding/ManualOnboarding';
import LegalEntityData from '../components/legalentity/LegalEntityData';
import LegalEntityHeader from '../components/legalentity/LegalEntityHeader';
import useHasPath from '../hooks/useHasPath';
import { CounterParty, ROUTES_UPDATE_LEGALENTITY } from '../utils/constants';
import { fetchLegalEntity } from '../store/entities/legalentity/queries';

const CompanySearch = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    onboardingListHasMatches,
    onboardingCountriesError,
    onboardingListError,
    onboardingBVDTimeout,
    showList,
    LEActivitiesListError,
    onboardingListLoading: isSearchInProgress,
  } = useSelector(state => state.onboarding);

  const slideSearchSpinnerIntoViewRef = useRef(null);

  useEffect(() => {
    if (isSearchInProgress)
      slideSearchSpinnerIntoViewRef.current.scrollIntoView();
  }, [isSearchInProgress]);

  const { counterPartyId, legalEntityId } = useParams();

  const { legalEntity } = useSelector(state => state.legalEntity);

  const isOnUpdateLegalEntityForm = useHasPath(ROUTES_UPDATE_LEGALENTITY)
    .hasPath;

  useEffect(() => {
    // if not called by clicking "update" button, legalEntity is null
    if (legalEntity === null) dispatch(fetchLegalEntity(legalEntityId));
  }, [dispatch, legalEntity, legalEntityId]);

  const isUpdatingManualData =
    isOnUpdateLegalEntityForm &&
    counterPartyId &&
    parseInt(counterPartyId) === CounterParty.MANUAL;
  const [manualOnboarding, setManualOnboarding] = useState(
    isUpdatingManualData
  );

  useEffect(() => {
    dispatch(
      setIsCompanyGroupView(location.pathname.split('/').includes('company'))
    );

    return () => {
      dispatch(resetOnboardingState());
    };
  }, [dispatch, location.pathname]);

  useEffect(() => {
    if (!onboardingListHasMatches) {
      showWarningNotification(
        t('onboarding.manual.notification.companyNotFound')
      );
    }
  }, [onboardingListHasMatches, t]);

  useEffect(() => {
    if (onboardingListError)
      showErrorNotification(
        t('onboarding.search.notification.onboardingListError')
      );
    if (onboardingCountriesError)
      showErrorNotification(
        t('onboarding.search.notification.onboardingCountriesError')
      );
    if (LEActivitiesListError)
      showErrorNotification(
        t('onboarding.search.notification.LEActivitiesListError')
      );
    if (onboardingBVDTimeout)
      showErrorNotification(
        t('onboarding.search.notification.onboardingBVDTimeout')
      );
  }, [
    onboardingListError,
    onboardingBVDTimeout,
    onboardingCountriesError,
    LEActivitiesListError,
    t,
  ]);

  return (
    <div className="container">
      <Col span={24}>
        {isOnUpdateLegalEntityForm &&
          (legalEntity ? (
            <>
              <LegalEntityHeader company={legalEntity} isWithCaption={true} />
              <LegalEntityData companyData={legalEntity} isFirstSource={true} />
            </>
          ) : (
            <>
              {/* this "else" part pertains only to header */}
              <Spin size="large" spinning={true} tip={t('global.loading')}>
                <Layout style={{ height: '100vh' }} />
              </Spin>
            </>
          ))}
        {
          // only show "counterParty/Manual" header if not in "Update" mode
          !isOnUpdateLegalEntityForm && (
            <OnboardingHeader
              manualOnboarding={manualOnboarding}
              setManualOnboarding={setManualOnboarding}
            />
          )
        }
        {manualOnboarding ? (
          <ManualOnboarding />
        ) : (
          <>
            {(!isOnUpdateLegalEntityForm || legalEntity) && (
              <div ref={slideSearchSpinnerIntoViewRef}>
                {/* this is search button and fields */}
                <OnboardingSearch
                  legalEntityBeingUpdated={legalEntity}
                  counterPartyToChoose={counterPartyId}
                />
              </div>
            )}
            {isSearchInProgress && (
              <Spin size="large" spinning={true} tip={t('global.loading')}>
                <Layout style={{ height: '100vh' }} />
              </Spin>
            )}
            {showList && <OnboardingTable />}
          </>
        )}
      </Col>
    </div>
  );
};

export default CompanySearch;
