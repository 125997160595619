import * as gql from 'gql-query-builder';

import { apiCallBegan } from '../../api';
import {
  getSavedDataBlocksSuccess,
  getSavedDataBlocksError,
  getSavedDataBlocksLoading,
  counterPartyConnectorsRequested,
  counterPartyConnectorsFailed,
  counterPartyConnectorsSuccess,
} from '.';
import { adminBackend } from '../../../api-config';

export const getCounterPartyConnectors = () =>
  apiCallBegan({
    url: adminBackend,
    data: gql.query({
      operation: 'listCounterPartyConnectors',
      fields: ['id', 'productName', 'uniqueId', 'counterPartyId'],
    }),
    onStart: counterPartyConnectorsRequested.type,
    onSuccess: counterPartyConnectorsSuccess.type,
    onFailure: counterPartyConnectorsFailed.type,
  });

export const getSavedDataBlocks = serviceId =>
  apiCallBegan({
    url: adminBackend,
    data: gql.query({
      operation: 'service2ndStep',
      variables: {
        id: {
          value: serviceId,
          type: 'Int!',
        },
      },
      fields: [
        'id',
        {
          listChapterServiceDataBlocks: [
            'id',
            'heading',
            'sequenceNumber',
            {
              dataBlock: ['id'],
            },
            {
              childBlocks: [
                'id',
                'heading',
                'title',
                'dataBlockId',
                'sequenceNumber',
                {
                  childBlocks: [
                    'id',
                    'heading',
                    'title',
                    'dataBlockId',
                    'sequenceNumber',
                  ],
                },
              ],
            },
          ],
        },
      ],
    }),
    onSuccess: getSavedDataBlocksSuccess.type,
    onFailure: getSavedDataBlocksError.type,
    onStart: getSavedDataBlocksLoading.type,
  });
