import React, { useEffect, useState } from 'react';
import { Col, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { renderTextWhenNoTranslationKey } from '../../utils';
import { questionnaireStatus } from '../../utils/constants';

import QuestionnaireCard from './QuestionnaireCard';
import ManualDataEntryModal from './QuestionnaireCard/ManualDataEntryModal';
import CardWithControls from './OwnershipStructure';

const { Option } = Select;

const ChapterTab = ({ childBlocks, chapterIdx, subChapterIdx }) => {
  const { t } = useTranslation();
  const {
    LNPrice,
    questionnaire: { statusId },
  } = useSelector(state => state.questionnaire);

  const [selectedDataBlockId, setSelectedDataBlockId] = useState();
  const [dataBlock, setDataBlock] = useState();
  const [entryModalOpen, setEntryModalOpen] = useState(false);

  const ownershipStructureBlocks = childBlocks.filter(
    ({ title, questionnaireDataBlockResult }) =>
      title.includes('OwnershipStructure') &&
      questionnaireDataBlockResult.length
  );
  const dataBlocksWithoutResults = childBlocks.filter(
    ({ questionnaireDataAttributeResults, title }) =>
      !questionnaireDataAttributeResults.length &&
      !title.includes('OwnershipStructure')
  );
  const rows = [];
  const regularDataBlocks = childBlocks.filter(
    ({ title, questionnaireDataAttributeResults }) =>
      !title.includes('OwnershipStructure') &&
      questionnaireDataAttributeResults.length
  );

  useEffect(() => {
    if (selectedDataBlockId) {
      const newDataBlock = childBlocks.find(
        block => block.id === Number(selectedDataBlockId)
      );
      setDataBlock(newDataBlock);
      setEntryModalOpen(true);
    }
  }, [childBlocks, selectedDataBlockId]);

  const handleModalClose = () => {
    setEntryModalOpen(false);
    setSelectedDataBlockId(undefined);
  };

  let index = 0;
  for (const block of regularDataBlocks) {
    if (block?.dataBlock?.isMultiple) {
      rows.push([block]);
      if (index % 2 !== 0) index = 0;
    } else {
      if (index % 2 === 0) {
        rows.push([block]);
      } else {
        rows[rows.length - 1].push(block);
      }
      index += 1;
    }
  }

  return (
    <>
      {dataBlocksWithoutResults.length &&
      statusId !== questionnaireStatus.finished ? (
        <Row justify="end" className="space-vertical-sm">
          <Select
            placeholder={t('questionnaire.insert.dataBlock.manually')}
            value={selectedDataBlockId}
            onChange={setSelectedDataBlockId}
          >
            {dataBlocksWithoutResults.map(childBlock => (
              <Option key={childBlock.id}>
                {renderTextWhenNoTranslationKey(childBlock.title)}
              </Option>
            ))}
          </Select>
        </Row>
      ) : null}
      {ownershipStructureBlocks.map(block => (
        <CardWithControls
          key={block.id}
          block={block}
          chapterIdx={chapterIdx}
          subChapterIdx={subChapterIdx}
          LNPrice={LNPrice}
          serviceDataBlockId={block.id}
        />
      ))}
      {rows.map((row, idx) => (
        <Row key={idx} gutter={16}>
          {row.map(block => (
            <React.Fragment key={block.id}>
              {block?.dataBlock?.isMultiple ? (
                <Col span={24}>
                  <QuestionnaireCard
                    key={block.id}
                    block={block}
                    chapterIdx={chapterIdx}
                    subChapterIdx={subChapterIdx}
                    layout="table"
                    LNPrice={LNPrice}
                  />
                </Col>
              ) : (
                <Col span={12}>
                  <QuestionnaireCard
                    key={block.id}
                    block={block}
                    chapterIdx={chapterIdx}
                    subChapterIdx={subChapterIdx}
                  />
                </Col>
              )}
            </React.Fragment>
          ))}
        </Row>
      ))}
      {dataBlock ? (
        <ManualDataEntryModal
          dataBlock={dataBlock}
          visible={entryModalOpen}
          onCancel={handleModalClose}
          serviceDataBlockId={selectedDataBlockId}
          chapterIdx={chapterIdx}
          subChapterIdx={subChapterIdx}
          questionnaireStatusId={statusId}
        />
      ) : null}
    </>
  );
};

export default ChapterTab;
