import React, { useState } from 'react';
import {
  Row,
  Col,
  DatePicker,
  Form,
  Select,
  Space,
  Button,
  Input,
  Card,
  Tooltip,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  UndoOutlined,
  SearchOutlined,
  PlusOutlined,
  MinusOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { camelCase } from 'lodash';

import { DATE_FORMAT } from '../../utils/createServiceHelpers';
import { setTableFilters } from '../../store/entities/serviceManagement/';

const { Option } = Select;

const ServiceManagementFilters = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { resources } = useSelector(state => state.resources);
  const [showMoreFilters, setShowMoreFilters] = useState(false);

  const clearFormFields = () => {
    form.resetFields();
    dispatch(setTableFilters([]));
  };

  const handleSubmit = values => {
    if (values.validFrom) {
      values.validFrom = moment(values.validFrom).format();
    }

    if (values.validTo) {
      values.validTo = moment(values.validTo).format();
    }

    dispatch(setTableFilters(values));
  };

  return (
    <div className="container">
      <Form
        form={form}
        hideRequiredMark={true}
        layout="vertical"
        name="serviceListFilters"
        onFinish={handleSubmit}
      >
        <Card className="card-simple space-vertical-lg has-shadow">
          <Row gutter={[16, 16]}>
            <Col xs={24} md={12} lg={5}>
              <Form.Item
                label={t('service.management.filters.validFrom')}
                name="validFrom"
              >
                <DatePicker
                  format={DATE_FORMAT}
                  allowClear={true}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={5}>
              <Form.Item
                label={t('service.management.filters.validTo')}
                name="validTo"
              >
                <DatePicker
                  format={DATE_FORMAT}
                  allowClear={true}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={14} xl={6}>
              <Form.Item
                label={t('service.management.filters.status')}
                name="statusId"
              >
                <Select optionFilterProp="children">
                  {resources.statuses.map(({ id, cI_Name }) => (
                    <Option key={id} value={id}>
                      {t(
                        `service.management.filters.status.${camelCase(
                          cI_Name
                        )}`
                      )}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} xl={8}>
              <Space
                className="space-between"
                align="center"
                style={{ height: '100%' }}
              >
                <Space>
                  <Tooltip
                    title={t('service.management.filters.clear.tooltip')}
                  >
                    <Form.Item>
                      <Button
                        type="link"
                        onClick={clearFormFields}
                        icon={<UndoOutlined />}
                      />
                    </Form.Item>
                  </Tooltip>
                  <Form.Item>
                    <Button
                      type="link"
                      onClick={() => setShowMoreFilters(!showMoreFilters)}
                      icon={
                        showMoreFilters ? <MinusOutlined /> : <PlusOutlined />
                      }
                    >
                      {t(
                        `service.management.filters.button.${
                          showMoreFilters ? 'lessFilters' : 'moreFilters'
                        }`
                      )}
                    </Button>
                  </Form.Item>
                </Space>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    icon={<SearchOutlined />}
                  >
                    {t('global.btn.search')}
                  </Button>
                </Form.Item>
              </Space>
            </Col>
          </Row>
          {showMoreFilters && (
            <Row gutter={[16, 16]}>
              <Col xs={24} md={12} lg={9}>
                <Form.Item
                  label={t('service.management.filters.title')}
                  name="title"
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} lg={5}>
                <Form.Item
                  label={t('service.management.filters.type')}
                  name="serviceTypeId"
                >
                  <Select optionFilterProp="children">
                    {resources.types.map(({ id, cI_Name }) => (
                      <Option key={id} value={id}>
                        {t(
                          `service.management.filters.type.${camelCase(
                            cI_Name
                          )}`
                        )}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={12} lg={5}>
                <Form.Item
                  label={t('service.management.filters.country')}
                  name="countryCodeISOAlpha3"
                >
                  <Select optionFilterProp="children" showSearch>
                    {resources.countries.map(({ countryCodeISOAlpha3 }) => (
                      <Option
                        key={countryCodeISOAlpha3}
                        value={countryCodeISOAlpha3}
                      >
                        {t(`global.country.${countryCodeISOAlpha3}`)}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={12} lg={5}>
                <Form.Item
                  label={t('service.management.filters.language')}
                  name="languageId"
                >
                  <Select optionFilterProp="children">
                    {resources.languages.map(({ id, cI_Name }) => (
                      <Option key={id} value={id}>
                        {t(
                          `service.management.filters.language.${camelCase(
                            cI_Name
                          )}`
                        )}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          )}
        </Card>
      </Form>
    </div>
  );
};

export default ServiceManagementFilters;
