import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

import { Modal, Form, Input, Row, Col, Spin, Typography } from 'antd';

import {
  checkCustomer,
  shareQuestionnaire,
} from '../../store/entities/sharing';
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../utils/showNotification';

const { Text } = Typography;

const ShareKYCModal = ({ setIsVisible, isVisible, questionnaireId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { sharingLoading } = useSelector(state => state.sharing);

  const [isValid, setIsValid] = useState(true);

  const shareService = async data => {
    const resultAction = await dispatch(
      shareQuestionnaire({
        questionnaireId,
        ...data,
      })
    );

    if (shareQuestionnaire.fulfilled.match(resultAction)) {
      showSuccessNotification(t('questionnaire.sharing.modal.success'));
      setIsVisible(false);
    } else {
      showErrorNotification(t('questionnaire.sharing.modal.error'));
    }
  };

  const onFinish = async data => {
    try {
      const formatedData = {
        ...data,
        customerAccountNumber: data.customerAccountNumber.toString(),
        regCode: data.regCode.toString(),
      };
      const customerCheckResult = await dispatch(checkCustomer(formatedData));
      const {
        data: { isValidCustomer },
      } = unwrapResult(customerCheckResult);

      if (isValidCustomer) {
        shareService(formatedData);
        setIsValid(true);
        return;
      }
      setIsValid(false);
    } catch (err) {
      showErrorNotification(t('questionnaire.sharing.modal.error'));
    }
  };

  const handleOk = async () => {
    form
      .validateFields()
      .then(values => {
        onFinish(values);
      })
      .catch(info => {
        console.info(info);
      });
  };

  return (
    <Modal
      title={t('questionnaire.sharing.modal.title')}
      visible={isVisible}
      onCancel={() => setIsVisible(false)}
      okText={t('global.btn.share')}
      onOk={handleOk}
      cancelText={t('global.btn.cancel')}
      okButtonProps={{ className: 'btn-secondary' }}
      destroyOnClose
    >
      <Spin spinning={sharingLoading}>
        <Form
          form={form}
          name="questionerSharing"
          hideRequiredMark
          layout="vertical"
        >
          <Row gutter={[16, 0]}>
            <Col xs={24} lg={12}>
              <Form.Item
                label={t('questionnaire.sharing.modal.label.customerId')}
                name="customerAccountNumber"
                rules={[
                  {
                    required: true,
                    message: t('questionnaire.sharing.modal.field.required'),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label={t('questionnaire.sharing.modal.label.regCode')}
                name="regCode"
                rules={[
                  {
                    required: true,
                    message: t('questionnaire.sharing.modal.field.required'),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label={t('questionnaire.sharing.modal.label.name')}
            name="name"
            rules={[
              {
                required: true,
                message: t('questionnaire.sharing.modal.field.required'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          {!isValid ? (
            <Text type="danger">
              {t('questionnaire.sharing.modal.customer.error')}
            </Text>
          ) : null}
        </Form>
      </Spin>
    </Modal>
  );
};

export default ShareKYCModal;
