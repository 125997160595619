import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Form,
  Input,
  Modal,
  Typography,
  Button,
  Row,
  Spin,
  Select,
  Radio,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { unwrapResult } from '@reduxjs/toolkit';

import {
  getClassification,
  saveManuallyInsertedData,
} from '../../../store/entities/questionnaire/thunks';
import { showErrorNotification } from '../../../utils/showNotification';
import {
  DDMMYYYY,
  questionnaireStatus,
  attributeIdmName,
} from '../../../utils/constants';
import {
  renderTextWhenNoTranslationKey,
  sortById,
  sortBySeqNumber,
} from '../../../utils';

import SourceUpload from './SourceUpload';
import DataBlockResultCards from './DataBlockResultCards';

const { Title, Text } = Typography;
const { Option } = Select;

const ManualDataEntryModal = ({
  dataBlock,
  visible,
  questionnaireStatusId,
  ...props
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const {
    questionnaire,
    manualDataLoading,
    classificationLoading,
    downloadUrlLoading,
  } = useSelector(state => state.questionnaire);

  const {
    serviceDataAttributes,
    questionnaireDataBlockResult,
    title,
    questionnaireDataAttributeResults,
    isManualDataBlock,
    id: serviceDataBlockId,
  } = dataBlock;

  const [file, setFile] = useState(null);

  const isMultiple = dataBlock?.dataBlock?.isMultiple;

  const visibleAttributes = serviceDataAttributes
    .filter(({ isVisible }) => isVisible)
    .sort(sortBySeqNumber);

  const isManualRadioField =
    isManualDataBlock && visibleAttributes[0].isManualRadioOption;
  const initialValues = {};
  const results = [];
  const EMTAKFields = [];

  const NACEFields = visibleAttributes?.filter(attr => {
    if (attr?.dataAttribute?.dataAttributeKindId) {
      if (attr?.dataAttribute?.cI_Name.includes('NACE')) return attr;
      else EMTAKFields.push(attr);
    }
  });

  for (const {
    id,
    createdBy,
    manuallyModifiedAt,
    questionnaireDataBlockResultFiles,
    retrievedAt,
    isEnteredManually,
    dataBlockReferenceSource,
  } of questionnaireDataBlockResult) {
    const attrResults = [];
    const createdByName = `${createdBy.kYCUser.firstname} ${createdBy.kYCUser.lastname}`;
    const formatedDate = moment(retrievedAt || manuallyModifiedAt).format(
      DDMMYYYY
    );

    const source = questionnaireDataBlockResultFiles?.[0];

    for (const result of questionnaireDataAttributeResults) {
      if (result.parentQuestionnaireDataBlockResultId === id) {
        if (isManualDataBlock) {
          const serviceDataAttribute = serviceDataAttributes.find(
            ({ id }) => id === result.serviceDataAttributeId
          );

          if (serviceDataAttribute) {
            if (serviceDataAttribute.isManualRadioOption) {
              if (result.cI_Value === 'true') {
                attrResults.push({
                  ...result,
                  isManualRadioOption: true,
                  cI_Name: serviceDataAttribute.title,
                });
              }
            } else {
              attrResults.push({
                ...result,
                cI_Name: serviceDataAttribute.title,
              });
            }
          }
        } else {
          attrResults.push({
            ...result,
            cI_Name: serviceDataAttributes.find(
              ({ id }) => id === result.serviceDataAttributeId
            )?.dataAttribute?.cI_Name,
          });
        }
      }
    }

    const filteredAttributes = attrResults
      .filter(res => res.cI_Name)
      .sort(sortById);

    results.push({
      id,
      attrResults: filteredAttributes,
      createdBy: createdByName,
      date: formatedDate,
      source,
      isEnteredManually,
      dataBlockReferenceSource,
    });
  }

  results.sort(sortById);

  visibleAttributes.forEach(attr => {
    if (isManualRadioField) {
      return (initialValues.chosenRadioOption =
        attr?.questionnaireDataAttributeResult?.cI_Value === 'true'
          ? attr.id
          : undefined);
    } else if (attr?.dataAttribute?.idmName === 'EntityType') {
      return (initialValues[attr.id] =
        attr?.questionnaireDataAttributeResult?.cI_Value ?? 'PP');
    }

    return (initialValues[attr.id] =
      attr?.questionnaireDataAttributeResult?.cI_Value);
  });

  const onFinish = async values => {
    let sourceUrl = null;
    const availableAttrs = Object.keys(values)
      .map(key => {
        if (key === 'url' && values[key]) {
          sourceUrl = values[key];
          return false;
        }
        return { id: key, value: values[key] };
      })
      .filter(({ value }) => value);

    if (!availableAttrs.length) {
      showErrorNotification(t('questionnaire.manual.modal.empty'));
      return;
    }

    const questionnaireDataAttributeResults = isManualRadioField
      ? visibleAttributes.map(attr => ({
          cI_Value: values.chosenRadioOption === attr.id ? 'true' : 'false',
          isEnteredManually: true,
          questionnaireId: 0,
          parentQuestionnaireDataBlockResultId: 0,
          serviceDataAttributeId: attr.id,
        }))
      : availableAttrs.map(attr => ({
          cI_Value: attr.value,
          isEnteredManually: true,
          questionnaireId: 0,
          parentQuestionnaireDataBlockResultId: 0,
          serviceDataAttributeId: attr.id,
        }));

    const payload = {
      isEnteredManually: true,
      questionnaireId: questionnaire.id,
      serviceDataBlockId,
      questionnaireDataAttributeResults,
    };

    const propsForThunk = {
      payload,
      chapterIdx: props.chapterIdx,
      subChapterIdx: props.subChapterIdx,
      isManualDataBlock,
    };

    if (sourceUrl || file) {
      payload.questionnaireDataBlockResultFiles = [
        {
          link: sourceUrl,
          fileName: file?.name,
          questionnaireId: 0,
          questionnaireDataBlockResultId: 0,
          contentBase64: file?.base64,
        },
      ];
    }

    const result = await dispatch(saveManuallyInsertedData(propsForThunk));

    if (saveManuallyInsertedData.fulfilled.match(result)) {
      props.onCancel();
    } else {
      showErrorNotification(t('questionnaire.manual.modal.failed'));
    }
  };

  const handleOk = () => {
    form
      .validateFields()
      .then(values => onFinish(values))
      .catch(err => console.info(err));
  };

  const handleCancel = () => {
    props.onCancel();
    setFile(null);
    form.resetFields();
  };

  const handleNACECodeCheck = async (fieldId, isNACE) => {
    try {
      const code = form.getFieldValue(fieldId);
      const actionResult = await dispatch(
        getClassification({
          code,
          source: isNACE ? 'NACE' : 'EMTAK',
        })
      );
      const { data } = unwrapResult(actionResult);
      const { textLocal, textEnglish } = data[
        isNACE ? 'listNACERev2WithPagination' : 'listEMTAK2008WithPagination'
      ].result[0];

      if (isNACE) {
        NACEFields.forEach(field => {
          if (field.dataAttribute.dataAttributeKindId === 2) {
            form.setFieldsValue({ [field.id]: textLocal });
          }
          if (field.dataAttribute.dataAttributeKindId === 3) {
            form.setFieldsValue({ [field.id]: textEnglish });
          }
        });
      } else {
        EMTAKFields.forEach(field => {
          if (field.dataAttribute.dataAttributeKindId === 2) {
            form.setFieldsValue({ [field.id]: textLocal });
          }
          if (field.dataAttribute.dataAttributeKindId === 3) {
            form.setFieldsValue({ [field.id]: textEnglish });
          }
        });
      }
    } catch (err) {
      console.info(err);
      showErrorNotification(t('questionnaire.manual.modal.nace.error'));
    }
  };

  const isEntityTypeAttribute = attrIdmName => {
    return (
      attrIdmName === attributeIdmName.entityType ||
      attrIdmName === attributeIdmName.typeOfEntity ||
      attrIdmName === attributeIdmName.typeOfMember
    );
  };

  const questionnaireIsUnfinished =
    questionnaireStatusId !== questionnaireStatus.finished;

  return (
    <Modal
      title={renderTextWhenNoTranslationKey(title)}
      visible={visible}
      onCancel={handleCancel}
      onOk={handleOk}
      cancelText={t('global.btn.close')}
      okText={t('global.btn.create')}
      cancelButtonProps={{ className: 'btn-secondary' }}
      destroyOnClose
      {...(props.isViewMode ? { width: 1000 } : {})}
    >
      <Spin
        spinning={
          manualDataLoading || classificationLoading || downloadUrlLoading
        }
      >
        {results.length > 1 ? (
          <DataBlockResultCards results={results} isMultiple={isMultiple} />
        ) : null}
        {questionnaireDataAttributeResults.length > 1 &&
        questionnaireIsUnfinished ? (
          <Row>
            <Title level={4}>{t('questionnaire.manual.modal.add.data')}</Title>
          </Row>
        ) : null}
        {questionnaireIsUnfinished && (
          <Form
            form={form}
            layout="vertical"
            name="questionnaireManualData"
            onFinish={onFinish}
            initialValues={initialValues}
          >
            {isManualRadioField ? (
              <Form.Item name="chosenRadioOption">
                <Radio.Group value={initialValues.chosenRadioOption}>
                  {visibleAttributes.map(({ id, title }) => {
                    return (
                      <Row key={`radio-row-${id}`}>
                        <Radio key={`radio-option-${id}`} value={id}>
                          {title}
                        </Radio>
                      </Row>
                    );
                  })}
                </Radio.Group>
              </Form.Item>
            ) : (
              visibleAttributes.map(attr => {
                const cI_Name = attr?.dataAttribute?.cI_Name ?? '';
                const dataAttributeKindId =
                  attr?.dataAttribute?.dataAttributeKindId;
                const fieldId = attr.id;
                const isNACE = cI_Name?.includes('NACE');
                const title = attr?.title;
                const attrIdmName = attr?.dataAttribute?.idmName ?? '';
                return (
                  <React.Fragment key={fieldId}>
                    <Form.Item
                      label={
                        cI_Name
                          ? t(cI_Name)
                          : title
                          ? t(title)
                          : t('questionnaire.manual.modal.missing.name')
                      }
                      name={fieldId}
                    >
                      {isEntityTypeAttribute(attrIdmName) ? (
                        <Select>
                          <Option value="PP">
                            {t('questionnaire.manual.modal.option.PP')}
                          </Option>
                          <Option value="LE">
                            {t('questionnaire.manual.modal.option.LE')}
                          </Option>
                        </Select>
                      ) : (
                        <Input
                          disabled={
                            dataAttributeKindId === 2 ||
                            dataAttributeKindId === 3
                          }
                        />
                      )}
                    </Form.Item>
                    {dataAttributeKindId === 1 ? (
                      <Button
                        size="small"
                        style={{ marginBottom: '1rem' }}
                        onClick={() => handleNACECodeCheck(fieldId, isNACE)}
                      >
                        {t('questionnaire.manual.modal.check.code')}
                      </Button>
                    ) : null}
                  </React.Fragment>
                );
              })
            )}
            <Title level={4}>{t('questionnaire.manual.modal.source')}</Title>
            <Form.Item
              label={t('questionnaire.manual.modal.url')}
              name="url"
              rules={[
                {
                  type: 'url',
                  message: t('global.validation.msg.url'),
                },
              ]}
              validateTrigger="onBlur"
            >
              <Input />
            </Form.Item>
            <SourceUpload setFile={setFile} />
            {file ? (
              <Row>
                <span>
                  <Text type="secondary">{file.name}</Text>{' '}
                  <DeleteOutlined onClick={() => setFile(null)} />
                </span>
              </Row>
            ) : null}
          </Form>
        )}
      </Spin>
    </Modal>
  );
};

export default ManualDataEntryModal;
