import { createSlice } from '@reduxjs/toolkit';

import { sortById, sortByIdDesc, sortBySeqNumber } from '../../../utils';
import {
  offlineConnectors,
  idmStrategyStatus,
  idmStrategyStatusImportanceOrder,
  CounterParty,
} from '../../../utils/constants';
import {
  createQdbrRequest,
  createSdbLNRequests,
  fetchIdmRequests,
  getClassification,
  getDownloadUrl,
  getQuestionnairePDF,
  saveComment,
  saveManuallyInsertedData,
  setQuestionnaireStatus,
  startFillQuestionnaire,
} from './thunks';

const filterByPendingStatus = ({ statusId }) =>
  statusId === idmStrategyStatus.pending ||
  statusId === idmStrategyStatus.buildingRequest;

const findFirstImportanceOrderedIdmRequest = (
  connectorUniqueId,
  idmRequests
) => {
  if (!idmRequests || idmRequests.length === 0) return null;

  const connectorIdmRequests = idmRequests.filter(
    ({ connectorId }) => connectorId === connectorUniqueId
  );

  if (connectorIdmRequests.length === 0) return null;

  if (connectorIdmRequests.length === 1) return connectorIdmRequests[0];

  connectorIdmRequests.sort((idmRequestA, idmRequestB) => {
    if (idmRequestA.statusId === idmRequestB.statusId) return 0; // no need to change order

    return idmStrategyStatusImportanceOrder[idmRequestA.statusId] <
      idmStrategyStatusImportanceOrder[idmRequestB.statusId]
      ? -1 // idmRequestA will be ordered before idmRequestB
      : 1; // idmRequestB will be ordered before idmRequestA
  });

  return connectorIdmRequests[0];
};

const setHasPendingRequests = (state, idmRequests) => {
  const pendingRequests = idmRequests.filter(filterByPendingStatus);
  const offlineRequestsCount = pendingRequests.filter(({ connector }) =>
    offlineConnectors.includes(connector.productName)
  ).length;

  const offlineRequestArePending = offlineRequestsCount > 0;
  const hasPendingIdmRequests =
    pendingRequests.length > 0 && pendingRequests.length > offlineRequestsCount;

  state.offlineRequestPending = offlineRequestArePending;
  state.hasPendingRequests = hasPendingIdmRequests;
};

const questionnaireReceivedInner = (state, queryResult) => {
  const connectors = [];

  const allServiceDataAttributes = queryResult.service.serviceDataAttributes;
  const allQuestionnaireDataBlockResults =
    queryResult.questionnaireDataBlockResults;
  const allQuestionnaireDataAttributeResults =
    queryResult.questionnaireDataAttributeResults;
  const allQuestionnaireComments = queryResult.questionnaireComments || [];
  const idmRequests = queryResult.iDMBeginStrategyRequests;

  const LNPrice = queryResult.service.serviceDataBlocks.find(
    ({ dataReferenceSources }) =>
      dataReferenceSources?.[0]?.counterpartyConnector.counterPartyId ===
      CounterParty.LEXISNEXIS
  )?.dataReferenceSources?.[0]?.counterpartyConnector?.price;

  const regularDataBlocks = queryResult.service.serviceDataBlocks
    .filter(({ heading, title }) => (heading && title) || !heading)
    .map(dataBlock => {
      const questionnaireDataBlockResult = allQuestionnaireDataBlockResults
        .filter(({ serviceDataBlockId }) => serviceDataBlockId === dataBlock.id)
        .sort(sortByIdDesc);

      if (!questionnaireDataBlockResult.length) {
        const questionnaireDataBlockResult =
          allQuestionnaireDataBlockResults.find(
            ({ serviceDataBlockId }) => serviceDataBlockId === dataBlock.id
          ) || [];

        const questionnaireDataAttributeResults = questionnaireDataBlockResult
          ? allQuestionnaireDataAttributeResults.filter(
              ({ parentQuestionnaireDataBlockResultId }) =>
                parentQuestionnaireDataBlockResultId ===
                questionnaireDataBlockResult.id
            )
          : [];

        const comments = questionnaireDataBlockResult
          ? allQuestionnaireComments.filter(
              ({ questionnaireDataBlockResultId }) =>
                questionnaireDataBlockResultId ===
                questionnaireDataBlockResult.id
            )
          : [];

        const serviceDataAttributes = allServiceDataAttributes
          .filter(
            ({ parentServiceDataBlockId }) =>
              parentServiceDataBlockId === dataBlock.id
          )
          .map(serviceDataAttribute => ({
            ...serviceDataAttribute,
            questionnaireDataAttributeResult: questionnaireDataBlockResult
              ? questionnaireDataAttributeResults.find(
                  ({ serviceDataAttributeId }) =>
                    serviceDataAttributeId === serviceDataAttribute.id
                )
              : [],
          }))
          .filter(({ isVisible }) => isVisible);

        return {
          ...dataBlock,
          serviceDataAttributes,
          questionnaireDataBlockResult,
          questionnaireDataAttributeResults,
          comments,
        };
      }

      let questionnaireDataAttributeResults = [];
      let comments = [];
      let serviceDataAttributes = [];

      for (const [idx, result] of questionnaireDataBlockResult.entries()) {
        questionnaireDataAttributeResults.push(
          ...allQuestionnaireDataAttributeResults
            .filter(
              ({ parentQuestionnaireDataBlockResultId }) =>
                parentQuestionnaireDataBlockResultId === result.id
            )
            .sort(sortById)
        );

        if (idx === 0) {
          serviceDataAttributes = allServiceDataAttributes
            .filter(
              ({ parentServiceDataBlockId }) =>
                parentServiceDataBlockId === dataBlock.id
            )
            .map(serviceDataAttribute => ({
              ...serviceDataAttribute,
              questionnaireDataAttributeResult: questionnaireDataAttributeResults.find(
                ({ serviceDataAttributeId }) =>
                  serviceDataAttributeId === serviceDataAttribute.id
              ),
            }))
            .filter(({ isVisible }) => isVisible);
        }

        const rowComments = allQuestionnaireComments.filter(
          ({ questionnaireDataBlockResultId }) =>
            questionnaireDataBlockResultId === result.id
        );

        for (let i = 0; i < rowComments.length; i++) {
          comments.push(rowComments[i]);
        }

        if (dataBlock?.dataBlock?.isLexisNexisInput) {
          const qdbrResults = {};
          const reqBody = [];

          const qdbrIdmRequests = idmRequests.filter(
            req => req.questionnaireDataBlockResultId === result.id
          );

          qdbrIdmRequests.forEach(request => {
            const requestBody = JSON.parse(request.request)?.params;

            for (const key in requestBody) {
              if (Object.hasOwnProperty.call(requestBody, key)) {
                const element = requestBody[key];
                if (key !== '$type' && element !== null) {
                  if (typeof element === 'object') {
                    reqBody.push({
                      cI_Name: key,
                      cI_Value: element.$values[0],
                    });
                  } else {
                    reqBody.push({
                      cI_Name: key,
                      cI_Value: element,
                    });
                  }
                }
              }
            }

            request.requestBody = reqBody;
            delete request.request;
            return request;
          });

          const LNResults = allQuestionnaireDataBlockResults.filter(
            ({ parentQuestionnaireDataBlockResultId }) =>
              parentQuestionnaireDataBlockResultId === result.id
          );

          for (const result of LNResults) {
            const category = queryResult.service.serviceDataBlocks.find(
              ({ id }) => id === result.serviceDataBlockId
            )?.title;
            const resultQdbrs = allQuestionnaireDataBlockResults.filter(
              ({ parentQuestionnaireDataBlockResultId }) =>
                parentQuestionnaireDataBlockResultId === result.id
            );
            const attrResults = [];

            for (const { id } of resultQdbrs) {
              const attrResult = allQuestionnaireDataAttributeResults.filter(
                ({ parentQuestionnaireDataBlockResultId, cI_Value }) =>
                  parentQuestionnaireDataBlockResultId === id && cI_Value
              );

              attrResult.forEach(attrRes => {
                const attr = queryResult.service.serviceDataAttributes.find(
                  ({ id }) => id === attrRes.serviceDataAttributeId
                );

                attrRes.cI_Name = attr?.dataAttribute?.cI_Name;
                attrRes.title = attr?.title;
                attrRes.idmName = attr?.dataAttribute?.idmName;
              });

              if (attrResult.length > 1) {
                attrResult.forEach(attr => attrResults.push({ ...attr }));
              } else {
                attrResults.push(attrResult[0]);
              }
            }

            if (!qdbrResults[category]) {
              qdbrResults[category] = [{ ...attrResults }];
            } else {
              qdbrResults[category].push({ ...attrResults });
            }
          }

          result.request = qdbrIdmRequests;
          result.LNResults = { ...qdbrResults };
        }
      }

      return {
        ...dataBlock,
        serviceDataAttributes,
        questionnaireDataBlockResult,
        questionnaireDataAttributeResults,
        comments,
      };
    })
    .sort(sortBySeqNumber);

  const chapters = queryResult.service.serviceDataBlocks
    .filter(
      ({ heading, parentServiceDataBlockId, title }) =>
        heading && !parentServiceDataBlockId && !(heading && title)
    )
    .map(chapter => {
      const childBlocks = regularDataBlocks.filter(
        ({ parentServiceDataBlockId }) =>
          parentServiceDataBlockId === chapter.id
      );
      return {
        ...chapter,
        childBlocks,
      };
    })
    .sort(sortBySeqNumber);

  const subChapters = queryResult.service.serviceDataBlocks
    .filter(
      ({ heading, parentServiceDataBlockId }) =>
        heading && parentServiceDataBlockId
    )
    .map(subChapter => {
      const childBlocks = regularDataBlocks.filter(
        ({ parentServiceDataBlockId }) =>
          parentServiceDataBlockId === subChapter.id
      );
      return {
        ...subChapter,
        childBlocks,
      };
    })
    .sort(sortBySeqNumber);

  const sortedChapters = chapters.map(chapter => ({
    ...chapter,
    subChapters: [
      ...subChapters.filter(
        ({ parentServiceDataBlockId }) =>
          parentServiceDataBlockId === chapter.id
      ),
    ],
  }));

  const createConnectorsList = childBlocks => {
    for (const { title, dataReferenceSources } of childBlocks) {
      dataReferenceSources.forEach(({ counterpartyConnector }) => {
        const connectorIndex =
          connectors && connectors.length
            ? connectors.findIndex(
                ({ uniqueId }) => uniqueId === counterpartyConnector.uniqueId
              )
            : -1;

        if (connectorIndex >= 0) {
          connectors[connectorIndex].dataBlocks.push(title);
        } else {
          connectors.push({
            ...counterpartyConnector,
            dataBlocks: [title],
          });
        }
      });
    }
  };

  for (const { subChapters, childBlocks } of sortedChapters) {
    if (childBlocks.length) {
      createConnectorsList(childBlocks);
    }

    if (subChapters.length) {
      for (const { childBlocks } of subChapters) {
        createConnectorsList(childBlocks);
      }
    }
  }

  for (const connector of connectors) {
    const idmRequest = findFirstImportanceOrderedIdmRequest(
      connector.uniqueId,
      idmRequests
    );

    connector.strategyStatusId = idmRequest?.statusId;
    connector.status = idmRequest?.statusName;
  }

  setHasPendingRequests(state, idmRequests);

  state.questionnaire = queryResult;
  state.chapters = sortedChapters;
  state.connectors = connectors;
  state.LNPrice = LNPrice;
  state.questionnaireLoading = false;
  state.questionnaireError = false;
};

const slice = createSlice({
  name: 'questionnaire',
  initialState: {
    questionnaire: null,
    chapters: [],
    questionnaireLoading: true,
    questionnaireError: false,
    manualDataLoading: false,
    commentLoading: false,
    downloadUrlLoading: false,
    pdfLoading: false,
    classificationLoading: false,
    connectors: [],
    questionnaireStatusLoading: false,
    requestsHistory: [],
    historyLoading: false,
    hasPendingRequests: false,
    offlineRequestPending: false,
  },
  reducers: {
    questionnaireRequested: state => {
      state.questionnaireLoading = true;
      state.questionnaireError = false;
    },
    questionnaireRequestFailed: state => {
      state.questionnaireLoading = false;
      state.questionnaireError = true;
    },
    sharedQuestionnaireReceived: (state, { payload }) => {
      questionnaireReceivedInner(state, payload.data.getSharedQuestionnaire);
    },
    questionnaireReceived: (state, { payload }) => {
      questionnaireReceivedInner(state, payload.data.getFullQuestionnaire);
    },
  },
  extraReducers: {
    [saveManuallyInsertedData.pending]: state => {
      state.manualDataLoading = true;
    },
    [saveManuallyInsertedData.fulfilled]: (state, actions) => {
      const { chapterIdx, subChapterIdx } = actions.meta.arg;
      const payload = actions.payload.data.questionnaireDataBlockResult;
      const stateChapters = state.chapters;

      if (subChapterIdx >= 0) {
        const childBlockIndex = stateChapters[chapterIdx].subChapters[
          subChapterIdx
        ].childBlocks.findIndex(({ id }) => id === payload.serviceDataBlockId);
        const childBlock =
          state.chapters[chapterIdx].subChapters[subChapterIdx].childBlocks[
            childBlockIndex
          ];

        childBlock.questionnaireDataBlockResult.push({
          id: payload.id,
          serviceDataBlockId: payload.serviceDataBlockId,
          createdBy: payload.createdBy,
          questionnaireDataBlockResultFiles:
            payload.questionnaireDataBlockResultFiles,
          isEnteredManually: true,
        });

        payload.childAttributes.forEach(attr => {
          childBlock.questionnaireDataAttributeResults.push({
            ...attr,
            parentQuestionnaireDataBlockResultId: payload.id,
          });
        });

        if (
          !childBlock.serviceDataAttributes.questionnaireDataAttributeResult
        ) {
          childBlock.serviceDataAttributes = childBlock.serviceDataAttributes.map(
            attr => ({
              ...attr,
              questionnaireDataAttributeResult: payload.childAttributes.find(
                ({ serviceDataAttributeId }) =>
                  serviceDataAttributeId === attr.id
              ),
            })
          );
        }
      } else {
        const childBlockIndex = stateChapters[chapterIdx].childBlocks.findIndex(
          ({ id }) => id === payload.serviceDataBlockId
        );
        const childBlock =
          state.chapters[chapterIdx].childBlocks[childBlockIndex];

        childBlock.questionnaireDataBlockResult.push({
          id: payload.id,
          serviceDataBlockId: payload.serviceDataBlockId,
          createdBy: payload.createdBy,
          questionnaireDataBlockResultFiles:
            payload.questionnaireDataBlockResultFiles,
          isEnteredManually: true,
        });

        payload.childAttributes.forEach(attr => {
          childBlock.questionnaireDataAttributeResults.push({
            ...attr,
            parentQuestionnaireDataBlockResultId: payload.id,
          });
        });

        if (
          !childBlock.serviceDataAttributes.questionnaireDataAttributeResult
        ) {
          childBlock.serviceDataAttributes = childBlock.serviceDataAttributes.map(
            attr => ({
              ...attr,
              questionnaireDataAttributeResult: payload.childAttributes.find(
                ({ serviceDataAttributeId }) =>
                  serviceDataAttributeId === attr.id
              ),
            })
          );
        }
      }

      state.manualDataLoading = false;
    },
    [saveManuallyInsertedData.rejected]: state => {
      state.manualDataLoading = false;
    },
    [saveComment.pending]: state => {
      state.commentLoading = true;
    },
    [saveComment.fulfilled]: (state, actions) => {
      const {
        chapterIdx,
        subChapterIdx,
        serviceDataBlockId,
      } = actions.meta.arg;
      const payload = actions.payload.data.questionnaireComment;
      const stateChapters = state.chapters;

      if (subChapterIdx >= 0) {
        const childBlockIndex = stateChapters[chapterIdx].subChapters[
          subChapterIdx
        ].childBlocks.findIndex(({ id }) => id === serviceDataBlockId);
        const childBlock =
          state.chapters[chapterIdx].subChapters[subChapterIdx].childBlocks[
            childBlockIndex
          ];

        childBlock.comments.push(payload);
      } else {
        const childBlockIndex = stateChapters[chapterIdx].childBlocks.findIndex(
          ({ id }) => id === serviceDataBlockId
        );
        const childBlock =
          state.chapters[chapterIdx].childBlocks[childBlockIndex];

        childBlock.comments.push(payload);
      }

      state.commentLoading = false;
    },
    [saveComment.rejected]: state => {
      state.commentLoading = false;
    },
    [getDownloadUrl.pending]: state => {
      state.downloadUrlLoading = true;
    },
    [getDownloadUrl.fulfilled]: state => {
      state.downloadUrlLoading = false;
    },
    [getDownloadUrl.rejected]: state => {
      state.downloadUrlLoading = false;
    },
    [getQuestionnairePDF.pending]: state => {
      state.pdfLoading = true;
    },
    [getQuestionnairePDF.fulfilled]: state => {
      state.pdfLoading = false;
    },
    [getQuestionnairePDF.rejected]: state => {
      state.pdfLoading = false;
    },
    [getClassification.pending]: state => {
      state.classificationLoading = true;
    },
    [getClassification.fulfilled]: state => {
      state.classificationLoading = false;
    },
    [getClassification.rejected]: state => {
      state.classificationLoading = false;
    },
    [setQuestionnaireStatus.pending]: state => {
      state.questionnaireStatusLoading = true;
    },
    [setQuestionnaireStatus.fulfilled]: (state, actions) => {
      state.questionnaire.statusId =
        actions.payload.data.setQuestionnaireStatus.statusId;
      state.questionnaireStatusLoading = false;
    },
    [setQuestionnaireStatus.rejected]: state => {
      state.questionnaireStatusLoading = false;
    },
    [fetchIdmRequests.pending]: state => {
      state.historyLoading = true;
    },
    [fetchIdmRequests.fulfilled]: (state, { payload }) => {
      const idmRequests = payload.data.getIdmRequests;

      setHasPendingRequests(state, idmRequests);

      state.requestsHistory = idmRequests;
      state.historyLoading = false;
    },
    [fetchIdmRequests.rejected]: state => {
      state.historyLoading = false;
    },
    [startFillQuestionnaire.pending]: (state, actions) => {
      if (actions.meta.arg.offlineRequest) {
        state.offlineRequestPending = true;
      } else {
        state.hasPendingRequests = true;
      }
    },
    [startFillQuestionnaire.rejected]: state => {
      state.hasPendingRequests = false;
    },
    [createSdbLNRequests.pending]: state => {
      state.hasPendingRequests = true;
    },
    [createSdbLNRequests.rejected]: state => {
      state.hasPendingRequests = false;
    },
    [createQdbrRequest.pending]: state => {
      state.hasPendingRequests = true;
    },
    [createQdbrRequest.rejected]: state => {
      state.hasPendingRequests = false;
    },
  },
});

export const {
  questionnaireRequested,
  questionnaireReceived,
  sharedQuestionnaireReceived,
  questionnaireRequestFailed,
} = slice.actions;

export default slice.reducer;
