import React, { useEffect } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { unwrapResult } from '@reduxjs/toolkit';
import { Col, Row, Spin, Tabs, Layout as AntLayout, Modal } from 'antd';

import { getFullQuestionnaire } from '../store/entities/questionnaire/queries';
import { showErrorNotification } from '../utils/showNotification';
import { fetchIdmRequests } from '../store/entities/questionnaire/thunks';
import { useRefresh } from '../hooks/useRefresh';

import QuestionnaireHeader from '../components/questionnaire/QuestionnaireHeader';
import ChapterTab from '../components/questionnaire/ChapterTab';

import { idmStrategyStatus } from '../utils/constants';

const { TabPane } = Tabs;

const Questionnaire = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { questionnaireId } = useParams();
  const history = useHistory();
  const refresh = useRefresh(history.location.pathname);
  const {
    questionnaireLoading,
    questionnaireError,
    chapters,
    downloadUrlLoading,
    questionnaireStatusLoading,
    hasPendingRequests,
  } = useSelector(state => state.questionnaire);

  const [failedRequests, setFailedRequests] = React.useState(null);

  useEffect(() => {
    const { pathname } = history.location;

    const operation =
      pathname.indexOf('/kyc/shared/withMe/questionnaire/') > -1
        ? 'getSharedQuestionnaire'
        : 'getFullQuestionnaire';

    dispatch(getFullQuestionnaire(operation, questionnaireId));
  }, [dispatch, questionnaireId, history]);

  useEffect(() => {
    if (questionnaireError)
      showErrorNotification(t('questionnaire.fetching.error'));
  }, [questionnaireError, t]);

  useEffect(() => {
    let interval;
    const getRequests = async () => {
      const result = await dispatch(fetchIdmRequests(questionnaireId));

      if (fetchIdmRequests.fulfilled.match(result)) {
        const {
          data: { getIdmRequests },
        } = unwrapResult(result);

        if (getIdmRequests.length > 0) {
          let hasFailedRequests = false;
          const connectorRequestStatuses = {};

          for (const { connectorId, statusId } of getIdmRequests) {
            if (
              statusId === idmStrategyStatus.pending ||
              statusId === idmStrategyStatus.buildingRequest
            )
              return;

            if (!connectorRequestStatuses.hasOwnProperty(connectorId))
              connectorRequestStatuses[connectorId] = [];

            connectorRequestStatuses[connectorId].push(statusId);
          }

          for (const connectorStatuses of Object.values(
            connectorRequestStatuses
          )) {
            if (
              connectorStatuses.every(
                strategyStatusId =>
                  strategyStatusId === idmStrategyStatus.failed
              )
            ) {
              hasFailedRequests = true;
              break;
            }
          }

          setFailedRequests(hasFailedRequests);
        }
      } else {
        clearInterval(interval);
        Modal.error({
          content: t('questionnaire.requests.failed'),
          okText: t('global.btn.refresh'),
          cancelText: t('global.btn.cancel'),
          onOk: () => refresh(),
        });
      }
    };

    if (hasPendingRequests) {
      interval = setInterval(() => {
        getRequests();
      }, 5000);
    }

    if (!hasPendingRequests && typeof failedRequests === 'boolean') {
      Modal.confirm({
        content: t(
          `questionnaire.requests.finished${failedRequests ? '.failed' : ''}`
        ),
        okText: t('global.btn.refresh'),
        cancelText: t('global.btn.cancel'),
        onOk: () => refresh(),
      });
      clearInterval(interval);
      setFailedRequests(null);
    }

    return () => clearInterval(interval);
  }, [
    dispatch,
    failedRequests,
    hasPendingRequests,
    questionnaireId,
    refresh,
    t,
  ]);

  return (
    <div className="container">
      {questionnaireLoading && (
        <Spin size="large" spinning={true} tip={t('global.loading')}>
          <AntLayout style={{ height: '100vh' }} />
        </Spin>
      )}
      {questionnaireError && <Redirect to="/" />}
      {!questionnaireLoading && !questionnaireError && (
        <Spin
          spinning={
            downloadUrlLoading ||
            questionnaireStatusLoading ||
            hasPendingRequests
          }
          size="large"
          tip={t('questionnaire.requests.loading')}
        >
          <Col span={24}>
            <QuestionnaireHeader />
            <Row>
              <Tabs
                defaultActiveKey="1"
                tabPosition="top"
                style={{ height: 'auto', width: '100%' }}
              >
                {chapters.map(
                  (
                    { heading, subChapters, childBlocks: chapterChildBlocks },
                    chapterIdx
                  ) => (
                    <TabPane tab={t(heading)} key={chapterIdx + 1}>
                      {subChapters.length > 0 ? (
                        <Tabs defaultActiveKey="1" tabPosition="top">
                          {subChapters.map(
                            (
                              { heading, childBlocks: subChapterChildBlocks },
                              subChapterIdx
                            ) => (
                              <TabPane tab={t(heading)} key={subChapterIdx + 1}>
                                <ChapterTab
                                  childBlocks={subChapterChildBlocks}
                                  chapterIdx={chapterIdx}
                                  subChapterIdx={subChapterIdx}
                                />
                              </TabPane>
                            )
                          )}
                        </Tabs>
                      ) : (
                        <ChapterTab
                          childBlocks={chapterChildBlocks}
                          chapterIdx={chapterIdx}
                        />
                      )}
                    </TabPane>
                  )
                )}
              </Tabs>
            </Row>
          </Col>
        </Spin>
      )}
    </div>
  );
};

export default Questionnaire;
