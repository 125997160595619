import React from 'react';
import { ConfigProvider } from 'antd';
import etEE from 'antd/es/locale/et_EE';

import 'moment/locale/et';

const LocaleProvider = ({ children }) => {
  return <ConfigProvider locale={etEE}>{children}</ConfigProvider>;
};

export default LocaleProvider;
