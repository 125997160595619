import React from 'react';

import OwnershipTreeNode from '../components/questionnaire/OwnershipStructure/OwnershipTree/OwnershipTreeNode';

export const mapTreeData = (dataToMap, props) => {
  const treeData = [];

  dataToMap.forEach(node => {
    const newNode = {
      ID: node.ID,
      ParentID: node.ParentID,
      title: <OwnershipTreeNode {...node} {...props} />,
      key: `${node.ID}-${node.FamilyNameOfMember}`,
      children: [],
    };

    if (node.ParentID === '1') {
      treeData.push(newNode);
    } else {
      for (const parentNode of treeData) {
        if (parentNode.ID === node.ParentID) {
          parentNode.children.push(newNode);
          break;
        } else {
          if (parentNode.children.length) {
            mapTreeChildNodes(parentNode.children, newNode);
          }
        }
      }
    }
  });

  return treeData;
};

const mapTreeChildNodes = (children, newNode) => {
  for (const childNode of children) {
    if (childNode.ID === newNode.ParentID) {
      childNode.children.push(newNode);
      break;
    } else {
      if (childNode.children.length) {
        mapTreeChildNodes(childNode.children, newNode);
      }
    }
  }
};

export const createOwnershipData = (attributes, results) => {
  const modifiedData = [];
  for (const result of results) {
    const currentResults = {};

    for (const { serviceDataAttributeId, cI_Value } of result) {
      const idmName = attributes.find(({ id }) => id === serviceDataAttributeId)
        ?.dataAttribute?.idmName;

      currentResults[idmName] = cI_Value;
    }

    modifiedData.push({
      ...currentResults,
      parentQuestionnaireDataBlockResultId:
        result[0].parentQuestionnaireDataBlockResultId,
    });
  }

  modifiedData.shift();

  return modifiedData;
};

export const getOwnershipStructureInfo = (serviceDataAttributes, qdar) => {
  const info = {};

  for (const attribute of requiredAttributesNames) {
    const id = serviceDataAttributes.find(
      ({ dataAttribute }) => dataAttribute.idmName === attribute
    )?.id;
    const value = qdar.find(
      ({ serviceDataAttributeId }) => serviceDataAttributeId === id
    )?.cI_Value;

    info[attribute] = value;
  }

  return info;
};

const requiredAttributesNames = [
  'NameOfRefereneSource',
  'TimestampOfOwnershipStructure',
  'BusinessNameOfLegalEntity',
  'RegisterCodeOfLegalEntity',
  'CodeOfWarningNoteLegalEntity',
  'WarningNoteTextLegalEntityLocal',
];
