import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Input, Button } from 'antd';
import { useTranslation } from 'react-i18next';

import { addTranslationReset } from '../../store/entities/translation';
import { addTranslation } from '../../store/entities/translation/queries';
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../utils/showNotification';

const AddTranslationForm = ({ visible, setVisible }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const addTranslationSuccess = useSelector(
    state => state.translation.addTranslationSuccess
  );
  const addTranslationFailed = useSelector(
    state => state.translation.addTranslationFailed
  );
  const addTranslationLoading = useSelector(
    state => state.translation.addTranslationLoading
  );

  useEffect(() => {
    if (addTranslationSuccess)
      showSuccessNotification(t('translation.editor.addModal.addSuccess'));
    if (addTranslationFailed)
      showErrorNotification(t('translation.editor.addModal.addFailed'));
  }, [addTranslationSuccess, addTranslationFailed, t]);

  const [form] = Form.useForm();

  const onFinish = async data => {
    try {
      await form.validateFields();
      dispatch(addTranslation(data));
    } catch (errorInfo) {
      return;
    }
  };

  const handleClose = () => {
    if (addTranslationLoading) return;
    else {
      setVisible(false);
      dispatch(addTranslationReset());
    }
  };

  const initialValues = { tKey: '', tValue: '' };
  const hideOkBtn = { style: { display: 'none' } };

  return (
    <Modal
      form={form}
      title={t('translation.editor.addModal.title')}
      visible={visible}
      onCancel={handleClose}
      okButtonProps={hideOkBtn}
      cancelText={t('global.btn.close')}
    >
      <Form name="basic" initialValues={initialValues} onFinish={onFinish}>
        <Form.Item
          label={t('translation.editor.key')}
          name="tKey"
          rules={[
            {
              required: true,
              message: t('translation.editor.addModal.keyRequiredMsg'),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t('translation.editor.value')}
          name="tValue"
          rules={[
            {
              required: true,
              message: t('translation.editor.addModal.valueRequiredMsg'),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button
            loading={addTranslationLoading}
            type="primary"
            htmlType="submit"
          >
            {t('translation.editor.add')}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddTranslationForm;
