import * as gql from 'gql-query-builder';

import { apiCallBegan } from '../../api';
import {
  usersListRequested,
  usersListRequestFailed,
  usersListRequestSuccess,
  servicePublishingSuccess,
  serviceActionStarted,
  serviceActionError,
  saveDomainSuccess,
  saveDomainLoading,
  saveDomainError,
  serviceDataRequestSuccess,
  serviceDataRequested,
  serviceDataRequestFailed,
  serviceUsersRequested,
  serviceUsersRequestSuccess,
  serviceUsersRequestFailed,
} from '.';
import { adminBackend } from '../../../api-config';

export const fetchUsers = name =>
  apiCallBegan({
    url: adminBackend,
    data: gql.query({
      operation: 'searchusers',
      variables: { text: name },
      fields: ['id', 'name'],
    }),
    onStart: usersListRequested.type,
    onSuccess: usersListRequestSuccess.type,
    onFailure: usersListRequestFailed.type,
  });

export const fetchUser = id =>
  apiCallBegan({
    url: adminBackend,
    data: gql.query({
      operation: 'users',
      variables: { id },
      fields: ['id', 'firstname', 'lastname'],
    }),
    onStart: serviceUsersRequested.type,
    onSuccess: serviceUsersRequestSuccess.type,
    onFailure: serviceUsersRequestFailed.type,
  });

export const publishService = (service, isFirstSave) =>
  apiCallBegan({
    url: adminBackend,
    data: gql.mutation({
      operation: 'service',
      variables: {
        input: {
          value: {
            ...service,
          },
          type: 'ServiceInputType!',
        },
      },
      fields: [
        'id',
        'statusId',
        isFirstSave ? { serviceDomains: ['domainId', 'id'] } : '',
      ],
    }),
    onStart: serviceActionStarted.type,
    onSuccess: servicePublishingSuccess.type,
    onFailure: serviceActionError.type,
  });

export const saveDomain = ({
  serviceId,
  domainId,
  title,
  serviceDomainId,
  type,
}) =>
  apiCallBegan({
    url: adminBackend,
    propsToPass: { type, domainId, title },
    data: gql.mutation({
      operation: 'serviceDomain',
      variables: {
        input: {
          value: {
            serviceId,
            domainId,
            title,
            id: serviceDomainId,
          },
          type: 'ServiceDomainInputType!',
        },
      },
      fields: ['id'],
    }),
    onSuccess: saveDomainSuccess.type,
    onStart: saveDomainLoading.type,
    onFailure: saveDomainError.type,
  });

export const fetchService = id =>
  apiCallBegan({
    url: adminBackend,
    data: gql.query({
      operation: 'getFullService',
      variables: {
        id: {
          value: id,
          type: 'Int!',
        },
      },
      fields: [
        'id',
        'title',
        'logo',
        'statusId',
        'languageId',
        'description',
        'validFrom',
        'validTo',
        'isLegalEntity',
        'isPriceVisibleToUser',
        'serviceTypeId',
        'users',
        'onlyLegalRepresentativeCanFinish',
        {
          serviceCountries: ['countryCodeISOAlpha3'],
        },
        {
          serviceDataBlocks: [
            'id',
            'dataBlockId',
            'parentServiceDataBlockId',
            'sequenceNumber',
            'helpText',
            'isUserSelectableReferenceSource',
            'noReferenceSource',
            {
              dataReferenceSources: [
                'id',
                'dataBlockId',
                'counterpartyConnectorUniqueId',
              ],
            },
          ],
        },
        {
          serviceDomains: [
            'id',
            'title',
            {
              domain: [
                'id',
                'title',
                'sequenceNumber',
                {
                  dataBlocks: [
                    'id',
                    'title',
                    'domainId',
                    'sequenceNumber',
                    'serviceProviderHelpText',
                    'isManualEntryForbidden',
                    {
                      childAttributes: ['id', 'cI_Name', 'sequenceNumber'],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          serviceDataAttributes: [
            'id',
            'isVisible',
            'sequenceNumber',
            'parentServiceDataBlockId',
            {
              dataAttribute: ['id', 'cI_Name'],
            },
          ],
        },
      ],
    }),
    onStart: serviceDataRequested.type,
    onFailure: serviceDataRequestFailed.type,
    onSuccess: serviceDataRequestSuccess.type,
  });
