import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Row, Col, Card, Button, Select } from 'antd';
import {
  CounterParty,
  ROUTES_UPDATE_LEGALENTITY,
  SearchConnectorNames,
} from '../../utils/constants';
import {
  DATE_FORMAT,
  DATE_FORMAT_LONG,
} from '../../utils/createServiceHelpers';
import { useHistory } from 'react-router-dom';
import useHasPath from '../../hooks/useHasPath';
import { useDispatch } from 'react-redux';
import { setLegalEntity } from '../../store/entities/legalentity';

const { Option } = Select;

const LegalEntityKeyDatasGroup = ({
  companyData,
  keyDatas,
  counterPartiesAvailableToAdd,
  isFirstSource,
  isVisibleBtnLegalEntityKeyDataView,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const { id } = companyData;

  const isOnUpdateLegalEntityForm = useHasPath(ROUTES_UPDATE_LEGALENTITY)
    .hasPath;

  const updateWithSource = sourceId => {
    dispatch(setLegalEntity(companyData));
    history.push(
      `/${language}/kyc/${ROUTES_UPDATE_LEGALENTITY}/legalEntity/${id}/counterParty/${sourceId}`
    );
  };

  const handleUpdateClick = () => updateWithSource(counterPartyIdToShow);

  const handleSelectSource = value => updateWithSource(value);

  const handleViewPurchasedDataClick = () => {
    dispatch(setLegalEntity(companyData));
    history.push(`/${language}/kyc/legalEntityKeyDataView/${id}`);
  };

  const updated = keyDatas[0].createdAt;
  const counterPartyIdToShow = keyDatas[0].connector.counterPartyId;
  const member = keyDatas[0].member;

  const getKeyData = keyIntoData =>
    keyDatas.find(x => x.cI_Key === keyIntoData)?.cI_Value;

  const CompanyDataRow = ({ type, value, keyIntoData }) => (
    <Row className="fs-16 space-vertical-md">
      <Col xs={12}>{t(`company.data.${type}`)}</Col>
      <Col xs={12}>
        <strong>
          {(keyIntoData ? getKeyData(keyIntoData) : value) ||
            t('company.data.notAvailable')}
        </strong>
      </Col>
    </Row>
  );

  return (
    <Card bordered={false} className="card-simple space-vertical-lg has-shadow">
      <Row className="statistic-block statistic-block--company" gutter={[8, 8]}>
        <Col xs={24} md={12}>
          {counterPartyIdToShow === CounterParty.MANUAL ? (
            <>
              <CompanyDataRow
                type="AccountHolderName"
                value={member && member.customer.name}
              />
              <CompanyDataRow
                type="AccountHolderRepresentative"
                value={
                  member &&
                  member.kYCUser.firstname + ' ' + member.kYCUser.lastname
                }
              />
            </>
          ) : (
            <CompanyDataRow
              type={isFirstSource ? 'mainSource' : 'dataSource'}
              value={
                counterPartyIdToShow && counterPartyIdToShow !== 0
                  ? t(SearchConnectorNames[counterPartyIdToShow])
                  : null
              }
            />
          )}
        </Col>
        <Col xs={24} md={12}>
          <Row className="fs-16 space-vertical-md">
            <Col xs={12}>{t(`company.data.${'timestamp'}`)}</Col>
            <Col xs={6}>
              <strong>
                {updated
                  ? moment(updated).format(DATE_FORMAT_LONG)
                  : t('company.data.notAvailable')}
              </strong>
            </Col>
            <Col xs={6}>
              {!isOnUpdateLegalEntityForm && (
                <Button type="primary" onClick={handleUpdateClick}>
                  {t('company.data.action.Update')}
                </Button>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="statistic-block statistic-block--company" gutter={[8, 8]}>
        <Col xs={24} md={12}>
          <CompanyDataRow type="name" keyIntoData="CI_Name" />
          <CompanyDataRow type="regCode" keyIntoData="RegisterCode" />
          {counterPartyIdToShow === CounterParty.BVD && (
            <CompanyDataRow type="typeOfCode" keyIntoData="NationalIdLabel" />
          )}
          <CompanyDataRow
            type="country"
            value={
              getKeyData('CountryCodeISOAlpha3')
                ? t(`global.country.${getKeyData('CountryCodeISOAlpha3')}`)
                : t('company.data.notAvailable')
            }
          />
          <CompanyDataRow type="address" keyIntoData="Address" />
          {counterPartyIdToShow === CounterParty.ASIAKASTIETO && (
            <>
              <CompanyDataRow type="domicile" keyIntoData="Domicile" />
              <CompanyDataRow
                type="status"
                keyIntoData="DissolvedFromRegister"
              />
            </>
          )}
        </Col>
        <Col xs={24} md={12}>
          {counterPartyIdToShow === CounterParty.CI && (
            <>
              <CompanyDataRow
                type="regDate"
                value={
                  getKeyData('RegistrationDate')
                    ? moment(getKeyData('RegistrationDate')).format(DATE_FORMAT)
                    : t('company.data.notAvailable')
                }
              />
              <CompanyDataRow
                type="statusInRegister"
                keyIntoData="StatusInRegister"
              />
              <CompanyDataRow type="website" keyIntoData="Website" />
              <CompanyDataRow type="email" keyIntoData="Email" />
              <CompanyDataRow type="phone" keyIntoData="Phone" />
            </>
          )}
          {counterPartyIdToShow === CounterParty.LVA && (
            <>
              <CompanyDataRow type="legalForm" keyIntoData="LegalForm" />
              <CompanyDataRow
                type="statusInRegister"
                keyIntoData="StatusInRegister"
              />
              <CompanyDataRow type="previousName" keyIntoData="previousName" />
              <CompanyDataRow type="registerName" keyIntoData="register" />
            </>
          )}
          {counterPartyIdToShow === CounterParty.LTU && (
            <>
              <CompanyDataRow type="legalForm" keyIntoData="LegalForm" />
              <CompanyDataRow
                type="statusInRegister"
                keyIntoData="StatusInRegister"
              />
              <CompanyDataRow type="nace" keyIntoData="NACE_ENG2" />
              {/* todo: keydata: or is it NACE2? */}
            </>
          )}
          {counterPartyIdToShow === CounterParty.BVD && (
            <>
              <CompanyDataRow type="legalForm" keyIntoData="LegalForm" />
              <CompanyDataRow type="website" keyIntoData="Website" />
              <CompanyDataRow type="email" keyIntoData="Email" />
              <CompanyDataRow
                type="consolidationCode"
                keyIntoData="ConsolidationCode"
              />
            </>
          )}
          {counterPartyIdToShow === CounterParty.PP && (
            <>
              <CompanyDataRow type="legalForm" keyIntoData="LegalForm" />
              <CompanyDataRow
                type="statusInRegister"
                keyIntoData="StatusInRegister"
              />
            </>
          )}
          {counterPartyIdToShow === CounterParty.ASIAKASTIETO && (
            <>
              <CompanyDataRow
                type="parallelBusinessName"
                keyIntoData="ParallelBusinessName"
              />
              <CompanyDataRow type="tradingName" keyIntoData="TradingName" />
              <CompanyDataRow type="previousName" keyIntoData="PreviousName" />
              <CompanyDataRow type="nameOfGroup" keyIntoData="Group" />
              <CompanyDataRow
                type="groupTradingName"
                keyIntoData="GroupTradingName"
              />
              <CompanyDataRow
                type="groupPreviousName"
                keyIntoData="GroupPreviousName"
              />
            </>
          )}
          {counterPartyIdToShow === CounterParty.UCAB && (
            <>
              <CompanyDataRow
                type="statusInRegister"
                value={
                  [getKeyData('StatusInRegister'), getKeyData('Status2')]
                    .filter(x => x)
                    .join(', ') || t('company.data.notAvailable')
                }
              />
              <CompanyDataRow type="phone" keyIntoData="Phone" />
            </>
          )}
          {counterPartyIdToShow === CounterParty.MANUAL && (
            <>
              <CompanyDataRow
                type="regDate"
                value={
                  getKeyData('RegistrationDate')
                    ? moment(getKeyData('RegistrationDate')).format(DATE_FORMAT)
                    : t('company.data.notAvailable')
                }
              />
              <CompanyDataRow type="legalForm" keyIntoData="LegalForm" />
              <CompanyDataRow type="website" keyIntoData="Website" />
              <CompanyDataRow type="email" keyIntoData="Email" />
              <CompanyDataRow type="phone" keyIntoData="Phone" />
            </>
          )}
        </Col>
      </Row>
      {!isOnUpdateLegalEntityForm &&
        isFirstSource &&
        (counterPartiesAvailableToAdd.length > 0 ? (
          <>
            <Row>
              <Col xs={12}>{t('legalentity.keydatas.identificationData')}</Col>
            </Row>
            <Row>
              <Col xs={11}>
                <Select
                  size="large"
                  onChange={handleSelectSource}
                  placeholder={t('legalentity.keydatas.selectOtherSource')}
                  style={{ width: '100%' }}
                >
                  {counterPartiesAvailableToAdd.map(val => (
                    <Option value={val} key={val}>
                      {t(SearchConnectorNames[val])}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col xs={1}></Col>
              <Col>
                {isVisibleBtnLegalEntityKeyDataView && (
                  <Button type="primary" onClick={handleViewPurchasedDataClick}>
                    {t('company.data.action.viewPurchasedData')}
                  </Button>
                )}
              </Col>
            </Row>
          </>
        ) : (
          isVisibleBtnLegalEntityKeyDataView && (
            <Row>
              <Col xs={12}>
                <Button type="primary" onClick={handleViewPurchasedDataClick}>
                  {t('company.data.action.viewPurchasedData')}
                </Button>
              </Col>
            </Row>
          )
        ))}
    </Card>
  );
};

export default LegalEntityKeyDatasGroup;
