import { createSlice } from '@reduxjs/toolkit';
import { sortCountriesByISOAlpha3 } from '../../../utils';

const slice = createSlice({
  name: 'resources',
  initialState: {
    resources: {
      domains: [],
      languages: [],
      countries: [],
      types: [],
      statuses: [],
    },
    resourcesLoading: false,
    resourcesError: false,
  },
  reducers: {
    resourcesRequested: state => {
      state.resourcesLoading = true;
      state.resourcesError = false;
    },
    resourcesRequestFailed: state => {
      state.resourcesLoading = false;
      state.resourcesError = true;
    },
    resourcesRequestSuccess: (state, { payload }) => {
      const domain = payload.data.listDomains
        .filter(({ title }) => !title.includes('Lexis Nexis'))
        .sort((a, b) => a.sequenceNumber - b.sequenceNumber);

      state.resources.domains = domain;
      state.resources.languages = payload.data.listServiceLanguages;
      state.resources.countries = sortCountriesByISOAlpha3(
        payload.data.listCountries
      );
      state.resources.types = payload.data.listServiceTypes;
      state.resources.statuses = payload.data.listServiceStatuses;
      state.resourcesLoading = false;
      state.resourcesError = false;
    },
  },
});

export const {
  resourcesRequested,
  resourcesRequestFailed,
  resourcesRequestSuccess,
} = slice.actions;

export default slice.reducer;
