import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Card, Row, Col, Tag, Space, Button, Tooltip } from 'antd';
import {
  EditOutlined,
  InfoCircleOutlined,
  MessageOutlined,
  WarningFilled,
} from '@ant-design/icons';
import { groupBy } from 'lodash';
import moment from 'moment';

import colors from '../../../utils/colors';
import {
  CounterParty,
  DDMMYYYY_HHmm,
  questionnaireStatus,
} from '../../../utils/constants';
import {
  createOwnershipData,
  getOwnershipStructureInfo,
  mapTreeData,
} from '../../../utils/OwnershipStructureHelpers';

import OwnershipTree from './OwnershipTree';
import IconWithTooltip from '../QuestionnaireCard/IconWithTooltip';
import CommentsModal from '../QuestionnaireCard/CommentsModal';
import HelpTextModal from '../QuestionnaireCard/HelpTextModal';
import CardActionsProvider, {
  CardActionsContext,
} from '../QuestionnaireCard/CardActionsProvider';
import OwnershipWarningModal from './OwnershipWarningModal';
import SourceWithPopover from '../QuestionnaireCard/SourceWithPopover';
import LNInitiationModal from '../QuestionnaireCard/LNInitiationModal';
import LNResultsModal from '../QuestionnaireCard/LNResultsModal';
import LNDetailsModal from '../QuestionnaireCard/LNDetailsModal';

const OwnershipStructure = ({ block, subChapterIdx, chapterIdx, LNPrice }) => {
  const { enabledCounterPartyConnectors } = useSelector(state => state.company);
  const {
    questionnaire: { statusId },
  } = useSelector(state => state.questionnaire);
  const {
    id: serviceDataBlockId,
    title,
    comments = [],
    serviceDataAttributes,
    dataBlock,
    helpText,
    questionnaireDataBlockResult,
    questionnaireDataAttributeResults,
  } = block;
  const { t } = useTranslation();
  const {
    toggleComments,
    toggleHelpText,
    toggleLNInitiationModal,
    toggleLNResults,
    isLNResultsOpen,
    isLNDetailsOpen,
  } = useContext(CardActionsContext);

  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [LNInitiationModalType, setLNInitiationModalType] = useState(
    'multiple'
  );
  const [qdbrId, setQdbrId] = useState(null);
  const [selectedLNResult, setSelectedLNResult] = useState({});
  const [nodeFirstName, setNodeFirstName] = useState(null);

  const hasComments = comments.length;
  const hasHelpText = helpText || dataBlock?.serviceProviderHelpText;
  const questionnaireDataBlockResultId = questionnaireDataBlockResult.id;
  const date =
    questionnaireDataBlockResult?.[0]?.retrievedAt ??
    questionnaireDataBlockResult?.[0]?.manuallyModifiedAt;
  const sourceFile =
    questionnaireDataBlockResult?.[0]?.questionnaireDataBlockResultFiles?.[0];
  const sourceName = questionnaireDataBlockResult?.[0]?.createdBy?.kYCUser;

  const getConnectorName = x =>
    x?.dataBlockReferenceSource?.counterpartyConnector?.productName;
  const connectorName =
    getConnectorName(questionnaireDataBlockResult) ??
    getConnectorName(questionnaireDataBlockResult?.[0]);

  const ownershipStructureInfo = getOwnershipStructureInfo(
    serviceDataAttributes,
    questionnaireDataAttributeResults
  );
  const hasLexisNexis = dataBlock?.isLexisNexisInput;
  const lexisNexisConnectorEnabled = enabledCounterPartyConnectors.some(
    x => x.counterPartyId === CounterParty.LEXISNEXIS
  );
  const hasLNResults = questionnaireDataBlockResult.some(
    ({ LNResults }) =>
      LNResults?.PEPs || LNResults?.Sanctions || LNResults?.Watchlists
  );
  const nameOfRefereneSource = ownershipStructureInfo['NameOfRefereneSource'];
  const timeStamp = ownershipStructureInfo['TimestampOfOwnershipStructure'];
  const businessName = ownershipStructureInfo['BusinessNameOfLegalEntity'];
  const businessRegisterCode =
    ownershipStructureInfo['RegisterCodeOfLegalEntity'];
  const warningCode = ownershipStructureInfo['CodeOfWarningNoteLegalEntity'];
  const warningText = ownershipStructureInfo['WarningNoteTextLegalEntityLocal'];

  const resultsByGroup = Object.values(
    groupBy(
      questionnaireDataAttributeResults,
      'parentQuestionnaireDataBlockResultId'
    )
  ).sort(
    (a, b) =>
      a.parentQuestionnaireDataBlockResultId -
      b.parentQuestionnaireDataBlockResultId
  );
  const resultsData = createOwnershipData(
    serviceDataAttributes,
    resultsByGroup
  );

  const legalEntityCount =
    hasLexisNexis && lexisNexisConnectorEnabled
      ? resultsData.filter(({ FirstNameOfMember }) => !FirstNameOfMember)
          .length + 1
      : 0;

  const handleWarningModal = () => {
    setIsWarningModalOpen(true);
  };

  const handleLNInitiationModal = (type, qdbrId, FirstNameOfMember) => {
    if (!lexisNexisConnectorEnabled) return;
    setQdbrId(qdbrId);
    if (type === 'result') {
      toggleLNResults();
    } else {
      setNodeFirstName(FirstNameOfMember);
      setLNInitiationModalType(type);
      toggleLNInitiationModal();
    }
  };

  const getActions = () => {
    const actions = [
      <SourceWithPopover
        sourceFile={sourceFile}
        referenceSource={connectorName}
        name={sourceName}
        questionnaireDataBlockResultId={questionnaireDataBlockResultId}
      />,
      <IconWithTooltip
        name="info"
        icon={
          <InfoCircleOutlined
            onClick={toggleHelpText}
            style={{
              color: colors[hasHelpText ? 'success' : 'gray'],
            }}
          />
        }
      />,
    ];

    if (hasComments || statusId !== questionnaireStatus.finished) {
      actions.push(
        <IconWithTooltip
          name="comments"
          icon={
            <MessageOutlined
              onClick={toggleComments}
              style={{
                color: colors[hasComments ? 'success' : 'gray'],
              }}
            />
          }
        />
      );
    }

    actions.push(
      <IconWithTooltip
        name="edit"
        icon={
          <EditOutlined
            style={{
              pointerEvents: 'none',
              color: '',
            }}
          />
        }
      />
    );

    return actions;
  };

  const treeNodeProps = {
    dataBlock: block,
    hasLexisNexis,
    lexisNexisConnectorEnabled,
    handleModal: handleLNInitiationModal,
    questionnaireStatusId: statusId,
  };

  return (
    <>
      <Card
        className="space-bottom-lg"
        bordered={false}
        title={
          <Row justify="space-between">
            <Space>
              <span>{t(title)}</span>
              {warningCode ? (
                <Button
                  className="btn-action btn-action--warning"
                  type="link"
                  icon={<WarningFilled />}
                  onClick={handleWarningModal}
                />
              ) : null}
            </Space>
            <Space>
              {hasLexisNexis ? (
                hasLNResults ? (
                  <Tag className="tag-sm">
                    {t('lexis.nexis.screening.output')}
                  </Tag>
                ) : lexisNexisConnectorEnabled ? (
                  <Button
                    className="btn-secondary"
                    onClick={() => handleLNInitiationModal('multiple')}
                    disabled={statusId === questionnaireStatus.finished}
                  >
                    {t('lexis.nexis.btn.screen.all')}
                  </Button>
                ) : null
              ) : null}
              {questionnaireDataBlockResult && (
                <Tooltip title={t('questionnaire.card.date.retrieved.at')}>
                  <Tag className="tag-sm">
                    {moment(date).format(DDMMYYYY_HHmm)}
                  </Tag>
                </Tooltip>
              )}
            </Space>
          </Row>
        }
        actions={getActions()}
      >
        <Row gutter={[16, 8]}>
          <Col span={6}>
            <span className="text-light">
              {t('ownershipStructure.refSource')}
            </span>
          </Col>
          <Col>{nameOfRefereneSource}</Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col span={6}>
            <span className="text-light">
              {t('ownershipStructure.timestamp')}
            </span>
          </Col>
          <Col>{timeStamp}</Col>
        </Row>
        <Row className="padding-lg">
          <OwnershipTree
            treeData={mapTreeData(resultsData, treeNodeProps)}
            businessName={businessName}
            businessRegisterCode={businessRegisterCode}
          />
        </Row>
      </Card>
      <CommentsModal
        comments={comments}
        chapterIdx={chapterIdx}
        subChapterIdx={subChapterIdx}
        questionnaireDataBlockResultId={questionnaireDataBlockResultId}
        serviceDataBlockId={serviceDataBlockId}
        questionnaireStatusId={statusId}
      />
      {hasHelpText ? (
        <HelpTextModal
          title={title}
          helpText={helpText}
          serviceProviderHelpText={dataBlock?.serviceProviderHelpText ?? null}
        />
      ) : null}
      <OwnershipWarningModal
        isVisible={isWarningModalOpen}
        handleClose={setIsWarningModalOpen}
        warningCode={warningCode}
        warningText={warningText}
        isStructureWarningModal
      />
      {hasLexisNexis && lexisNexisConnectorEnabled ? (
        <LNInitiationModal
          type={LNInitiationModalType}
          LECount={legalEntityCount}
          resultCount={questionnaireDataBlockResult.length}
          price={LNPrice}
          serviceDataBlockId={serviceDataBlockId}
          qdbrId={qdbrId}
          isLegalEntity={!Boolean(nodeFirstName)}
        />
      ) : null}
      {isLNResultsOpen ? (
        <LNResultsModal
          qdbrs={questionnaireDataBlockResult}
          qdbrId={qdbrId}
          setSelectedLNResult={setSelectedLNResult}
          price={LNPrice}
          questionnaireStatusId={statusId}
        />
      ) : null}
      {isLNDetailsOpen ? (
        <LNDetailsModal
          qdbrs={questionnaireDataBlockResult}
          qdbrId={qdbrId}
          selectedLNResult={selectedLNResult}
        />
      ) : null}
    </>
  );
};

const CardWithControls = ({ block, subChapterIdx, chapterIdx, LNPrice }) => (
  <CardActionsProvider>
    <OwnershipStructure
      block={block}
      subChapterIdx={subChapterIdx}
      chapterIdx={chapterIdx}
      LNPrice={LNPrice}
    />
  </CardActionsProvider>
);

export default CardWithControls;
