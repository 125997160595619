import React, { useEffect } from 'react';
import { Row, Col, Table, Space, Tag, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { camelCase } from 'lodash';

import { fetchServicesList } from '../../store/entities/serviceManagement/queries';
import {
  setTableState,
  serviceListUpdated,
} from '../../store/entities/serviceManagement/';
import { showErrorNotification } from '../../utils/showNotification';
import ServiceTableActions from './ServiceTableActions';
import { DDMMYYYY_HHmm } from '../../utils/constants';

const ServiceManagementTable = () => {
  const { t } = useTranslation();
  const {
    serviceList,
    serviceListSize,
    serviceTableState,
    serviceTableFilters,
    serviceListLoading,
    serviceListError,
    serviceListUpdate,
    cloneServiceLoading,
  } = useSelector(state => state.serviceManagement);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchServicesList(serviceTableState, serviceTableFilters));
  }, [dispatch, serviceTableState, serviceTableFilters]);

  useEffect(() => {
    if (serviceListError)
      showErrorNotification(t('global.error.requestFailed'));
  }, [serviceListError, t]);

  useEffect(() => {
    if (serviceListUpdate) {
      dispatch(fetchServicesList(serviceTableState, serviceTableFilters));
      dispatch(serviceListUpdated());
    }
  }, [serviceListUpdate]);

  const handleTableChange = (
    { current: page },
    UNUSED,
    { columnKey = 'title', order = 'ascend' }
  ) => dispatch(setTableState({ page, sortOrder: { columnKey, order } }));

  const columns = [
    {
      title: t('service.management.table.title'),
      dataIndex: 'title',
      key: 'title',
      sortDirections: ['descend', 'ascend'],
      defaultSortOrder: 'ascend',
      sorter: true,
    },
    {
      title: t('service.management.table.type'),
      key: 'serviceTypeId',
      align: 'right',
      sortDirections: ['descend', 'ascend'],
      sorter: true,
      render: ({ serviceType }) =>
        t(
          `service.management.table.type.${
            serviceType ? camelCase(serviceType.cI_Name) : 'notAvailable'
          }`
        ),
    },
    {
      title: t('service.management.table.version'),
      dataIndex: 'version',
      key: 'version',
      align: 'right',
      sortDirections: ['descend', 'ascend'],
      sorter: true,
    },
    {
      title: t('service.management.table.status'),
      key: 'statusId',
      sortDirections: ['descend', 'ascend'],
      sorter: true,
      render: ({ status }) => (
        <Space>
          <Tag
            color={status.id === 1 ? 'orange' : status.id === 2 ? 'green' : ''}
          >
            {t(
              `service.management.table.tag.${
                status ? camelCase(status.cI_Name) : 'notAvailable'
              }`
            ).toUpperCase()}
          </Tag>
        </Space>
      ),
    },
    {
      title: t('service.management.table.modifiedAt'),
      dataIndex: 'modifiedAt',
      key: 'modifiedAt',
      sortDirections: ['descend', 'ascend'],
      sorter: true,
      render: (text = '') => (text ? moment(text).format(DDMMYYYY_HHmm) : ''),
    },
    {
      key: 'actions',
      render: ({ id, status, version, versions }) => (
        <ServiceTableActions
          id={id}
          status={status}
          version={version}
          versions={versions}
        />
      ),
    },
  ];

  return (
    <div className="container">
      <Row gutter={[16, 16]}>
        <Col xs={24} lg={24}>
          <Spin
            size="large"
            spinning={serviceListLoading || cloneServiceLoading}
            tip={t('global.loading')}
          >
            <Table
              onChange={handleTableChange}
              dataSource={serviceList}
              columns={columns}
              pagination={{
                current: serviceTableState.pagination.pageIndex + 1,
                pageSize: serviceTableState.pagination.pageSize,
                total: serviceListSize,
                showSizeChanger: false,
              }}
              rowKey="id"
            />
          </Spin>
        </Col>
      </Row>
    </div>
  );
};

export default ServiceManagementTable;
