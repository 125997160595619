import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Checkbox, Tag, Row, Typography, Button } from 'antd';

import ServiceInfoModal from './ServiceInfoModal';

const { Title, Paragraph } = Typography;

const ServiceCard = ({ service, selected, setSelected }) => {
  const {
    service: { title, description, isPriceVisibleToUser },
    serviceId,
  } = service;
  const { t } = useTranslation();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const CardTitle = () => (
    <div
      className="view-card__title space-between"
      onClick={() => setSelected(selected === serviceId ? null : serviceId)}
    >
      <Checkbox className="checkbox-round" checked={selected === serviceId} />
      {isPriceVisibleToUser ? (
        <Tag className="tag-sm" color="green">
          {service.totalPrice} €
        </Tag>
      ) : null}
    </div>
  );

  return (
    <Card
      hoverable
      title={<CardTitle />}
      className={`datablock${selected === serviceId ? '--selected' : ''}`}
    >
      <Row>
        <Title level={4}>{title}</Title>
      </Row>
      <Row>
        <Paragraph>{description}</Paragraph>
      </Row>
      <Button onClick={() => setIsModalVisible(true)}>
        {t('global.btn.more.info')}
      </Button>
      {service ? (
        <ServiceInfoModal
          service={service}
          isVisible={isModalVisible}
          setIsVisible={setIsModalVisible}
        />
      ) : null}
    </Card>
  );
};

export default ServiceCard;
