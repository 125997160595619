import React, { useEffect, useState } from 'react';
import {
  Card,
  List,
  Checkbox,
  Typography,
  Tooltip,
  Input,
  Spin,
  Popover,
} from 'antd';
import {
  InfoCircleOutlined,
  SettingOutlined,
  EyeOutlined,
  MessageOutlined,
  CloseOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import useIsFirstRender from '../../../hooks/useIsFirstRender';

import {
  modifyDomainData,
  setDataBlockConnectors,
} from '../../../store/entities/createService';
import {
  deleteServiceDataBlock,
  fetchDataBlock,
  getReferenceSources,
  updateDataBlock,
} from '../../../store/entities/createService/thunks';
import colors from '../../../utils/colors';
import { isAttributeVisible } from '../../../utils/createServiceHelpers';
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../utils/showNotification';

import EditReferenceSources from './EditReferenceSources';
import EditVisibility from './EditVisibility';
import { confirmationModal } from '../ConfirmationModal';

const { Title, Text } = Typography;
const { TextArea } = Input;

const views = ['edit', 'help', 'visibility', 'info'];

const DataBlockCard = ({ dataBlock, domainIndex, dataBlockIndex }) => {
  const {
    serviceDataBlockId,
    title,
    childAttributes,
    id: dataBlockId,
    dataBlockReferenceSources: stateReferenceSources = [],
    serviceDataAttributes: savedDataAttributes = [],
    sequenceNumber: dataBlockSavedSeqNumber,
    helpText: savedHelpText,
    isUserSelectableReferenceSource: fetchedIsUserSelectableReferenceSource,
    serviceProviderHelpText: dataBlockInfoKey,
    savedReferenceSources = [],
    noReferenceSource: fetchedNoReferenceSource,
    isManualEntryForbidden,
  } = dataBlock;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    serviceId,
    domainsData,
    counterPartyConnectorsLoading,
    formType,
  } = useSelector(state => state.createService);
  const [isFirstRender, setIsFirstRender] = useIsFirstRender();
  const [firstEditFormRender, setFirstEditFormRender] = useIsFirstRender();

  const [isChecked, setIsChecked] = useState(
    domainsData[domainIndex].chosenBlocks.find(id => id === dataBlockId)
  );
  const [mode, setMode] = useState('view');
  const [dataReferenceSources, setDataReferencesSources] = useState([]);
  const [helpText, setHelpText] = useState(savedHelpText);
  const [isModified, setIsModified] = useState(false);
  const [
    isUserSelectableReferenceSource,
    setIsUserSelectableReferenceSource,
  ] = useState(fetchedIsUserSelectableReferenceSource);
  const [isLoading, setIsLoading] = useState(false);
  const [attributeVisibility, setAttributeVisibility] = useState(
    savedDataAttributes.length ? savedDataAttributes : childAttributes
  );
  const [noReferenceSource, setNoReferenceSource] = useState(
    fetchedNoReferenceSource
  );

  const domainData = domainsData[domainIndex];
  const isFirstDataBlockInDomain = !domainData.chapterDataBlockId;
  const chapterDataBlockId = domainData.chapterDataBlockId;
  const hasSavedDataAttributes =
    savedDataAttributes && savedDataAttributes.length > 0;
  const hasSavedReferenceSources =
    savedReferenceSources.length &&
    savedReferenceSources[0].hasOwnProperty('name');

  useEffect(() => {
    if (!savedDataAttributes.length) {
      setAttributeVisibility(childAttributes);
    }
  }, [childAttributes, savedDataAttributes.length]);

  useEffect(() => {
    if (hasSavedDataAttributes) setAttributeVisibility(savedDataAttributes);
  }, [dataBlock, hasSavedDataAttributes, savedDataAttributes]);

  useEffect(() => {
    if (stateReferenceSources.length) {
      const fetchedDataReferenceSources = [];
      stateReferenceSources.forEach(({ id }) => {
        fetchedDataReferenceSources.push(id.toString());
      });
      setDataReferencesSources(fetchedDataReferenceSources);
    }
  }, []);

  useEffect(() => {
    if (!savedReferenceSources.length) return;

    const setCounterPartyConnectors = () => {
      dispatch(
        setDataBlockConnectors({
          domainIndex,
          dataBlockIndex,
          type: 'savedSources',
        })
      );
    };

    if (
      formType === 'edit' &&
      !firstEditFormRender &&
      !hasSavedReferenceSources
    ) {
      setFirstEditFormRender(true);
      setCounterPartyConnectors();
    }
  }, [
    dataBlockIndex,
    dispatch,
    domainIndex,
    firstEditFormRender,
    formType,
    hasSavedReferenceSources,
    savedReferenceSources.length,
    setFirstEditFormRender,
    stateReferenceSources,
  ]);

  useEffect(() => {
    const fetchCounterPartyConnectors = async () => {
      const resultAction = await dispatch(
        getReferenceSources({ id: dataBlockId, domainIndex, dataBlockIndex })
      );

      if (getReferenceSources.fulfilled.match(resultAction)) {
        dispatch(
          setDataBlockConnectors({
            domainIndex,
            dataBlockIndex,
          })
        );
      }
    };

    if (mode === 'edit' && !isFirstRender) {
      setIsFirstRender(true);
      fetchCounterPartyConnectors();
    }
  }, [
    dataBlockId,
    dataBlockIndex,
    dispatch,
    domainIndex,
    isFirstRender,
    mode,
    setIsFirstRender,
  ]);

  const addDataBlock = async () => {
    setIsLoading(true);
    const resultAction = await dispatch(
      fetchDataBlock({
        serviceId,
        sequenceNumber: isFirstDataBlockInDomain
          ? 0
          : domainData.dataBlockSequenceNumber,
        dataBlock,
      })
    );

    if (fetchDataBlock.fulfilled.match(resultAction)) {
      dispatch(
        modifyDomainData({
          domainIndex,
          value: dataBlockId,
          modName: 'chosenDataBlocks',
          dataBlockIndex,
        })
      );
      setIsChecked(true);
    } else {
      showErrorNotification(t('new.service.dataBlock.error'));
    }
    setIsLoading(false);
  };

  const removeDataBlockFromDomain = async () => {
    setIsLoading(true);
    const resultAction = await dispatch(
      deleteServiceDataBlock({ id: serviceDataBlockId })
    );

    if (deleteServiceDataBlock.fulfilled.match(resultAction)) {
      setIsChecked(false);
      dispatch(
        modifyDomainData({
          domainIndex,
          value: dataBlockId,
          modName: 'chosenDataBlocks',
          dataBlockIndex,
        })
      );
      showSuccessNotification(t('new.service.dataBlock.delete.success'));
      setHelpText('');
      setDataReferencesSources([]);
      setIsUserSelectableReferenceSource(false);
      setNoReferenceSource(false);
      setIsFirstRender(false);
      setFirstEditFormRender(false);
    } else {
      showErrorNotification(t('new.service.dataBlock.delete.error'));
    }
    setIsLoading(false);
  };

  const handleCardClick = () => {
    if (mode !== 'view') return;
    if (!isChecked) {
      addDataBlock();
    } else {
      isModified
        ? confirmationModal(
            '.remove.dataBlock',
            t,
            removeDataBlockFromDomain,
            () => {}
          )
        : removeDataBlockFromDomain();
    }
  };

  const saveDataBlockInfo = async saveType => {
    const payload = {
      id: serviceDataBlockId,
      serviceId,
      dataBlockId,
      helpText: helpText || null,
      title,
      sequenceNumber: dataBlockSavedSeqNumber,
      isUserSelectableReferenceSource,
      parentServiceDataBlockId: chapterDataBlockId || null,
      noReferenceSource,
    };

    if (saveType === 'visibility') {
      payload.serviceDataAttributes = attributeVisibility.map((attr, idx) => ({
        id: attr.id,
        isVisible: attr.isVisible,
        sequenceNumber: idx,
        dataAttributeId: attr.dataAttribute
          ? attr.dataAttribute.id
          : attr.serviceDataAttributeId,
        parentServiceDataBlockId: serviceDataBlockId,
        serviceId,
      }));
    }

    if (saveType === 'referenceSource') {
      payload.dataReferenceSources = dataReferenceSources;
    }

    const resultAction = await dispatch(
      updateDataBlock({ payload, saveType, dataBlockIndex, domainIndex })
    );

    if (updateDataBlock.fulfilled.match(resultAction)) {
      showSuccessNotification(t('new.service.dataBlock.success'));
    } else {
      showErrorNotification(t('new.service.dataBlock.error'));
    }
  };

  const handleSave = () => {
    if (mode === 'edit') saveDataBlockInfo('referenceSource');
    if (mode === 'help') saveDataBlockInfo('helpText');
    if (mode === 'visibility') saveDataBlockInfo('visibility');
    setIsModified(true);
    return setMode('view');
  };

  const handleHelpTextChange = e => {
    setHelpText(e.target.value);
  };

  const handleDiscard = () => {
    if (mode === 'help') {
      setHelpText(savedHelpText);
    }
    if (mode === 'edit') {
      setNoReferenceSource(fetchedNoReferenceSource);
      setIsUserSelectableReferenceSource(
        fetchedIsUserSelectableReferenceSource
      );
      setDataReferencesSources(
        (savedReferenceSources || stateReferenceSources).map(({ id }) =>
          id.toString()
        )
      );
    }
    if (mode === 'visibility') {
      setAttributeVisibility(
        hasSavedDataAttributes ? savedDataAttributes : childAttributes
      );
    }

    setMode('view');
  };

  const CardAction = ({ mode, children }) => (
    <Tooltip
      placement={mode === 'info' ? 'bottom' : 'top'}
      title={() => (
        <>{t(`new.service.dataBlock.cardAction.${mode}.description`)}</>
      )}
    >
      {children}
    </Tooltip>
  );

  const CardTitle = () => (
    <div onClick={handleCardClick}>
      {mode === 'view' && (
        <>
          <div className="view-card__title space-between">
            <Spin size="small" spinning={isLoading}>
              <Checkbox
                className="checkbox-round"
                id={`checkbox`}
                checked={isChecked}
              />
            </Spin>
          </div>
          <Title level={4} strong>
            {title
              ? t(title)
              : mode !== 'view'
              ? t(`new.service.dataBlock.cardTitle.${mode}`)
              : null}
          </Title>
        </>
      )}
      {views.includes(mode) && (
        <div className="edit-card__title">
          <small className="dataBlock__card__title">
            {t(`new.service.dataBlock.cardTitle.${mode}`)}
          </small>
        </div>
      )}
    </div>
  );

  return (
    <Card
      hoverable
      title={<CardTitle />}
      className={`datablock${isChecked ? '--selected' : ''}`}
      actions={
        mode === 'view' && dataBlockInfoKey
          ? [
              <CardAction mode="edit">
                <SettingOutlined
                  style={{ pointerEvents: isChecked ? 'auto' : 'none' }}
                  key="settings"
                  onClick={() => setMode('edit')}
                />
              </CardAction>,
              <CardAction mode="help">
                <MessageOutlined
                  style={{
                    pointerEvents: isChecked ? 'auto' : 'none',
                    color: `${!!savedHelpText ? colors['success'] : ''}`,
                  }}
                  key="help"
                  onClick={() => setMode('help')}
                />
              </CardAction>,
              <CardAction mode="visibility">
                <EyeOutlined
                  style={{
                    pointerEvents: isChecked ? 'auto' : 'none',
                  }}
                  key="visibility"
                  onClick={() => setMode('visibility')}
                />
              </CardAction>,
              <Popover
                content={t(dataBlockInfoKey)}
                trigger="click"
                arrowPointAtCenter
              >
                <span>
                  <CardAction mode="info">
                    <InfoCircleOutlined
                      style={{ pointerEvents: isChecked ? 'auto' : 'none' }}
                      key="info"
                    />
                  </CardAction>
                </span>
              </Popover>,
            ]
          : mode !== 'view'
          ? [
              <Tooltip placement="top" title={t('global.btn.discard')}>
                <CloseOutlined
                  style={{ fontSize: '18px', color: colors['warning'] }}
                  onClick={handleDiscard}
                />
              </Tooltip>,
              <Tooltip placement="top" title={t('global.btn.save')}>
                <CheckOutlined
                  style={{ fontSize: '18px', color: colors['success'] }}
                  onClick={handleSave}
                />
              </Tooltip>,
            ]
          : [
              <CardAction mode="edit">
                <SettingOutlined
                  style={{ pointerEvents: isChecked ? 'auto' : 'none' }}
                  key="settings"
                  onClick={() => setMode('edit')}
                />
              </CardAction>,
              <CardAction mode="help">
                <MessageOutlined
                  style={{
                    pointerEvents: isChecked ? 'auto' : 'none',
                    color: `${!!savedHelpText ? colors['success'] : ''}`,
                  }}
                  key="help"
                  onClick={() => setMode('help')}
                />
              </CardAction>,
              <CardAction mode="visibility">
                <EyeOutlined
                  style={{
                    pointerEvents:
                      isChecked && attributeVisibility ? 'auto' : 'none',
                  }}
                  key="visibility"
                  onClick={() => setMode('visibility')}
                />
              </CardAction>,
            ]
      }
    >
      <>
        {mode === 'view' && (
          <div className="card-fade">
            <List size="small">
              {(hasSavedDataAttributes
                ? savedDataAttributes
                : childAttributes
              ).map(child => (
                <List.Item
                  style={{
                    display: isAttributeVisible(child, hasSavedDataAttributes),
                  }}
                  key={
                    hasSavedDataAttributes && child.dataAttribute
                      ? child.dataAttribute.id
                      : child.id
                  }
                >
                  {hasSavedDataAttributes && child.dataAttribute ? (
                    <>
                      {child.isVisible ? (
                        t(child.dataAttribute.cI_Name)
                      ) : (
                        <s>{t(child.dataAttribute.cI_Name)}</s>
                      )}
                    </>
                  ) : (
                    t(child.cI_Name)
                  )}
                </List.Item>
              ))}
            </List>
            {savedReferenceSources && savedReferenceSources.length ? (
              <p className="vertical-space-xs">
                <Text disabled>
                  <small>{t('new.service.dataBlock.reference.sources')}</small>
                </Text>
                <br />
                {savedReferenceSources.map((src, idx) =>
                  idx !== savedReferenceSources.length - 1
                    ? `${t(src.name)}, `
                    : t(src.name)
                )}
              </p>
            ) : null}
          </div>
        )}
        {mode === 'edit' && (
          <EditReferenceSources
            dataReferenceSources={dataReferenceSources}
            setDataReferencesSources={setDataReferencesSources}
            sources={stateReferenceSources}
            setIsUserSelectable={setIsUserSelectableReferenceSource}
            isUserSelectable={isUserSelectableReferenceSource}
            counterPartiesLoading={counterPartyConnectorsLoading}
            noReferenceSource={noReferenceSource}
            setNoReferenceSource={setNoReferenceSource}
            isManualEntryForbidden={isManualEntryForbidden}
          />
        )}
        {mode === 'visibility' && (
          <EditVisibility
            dataAttributes={attributeVisibility}
            setDataAttributes={setAttributeVisibility}
          />
        )}
        {mode === 'help' && (
          <TextArea
            autoFocus
            value={helpText}
            onChange={e => handleHelpTextChange(e)}
            autoSize={{ minRows: 5, maxRows: 5 }}
          />
        )}
      </>
    </Card>
  );
};

export default DataBlockCard;
