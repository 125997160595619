import React from 'react';
import { Row, Space, Col, Typography, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { DATE_FORMAT } from '../../utils/createServiceHelpers';
import moment from 'moment';

import useHasPath from '../../hooks/useHasPath';

const { Title } = Typography;

const CompanyHeader = ({ company }) => {
  const { cI_Name } = company;
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const history = useHistory();
  const { hasPath } = useHasPath('mykyc');
  const { user } = useSelector(state => state.user);

  const handleMyKYC = () => {
    history.push(`/${language}/kyc/company/mykyc`);
  };

  return (
    <>
      <Row className="space-vertical-lg company-data-block">
        <Space className="space-between">
          <Col className="page-title">
            <Title>{cI_Name}</Title>
          </Col>
          {!hasPath ? (
            <Col>
              <Button type="primary" onClick={handleMyKYC}>
                {t('company.button.mykyc')}
              </Button>
            </Col>
          ) : null}
        </Space>
      </Row>
      <Row>
        <Col xs={6}>{t('customer.accountNumber')}</Col>
        <Col xs={6}>
          <strong>{user.CustomerAccountNumber}</strong>
        </Col>
        <Col xs={6}>{t('customer.createdAt')}</Col>
        <Col xs={6}>
          <strong>
            {user.CustomerCreatedAt &&
              moment(user.CustomerCreatedAt).format(DATE_FORMAT)}
          </strong>
        </Col>
      </Row>
    </>
  );
};

export default CompanyHeader;
