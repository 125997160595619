import React from 'react';
import { Modal, Typography, List, Space, Button, Row, Col } from 'antd';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { CardActionsContext } from './CardActionsProvider';
import { createQdbrRequest } from '../../../store/entities/questionnaire/thunks';
import { showErrorNotification } from '../../../utils/showNotification';
import {
  DDMMYYYY_HHmm,
  LNRequestCategorys,
  questionnaireStatus,
} from '../../../utils/constants';

const { Text } = Typography;

const LNCategorys = ['Sanctions', 'PEPs', 'Watchlists'];

const LNResultsModal = ({
  qdbrs,
  qdbrId,
  setSelectedLNResult,
  price,
  questionnaireStatusId,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    questionnaire: {
      service: { isPriceVisibleToUser },
      id,
    },
  } = useSelector(state => state.questionnaire);

  const {
    isLNResultsOpen,
    toggleLNResults,
    toggleLNDetails,
  } = React.useContext(CardActionsContext);

  const qdbr = qdbrs.find(({ id }) => id === qdbrId);
  const LNResults = qdbr.LNResults;
  const requestInputs = qdbr?.request;
  const requestInput =
    requestInputs.find(x => x.statusId === 0) ?? requestInputs[0];
  const isLegalEntity =
    requestInput &&
    requestInput.requestBody.find(({ cI_Name }) => cI_Name === 'entity')
      ?.cI_Value !== 'individual';
  const requestStatuses = requestInputs.reduce((status, obj) => {
    const category =
      obj.lexisNexisRequestCategoryId === 1
        ? 'PEPs'
        : obj.lexisNexisRequestCategoryId === 2
        ? 'Sanctions'
        : 'Watchlists';
    status[category] = obj.statusName;
    return status;
  }, {});

  const handleDetails = (category, index) => {
    toggleLNResults();
    toggleLNDetails();
    setSelectedLNResult({ category, index });
  };

  const initiateScreening = async category => {
    const requestCategoryId = LNRequestCategorys.find(
      ({ cI_Value }) => cI_Value === category
    ).id;

    const result = await dispatch(
      createQdbrRequest({
        questionnaireId: id,
        qdbrId,
        requestCategoryIds: [requestCategoryId],
      })
    );

    !createQdbrRequest.fulfilled.match(result)
      ? showErrorNotification(t('lexis.nexis.initiation.error'))
      : toggleLNResults();
  };

  const confirmScreening = category => {
    Modal.confirm({
      content: isPriceVisibleToUser ? (
        <Trans i18nKey="lexis.nexis.initiate.cofirm">
          Initiate {{ category }} screening for {{ price }}€?
        </Trans>
      ) : (
        <Trans i18nKey="lexis.nexis.initiate.cofirm.no.price">
          Initiate {{ category }} screening?
        </Trans>
      ),
      okText: t('global.btn.initiate.request'),
      cancelText: t('global.btn.discard'),
      onOk: () => initiateScreening(category),
    });
  };

  return (
    <Modal
      title={t('lexis.nexis.results.modal.title')}
      visible={isLNResultsOpen}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ className: 'btn-secondary' }}
      cancelText={t('global.btn.close')}
      onCancel={toggleLNResults}
      width={800}
      centered
    >
      <RequestData requestInput={requestInput} isLegalEntity={isLegalEntity} />
      {LNCategorys.map(category => {
        if (category === 'PEPs' && isLegalEntity) return null;
        return (
          <List
            header={
              <div className="space-bottom-md">
                <Text strong>
                  {t(`lexis.nexis.results.modal.source.${category}`)}
                </Text>
              </div>
            }
            split={false}
            itemLayout="vertical"
            key={category}
            className="padding-none"
          >
            {LNResults[category]?.[0]?.[0]?.id ? (
              LNResults[category].map((result, index) => (
                <LNResultData
                  key={result[0].id}
                  result={Object.values(result)}
                  t={t}
                  handleDetails={handleDetails}
                  LNCategory={category}
                  index={index}
                />
              ))
            ) : (
              <>
                {LNResults[category]?.[0] ? (
                  <List.Item className="padding-none">
                    <Row align="middle">
                      <Col offset={1}>
                        <Text className="color-success">
                          {t('lexis.nexis.results.modal.no.results')}
                        </Text>
                      </Col>
                    </Row>
                  </List.Item>
                ) : (
                  <List.Item className="padding-none">
                    <div className="space-between center">
                      <Col offset={1}>
                        <Row>
                          <Text>
                            {`lexis.nexis.results.modal.${
                              requestStatuses[category] === 'Failed'
                                ? 'request.failed'
                                : 'not.screened'
                            }`}
                          </Text>
                        </Row>
                      </Col>
                      <Col>
                        <Button
                          onClick={() => confirmScreening(category)}
                          className="btn-secondary space-left-md"
                          disabled={
                            questionnaireStatusId ===
                            questionnaireStatus.finished
                          }
                        >
                          {t('global.btn.screen')}
                        </Button>
                      </Col>
                    </div>
                  </List.Item>
                )}
              </>
            )}
          </List>
        );
      })}
    </Modal>
  );
};

const LNResultData = ({ result, t, handleDetails, LNCategory, index }) => {
  const isLexisNexis = (title, name) =>
    title &&
    title.startsWith('dataattribute.title.LexisNexis') &&
    title.endsWith(name);

  const filterByCIName = name =>
    result.filter(({ title }) => isLexisNexis(title, name));

  const getNames = () => {
    const firstName = result.find(({ title }) =>
      isLexisNexis(title, 'InformationFirstName')
    )?.cI_Value;

    const lastName = result.find(({ title }) =>
      isLexisNexis(title, 'InformationLastName')
    )?.cI_Value;

    let name = !firstName ? '' : firstName;

    if (!!lastName) name = name.length === 0 ? lastName : `${name} ${lastName}`;

    return name;
  };

  const name =
    result.find(({ title }) => isLexisNexis(title, 'InformationFullName'))
      ?.cI_Value ?? getNames();
  const birthPlace = filterByCIName('PlaceOfBirth');
  const birthDate = filterByCIName('DateOfBirth');
  const category = filterByCIName('InformationCategory');
  const score = filterByCIName('InformationScore');

  const displayValues = values =>
    values.map(({ cI_Value }) => cI_Value).join('; ');

  return (
    <List.Item className="padding-none space-bottom-md">
      <div className="space-between center">
        <div className="inline-flex">
          <strong className="padding-right-xxsm">{`${index + 1}.`}</strong>
          <div>
            <Row>
              <Text>
                {`${name}, ${displayValues(birthPlace)}, ${displayValues(
                  birthDate
                )}`}
              </Text>
            </Row>
            <Row>
              <Text>
                {t('lexis.nexis.result.modal.category')}{' '}
                {category.length ? displayValues(category) : '-'}
                {', '}({t('lexis.nexis.result.modal.score')}{' '}
                {displayValues(score)})
              </Text>
            </Row>
          </div>
        </div>
        <Button
          className="space-left-md"
          onClick={() => handleDetails(LNCategory, index)}
        >
          {t('global.btn.details')}
        </Button>
      </div>
    </List.Item>
  );
};

const RequestData = ({ requestInput, isLegalEntity }) => {
  const { t } = useTranslation();

  const renderInputs = (cI_Name, cI_Value, isLegalEntity) => {
    switch (cI_Name) {
      case 'entity':
        return (
          <Text key={cI_Name}>{`${t('lexis.nexis.result.modal.entity')} ${t(
            `lexis.nexis.results.modal.entity.${
              cI_Value === 'individual' ? 'individual' : 'legal'
            }`
          )}`}</Text>
        );
      case 'names':
        return (
          <Text key={cI_Name}>
            {t('lexis.nexis.result.modal.entity.name')}{' '}
            {isLegalEntity
              ? `${cI_Value.name}${
                  cI_Value.legalForm ? `, ${cI_Value.legalForm}` : ''
                }`
              : `${
                  cI_Value.fullName
                    ? cI_Value.fullName
                    : [
                        cI_Value.firstName,
                        cI_Value.middleName,
                        cI_Value.lastName,
                      ].join(' ')
                }`}
          </Text>
        );
      case 'entityIds':
        return (
          <Text key={cI_Name}>{`${t('lexis.nexis.result.modal.entity.id')} ${
            cI_Value.id
          }${cI_Value.idCountry ? ` (${cI_Value.idCountry})` : ''}`}</Text>
        );
      case 'addresses':
        return (
          <Text key={cI_Name}>
            {t('lexis.nexis.result.modal.entity.address')}{' '}
            {`${cI_Value.address ?? ''} ${
              cI_Value.addressCountry ? `(${cI_Value.addressCountry})` : ''
            }`}
          </Text>
        );
      default:
        return (
          <Text key={cI_Name}>{`${t(
            `lexis.nexis.result.modal.${cI_Name}`
          )} ${cI_Value}`}</Text>
        );
    }
  };

  return (
    <Space direction="vertical" className="space-top-none">
      <Text strong>{t('lexisn.nexis.results.modal.req.input')}</Text>
      <Text>{`${t('lexis.nexis.result.modal.request.initiated')} ${moment(
        requestInput.lastAttemptAt
      ).format(DDMMYYYY_HHmm)}`}</Text>
      {requestInput.requestBody.map(({ cI_Name, cI_Value }) =>
        renderInputs(cI_Name, cI_Value, isLegalEntity)
      )}
    </Space>
  );
};

export default LNResultsModal;
