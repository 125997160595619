import { createSlice } from '@reduxjs/toolkit';
import { cloneService } from './thunks';

const slice = createSlice({
  name: 'serviceManagement',
  initialState: {
    serviceList: [],
    serviceListSize: 0,
    serviceTableState: {
      pagination: {
        pageSize: 10,
        pageIndex: 0,
      },
      sortOrder: { sortColumn: 'title', isSortAsc: true },
    },
    serviceTableFilters: [],
    serviceListLoading: false,
    serviceListError: false,
    updateServiceStatusLoading: false,
    updateServiceStatusError: false,
    serviceListUpdate: false,
    cloneServiceLoading: false,
  },
  reducers: {
    serviceListRequested: state => {
      state.serviceListLoading = true;
      state.serviceListError = false;
    },
    serviceListRequestFailed: state => {
      state.serviceListLoading = false;
      state.serviceListError = true;
    },
    serviceListReceived: (state, { payload }) => {
      state.serviceList = payload.data.listServicesWithPagination.result;
      state.serviceListSize = payload.data.listServicesWithPagination.total;
      state.serviceListLoading = false;
      state.serviceListError = false;
    },
    setTableState: (state, { payload }) => {
      state.serviceTableState.pagination.pageIndex = payload.page - 1;
      state.serviceTableState.sortOrder = {
        sortColumn: payload.sortOrder.columnKey,
        isSortAsc: payload.sortOrder.order === 'ascend',
      };
    },
    setTableFilters: (state, { payload }) => {
      const isDateValue = key => key === 'validFrom' || key === 'validTo';

      state.serviceTableFilters = Object.keys(payload)
        .filter(key => payload[key])
        .map(key => ({
          propertyName:
            key === 'countryCodeISOAlpha3' ? `serviceCountries.${key}` : key,
          [isDateValue(key)
            ? key === 'validFrom'
              ? 'dateFrom'
              : 'dateTo'
            : key === 'title'
            ? 'likeValue'
            : 'value']: payload[key].toString(),
        }));
      state.serviceTableState.pagination.pageIndex = 0;
    },
    updateServiceStatusRequested: state => {
      state.updateServiceStatusLoading = true;
      state.serviceListLoading = true;
      state.updateServiceStatusError = false;
    },
    updateServiceStatusError: state => {
      state.updateServiceStatusLoading = false;
      state.serviceListLoading = false;
      state.updateServiceStatusError = true;
    },
    updateServiceStatusSuccess: state => {
      state.updateServiceStatusLoading = false;
      state.serviceListUpdate = true;
    },
    serviceListUpdated: state => {
      state.serviceListUpdate = false;
    },
  },
  extraReducers: {
    [cloneService.pending]: state => {
      state.cloneServiceLoading = true;
    },
    [cloneService.fulfilled]: state => {
      state.cloneServiceLoading = false;
    },
    [cloneService.rejected]: state => {
      state.cloneServiceLoading = false;
    },
  },
});

export const {
  serviceListRequested,
  serviceListRequestFailed,
  serviceListReceived,
  setTableState,
  setTableFilters,
  updateServiceStatusRequested,
  updateServiceStatusError,
  updateServiceStatusSuccess,
  serviceListUpdated,
} = slice.actions;

export default slice.reducer;
