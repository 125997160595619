import React from 'react';
import StatusBadge from '../global/StatusBadge';
import { CounterParty } from '../../utils/constants';

export const getTableColumnsForSource = (sourceId, t, language) => {
  switch (sourceId) {
    case CounterParty.BVD:
      return getBvDColumns(t);
    case CounterParty.CI:
      return getCIColumns(t);
    case CounterParty.PP:
      return getPPColumns(t);
    case CounterParty.LTU:
      const isNative = language === 'lt';
      return getLTUColumns(t, isNative);
    case CounterParty.LVA:
      return getLVAColumns(t);
    case CounterParty.ASIAKASTIETO:
      return getAsiakastietoColumns(t);
    case CounterParty.UCAB:
      return getUcabColumns(t);
    default:
  }
};

const getUcabColumns = t => [
  {
    title: t('onboarding.table.nr'),
    dataIndex: 'Nr',
    key: 'nr',
  },
  {
    title: t('onboarding.table.name'),
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: t('onboarding.table.regCode'),
    dataIndex: 'registerCode',
    key: 'registerCode',
  },
  {
    title: t('onboarding.table.address'),
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: t('onboarding.table.ucab.city'),
    dataIndex: 'city',
    key: 'city',
  },
  {
    title: t('onboarding.table.ucab.postalCode'),
    dataIndex: 'postalCode',
    key: 'postalCode',
  },
  {
    title: t('onboarding.table.phone'),
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: t('onboarding.table.ucab.status1'),
    dataIndex: 'status1',
    key: 'status1',
  },
  {
    title: t('onboarding.table.ucab.status2'),
    dataIndex: 'status2',
    key: 'status2',
  },
];

const getAsiakastietoColumns = t =>
  [
    {
      title: t('onboarding.table.nr'),
      dataIndex: 'Nr',
      key: 'nr',
    },
    {
      title: t('onboarding.table.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('onboarding.table.regCode'),
      dataIndex: 'businessId',
      key: 'businessId',
    },
    {
      title: t('onboarding.table.address'),
      dataIndex: 'address',
      key: 'address',
    },
  ].concat(
    [
      'domicile',
      'dissolvedFromRegister', // todo: SK-350: translatable
      'group',
      'parallelBusinessName',
      'tradingName',
      'groupTradingName',
      'previousName',
      'groupPreviousName',
      'underFoundation', // todo: SK-350: translatable
    ].map(x => {
      return {
        title: t('onboarding.table.asiakastieto.' + x),
        key: x,
        dataIndex: x,
      };
    })
  );

const getLVAColumns = t => [
  {
    title: t('onboarding.table.nr'),
    dataIndex: 'Nr',
    key: 'nr',
  },
  {
    title: t('onboarding.table.name'),
    dataIndex: 'currentName',
    key: 'currentName',
  },
  {
    title: t('onboarding.table.regCode'),
    dataIndex: 'registrationNumber',
    key: 'registrationNumber',
  },
  {
    title: t('onboarding.table.address'),
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: t('onboarding.table.lva.type'),
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: t('onboarding.table.lva.previousName'),
    dataIndex: 'previousName',
    key: 'previousName',
  },
  {
    title: t('onboarding.table.status'),
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: t('onboarding.table.lva.register'),
    dataIndex: 'register',
    key: 'register',
  },
];

const getLTUColumns = (t, isNative) => [
  {
    title: t('onboarding.table.nr'),
    dataIndex: 'Nr',
    key: 'nr',
  },
  {
    title: t('onboarding.table.name'),
    dataIndex: isNative ? 'nameNative' : 'nameEN',
    key: isNative ? 'nameNative' : 'nameEN',
  },
  {
    title: t('onboarding.table.ltu.code'),
    dataIndex: 'jar_kodas',
    key: 'jar_kodas',
  },
  {
    title: t('onboarding.table.address'),
    key: 'address',
    render: ({ streetNative, streetEN, postalCode }) => (
      <span>{`${isNative ? streetNative : streetEN}${
        postalCode && postalCode !== 'NULL' ? ', ' + postalCode : ''
      }`}</span>
    ),
  },
  {
    title: t('onboarding.table.ltu.legalForm'),
    dataIndex: 'org_name_status_code',
    key: 'org_name_status_code',
  },
  {
    title: t('onboarding.table.ltu.legalStatus'),
    dataIndex: isNative ? 'lERStatusName' : 'lERStatusNameEN',
    key: isNative ? 'lERStatusName' : 'lERStatusNameEN',
  },
  {
    title: t('onboarding.table.ltu.sector'),
    dataIndex: isNative ? 'nACE2' : 'nACE_ENG2',
    key: isNative ? 'nACE2' : 'nACE_ENG2',
  },
];

const getBvDColumns = t => [
  {
    title: t('onboarding.table.nr'),
    dataIndex: 'Nr',
    key: 'nr',
  },
  {
    title: t('onboarding.table.bvd.score'),
    dataIndex: 'score',
    key: 'score',
  },
  {
    title: t('onboarding.table.bvd.matched.name'),
    dataIndex: 'matchedName',
    key: 'matchedName',
  },
  {
    title: t('onboarding.table.bvd.matched.name.type'),
    dataIndex: 'matchedNameType',
    key: 'matchedNameType',
  },
  {
    title: t('onboarding.table.bvd.name'),
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: t('onboarding.table.bvd.legal.form'),
    dataIndex: 'legalForm',
    key: 'legalForm',
  },
  {
    title: t('onboarding.table.bvd.national.id'),
    dataIndex: 'nationalId',
    key: 'nationalId',
  },
  {
    title: t('onboarding.table.bvd.national.id.label'),
    dataIndex: 'nationalIdLabel',
    key: 'nationalIdLabel',
  },
  {
    title: t('onboarding.table.bvd.country'),
    dataIndex: 'country',
    key: 'country',
    width: 75,
  },
  {
    title: t('onboarding.table.bvd.address'),
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: t('onboarding.table.bvd.email.or.webSite'),
    dataIndex: 'emailOrWebSite',
    key: 'emailOrWebSite',
  },
  {
    title: t('onboarding.table.bvd.consolidation.code'),
    dataIndex: 'consolidationCode',
    key: 'consolidationCode',
    width: 120,
  },
];

const getPPColumns = t => [
  {
    title: t('onboarding.table.nr'),
    dataIndex: 'Nr',
    key: 'nr',
  },
  {
    title: t('onboarding.table.pp.name'),
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: t('onboarding.table.pp.legalForm'),
    dataIndex: 'legalForm',
    key: 'legalForm',
  },
  {
    title: t('onboarding.table.pp.code'),
    dataIndex: 'registryCode',
    key: 'registryCode',
  },
  {
    title: t('onboarding.table.pp.address'),
    dataIndex: 'legalAddress',
    key: 'legalAddress',
  },

  {
    title: t('onboarding.table.pp.legalStatus'),
    dataIndex: 'status',
    key: 'status',
  },
];

const getCIColumns = t => [
  {
    title: t('onboarding.table.nr'),
    dataIndex: 'Nr',
    key: 'nr',
  },
  {
    title: t('onboarding.table.name'),
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: t('onboarding.table.status'),
    dataIndex: 'status',
    key: 'status',
    render: status => <StatusBadge status={status} />,
  },
  {
    title: t('onboarding.table.regCode'),
    dataIndex: 'regCode',
    key: 'regCode',
  },

  {
    title: t('onboarding.table.address'),
    key: 'address',
    render: ({ legalStreet, legalCity }) => (
      <span>{`${legalStreet}, ${legalCity}`}</span>
    ),
  },
  {
    title: t('onboarding.table.phone'),
    dataIndex: 'phones',
    key: 'phones',
  },
  {
    title: t('onboarding.table.email'),
    dataIndex: 'emails',
    key: 'emails',
  },
  {
    title: t('onboarding.table.website'),
    dataIndex: 'web',
    key: 'web',
  },
];
