import * as gql from 'gql-query-builder';

import { apiCallBegan } from '../../api';
import { adminBackend } from '../../../api-config';
import {
  serviceListRequested,
  serviceListRequestFailed,
  serviceListReceived,
  updateServiceStatusRequested,
  updateServiceStatusSuccess,
  updateServiceStatusError,
} from './';

export const fetchServicesList = (tableState, filters) =>
  apiCallBegan({
    url: adminBackend,
    data: gql.query({
      operation: 'listServicesWithPagination',
      variables: {
        searchParams: {
          value: {
            ...tableState,
            filter: {
              filterConditions: filters,
            },
          },
          type: 'SearchParamsInputType!',
        },
      },
      fields: [
        'total',
        {
          result: [
            'id',
            'title',
            'version',
            {
              serviceType: ['cI_Name'],
            },
            'version',
            {
              status: ['id', 'cI_Name'],
            },
            'modifiedAt',
            {
              versions: ['id', 'version', 'statusId'],
            },
          ],
        },
      ],
    }),
    onStart: serviceListRequested.type,
    onSuccess: serviceListReceived.type,
    onFailure: serviceListRequestFailed.type,
  });

export const updateServiceStatus = (id, statusId) =>
  apiCallBegan({
    url: adminBackend,
    data: gql.mutation({
      operation: 'serviceUpdateProperty',
      variables: {
        propertyName: 'statusId',
        input: {
          value: {
            id,
            statusId,
          },
          type: 'ServiceInputType!',
        },
      },
      fields: ['id', 'statusId'],
    }),
    onStart: updateServiceStatusRequested.type,
    onSuccess: updateServiceStatusSuccess.type,
    onFailure: updateServiceStatusError.type,
  });
