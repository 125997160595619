import { clientBackend, adminBackend } from '../api-config';

export const apiBaseConfig = {
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
  url: clientBackend,
  method: 'POST',
};

export const adminApiBaseConfig = {
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
  url: adminBackend,
  method: 'POST',
};
