import axios from 'axios';
import * as gql from 'gql-query-builder';

import { apiBaseConfig } from '../../../utils/apiUtils';
import { LEGALENTITY_FIELDS } from '../company/queries';

export const postUpdateLegalEntityKeyDatas = ps => {
  const response = axios({
    ...apiBaseConfig,
    data: gql.mutation({
      operation: 'updateLegalEntityKeyDatas',
      variables: {
        ps: {
          value: {
            ...ps,
          },
          type: 'CreateLegalEntityFromIdmParamsInputType!',
        },
      },
      fields: LEGALENTITY_FIELDS,
    }),
  });
  return response;
};

export const postUpdateManuallyInserted = ps => {
  const response = axios({
    ...apiBaseConfig,
    data: gql.mutation({
      operation: 'updateManuallyInserted',
      variables: {
        ps: {
          value: {
            ...ps,
          },
          type: 'LegalEntityInputType!',
        },
      },
      fields: LEGALENTITY_FIELDS,
    }),
  });
  return response;
};

export const postOnboardingForm = onboardingData => {
  const response = axios({
    ...apiBaseConfig,
    data: gql.mutation({
      operation: 'legalEntityRelation',
      variables: {
        input: {
          value: {
            ...onboardingData,
          },
          type: 'LegalEntityRelationInputType!',
        },
      },
      fields: ['id', 'childLegalEntityId'],
    }),
  });
  return response;
};

export const createQuestionnaire = payload => {
  const response = axios({
    ...apiBaseConfig,
    data: gql.mutation({
      operation: 'createQuestionnaireFromService',
      variables: {
        serviceId: {
          value: payload.serviceId,
          type: 'Int!',
        },
        legalEntityId: {
          value: payload.legalEntityId,
          type: 'Int!',
        },
      },
      fields: ['id'],
    }),
  });
  return response;
};
