import axios from 'axios';
import * as gql from 'gql-query-builder';

import { apiBaseConfig } from '../../../utils/apiUtils';

export const fetchQuestionnaires = (tableState, legalEntityId) => {
  const response = axios({
    ...apiBaseConfig,
    data: gql.query({
      operation: 'listQuestionnairesWithPagination',
      variables: {
        searchParams: {
          value: {
            ...tableState,
            filter: {
              filterConditions: [
                {
                  propertyName: 'subjectLegalEntityId',
                  value: legalEntityId,
                },
              ],
            },
          },
          type: 'SearchParamsInputType!',
        },
      },
      fields: [
        'total',
        {
          result: [
            'id',
            'title',
            'subjectLegalEntityId',
            'createdAt',
            {
              status: ['id', 'cI_Name'],
            },
            {
              createdBy: [{ kYCUser: ['firstname', 'lastname'] }],
            },
          ],
        },
      ],
    }),
  });
  return response;
};

export const fetchServices = country => {
  const response = axios({
    ...apiBaseConfig,
    data: gql.query({
      operation: 'getWithConnectors',
      variables: {
        searchParams: {
          value: {
            filter: {
              filterConditions: [
                {
                  propertyName: 'statusId',
                  value: '2',
                },
                {
                  propertyName: 'serviceCountries.countryCodeISOAlpha3',
                  value: country,
                },
              ],
            },
          },
          type: 'SearchParamsInputType!',
        },
      },
      fields: [
        'serviceId',
        'totalPrice',
        {
          counterpartyConnectors: ['id', 'productName', 'counterPartyId'],
        },
        {
          service: [
            'title',
            'isPriceVisibleToUser',
            'description',
            {
              listChapterServiceDataBlocks: [
                'id',
                'heading',
                'sequenceNumber',
                {
                  childBlocks: [
                    'id',
                    'heading',
                    'title',
                    'sequenceNumber',
                    {
                      childBlocks: ['id', 'heading', 'title', 'sequenceNumber'],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    }),
  });
  return response;
};
