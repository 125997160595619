import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Comment, Input, Modal, Space, Spin } from 'antd';
import { CardActionsContext } from './CardActionsProvider';

import { saveComment } from '../../../store/entities/questionnaire/thunks';
import { showErrorNotification } from '../../../utils/showNotification';
import { questionnaireStatus } from '../../../utils/constants';

const { TextArea } = Input;

const CommentsModal = ({
  comments,
  chapterIdx,
  subChapterIdx,
  questionnaireDataBlockResultId,
  serviceDataBlockId,
  questionnaireStatusId,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { commentsOpen: isOpen, toggleComments: toggleVisible } = useContext(
    CardActionsContext
  );
  const {
    questionnaire: { id: questionnaireId },
    commentLoading,
  } = useSelector(state => state.questionnaire);

  const [comment, setComment] = useState(null);

  const postComment = async e => {
    e.preventDefault();
    const payload = {
      comment,
      questionnaireId,
      questionnaireDataBlockResultId,
    };
    const propsForThunk = {
      payload,
      chapterIdx,
      subChapterIdx,
      serviceDataBlockId,
    };

    const result = await dispatch(saveComment(propsForThunk));

    saveComment.fulfilled.match(result)
      ? setComment(null)
      : showErrorNotification(t('questionnaire.comment.save.error'));
  };

  return (
    <Modal
      title={'Comments'}
      visible={isOpen}
      onCancel={toggleVisible}
      cancelText={t('global.btn.close')}
      cancelButtonProps={{ className: 'btn-secondary' }}
      okButtonProps={{ style: { display: 'none' } }}
    >
      <Spin spinning={commentLoading}>
        {comments.map(({ comment, createdAt, createdBy }) => (
          <Comment
            key={createdAt}
            author={
              <a>{`${createdBy.kYCUser.firstname} ${createdBy.kYCUser.lastname}`}</a>
            }
            content={<p>{comment}</p>}
            datetime={
              <span>{moment(createdAt).format('DD.MM.YYYY HH:mm')}</span>
            }
          />
        ))}
        {questionnaireStatusId !== questionnaireStatus.finished && (
          <Comment
            content={
              <form onSubmit={postComment}>
                <TextArea
                  value={comment}
                  onChange={e => setComment(e.target.value)}
                  rows={4}
                />
                <Space direction="vertical">
                  <Button htmlType="submit" type="primary">
                    {t('global.btn.add.comment')}
                  </Button>
                </Space>
              </form>
            }
          />
        )}
      </Spin>
    </Modal>
  );
};

export default CommentsModal;
