import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import useHasPath from '../hooks/useHasPath';
import { fetchCustomer } from '../store/entities/customer/queries';
import { resetCustomer } from '../store/entities/customer';

import CustomerHeader from '../components/customer/CustomerHeader';
import LegalEntityData from '../components/legalentity/LegalEntityData';
import CustomerServicesTable from '../components/customer/CustomerServicesTable';
import CompanyHeader from '../components/company/CompanyHeader';

const Customer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { customerId } = useParams();

  const { hasPath } = useHasPath('mykyc');

  const { customer, customerLoading } = useSelector(state => state.customer);
  const { company, companyLoading } = useSelector(state => state.company);

  useEffect(() => {
    if (!hasPath) dispatch(fetchCustomer(customerId));

    return () => dispatch(resetCustomer());
  }, [company, customerId, dispatch, hasPath]);

  return (
    <Spin
      size="large"
      spinning={customerLoading || companyLoading}
      tip={t('global.loading')}
    >
      <div className="container">
        {customer && !hasPath && company ? (
          <>
            <CustomerHeader customer={customer} />
            <LegalEntityData
              companyData={customer?.childLegalEntity}
              isFirstSource={true}
            />
            <CustomerServicesTable
              id={customer?.childLegalEntity?.id}
              relationStatusId={customer?.relationStatusId}
            />
          </>
        ) : hasPath && company ? (
          <>
            <CompanyHeader company={company} />
            <LegalEntityData companyData={company} isFirstSource={true} />
            <CustomerServicesTable id={company.id} />
          </>
        ) : null}
      </div>
    </Spin>
  );
};

export default Customer;
