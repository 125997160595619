import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Table, Button } from 'antd';

import { PAGESIZE, ROUTES_UPDATE_LEGALENTITY } from '../../utils/constants';

import { getTableColumnsForSource } from './tableColumns';
import CompanySearchModal from './CompanySearchModal';
import useHasPath from '../../hooks/useHasPath';
import { useParams } from 'react-router-dom';
import LegalEntityUpdateModal from './LegalEntityUpdateModal';

const OnboardingTable = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const {
    onboardingList: onboardingResult,
    onboardingDatasource,
    isCompanyGroupView,
    onboardingCountryId,
  } = useSelector(state => state.onboarding);

  const isOnUpdateLegalEntityForm = useHasPath(ROUTES_UPDATE_LEGALENTITY)
    .hasPath;
  // todo: keydata: or is it better if we pass this legalEntityId from parent component?
  const { legalEntityId } = useParams();

  const [columns, setColumns] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalForUpdatingVisible, setIsModalForUpdatingVisible] = useState(
    false
  );
  const [legalEntityFromIdmParams, setLegalEntityFromIdmParams] = useState(
    null
  );

  useEffect(() => {
    setTableColumns();
  }, [onboardingDatasource, t]);

  const handleCompanySelect = info => {
    if (isOnUpdateLegalEntityForm) setIsModalForUpdatingVisible(true);
    else setIsModalVisible(true);

    setLegalEntityFromIdmParams({
      countryAlpha: onboardingCountryId,
      iDMBeginStrategyRequestId: info.iDMBeginStrategyRequestId,
      indexInResponse: info.indexInResponse,
      counterPartyId: onboardingDatasource,
      legalEntityId: legalEntityId,
    });
  };

  const setTableColumns = () => {
    if (onboardingDatasource) {
      const columnsForDatasource = getTableColumnsForSource(
        onboardingDatasource,
        t,
        language
      );
      columnsForDatasource.unshift({
        title: t('onboarding.table.actions'),
        key: 'actions',
        render: (text, record) => (
          <div>
            <Button
              className="is-primary"
              onClick={() => handleCompanySelect(record)}
            >
              {isOnUpdateLegalEntityForm
                ? t('onboarding.table.actions.select')
                : t('onboarding.table.actions.start')}
            </Button>
          </div>
        ),
      });

      setColumns(columnsForDatasource);
    } else setColumns([]);
  };

  return (
    <>
      {onboardingResult.length ? (
        <Table
          rowKey={record => record?.Nr ?? record.regCode}
          columns={columns}
          dataSource={onboardingResult}
          scroll={{ x: 'max-content' }}
          pagination={{
            pageSize: PAGESIZE,
            showSizeChanger: false,
          }}
        />
      ) : null}
      <CompanySearchModal
        isVisible={isModalVisible}
        setIsVisible={setIsModalVisible}
        isGroupCompany={isCompanyGroupView}
        legalEntityFromIdmParams={legalEntityFromIdmParams}
        isManual={false}
      />
      <LegalEntityUpdateModal
        isVisible={isModalForUpdatingVisible}
        setIsVisible={setIsModalForUpdatingVisible}
        isGroupCompany={isCompanyGroupView}
        legalEntityFromIdmParams={legalEntityFromIdmParams}
        isManual={false}
      />
    </>
  );
};

export default OnboardingTable;
