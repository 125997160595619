import axios from 'axios';
import * as gql from 'gql-query-builder';

import { adminApiBaseConfig } from '../../../utils/apiUtils';

export const fetchDomainDataBlocks = domainId => {
  const response = axios({
    ...adminApiBaseConfig,
    data: gql.query({
      operation: 'domain',
      variables: {
        id: {
          value: domainId,
          type: 'Int!',
        },
      },
      fields: [
        'id',
        'title',
        {
          dataBlocks: [
            'id',
            'title',
            'domainId',
            'serviceProviderHelpText',
            'isManualEntryForbidden',
            'sequenceNumber',
            { childAttributes: ['id', 'cI_Name', 'sequenceNumber'] },
          ],
        },
      ],
    }),
  });
  return response;
};

export const deleteDomain = id => {
  const response = axios({
    ...adminApiBaseConfig,
    data: gql.mutation({
      operation: 'deleteServiceDomain',
      variables: {
        id: {
          value: id,
          type: 'Int!',
        },
      },
    }),
  });
  return response;
};

export const fetchDataBlockInfo = props => {
  const { dataBlock, sequenceNumber, serviceId } = props;
  const response = axios({
    ...adminApiBaseConfig,
    data: gql.mutation({
      operation: 'serviceDataBlock',
      variables: {
        input: {
          value: {
            serviceId,
            sequenceNumber,
            dataBlockId: dataBlock.id,
            title: dataBlock.title,
            isUserSelectableReferenceSource: false,
            noReferenceSource: false,
            isManualDataBlock: false,
            serviceDataAttributes: dataBlock.childAttributes.map(attr => ({
              parentServiceDataBlockId: 0,
              serviceId,
              sequenceNumber: attr.sequenceNumber,
              dataAttributeId: attr.id,
              isVisible: true,
            })),
          },
          type: 'ServiceDataBlockInputType!',
        },
      },
      fields: [
        'id',
        'sequenceNumber',
        'parentServiceDataBlockId',
        {
          childAttributes: ['id', 'sequenceNumber'],
        },
      ],
    }),
  });
  return response;
};

export const deleteDataBlock = id => {
  const response = axios({
    ...adminApiBaseConfig,
    data: gql.mutation({
      operation: 'deleteServiceDataBlock',
      variables: {
        id: {
          value: id,
          type: 'Int!',
        },
      },
    }),
  });
  return response;
};

export const updateServiceDataBlockHeading = props => {
  const response = axios({
    ...adminApiBaseConfig,
    data: gql.mutation({
      operation: 'serviceDataBlockUpdateProperty',
      variables: {
        input: {
          value: {
            ...props,
            sequenceNumber: 0,
            serviceId: 0,
          },
          type: 'ServiceDataBlockInputType!',
        },
        propertyName: 'heading',
      },
      fields: ['heading'],
    }),
  });
  return response;
};

export const fetchDataBlockReferenceSources = id => {
  const response = axios({
    ...adminApiBaseConfig,
    data: gql.query({
      operation: 'dataBlock',
      variables: {
        id: {
          value: id,
          type: 'Int!',
        },
      },
      fields: [
        'id',
        {
          dataBlockReferenceSources: ['id', 'counterpartyConnectorUniqueId'],
        },
      ],
    }),
  });
  return response;
};

export const deleteCurrentService = id => {
  const response = axios({
    ...adminApiBaseConfig,
    data: gql.mutation({
      operation: 'deleteService',
      variables: {
        id: {
          value: id,
          type: 'Int!',
        },
      },
    }),
  });
  return response;
};

export const updateServiceDataBlock = ({ dataBlockInfo, type }) => {
  const isManualField = type === 'manualField' || type === 'manualFieldEdit';
  const response = axios({
    ...adminApiBaseConfig,
    data: gql.mutation({
      operation: 'serviceDataBlock',
      variables: {
        input: {
          value: {
            ...dataBlockInfo,
            serviceDataAttributes: isManualField
              ? dataBlockInfo.serviceDataAttributes.map(attr => ({
                  id: attr.id,
                  parentServiceDataBlockId: 0,
                  serviceId: 0,
                  title: attr.title,
                  sequenceNumber: attr.sequenceNumber,
                  isManualRadioOption: attr.isManualRadioOption,
                  isManuallyFilled: true,
                  isVisible: true,
                }))
              : null,
          },
          type: 'ServiceDataBlockInputType!',
        },
      },
      fields: [
        'id',
        'sequenceNumber',
        'heading',
        isManualField ? 'title' : '',
        'parentServiceDataBlockId',
      ],
    }),
  });
  return response;
};

export const fetchManualFieldData = id => {
  const response = axios({
    ...adminApiBaseConfig,
    data: gql.query({
      operation: 'serviceDataBlock',
      variables: {
        id: {
          value: id,
          type: 'Int!',
        },
      },
      fields: [
        'title',
        'helpText',
        {
          childAttributes: ['id', 'isManualRadioOption', 'title'],
        },
      ],
    }),
  });
  return response;
};

export const saveDataBlock = payload => {
  const response = axios({
    ...adminApiBaseConfig,
    data: gql.mutation({
      operation: 'serviceDataBlock',
      variables: {
        input: {
          value: {
            ...payload,
          },
          type: 'ServiceDataBlockInputType!',
        },
      },
      fields: ['id', 'dataBlockId'],
    }),
  });
  return response;
};
