import React, { useEffect } from 'react';
import { Button, Carousel, Col, Row, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { Spin, Layout as AntLayout } from 'antd';

import { getUser } from '../store/user';
import { showErrorNotification } from '../utils/showNotification';

import image1 from '../assets/img/pexels-lukas-590037.jpg';
import image2 from '../assets/img/pexels-lukas-590041.jpg';
import image3 from '../assets/img/pexels-lukas-669623.jpg';

const { Title } = Typography;

const Home = () => {
  const { userLoading } = useSelector(state => state.user);
  const dispatch = useDispatch();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const authStatus = urlParams.get('authsuccess');

    const fetchUser = async () => {
      try {
        await dispatch(getUser());
        history.push(`/${language}/kyc/company`);
      } catch (err) {
        showErrorNotification(t('user.error'));
      }
    };

    if (authStatus === '1') {
      fetchUser();
    }
  }, [dispatch, history, language, t]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    const authError = urlParams.get('autherror');

    if (
      ['yes', 'nouserdokobit', 'nouserauth0', 'inactive'].includes(authError)
    ) {
      showErrorNotification(
        `${t('auth.error.' + authError)} ${urlParams.get('incidentguid')}`,
        { duration: 30 }
      );
    }
  }, [t]);

  const handleAuth = () => {
    window.location.assign(
      `clientbackend/auth/authenticate?language=${language}`
    );
  };

  const handleAuthPassword = async () => {
    window.location.assign(
      `clientbackend/auth/authenticatePassword?language=${language}`
    );
  };

  if (userLoading) {
    return (
      <div className="container">
        <Spin size="large" spinning={true}>
          <AntLayout style={{ height: '100vh' }} />
        </Spin>
      </div>
    );
  }

  return (
    <div className="landing-page">
      <div className="logo" />
      <Title level={1}>{t('landing.title')}</Title>
      <div className="carousel">
        <Carousel autoplay autoplaySpeed={5000} dots={false}>
          <div>
            <img alt="" src={image1} />
          </div>
          <div>
            <img alt="" src={image2} />
          </div>
          <div>
            <img alt="" src={image3} />
          </div>
        </Carousel>
        <Row justify="center">
          <Col className="carousel-overlay" xxl={14} xl={16} lg={18}>
            <p>
              <Trans i18nKey="landing.text">
                Due dilligence utility for
                <br />
                Know Your Company and Group,
                <br />
                Know Your Customers and Partners,
                <br />
                share infromation.
              </Trans>
            </p>
            <Button className="btn-darkred" onClick={handleAuth}>
              {t('global.btn.login')}
            </Button>
            <br />
            <Link to="#" onClick={handleAuthPassword} className="text-white">
              {t('global.btn.login.password')}
            </Link>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Home;
