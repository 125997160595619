export const PAGESIZE = 10;
export const DDMMYYYY = 'DD.MM.YYYY';
export const DDMMYYYY_HHmm = 'DD.MM.YYYY HH:mm';
export const YYYYMMDD = 'YYYY-MM-DD';

export const ROUTES_UPDATE_LEGALENTITY = 'update';
export const CounterParty = {
  CI: 3,
  LEXISNEXIS: 4,
  ASIAKASTIETO: 5,
  BVD: 6,
  PP: 7,
  LTU: 8,
  LVA: 9,
  UCAB: 10,
  MANUAL: 113178212, // fictional
};

// maybe this can be fetched from backend, CounterPartyConnector where IsSearchConnector=1
export const SearchConnectorNames = {
  [CounterParty.CI]: 'connector.name.kiCompany',
  [CounterParty.ASIAKASTIETO]: 'connector.name.asiakastietoSearch',
  [CounterParty.BVD]: 'connector.name.bvd.companiesMatch',
  [CounterParty.PP]: 'connector.name.pp.search',
  [CounterParty.LTU]: 'connector.name.LTUBusinessRegistry',
  [CounterParty.LVA]: 'connector.name.creditInfoLV',
  [CounterParty.UCAB]: 'connector.name.UCABSearch',
  [CounterParty.MANUAL]: 'company.data.manuallyEntered',
};

export const LNRequestCategoryIds = {
  PEPS: 1,
  SANCTIONS: 2,
  WATCHLISTS: 3,
};

export const prepareCounterPartiesDependingOnCountry = countyAlpha => {
  let result = [CounterParty.BVD];
  switch (countyAlpha) {
    case 'EST':
      result.push(CounterParty.CI);
      break;
    case 'LVA':
      result.push(CounterParty.LVA);
      break;
    case 'FIN':
      result.push(CounterParty.ASIAKASTIETO);
      result.push(CounterParty.PP);
      break;
    case 'LTU':
      result.push(CounterParty.LTU);
      break;
    case 'SWE':
      result.push(CounterParty.UCAB);
      result.push(CounterParty.PP);
      break;
    default:
  }
  return result;
};

export const LNRequestCategorys = [
  {
    id: LNRequestCategoryIds.PEPS,
    cI_Value: 'PEPs',
  },
  {
    id: LNRequestCategoryIds.SANCTIONS,
    cI_Value: 'Sanctions',
  },
  {
    id: LNRequestCategoryIds.WATCHLISTS,
    cI_Value: 'Watchlists',
  },
];

export const SanctionsList = [
  'entityType',
  'firstName',
  'lastName',
  'fullName',
  'otherNames',
  'additionalInformation',
  'authority',
  'listName',
  'listType',
  'listPublicationDate',
  'whitelist',
];

export const PEPsList = [
  'titles',
  'gender',
  'firstName',
  'lastName',
  'fullName',
  'otherNames',
  'alternativeScripts',
  'additionalInformation',
  'birthDates',
  'birthPlaces',
  'country',
  'functions',
  'specificFunction',
  'category',
  'exPep',
  'notInChargeSinceDate',
  'originCountries',
  '_secondary',
];

export const Watchlists = [
  'entityType',
  'titles',
  'firstName',
  'lastName',
  'fullName',
  'otherNames',
  'alternativeScripts',
  'additionalInformation',
  'birthPlaces',
  'birthDates',
  'country',
  'passportNrs',
  'category',
  'authority',
  'listName',
  'cases',
  'informationDate',
  'noLongerListed',
];

export const relationTypes = {
  parent: 1,
  subsidiary: 2,
  associatedCompany: 3,
  partner: 4,
  customer: 5,
  onboarding: 6,
};

export const relationStatuses = {
  onboarding: 1,
  active: 2,
  offboarded: 3,
  canceled: 4,
};

export const sameRegisterCodeError =
  'Cannot add legalentityrelation where both parent and child have same register code';
export const dublicateEntityInPortfolioError =
  "One legal entity may be actively inserted into a single customer's portfolio only once";

// all PP except for connector.name.pp.orderRealtime
export const offlineConnectors = [
  'connector.name.pp.orderStandard',
  'connector.name.pp.orderFast',
  'connector.name.pp.orderExpress',
];

export const idmStrategyStatus = {
  ok: 0,
  pending: 1,
  failed: 2,
  buildingRequest: 3,
};

export const idmStrategyStatusImportanceOrder = {
  [idmStrategyStatus.ok]: 0,
  [idmStrategyStatus.pending]: 1,
  [idmStrategyStatus.buildingRequest]: 2,
  [idmStrategyStatus.failed]: 3,
};

export const questionnaireStatus = {
  inProgress: 1,
  approved: 2,
  finished: 3,
};

export const attributeIdmName = {
  entityType: 'EntityType',
  typeOfEntity: 'TypeOfEntity',
  typeOfMember: 'TypeOfMember',
};
