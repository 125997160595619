import { useRef } from 'react';

const useIsFirstRender = () => {
  const ref = useRef(null);

  const setRef = value => (ref.current = value);

  return [ref.current, setRef];
};

export default useIsFirstRender;
