const colors = {
  success: '#12C800',
  error: '#EF0000',
  info: '#0D6BFE',
  warning: '#FF7900',
  gray: '#c5c6c7',
  darkergray: '#6d7179',
};

export default colors;
