export const downloadFile = (downloadUrl, backend = 'clientbackend') => {
  const link = downloadUrl.split('/');
  const linkElement = document.createElement('a');

  link.shift();
  linkElement.href = `/${backend}/${link.join('/')}`;
  linkElement.setAttribute('download', '');
  linkElement.style.display = 'none';

  document.body.appendChild(linkElement);
  linkElement.click();
  linkElement.parentNode.removeChild(linkElement);
};
