import axios from 'axios';
import * as gql from 'gql-query-builder';

import { apiBaseConfig } from '../../../utils/apiUtils';

export const isValidCustomer = props => {
  const { customerAccountNumber, regCode, name } = props;
  const response = axios({
    ...apiBaseConfig,
    data: JSON.stringify({
      query: `{isValidCustomer(customerAccountNumber: "${customerAccountNumber}", regCode: "${regCode}", name: "${name}")}`,
    }),
  });
  return response;
};

export const sharedQuestionnaire = props => {
  const response = axios({
    ...apiBaseConfig,
    data: gql.mutation({
      operation: 'sharedQuestionnaire',
      variables: {
        input: {
          value: {
            ...props,
          },
          type: 'SharedQuestionnaireInputType!',
        },
      },
      fields: ['id'],
    }),
  });
  return response;
};
